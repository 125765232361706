import React from 'react'
import { Button, Text } from '@chakra-ui/react'

export interface ScheduleBlockProps {
  time: string
  index: number
  handleSelect: (index: number) => void;
  isSelected: boolean
}

export default function ScheduleBlock({ time, isSelected, index, handleSelect }: ScheduleBlockProps) {
  return (
    <Button
      onClick={() => handleSelect(index)}
      bg={'green'}
      colorScheme={'green'}
      borderRadius={0}
      border={'2px'}
      borderColor={isSelected ? 'black' : 'transparent'}
      minWidth={136}
      py={6}
      px={12}
    >
      <Text
        color={'black'}
        fontWeight={isSelected ? 'bold' : 'normal'}
      >
        {time}
      </Text>
    </Button>
  )
}