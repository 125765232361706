import React from 'react'
import { Text } from '@chakra-ui/react'
import EmptyModal from 'src/components/Modal/ModalVariants/EmptyModal';

export interface RecommendationsModalProps {
  isOpen: boolean
  onClose: () => void
}

export default function RecommendationsModal({ isOpen, onClose }: RecommendationsModalProps) {
  return (<EmptyModal bgColor='white' borderColor='green' isOpen={isOpen} onClose={onClose} rightButtonClick={onClose} rightButtonText='Entendi!'>
    <Text variant='title'>Regras e Recomendações</Text>
    <Text mb={6} variant='subtitle' color='black'>Tudo que você precisa saber para se divertir no Arcade Haus!</Text>

    <Text variant='subtitle' color='black'>Atenção às regras!</Text>
    <Text mb={6} variant='text' color='black'>1. É terminantemente proibida a entrada de bebidas ou alimentos dentro da sala de
      jogos;
      <br />
      2. O consumo de bebidas e alimentos no lounge de espera é controlado - confira a lista
      de restrições no site;
      <br />
      3. É proibido entrar na sala de jogos descalço ou com calçados de salto de qualquer
      tipo;
      <br />
      4. Apenas 4 jogadores podem ficar dentro da sala de jogos a cada partida. Os demais
      participantes devem aguardar sua vez no lounge;
      <br />
      5. É proibido pressionar os botões nas paredes com os pés;
      <br />
      6. Os paineis de LED acima dos botões da parede são apenas para visualização, não
      os pressione;
      <br />
      7. Se a equipe perder um jogo, será possível jogá-lo novamente com as mesmas
      predefinições (dificuldade e jogadores), apertando o botão verde próximo a entrada
      da sala, que acenderá no final da partida;
      <br />
      8. O tablet localizado dentro da sala de jogos não pode ser retirado do local;
      <br />
      9. Após o fim da sua reserva, entregue as pulseiras de identificação na recepção;
      <br />
      10. Pessoas gestantes não podem jogar devido ao intenso exercício físico.</Text>

    <Text variant='subtitle' color='black'>Atenção às regras!</Text>
    <Text variant='text' color='black'>
      ● Para melhor aproveitamento da experiência, indicamos a idade mínima de 8 anos;
      <br />
      ● Os jogos possuem muitos estímulos visuais e auditivos, pessoas com sensibilidade
      podem se sentir desconfortáveis ao jogar;
      <br />
      ● Devido à intensidade das cores, não indicamos que pessoas com epilepsia
      participem dos jogos;
      <br />
      ● Para maior conforto e aproveitamento, indicamos o uso de tênis e roupas esportivas
      durante os jogos;
      <br />
      ● Todos os botões (da parede e do piso) são sensíveis ao contato, evite força
      excessiva;
      <br />
      ● Os jogos do Arcade envolvem agilidade e raciocínio, desenvolva a melhor estratégia
      para concluir as fases;
      <br />
      ● Aguardando no lounge? Que tal já ir selecionando os jogos que serão jogados na
      sequência? Use o tablet para ver, aprender e selecionar os próximos jogos. Basta
      colocá-los na fila.
      <br />
      ● Tenha muito cuidado com aparelhos celulares e câmeras dentro da sala de jogos.
      Não nos responsabilizamos por qualquer dano que possa acontecer aos aparelhos
      devido ao manuseio durante os jogos.
    </Text>
  </EmptyModal>);
}