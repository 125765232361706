import { Center, CenterProps, HStack, Text } from '@chakra-ui/react';
import React from 'react';

export interface StepFilterProps {
  view: 1 | 2 | 3;
  setter: React.Dispatch<React.SetStateAction<1 | 2 | 3>>;
}

export default function StepFilter({
  setter,
  view,
  ...props
}: StepFilterProps & CenterProps) {
  return (
    <HStack>
      <FilterButton
        selected={view === 3}
        label="Mês"
        handleClick={() => setter(3)}
      />
      <FilterButton
        selected={view === 2}
        label="Semana"
        handleClick={() => setter(2)}
      />
      <FilterButton
        selected={view === 1}
        label="Dia"
        handleClick={() => setter(1)}
      />
    </HStack>
  );
}

const FilterButton = ({
  label,
  selected,
  handleClick,
}: {
  label: string;
  selected?: boolean;
  handleClick: () => void;
}) => {
  return (
    <Center
      onClick={handleClick}
      bg={selected ? 'green' : 'white'}
      color={selected ? 'white' : 'black'}
      cursor="pointer"
      p={3}
      _hover={{ outline: '2px solid #B0CA0A' }}
      outlineOffset="-2px"
    >
      <Text fontWeight="bold">{label}</Text>
    </Center>
  );
};
