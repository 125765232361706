import { CityResult } from 'src/api/interfaces/city';
import { AddressResult } from 'src/api/interfaces/location';
import { StateResult } from 'src/api/interfaces/state';
import { ApiRepository, ApiResponse, useFetch } from '../..';

const api = new ApiRepository();

export function useGetStates() {
  const args = {
    method: 'get',
    url: `location/states`,
  };

  const { data, error, isLoading } = useFetch<ApiResponse<StateResult[]>>(
    api,
    args,
    { revalidateOnFocus: false }
  );
  return { data, error, isLoading };
}

export function useGetCitiesByStateID(stateID: number) {
  const args = stateID
    ? {
        method: 'get',
        url: `location/${stateID}`,
      }
    : null;

  const { data, error, isLoading } = useFetch<ApiResponse<CityResult[]>>(
    api,
    args,
    { revalidateOnFocus: false }
  );
  return { data: data?.result, error, isLoading };
}

export function useGetAddress(cep: number) {
  const args = cep
    ? {
        method: 'get',
        url: `location/cep?cep=${cep}`,
      }
    : null;

  const { data, error, isLoading } = useFetch<ApiResponse<AddressResult>>(
    api,
    args,
    { revalidateOnFocus: false }
  );
  return { data: data?.result, error, isLoading };
}

export async function getAddressByCep(cep: number) {
  const res = await api.apiRequestWrapper<ApiResponse<AddressResult>>({
    method: 'get',
    url: `location/cep?cep=${cep}`,
  });
  return res.result;
}
