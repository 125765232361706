import React, { useEffect } from 'react'
import { Box, Input, Select, Stack, Text } from '@chakra-ui/react'
import { useGetEventTypes } from 'src/api/services/event'

export interface EventTypeInputProps {
  value: string
  setter: React.Dispatch<React.SetStateAction<string>>
  setLoading: (loading: boolean) => void
}

const EventTypeInput = ({ value, setter, setLoading }: EventTypeInputProps) => {
  const { data, isLoading } = useGetEventTypes()

  useEffect(() => {
    setLoading(isLoading)
  }, [isLoading])

  return (
    <Stack>
      <Text color='green'>SELECIONE O TIPO DE EVENTO</Text>
      <Select
        boxShadow={0}
        variant="flushed"
        color={'#131313'}
        bg="white"
        _placeholder={{ color: 'gray' }}
        placeholder='Selecione o tipo de evento'
        value={value}
        onChange={(e) => setter(e.target.value)}
        style={{ paddingLeft: '10px' }}
        disabled={isLoading}
      >
        {
          data?.map((t: any, i: number) => <option key={i} value={t.bookingTypeID}>{t.description}</option>)
        }
      </Select>
    </Stack>
  )
}

export default EventTypeInput