import React, { ReactNode } from 'react';
import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  Icon,
  useColorModeValue,
  Link,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Image,
  Stack,
} from '@chakra-ui/react';
import { FiMenu, FiChevronDown, FiBell } from 'react-icons/fi';
import { IconType } from 'react-icons';
import Logo from 'src/assets/Logo-office.png';
import { adminMenuItems } from './routes';
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom';
import { NavItem } from '../NavItem';
import { useAppSelector } from 'src/redux/hooks';
import { HiOutlineSquares2X2 } from 'react-icons/hi2';
import { IoExit, IoExitOutline, IoHomeOutline } from 'react-icons/io5';
import { ROLES } from 'src/redux/user';
import AdminNotifications from 'src/components/AdminNotifications';

export default function AdminNavBar({ children }: { children: ReactNode }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isNotificationsOpen, onToggle } = useDisclosure()
  return (

    <Flex flex={1} direction="row">
      <AdminNotifications isOpen={isNotificationsOpen} />
      <SidebarContent
        onClose={() => onClose}
        display={{ base: 'none', md: 'flex' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <Flex flex={1} direction="column">
        <MobileNav onOpen={onOpen} toggleNotifications={onToggle} isNotificationsOpen={isNotificationsOpen} />
        {children}
      </Flex>
    </Flex>

  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {


  return (
    <Flex
      transition="3s ease"
      bg={useColorModeValue('black', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('white', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      h="full"
      direction="column"
      {...rest}
    >
      <Flex h="20" alignItems="center" px={8} justifyContent="space-between">
        <Image width="100%" src={Logo} alt="Logo" px={4} py={4} />
        <CloseButton
          display={{ base: 'flex', md: 'none' }}
          color="white"
          onClick={onClose}
        />
      </Flex>
      {adminMenuItems.map((link) => (
        <NavItem key={link.name} icon={link.icon} url={link.url}>
          {link.name}
        </NavItem>
      ))}
    </Flex>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
  toggleNotifications: () => void;
  isNotificationsOpen: boolean
}
const MobileNav = ({ onOpen, toggleNotifications, isNotificationsOpen, ...rest }: MobileProps) => {
  const { user } = useAppSelector((state) => state.user);
  const userIsAdmin = user?.roles?.includes(ROLES.ADMIN);

  return (
    <Flex
      flex={0.1}
      minH="81px"
      w="full"
      px={4}
      alignItems="center"
      bg={useColorModeValue('black', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('white', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}
    >
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        color="white"
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Image display={{ base: 'flex', md: 'none' }} w='100%' maxW='150px' src={Logo} px={4} py={4} />

      <HStack spacing={{ base: '0', md: '6' }}>
        <IconButton
          color={isNotificationsOpen ? 'white' : 'green'}
          size="lg"
          variant="ghost"
          aria-label="open menu"
          icon={<FiBell />}
          onClick={toggleNotifications}
        />
        <Flex alignItems={'center'}>
          <Stack direction="row" align="center" spacing={6}>
            <Text color="white" fontWeight={'bold'}>
              {user?.name}
            </Text>
            <Avatar
              name={user?.name}
              border="2px"
              color="green"
              src={user?.picture}
            />
            <Menu>
              <MenuButton
                py={2}
                px={4}
                transition="all 0.3s"
                display={{ base: 'none', md: 'inline-flex' }}
                bg={'transparent'}
                rounded={'base'}
                _focus={{ boxShadow: 'none' }}
                _hover={{
                  bg: 'green',
                }}
              >
                <HiOutlineSquares2X2 color="white" size="24" />
              </MenuButton>
              <MenuList
                bg="black"
                borderLeft="1px"
                borderColor={'white'}
                py={6}
              >
                <NavItem
                  icon={IoHomeOutline}
                  url={'/'}
                  py={2}
                  px={4}
                  fontSize={'sm'}
                >
                  Ir para Site
                </NavItem>
                <NavItem
                  icon={IoExitOutline}
                  url={'/logout'}
                  py={2}
                  px={4}
                  fontSize={'sm'}
                >
                  Sair da Conta
                </NavItem>
              </MenuList>
            </Menu>
          </Stack>
        </Flex>
      </HStack>
    </Flex>
  );
};
