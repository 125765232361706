import React from 'react';
import { CalendarIcon, TimeIcon } from '@chakra-ui/icons';
import {
  Box,
  HStack,
  Icon,
  Spacer,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { FaEdit, FaRegWindowClose, FaTicketAlt } from 'react-icons/fa';
import { FiUser, FiBox, FiPlay } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { BookingResult } from 'src/api/interfaces/booking';
import dayjs from 'dayjs';
import { ErrorModal } from 'src/components/Modal/ModalVariants/ErrorModal';
import { cancelBooking } from 'src/api/services/booking';
import usePHToast from 'src/hooks/useToast';
import { BsAlarm } from 'react-icons/bs';
import { PophausModal } from 'src/components/Modal';
import { CouponResult } from 'src/api/interfaces/coupon';

export const useAdminCouponsColumns = () => {
  const columnHelper = createColumnHelper<CouponResult>();

  const columns = [
    columnHelper.accessor('description', {
      cell: (info) => <Text variant="text">{info.getValue()}</Text>,
      header: () => <span style={{ color: 'white' }}>CÓDIGO</span>,
    }),
    columnHelper.accessor('created', {
      cell: (info) => (
        <Text variant="text">
          {dayjs(info.getValue()).format('DD/MM/YYYY')}
        </Text>
      ),
      header: () => <span style={{ color: 'white' }}>CRIADO EM</span>,
    }),
    columnHelper.accessor('lifeSpanStartDate', {
      cell: (info) => <Text variant="text">{info.getValue()}</Text>,
      header: () => <span style={{ color: 'white' }}>DATA INICIAL</span>,
    }),
    columnHelper.accessor('lifeSpanEndDate', {
      cell: (info) => <Text variant="text">{info.getValue()}</Text>,
      header: () => <span style={{ color: 'white' }}>DATA FINAL</span>,
    }),
    columnHelper.accessor('businessUnit', {
      cell: (info) => <Text variant="text">{info.getValue()}</Text>,
      header: () => <span style={{ color: 'white' }}>UNIDADE</span>,
    }),
    columnHelper.accessor('isActive', {
      cell: (info) => (
        <Text variant="text">{info.getValue() ? 'ATIVO' : 'DESATIVADO'}</Text>
      ),
      header: () => <span style={{ color: 'white' }}>STATUS</span>,
    }),
    columnHelper.accessor('usage', {
      cell: (info) => <Text variant="text">{`${info.getValue()} vezes`}</Text>,
      header: () => <span style={{ color: 'white' }}>USADO</span>,
    }),
    columnHelper.display({
      id: 'actions',
      cell: (info) => {
        const navigate = useNavigate();
        const couponID = info.row.original.couponID;

        return (
          <HStack spacing={4} pl={6}>
            <Spacer borderRight="2px dashed #B0CA0A" />
            <Box
              justifyContent="center"
              as="button"
              _hover={{ color: '#B0CA0A' }}
              onClick={() => {

                navigate(`/admin/cupons/editar/${couponID}`);
              }}
            >
              <Icon as={FaEdit} w="20px" h="20px" />
              <Text>Editar</Text>
            </Box>
          </HStack>
        );
      },
    }),
  ];

  return columns;
};
