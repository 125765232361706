import { ApiRepository, ApiResponse, useFetch } from '../..';
import { ReceiptResult } from 'src/api/interfaces/receipt';

const api = new ApiRepository();

export function useGetReceiptByBookingID(bookingID?: string) {
  const args = bookingID
    ? {
        method: 'get',
        url: `Receipt?BookingID=${bookingID}`,
      }
    : null;

  const { data, error, isLoading, mutate } = useFetch<
    ApiResponse<ReceiptResult>
  >(api, args, { revalidateOnFocus: false });
  return { data: data?.result, error, isLoading, mutate };
}
