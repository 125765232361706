import React, { useEffect, useRef } from 'react'
import { HStack, Icon, Input, Button, Text, Stack, useTimeout, Box } from "@chakra-ui/react";
import { BsXCircle } from "react-icons/bs";
import { BookingResult } from "src/api/interfaces/booking";
import Form from 'src/components/Form/Form';
import { FormInput } from 'src/components/Form/Input';
import { UseFormReturn } from 'react-hook-form';
import { LoginFormValues } from 'src/pages/login';
import { CouponFormValues } from '..';

export interface CouponHandlerProps {
  data: BookingResult;
  handleRemoveCoupon: () => Promise<void>;
  loading: boolean;
  rhfForm: UseFormReturn<CouponFormValues, any>;
  handleCouponSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}

const EventCouponHandler = ({
  data,
  handleRemoveCoupon,
  loading,
  handleCouponSubmit,
  rhfForm
}: CouponHandlerProps) => {
  const {
    register,
    formState: { errors },
  } = rhfForm;

  return (
    <Form onSubmit={handleCouponSubmit}>
      <Stack>
        <Text color="green">CUPOM DE DESCONTO </Text>
        {data.coupon ? (
          <HStack>
            <Text color="red">Cupom {data.coupon.description} aplicado</Text>
            <Icon
              as={BsXCircle}
              color="red"
              cursor="pointer"
              onClick={handleRemoveCoupon}
            />
          </HStack>
        ) : (
          <HStack>

            <FormInput

              rhfRegister={{
                ...register('coupon'),
              }}
              errorState={errors.coupon}
              inputProps={{ placeholder: 'CUPOM', w: 'full' }}
            />


            <Button
              px={6}
              variant={'pophausOutline'}
              type="submit"
              isLoading={loading}
            >
              OK
            </Button>
          </HStack>
        )}
      </Stack>

    </Form>
  );
};

export default EventCouponHandler