import {
  ChargeCardOneShootRequest,
  ChargeStatusResult,
  ChargePixRequest,
  LocalPaymentRequest,
  InstallmentsResult,
} from 'src/api/interfaces/charge';
import { ApiRepository, ApiResponse, useFetch } from '../..';

const api = new ApiRepository();

export async function chargeCardOneShoot(data: ChargeCardOneShootRequest) {
  const res = await api.apiRequestWrapper<ApiResponse<ChargeStatusResult>>({
    method: 'post',
    url: 'payment/cardoneshoot',
    data: data,
  });
  return res;
}

export async function LocalPayment(data: LocalPaymentRequest) {
  const res = await api.apiRequestWrapper<ApiResponse<ChargeStatusResult>>({
    method: 'post',
    url: 'payment/localpayment',
    data: data,
  });
  return res;
}

export async function onlineCourtesy(data: LocalPaymentRequest) {
  const res = await api.apiRequestWrapper<ApiResponse<ChargeStatusResult>>({
    method: 'post',
    url: 'payment/OnlineCourtesy',
    data: data,
  });
  return res;
}

export async function chargePix(data: ChargePixRequest) {
  const res = await api.apiRequestWrapper<ApiResponse<ChargeStatusResult>>({
    method: 'post',
    url: 'payment/pix',
    data: data,
  });
  return res;
}

export function useGetBookingInstallments(
  BookingID?: string,
  PaymentMethodID?: number
) {
  const args =
    BookingID && PaymentMethodID
      ? {
          method: 'get',
          url: `payment/CalculateInstallment`,
          params: { BookingID, PaymentMethodID },
        }
      : null;

  const { data, error, isLoading } = useFetch<ApiResponse<InstallmentsResult>>(
    api,
    args,
    { revalidateOnFocus: false }
  );
  return { data: data?.result, error, isLoading };
}

export async function getBookingTransactionsByBookingID(bookingID?: string) {
  const res = await api.apiRequestWrapper<ApiResponse<ChargeStatusResult[]>>({
    method: 'get',
    url: `payment/BookingTransactions`,
    params: {
      bookingID,
    },
  });
  return res.result;
}
