import {
  Button,
  Stack,
  Text,
  Icon,
  Link,
  Box,
  HStack,
  Spacer,
} from '@chakra-ui/react';
import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import DashedContainer from 'src/components/Container/DashedContainer';
import { useAppSelector } from 'src/redux/hooks';
import { IoTrophyOutline } from 'react-icons/io5';
import { useGetUserBookingHistory } from 'src/api/services/booking';
import { BookingResult } from 'src/api/interfaces/booking';
import dayjs from 'dayjs';
import { formatCurrency } from 'src/utils/format';

const HistoryItemText = ({ children }: any) => {
  return (
    <Text
      color="white"
      fontSize="14px"
      fontWeight={700}
      textTransform="uppercase"
    >
      {children}
    </Text>
  );
};

interface HistoryItemProps {
  booking: BookingResult;
}

const HistoryItem = (props: HistoryItemProps) => {
  const { booking } = props;
  return (
    <Stack direction="row" w="full" justify="space-between">
      <Stack direction="row">
        <HistoryItemText>
          {dayjs(booking.startDate).format('DD/MM')}
        </HistoryItemText>
        <HistoryItemText>{booking.bookingStatus?.description}</HistoryItemText>
      </Stack>
      <HistoryItemText>{formatCurrency(booking.total)}</HistoryItemText>
    </Stack>
  );
};

export const HistoryOverview = () => {
  const { user } = useAppSelector((state) => state.user);
  const { data: bookingHistory } = useGetUserBookingHistory(user?.userID);
  return (
    <DashedContainer h="full">
      <Stack direction="column" w="full" align="center" spacing={4}>
        <Stack
          direction="row"
          w="full"
          align="center"
          justify="space-between"
          px={4}
        >
          <Text variant="title">Histórico</Text>
          <Link
            as={ReactRouterLink}
            to="/jogador/historico"
            fontSize={'12px'}
            fontWeight={700}
            color="white"
          >
            {'VER TODOS >'}
          </Link>
        </Stack>
        <HStack w="full" gap={1}>
          <Text variant="subtitle">Data</Text>
          <Text variant="subtitle">Status</Text>
          <Spacer />
          <Text variant="subtitle">Valor</Text>
        </HStack>
        {bookingHistory
          ?.slice(0, 10)
          .reverse()
          .map((b) => (
            <HistoryItem key={b.bookingID} booking={b} />
          ))}
      </Stack>
    </DashedContainer>
  );
};
