import React, { ReactNode, useEffect } from 'react';
import {
  Button,
  Flex,
  HStack,
  Slide,
  Stack,
  StackProps,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import PopHausBackground from 'src/assets/Background.png';
import NavBar from '../NavBar';
import { useAppSelector } from 'src/redux/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { PophausBreadcrumb, breadcrumbItems } from 'src/components/Breadcrumb';
import Footer from 'src/components/Footer';
import AdminNavBar from '../AdminNavBar';
import { NavItem } from '../NavItem';
import { PophausTimer } from 'src/components/Timer';
import { ROLES } from 'src/redux/user';
import { useGetBookingByID } from 'src/api/services/booking';

interface PageContainerProps {
  restricted?: boolean;
  children: ReactNode | ReactNode[];
  presentGoBack?: boolean;
  overrideGoBack?: () => void;
  containerProps?: StackProps;
}

const PageContainer = ({
  children,
  restricted,
  presentGoBack,
  overrideGoBack,
  containerProps,
}: PageContainerProps) => {
  const { user } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();

  const breadcrumbRoutes = Object.keys(breadcrumbItems);
  const isAdmin = location.pathname.startsWith('/admin');
  const userIsAdmin = user?.roles?.includes(ROLES.ADMIN);
  const { bookingId } = useAppSelector((state) => state.booking.booking);

  useEffect(() => {
    if (isAdmin && !userIsAdmin) {
      navigate('/login');
    }
    if (restricted && !user) {
      navigate('/login');
    }
  }, [restricted, user]);

  const AppContent = () => {
    return (
      <VStack
        w={'full'}
        // minH={'100%'}
        flex={1}
        px={{ base: 0, md: '10%' }}
        pt={'16px'}
        pb={8}
        align={'center'}
        backgroundImage={`url(${PopHausBackground})`}
        // justify={'space-between'}
        backgroundColor="#131313"
        {...containerProps}
      >
        {presentGoBack && (
          <HStack w={'full'} justify={'flex-start'} align={'center'}>
            <Button
              backgroundColor={'transparent'}
              colorScheme={'white'}
              onClick={overrideGoBack ? overrideGoBack : () => navigate(-1)}
              pl={0}
            >
              <ArrowBackIcon style={{ color: 'white', marginRight: 6 }} />
              voltar
            </Button>
          </HStack>
        )}
        {children}
      </VStack>
    );
  };

  const showNotPaymentPopTimer =
    (location.pathname.startsWith('/login') ||
      location.pathname.startsWith('/ingressos/resumo')) &&
    bookingId;

  const showPaymentPopTimer =
    location.pathname.startsWith('/ingressos/pagamento') ||
    location.pathname.startsWith('/eventos/resumo');

  const showPlayersPopTimer = location.pathname.startsWith(
    '/ingressos/jogadores'
  );

  const showPopTimer =
    showNotPaymentPopTimer || showPaymentPopTimer || showPlayersPopTimer;

  return (
    <Flex direction="column" h="100%">
      {isAdmin ? (
        <AdminNavBar>
          <AppContent />
        </AdminNavBar>
      ) : (
        <>
          <NavBar />
          {breadcrumbRoutes.includes(location.pathname) && (
            <PophausBreadcrumb />
          )}
          {showPopTimer && (
            <PophausTimer
              payment={showPaymentPopTimer}
              playersIdentification={showPlayersPopTimer}
            />
          )}
          <AppContent />
          <Footer />
        </>
      )}
    </Flex>
  );
};

export default PageContainer;
