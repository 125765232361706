import React, { useCallback, useEffect, useState } from 'react';
import PageContainer from 'src/components/Container/PageContainer';
import { useNavigate, useParams } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { useGetMediaById } from 'src/api/services/medias';
import VideoBox from 'src/components/VideoBox';
import { AspectRatio, Box, Text, useMediaQuery } from '@chakra-ui/react';
import { Loading } from 'src/components/Loading';

export default function MediaShareView() {
  const { mediaID } = useParams<{ mediaID: string }>();
  const { data, isLoading } = useGetMediaById(mediaID)
  const [largerThan500] = useMediaQuery('(min-width: 500px)')

  return (
    <PageContainer>
      {isLoading && <Loading />}
      {!isLoading && data &&
        <Box mt={6} w='full' maxW='80%' justifyContent='center' alignItems='center'>
          <Box position="relative" maxH={'none'} maxW={'none'} w={'full'}>
            <AspectRatio ratio={largerThan500 ? 16 / 9 : 9 / 16} maxH={'none'} maxW={'none'} w="full" zIndex={1}>
              <ReactPlayer controls url={data.url} height='inherit' width='inherit' />
            </AspectRatio>
            <Box
              bg="transparent"
              w="100%"
              h="100%"
              p={4}
              position="absolute"
              top="-15px"
              left="15px"
              border='2px solid white'
            />
          </Box>
        </Box>
      }
      {!isLoading && !data && <Text color='red'>opss... parece que não encontramos sua midia</Text>}
    </PageContainer >
  );
}
