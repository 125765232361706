import {
  EventFormRequest,
  EventFormResult,
  EventRequest,
  EventResult,
  GetFormEventsRequest,
  GetFormEventsResult,
} from 'src/api/interfaces/event';
import { ApiRepository, ApiResponse, useFetch } from '../..';
import { BookingResult } from 'src/api/interfaces/booking';

const api = new ApiRepository();

export async function getEventFilter(
  date?: string,
  businessUnitID?: string,
  name?: string
) {
  const res = await api.apiRequestWrapper<ApiResponse<BookingResult[]>>({
    method: 'get',
    url: `Event/Filter?Date=${date}&Name=${name}&BusinessUnitID=${businessUnitID}`,
  });
  return res.result;
}

export const getScheduleForEvents = async (
  businessUnitID: string,
  date: string,
  duration: string
) => {
  const res = await api.apiRequestWrapper<ApiResponse<EventResult[]>>({
    method: 'get',
    url: `Event/GetSchedulesForEvents?BusinessUnitID=${businessUnitID}&Date=${date}&Duration=${duration}`,
  });
  return res.result;
};

export const getReschedulesForEvent = async (
  date: string,
  bookingId: string
) => {
  const res = await api.apiRequestWrapper<ApiResponse<EventResult[]>>({
    method: 'get',
    url: `Event/GetSchedulesForEventByID?Date=${date}&BookingID=${bookingId}`,
  });
  return res.result;
};

export const postCreateEvent = async (data: EventRequest) => {
  const res = await api.apiRequestWrapper<ApiResponse<BookingResult>>({
    method: 'post',
    url: 'Event',
    data,
  });
  return res.result;
};

export const useGetEventTypes = () => {
  const args = {
    method: 'get',
    url: `Event/GetEventTypes`,
  };

  const { data, error, isLoading, mutate } = useFetch<ApiResponse<any>>(
    api,
    args,
    { revalidateOnFocus: false }
  );
  return { data: data?.result, error, isLoading, mutate };
};

export const putUpdateEventType = async (bookingID: string, typeID: string) => {
  const res = await api.apiRequestWrapper<ApiResponse<BookingResult>>({
    method: 'put',
    url: `Event/UpdateEventType?BookingID=${bookingID}&TypeID=${typeID}`,
  });
  return res.result;
};

export const putUpdateEvent = async (bookingId: string, data: EventRequest) => {
  const res = await api.apiRequestWrapper<ApiResponse<BookingResult>>({
    method: 'put',
    url: `Event?BookingID=${bookingId}`,
    data,
  });
  return res.result;
};

export async function cancelEvent(eventID: string) {
  const res = await api.apiRequestWrapper<ApiResponse<BookingResult>>({
    method: 'put',
    url: `Event/CancelEvent?BookingID=${eventID}`,
  });
  return res.result;
}

export const postCreateEventForm = async (data: EventFormRequest) => {
  const res = await api.apiRequestWrapper<ApiResponse<EventFormResult>>({
    method: 'post',
    url: 'FormEvent',
    data,
  });
  return res.result;
};

export const putAddBookingToEventForm = async (
  bookingId: string,
  eventFormId: number
) => {
  const res = await api.apiRequestWrapper<ApiResponse<any>>({
    method: 'put',
    url: `FormEvent/AddBookingToFormEvent?FormEventID=${eventFormId}&BookingID=${bookingId}`,
  });
  return res.result;
};

export const getFormEvents = async (data: GetFormEventsRequest) => {
  const res = await api.apiRequestWrapper<ApiResponse<GetFormEventsResult>>({
    method: 'get',
    url: `FormEvent/GetFormEvents?Page=${data.page}&Limit=${data.limit}`,
    data,
  });
  return res.result;
};

export const updateContacted = async (
  FormEventID: number,
  contacted: boolean
) => {
  const res = await api.apiRequestWrapper<ApiResponse<EventFormResult>>({
    method: 'put',
    url: `FormEvent/UpdateContacted/${FormEventID}`,
    data: { contacted },
  });
  return res.result;
};
