import React from 'react';
import { Breadcrumb, BreadcrumbItem, Flex, Text } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

export const breadcrumbItems: Record<string, string> = {
  '/ingressos': 'SELECIONE OS INGRESSOS',
  '/ingressos/resumo': 'RESUMO DA COMPRA',
  '/ingressos/pagamento': 'PAGAMENTO',
  '/ingressos/confirmacao': 'CONFIRMAÇÃO',
};

export const PophausBreadcrumb = () => {
  const location = useLocation();
  const pathIndex = Object.keys(breadcrumbItems).findIndex(
    (k) => k === location.pathname
  );

  return (
    <Flex
      w="full"
      bg="green"
      justify="center"
      py={2}
      display={{ base: 'none', md: 'flex' }}
    >
      <Breadcrumb separator={'>'}>
        {Object.entries(breadcrumbItems).map(([k, v], idx) => (
          <BreadcrumbItem key={k}>
            <Text
              fontSize={'xs'}
              color="black"
              fontWeight={idx <= pathIndex ? 700 : 500}
            >
              {v}
            </Text>
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
    </Flex>
  );
};
