import { Stack, Text, Icon, Link } from '@chakra-ui/react';
import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import DashedContainer from 'src/components/Container/DashedContainer';
import { FaRegCalendar } from 'react-icons/fa';
import dayjs from 'dayjs';
import { useAppSelector } from 'src/redux/hooks';
import { useGetUserActiveBooking } from 'src/api/services/booking';
import { BookingResult } from 'src/api/interfaces/booking';
import { BookingRoomResult } from 'src/api/interfaces/booking';
import { useGetRoom } from 'src/api/services/room';
import { BusinessUnitResult } from 'src/api/interfaces/businessUnit';
import { getBusinessUnit } from 'src/api/services/businessUnit';

interface ActiveTicketsItemProps {
  booking: BookingResult;
}

const ActiveTicketItem = (props: ActiveTicketsItemProps) => {
  const { booking } = props;

  return (
    <Stack
      direction="row"
      w="full"
      align="center"
      justify="flex-start"
      spacing={4}
    >
      <Icon as={FaRegCalendar} color="green" boxSize={6} />
      <Stack direction="column">
        <Text variant="highlighted">{`${dayjs(booking.startDate).format(
          'DD/MM/YYYY'
        )} das ${booking.bookingRooms.length > 0
          ? dayjs(booking.bookingRooms[booking.bookingRooms.length - 1].startTime).format('HH:mm')
          : '-'
          }
        às ${booking.bookingRooms.length > 0
            ? dayjs(booking.bookingRooms[booking.bookingRooms.length - 1].endTime).format('HH:mm')
            : ''
          }`}</Text>
        <Text variant="highlighted">
          {booking.bookingRooms.length > 0
            ? booking.bookingRooms[booking.bookingRooms.length - 1].room?.businessUnit.description
            : '-'}
        </Text>
      </Stack>
    </Stack>
  );
};

export const ActiveTicketsOverview = () => {
  const { user } = useAppSelector((state) => state.user);
  const { data: activeBooking } = useGetUserActiveBooking(user?.userID);
  //const { data: activeBookingRooms } = useGetRoom();
  const activeTickets = [
    {
      id: 1,
      date: new Date(),
      unit: 'Tatuapé',
      startTime: '10:00',
      endTime: '11:00',
    },
  ];
  return (
    <DashedContainer>
      <Stack direction="column" w="full" align="center" spacing={4}>
        <Stack
          direction="row"
          w="full"
          align="center"
          justify="space-between"
          px={4}
        >
          <Text variant="title">Reservas Ativas</Text>
          <Link
            as={ReactRouterLink}
            to="/jogador/ingressos-ativos"
            fontSize={'12px'}
            fontWeight={700}
            color="white"
          >
            {'VER TODOS >'}
          </Link>
        </Stack>
        {activeBooking?.map((b) => (
          <ActiveTicketItem key={b.bookingID} booking={b} />
        ))}
      </Stack>
    </DashedContainer>
  );
};
