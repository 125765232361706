import React from 'react';
import { UseToastOptions, useToast } from '@chakra-ui/react';

export default function usePHToast() {
  const chakraToast = useToast();

  const toast = ({ ...options }: UseToastOptions) => {
    return chakraToast({
      isClosable: true,
      status: 'info',
      position: 'top',
      ...options,
    });
  };

  return toast;
}
