import React, { useState } from 'react';
import {
  Button,
  FlexProps,
  HStack,
  Icon,
  Input,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import DashedContainer from '../Container/DashedContainer';
import { BookingResult } from 'src/api/interfaces/booking';
import { formatCurrency } from 'src/utils/format';
import { removeCoupon, validateCoupon } from 'src/api/services/coupon';
import usePHToast from 'src/hooks/useToast';
import { ApiResponse } from 'src/api';
import { KeyedMutator } from 'swr';
import { BsXCircle } from 'react-icons/bs';
import { updateBookingAmount } from 'src/api/services/booking';
import CouponHandler from './CouponHandler';

interface SummaryProps {
  data: BookingResult;
  hasCoupon?: boolean;
  mutate?: KeyedMutator<ApiResponse<BookingResult>>;
  isAdmin?: boolean;
  isRebuy?: boolean;
}

export const Summary = ({
  data,
  hasCoupon = false,
  isAdmin = false,
  mutate,
  isRebuy = false,
  ...props
}: SummaryProps & FlexProps) => {
  const room = data?.bookingRooms?.[0];
  const perPerson = data ? data.total / data.tickets : 0;
  const [coupon, setCoupon] = useState('');
  const [loading, setLoading] = useState(false);
  const [priceEditing, setPriceEditing] = useState(false);
  const [totalPrice, setTotalPrice] = useState(
    isRebuy ? data.additionalTotal : data.total
  );
  const [isUpdateAmountOnRequest, setIsUpdateAmountOnRequest] = useState(false);
  const toast = usePHToast();
  const recommendationsLink =
    'https://arcadehausstorage.blob.core.windows.net/documentos-site/Regras_e_Recomendacoes_-_Geral_-_Arcade_Haus.pdf';
  const refoundLink =
    'https://drive.google.com/file/d/1ugyaPogRkYSVrybz-CcDklyZfmndynI2/view';

  const handleCouponApply = async () => {
    try {
      setLoading(true);
      if (data.coupon != null) await removeCoupon(data.bookingID);

      const res = await validateCoupon(coupon, data.bookingID, data.userID);
      if (mutate) await mutate();
      if (res.available) toast({ status: 'success', title: 'Cupom aplicado' });
      if (!res.available)
        toast({ status: 'error', title: 'Cupom indisponível' });
    } catch (err: any) {
      console.log({ err });

      if (err?.response?.data?.errors && err?.response?.data?.errors[0]) {
        for (const error of err.response.data.errors) {
          toast({ status: 'error', title: error });
        }
      } else toast({ status: 'error', title: err.message });
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveCoupon = async () => {
    try {
      setLoading(true);
      const res = await removeCoupon(data.bookingID);
      if (mutate) await mutate();
      toast({ status: 'success', title: 'Cupom removido' });
    } catch (err: any) {
      toast({ status: 'error', title: err.message });
    } finally {
      setLoading(false);
    }
  };

  const handlePriceEditing = async () => {
    if (data.total === totalPrice) return setPriceEditing(false);

    try {
      setIsUpdateAmountOnRequest(true);
      await updateBookingAmount(data.bookingID, totalPrice);
      toast({
        status: 'success',
        title: 'Valor da reserva alterado com sucesso',
      });
      setPriceEditing(false);
    } catch (e: any) {
      toast({ status: 'error', title: e.message });
    } finally {
      setIsUpdateAmountOnRequest(false);
    }
  };

  return (
    <DashedContainer p={8} h="full" alignItems={'flex-start'} {...props}>
      <VStack alignItems={'flex-start'} py={5}>
        <SummaryText
          title={'HORÁRIO:'}
          value={`${dayjs(room?.startTime).format('HH:mm')} ÀS ${dayjs(
            room?.endTime
          ).format('HH:mm')}`}
        />
        <SummaryText
          title={'DATA:'}
          value={dayjs(data?.startDate).format('DD/MM/YYYY')}
        />
        <SummaryText title={'DURAÇÃO:'} value={`${data?.durationFormatted}`} />
        <SummaryText
          title={'INGRESSOS:'}
          value={`${isRebuy ? data?.additionalTickets : data?.tickets}`}
        />
        <SummaryText
          title={'PREÇO POR PESSOA:'}
          value={`${formatCurrency(perPerson)}`}
        />

        {isAdmin ? (
          <>
            {priceEditing ? (
              <HStack>
                <Text color="green">SUBTOTAL:</Text>
                <Input
                  type="number"
                  px={2}
                  boxShadow={0}
                  variant="flushed"
                  color={'#131313'}
                  bg="white"
                  _placeholder={{ color: 'gray' }}
                  value={totalPrice}
                  onChange={(e) => setTotalPrice(Number(e.target.value))}
                />
                <Button
                  isLoading={isUpdateAmountOnRequest}
                  onClick={handlePriceEditing}
                  paddingInline={5}
                  variant="pophausOutline"
                >
                  OK
                </Button>
              </HStack>
            ) : (
              <HStack>
                <SummaryText
                  title={'SUBTOTAL:'}
                  value={`${formatCurrency(totalPrice)}`}
                />
                <Button onClick={() => setPriceEditing(true)}>Editar</Button>
              </HStack>
            )}
          </>
        ) : (
          <SummaryText
            title={'SUBTOTAL:'}
            value={`${formatCurrency(totalPrice)}`}
          />
        )}

        {hasCoupon && (
          <CouponHandler
            coupon={coupon}
            data={data}
            handleCouponApply={handleCouponApply}
            handleRemoveCoupon={handleRemoveCoupon}
            loading={loading}
            setCoupon={setCoupon}
          />
        )}
      </VStack>
      <Text color="grey" fontSize={14} fontStyle="italic">
        <Text textDecoration="underline" display="inline">
          Atenção:
        </Text>
        {` `}Por ora, todos nossos jogos são colaborativos e jogam{` `}
        <Text color="green" display="inline">
          até 4 jogadores por partida!
        </Text>
      </Text>
      <Text color="grey" fontSize={14} fontStyle="italic">
        Clique aqui para consultar:{` `}
        <Link target="_blank" href={recommendationsLink} color="green">
          Regras e Recomendações
        </Link>
        {` e `}
        <Link target="_blank" href={refoundLink} color="green">
          Políticas de Reagendamento e Reembolso
        </Link>
      </Text>
    </DashedContainer>
  );
};

const SummaryText = ({ title, value }: { title: string; value: string }) => {
  return (
    <HStack>
      <Text color="green">{title}</Text>
      <Text color="white">{value}</Text>
    </HStack>
  );
};
