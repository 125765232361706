import { ApiRepository, ApiResponse, useFetch } from 'src/api';
import { BusinessUnitResult } from 'src/api/interfaces/businessUnit';

const api = new ApiRepository();

export async function createBooking(BusinessUnitRequest: string) {
  const res = await api.apiRequestWrapper<BusinessUnitResult>({
    method: 'post',
    url: 'BusinessUnit',
    data: { BusinessUnitRequest },
  });
  return res;
}

export async function getBusinessUnit(businessUnitID?: number) {
  const res = await api.apiRequestWrapper<ApiResponse<BusinessUnitResult>>({
    method: 'get',
    url: `BusinessUnit/${businessUnitID}`,
  });
  return res.result;
}

export function useGetBusinessUnit(BusinessUnitID?: number) {
  const args = BusinessUnitID
    ? {
        method: 'get',
        url: `BusinessUnit/${BusinessUnitID}`,
      }
    : null;
  const { data, error, isLoading } = useFetch<ApiResponse<BusinessUnitResult>>(
    api,
    args,
    { revalidateOnFocus: false }
  );
  return { data: data?.result, error, isLoading };
}

export function useGetBusinessUnits() {
  const args = {
    method: 'get',
    url: `BusinessUnit`,
  };

  const { data, error, isLoading, mutate } = useFetch<
    ApiResponse<BusinessUnitResult[]>
  >(api, args, { revalidateOnFocus: false });
  return { data: data?.result, error, isLoading, mutate };
}

export function useGetBusinessUnitRooms() {
  const companyId = 1;
  const args = companyId
    ? {
        method: 'get',
        url: `BusinessUnit/ListBusinessUnitsByCompanyID?CompanyID=${1}`,
      }
    : null;

  const { data, error, isLoading } = useFetch<
    ApiResponse<BusinessUnitResult[]>
  >(api, args, { revalidateOnFocus: false });
  return { data: data?.result, error, isLoading };
}
