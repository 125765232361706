import React from 'react';
import {
  Link as ChakraLink,
  useColorModeValue,
  LinkProps as ChakraLinkProps,
} from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';

interface CustomLinkProps extends ChakraLinkProps {
  to?: string;
  href?: string;
  isExternal?: boolean;
  label: string;
  color?: string;
}

const CustomLink: React.FC<CustomLinkProps> = ({
  to,
  href,
  isExternal,
  label,
  color,
  ...props
}) => {
  const linkColor = useColorModeValue(color || 'gray', 'gray.200');
  const linkHoverColor = useColorModeValue('green', 'white');

  const commonLinkStyles = {
    p: 2,
    fontSize: 'lg',
    fontWeight: 'semibold',
    color: linkColor,
    _hover: {
      textDecoration: 'none',
      color: linkHoverColor,
    },
    align: 'center',
    justify: 'center',
    whiteSpace: 'pre-wrap',
  };

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (isExternal && href) {
      event.preventDefault();
      event.stopPropagation();
      window.open(href, '_blank');
    }
  };

  if (isExternal) {
    return (
      <ChakraLink {...commonLinkStyles} href={href} onClick={handleClick}>
        {label}
      </ChakraLink>
    );
  } else {
    return (
      <ChakraLink
        as={ReactRouterLink}
        {...commonLinkStyles}
        to={to ?? href ?? '#'}
      >
        {label}
      </ChakraLink>
    );
  }
};

export default CustomLink;
