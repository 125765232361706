import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga'
import TikTokPixel from 'tiktok-pixel'
import PageContainer from 'src/components/Container/PageContainer';
import { Button, Checkbox, Stack, Text } from '@chakra-ui/react';
import CreateAccountForm, {
  CreateAccountFormValues,
} from 'src/modules/Forms/CreateAccountForm';
import { useForm } from 'react-hook-form';
import { clearNonDigits } from 'src/utils/clearNonDigits';
import { updateUser } from 'src/api/services/user';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { UserActions } from 'src/redux/user';
import usePHToast from 'src/hooks/useToast';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

export default function PlayerData() {
  const dispatch = useAppDispatch();
  const toast = usePHToast();
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.user);

  const [loading, setLoading] = useState<boolean>(false);
  const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
  const [acceptPromo, setAcceptPromo] = useState<boolean>(false);

  const updateUserForm = useForm<CreateAccountFormValues>({
    values: {
      name: user?.name ?? '',
      lastName: user?.lastName ?? '',
      nickName: user?.nickName ?? '',
      phone: user?.phone ?? '',
      document: user?.document ?? '',
      birthDate: dayjs(user?.birthDate).format('DD/MM/YYYY').toString() ?? '',
      zipCode: user?.zipCode.replace(/-/g, '') ?? '',
      state: user?.city.state.abbreviation ?? '',
      stateID: user?.stateID ?? 0,
      city: user?.city.name ?? '',
      cityID: user?.cityID ?? 0,
      address: user?.address ?? '',
      number: user?.number ?? '',
      email: user?.email ?? '',
      complement: user?.complement ?? '',
      password: '',
      confirmPassword: '',
    },
  });
  const { handleSubmit, setValue } = updateUserForm;

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    TikTokPixel.pageView()
  }, [])

  const onSubmit = async (data: CreateAccountFormValues) => {
    try {
      setLoading(true);
      const cleanFormValues = {
        ...data,
        phone: clearNonDigits(data.phone),
        document: clearNonDigits(data.document),
        zipCode: clearNonDigits(data.zipCode),
      };

      if (user) {
        const updateResult = await updateUser(user.userID, cleanFormValues);

        if (updateResult) {
          dispatch(
            UserActions.setUser({
              ...updateResult,
            })
          );
        }
      }

      toast({ status: 'success', title: 'Usuário atualizado com sucesso' });
    } catch (e: any) {
      toast({ status: 'error', title: e?.message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageContainer restricted>
      <Stack direction={'column'} spacing={6} w="full" pb={4}>
        <Text variant="link" onClick={() => navigate(-1)}>
          voltar
        </Text>
        <Text variant="title">Meus Dados</Text>
        <CreateAccountForm rhfForm={updateUserForm} isUpdate />
        <Text variant="link" onClick={() => navigate('/jogador/dados/senha')}>
          Alterar senha
        </Text>
        <Checkbox
          iconColor="green"
          isChecked={acceptTerms}
          onChange={() => setAcceptTerms(!acceptTerms)}
        >
          <Text variant="text">
            Aceito os
            <Text as={'span'} variant="highlighted" fontSize={'14px'}>
              {' Termos e Condições '}
            </Text>
            e autorizo o uso de meus dados de acordo com a
            <Text as={'span'} variant="highlighted" fontSize={'14px'}>
              {' Política de privacidade'}
            </Text>
            .
          </Text>
        </Checkbox>
        <Checkbox
          borderColor="green"
          iconColor="green"
          isChecked={acceptPromo}
          onChange={() => setAcceptPromo(!acceptPromo)}
          defaultChecked
        >
          <Text variant="text">
            Desejo receber promoções, novidades e notificações do Arcadehaus.
          </Text>
        </Checkbox>
      </Stack>
      <Button
        variant="pophausOutline"
        w="50%"
        type="submit"
        isLoading={loading}
        onClick={handleSubmit(onSubmit)}
      >
        Alterar dados
      </Button>
    </PageContainer>
  );
}
