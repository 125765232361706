import React from 'react';
import {
  Center,
  CircularProgress,
  CircularProgressProps,
} from '@chakra-ui/react';

export const Loading = ({ ...props }: CircularProgressProps) => {
  return (
    <Center>
      <CircularProgress isIndeterminate color="blue" {...props} />
    </Center>
  );
};
