import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import TikTokPixel from 'tiktok-pixel';
import {
  Box,
  useMediaQuery,
  Text,
  Image,
  Stack,
  HStack,
  Icon,
  WrapItem,
  Wrap,
  SimpleGrid,
  Button,
  Spacer,
  useDisclosure,
} from '@chakra-ui/react';
import PageContainer from 'src/components/Container/PageContainer';
import Section from 'src/components/Section';
import BgHome from 'src/assets/events/bgsec1.webp';
import BgSec2 from 'src/assets/events/bgsec2.webp';
import { SecondSectionItems, ThirdSectionItems } from './section-items';
import EventLeadFormModal, {
  EventLeadFormValues,
} from 'src/modules/Modals/EventsModal';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import usePHToast from 'src/hooks/useToast';
import { postCreateEventForm } from 'src/api/services/event';
import { useAppDispatch } from 'src/redux/hooks';
import { BookingActions } from 'src/redux/booking';

export default function EventPreSchedule() {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const alert = usePHToast();
  const form = useForm<EventLeadFormValues>();
  const navigate = useNavigate();
  const [smallerThan1390] = useMediaQuery('(max-width: 1390px)');
  const [smallerThan900] = useMediaQuery('(max-width: 900px)');
  const [smallerThan570] = useMediaQuery('(max-width: 570px)');
  const [smallerThan500] = useMediaQuery('(max-width: 500px)');
  const [pdf, setPdf] = useState<boolean>(false);

  const eventTypes = [
    'HappyHour',
    'Team Building',
    'Evento Corporativo',
    'Confraternização',
    'Aniversário',
  ];

  const triggerPixelEvents = () => {
    if (window.fbq) window.fbq('track', 'Lead');
    if (window.gtag)
      window.gtag('event', 'conversion_event_submit_lead_form', {
        event_callback: console.log,
        event_timeout: 2000,
      });
  };

  const submitForm = async () => {
    try {
      setLoading(true);
      const res = await postCreateEventForm({
        email: form.getValues('email'),
        lastName: form.getValues('lastName'),
        name: form.getValues('name'),
        phone: form.getValues('phone'),
      });

      if (res) {
        triggerPixelEvents();
        dispatch(BookingActions.setEventFormID(res.formEventID));
      }
      setPdf(true);
      // navigate('/eventos/novo');
    } catch (e: any) {
      alert({ status: 'error', title: e.message });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    TikTokPixel.pageView();
  }, []);

  return (
    <PageContainer containerProps={{ py: 0, px: 0, spacing: 0 }}>
      <Box overflow={'hidden'} position="relative" w="100%" maxW="100vw">
        <Image
          opacity={0.4}
          position={'absolute'}
          left={0}
          top={0}
          width={'100%'}
          h="100%"
          src={
            'https://arcadehausstorage.blob.core.windows.net/festas/Bgtop1_Festas.webp'
          }
          fit="cover"
          objectPosition={'top center'}
        />
        <Section
          position="relative"
          px={{ base: 0, md: 64 }}
          minH="75vh"
          gap={smallerThan900 ? 0 : 6}
          direction="row"
          textAlign="center"
        >
          <Stack gap={6} w="full" h="100%" align="center" justify="center">
            <Text
              fontSize={
                smallerThan500 ? '24px' : smallerThan1390 ? '36px' : '56px'
              }
              fontWeight={'black'}
              lineHeight={'normal'}
              textTransform={'uppercase'}
              color="white"
            >
              Faça seu evento <br /> no Arcade Haus
            </Text>
            <Text variant="text" fontSize={smallerThan1390 ? '14px' : '20px'}>
              Você e seus convidados com acesso exclusivo <br /> ao primeiro
              fliperama imersivo do Brasil.
            </Text>
            <Button
              w="90%"
              maxW="300px"
              px={0}
              variant="pophausOutline"
              onClick={onOpen}
            >
              Conheça os Pacotes
            </Button>
          </Stack>
        </Section>
      </Box>
      <HStack
        px={3}
        gap={3}
        justifyContent="space-evenly"
        divider={
          <Text
            display={smallerThan570 ? 'none' : 'block'}
            fontWeight="bold"
            fontSize={smallerThan900 ? '12px' : '18px'}
          >
            {' >>> '}
          </Text>
        }
        w="100%"
        h={smallerThan570 ? '20px' : '46px'}
        bg="green"
      >
        {eventTypes.map((t, i) => (
          <Text
            display={smallerThan570 ? 'none' : 'block'}
            fontSize={smallerThan900 ? '12px' : '18px'}
            fontWeight="bold"
            key={i}
          >
            {t}
          </Text>
        ))}
      </HStack>

      <Box overflow={'hidden'} position="relative" w="100%" maxW="100vw">
        <Image
          opacity={0.4}
          position={'absolute'}
          left={0}
          top={0}
          width={'100%'}
          h="100%"
          src={BgSec2}
          fit="cover"
          objectPosition={'center center'}
        />
        <Section
          position="relative"
          px={smallerThan900 ? 0 : 64}
          minH="75vh"
          gap={smallerThan900 ? 0 : 6}
          direction="row"
          textAlign="center"
          justify="center"
        >
          <Stack
            gap={0}
            w="full"
            border={smallerThan900 ? 'none' : '2px dashed #B0CA0A'}
            direction={smallerThan1390 ? 'column' : 'row'}
            maxW="900px"
            bg={smallerThan900 ? 'none' : 'black'}
          >
            <Stack
              borderRight={smallerThan1390 ? 'none ' : '2px dashed #B0CA0A'}
              borderBottom={smallerThan1390 ? '2px dashed #B0CA0A' : 'none'}
              flex={1 / 2}
              justify="center"
              gap={6}
              padding={12}
              textAlign="start"
            >
              <Text
                color="white"
                fontSize={smallerThan500 ? '36px' : '56px'}
                variant="title"
              >
                Como <br /> Funciona?
              </Text>
              <Text color="white">
                No Arcade Haus, os eventos são sempre exclusivos: você reserva o
                espaço e tem acesso ao lounge e às duas salas de jogos
                simultaneamente. A gente cuida de tudo e sua única preocupação é
                se divertir!
              </Text>
              <Text color="yellow">
                Escolha um pacote ou personalize como quiser!
              </Text>
              <Button
                px={0}
                variant="pophausOutline"
                colorScheme="popYellow"
                onClick={onOpen}
              >
                Conheça os Pacotes
              </Button>
            </Stack>

            <Stack flex={1 / 2} gap={6} justify="center" padding={12}>
              {SecondSectionItems.map((e, i) => (
                <HStack textAlign="start" key={i}>
                  <Image src={e.icon} w={8} />
                  <Text variant="text" fontSize="18px" fontWeight="bold">
                    {e.label}
                  </Text>
                </HStack>
              ))}

              <Box border="2px solid white" textAlign="start" padding={3}>
                <Text variant="text"> Condições especiais:</Text>
                <Text variant="text" as="li">
                  Ganhe 5% de desconto à vista
                </Text>
                <Text variant="text" as="li">
                  50% na reserva + 50% no dia do evento
                </Text>
                <Text variant="text" as="li">
                  Parcele em até 5x (sem juros) no cartão
                </Text>
              </Box>
            </Stack>
          </Stack>
        </Section>
      </Box>

      {false && (
        <Section
          borderBottom="2px solid white"
          gap={6}
          direction="column"
          justify="center"
          bg="black"
        >
          <Text
            variant="title"
            fontSize={smallerThan500 ? '24px' : '36px'}
            color="white"
          >
            Passo a passo para fazer <br /> seu evento no arcade haus
          </Text>
          <Wrap justify="center" maxW="1200px">
            {ThirdSectionItems.map((e, i) => (
              <WrapItem key={i}>
                <Box w="300px" height="240px" padding={6} bg={e.color}>
                  <HStack w="full">
                    <Spacer />
                    <Image width="60px" src={e.icon} />
                  </HStack>
                  <Text fontWeight="bold" fontSize="24px">
                    {i + 1}
                  </Text>
                  <Text fontSize="18px">{e.label}</Text>
                </Box>
              </WrapItem>
            ))}
          </Wrap>
          <Button
            w="full"
            maxW="900px"
            px={0}
            variant="pophausOutline"
            onClick={onOpen}
          >
            Faça seu evento
          </Button>
        </Section>
      )}
      <EventLeadFormModal
        form={form}
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={submitForm}
        isLoading={loading}
        pdf={pdf}
        setPdf={setPdf}
      />
    </PageContainer>
  );
}
