import React, { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { FormInput } from 'src/components/Form/Input';
import { FormSelect } from 'src/components/Form/Select';
import { Box, Button, Stack } from '@chakra-ui/react';
import Form from 'src/components/Form/Form';
import {
  getBusinessUnit,
  useGetBusinessUnitRooms,
  useGetBusinessUnits,
} from 'src/api/services/businessUnit';
import { CopyIcon } from '@chakra-ui/icons';
import { useDispatch } from 'react-redux';
import { calendarResultActions } from 'src/redux/calendarData';
import { useAppSelector } from 'src/redux/hooks';

export interface CalendarFilterFormValues {
  date: string;
  startTime: string;
  endTime: string;
  businessUnitID: string;
  isActive: number;
  rooms: number;
}

interface CalendarFilterFormProps {
  form: UseFormReturn<CalendarFilterFormValues, any>;
  handleForm: () => void;
  hadleReserve: () => void;
  isLoading?: boolean;
}

export default function CalendarFilterForm({
  form,
  handleForm,
  hadleReserve,
  isLoading = false,
}: CalendarFilterFormProps) {
  const dispatch = useDispatch();
  const { calendarUnitRooms } = useAppSelector((state) => state.calendarResult);
  const [unit, setUnit] = useState<any>();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = form;

  const { data: businessUnits } = useGetBusinessUnits();

  const onSubmit = (data: CalendarFilterFormValues) => {
    return data;
  };

  const getBusiness = async () => {
    if (unit) {
      const res = await getBusinessUnit(unit);
      dispatch(calendarResultActions.setCalendarResultRooms(res));
    }
  };

  useEffect(() => {
    getBusiness();
  }, [unit]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Stack mb={6} direction={['column', 'row']} w={'full'} spacing={6}>
        <FormInput
          label="Data"
          rhfRegister={{
            ...register('date'),
          }}
          errorState={errors.date}
          inputProps={{ placeholder: 'DATA', type: 'date' }}
        />

        <Box width="30%">
          <FormInput
            label="Início"
            rhfRegister={{
              ...register('startTime'),
            }}
            errorState={errors.startTime}
            inputProps={{ type: 'time' }}
          />
        </Box>

        <Box width="30%">
          <FormInput
            label="Fim"
            rhfRegister={{
              ...register('endTime'),
            }}
            errorState={errors.endTime}
            inputProps={{ type: 'time' }}
          />
        </Box>

        <FormSelect
          label="Unidade"
          rhfRegister={{
            ...register('businessUnitID', {
              required: 'Campo obrigatório',
            }),
          }}
          errorState={errors.businessUnitID}
          icon={CopyIcon}
          onChange={(e: any) => setUnit(e.target.value)}
        >
          <option>Selecione a unidade</option>
          {businessUnits?.map((b) => (
            <option key={b.businessUnitID} value={b.businessUnitID}>
              {b.description}
            </option>
          ))}
        </FormSelect>

        <FormSelect
          label="Salas"
          rhfRegister={{
            ...register('rooms', {
              required: 'Campo obrigatório',
            }),
          }}
          errorState={errors.rooms}
        >
          <option>Selecione a sala</option>
          {calendarUnitRooms?.rooms?.map((items: any) => (
            <option key={items?.roomID} value={items?.roomID}>
              {items.description}
            </option>
          ))}
        </FormSelect>

        <Button
          insetY={6}
          paddingInline={50}
          type="submit"
          variant="pophausOutline"
          onClick={handleForm}
          isLoading={isLoading}
        >
          OK
        </Button>

        <FormSelect
          label="Status"
          rhfRegister={{
            ...register('isActive', {
              required: 'Campo obrigatório',
            }),
          }}
          errorState={errors.isActive}
        >
          <option value={1}>Reservado para público e festas</option>
          <option value={2}>Reservado somente para festas</option>
          <option value={3}>Fechado</option>
          <option value={4}>Feriado</option>
        </FormSelect>

        <Button
          insetY={6}
          paddingInline={50}
          type="submit"
          variant="pophausOutline"
          onClick={hadleReserve}
          isLoading={isLoading}
        >
          Reservar
        </Button>
      </Stack>
    </Form>
  );
}
