import { Stack } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';
import { cpf } from 'cpf-cnpj-validator';
import { CEPResult } from 'src/api/interfaces/user';
import {
  checkIfNicknameIsAvailableForNewUser,
  CheckIfNicknameIsAvailableInUpdateUser,
} from 'src/api/services/player';
import Form from 'src/components/Form/Form';
import { FormInput } from 'src/components/Form/Input';
import { FormMaskedInput } from 'src/components/Form/Input/MaskedInput';
import { FormInputPassword } from 'src/components/Form/Input/PasswordInput';
import { getAddressByCep } from 'src/api/services/location';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import usePHToast from 'src/hooks/useToast';

export interface ChangePasswordFormValues {
  email: string,
  password: string;
  confirmPassword: string;
}

interface ChangePasswordFormProps {
  rhfForm: UseFormReturn<ChangePasswordFormValues, any>;
}

export default function ChangePasswordForm({
  rhfForm,
}: ChangePasswordFormProps) {
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
  } = rhfForm;
  const toast = usePHToast();

  const { user } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (user) {
      setValue('email', user?.email)
    }
  }, [user])

  return (
    <Form>
      <Stack direction={['column', 'row']} spacing={4}>
        <FormInputPassword
          rhfRegister={{
            ...register('password', {
              required: 'Campo obrigatório',
              pattern: {
                value: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/,
                message:
                  'Senha precisa conter ao menos: 1 letra maiúscula, 1 letra minúscula, 1 número e 1 símbolo. ',
              },
            }),
          }}
          errorState={errors.password}
        />
        <FormInputPassword
          label={'CONFIRMAR SENHA'}
          rhfRegister={{
            ...register('confirmPassword', {
              required: 'Campo obrigatório',
              validate: (value) => {
                const { password } = getValues();
                return password === value || 'As senhas devem ser iguais!';
              },
            }),
          }}
          errorState={errors.confirmPassword}
        />
      </Stack>
    </Form>
  );
}
