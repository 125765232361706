import React, { useEffect, useState } from 'react';
import PageContainer from '../../../components/Container/PageContainer';
import { useNavigate } from 'react-router-dom';
import StepFilter from './StepFilter';
import { Box, Button, Checkbox, Spacer, Text, VStack } from '@chakra-ui/react';
import { BsBox, BsAlarm, BsXSquare } from 'react-icons/bs';
import CalendarFilterForm, {
  CalendarFilterFormValues,
} from 'src/modules/Forms/Backoffice/CalendarFilterForm';
import { set, useForm } from 'react-hook-form';
import { PaginatedTable } from 'src/components/Table/PaginatedTable';
import { useAdminCalendarColumns } from './TableData';
import {
  postAddScheduleRule,
  postRescheduleCalendar,
  postReturnCalendar,
  useBookingFilter,
} from 'src/api/services/booking';
import DashedContainer from 'src/components/Container/DashedContainer';
import { PophausModal } from 'src/components/Modal';
import { RescheduleModal } from 'src/components/Modal/ModalVariants/RescheduleCalendarModal';
import { error } from 'console';
import { SuccessModal } from 'src/components/Modal/ModalVariants/SuccessModal';
import { ErrorModal } from 'src/components/Modal/ModalVariants/ErrorModal';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';

interface RescheduleProps {
  reschedule: string;
  ticket: string;
}

export default function AdminCalendar() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [view, setView] = useState<1 | 2 | 3>(1);
  const form = useForm<CalendarFilterFormValues>();
  const { data } = useBookingFilter();
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [resultCalendar, setResultCalendar] = useState<any>();
  const [openModal, setOpenModal] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState('');
  const [messageError, setMessageError] = useState('');
  const [dateReschedule, setDateReschedule] = useState<RescheduleProps>({
    reschedule: '',
    ticket: '',
  });

  const adminCalendarData = {
    selectedRows,
    setSelectedRows,
    selectAll,
  };

  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectAll(event.target.checked);

    if (event.target.checked && data) {
      const allBookingIDs = resultCalendar.map(
        (booking: any) => booking.bookingID
      );
      setSelectedRows(allBookingIDs);
    } else {
      setSelectedRows([]);
    }
  };

  const columns = useAdminCalendarColumns(adminCalendarData);

  const submitReschedule = () => {
    if (selectedRows.length > 0) {
      setOpenModal(true);
    }
  };

  const handleResultCalendar = async () => {
    setTimeout(async () => {
      const res = await postReturnCalendar({
        date: form?.control?._formValues?.date,
        endTime: form?.control?._formValues?.endTime,
        startTime: form?.control?._formValues?.startTime,
        roomID: [Number(form?.control?._formValues.rooms)],
        unitID: view,
      });
      setResultCalendar(res);
    }, 500);
  };

  const handleReschuleDate = async () => {
    try {
      await postRescheduleCalendar({
        bookingID: selectedRows,
        rescheduleLimitDate: dateReschedule.reschedule,
        expiryDate: dateReschedule.ticket,
      });
      setOpenModal(false);
      setOpenSuccess(true);
      setMessageSuccess('REAGENDADO COM SUCESSO!');
    } catch {
      setOpenModal(false);
      setOpenError(true);
      setMessageError('NÃO FOI POSSÍVEL REAGENDAR');
    }
  };

  const handleAddScheduleRule = async () => {
    try {
      setLoading(true)

      await postAddScheduleRule({
        date: form?.control?._formValues?.date,
        endTime: form?.control?._formValues?.endTime,
        startTime: form?.control?._formValues?.startTime,
        open: true,
        scheduleRuleStatusID: Number(form?.control?._formValues?.isActive),
        roomIDs: [Number(form?.control?._formValues.rooms)],
      });
      setOpenSuccess(true);
      setMessageSuccess('RESERVADO COM SUCESSO!');
    } catch {
      setOpenError(true);
      setMessageError('NÃO FOI POSSÍVEL RESERVAR!');
    } finally {
      setLoading(false)
    }
  };

  return (
    <PageContainer restricted>
      <VStack w="full">
        <Box w="full" mb={5}>
          <Text mb={5} variant="title">
            Calendário
          </Text>
          <StepFilter view={view} setter={setView} />
        </Box>
        <CalendarFilterForm
          form={form}
          handleForm={handleResultCalendar}
          hadleReserve={handleAddScheduleRule}
          isLoading={loading}
        />

        {resultCalendar && (
          <Box w="full">
            <DashedContainer
              flexDir="row"
              padding={6}
              alignItems="start"
              borderBottom="none"
            >
              <Checkbox
                color="white"
                isChecked={selectAll}
                onChange={handleSelectAllChange}
              >
                Selecionar todas
              </Checkbox>

              <Spacer />
              <Button
                onClick={submitReschedule}
                padding={5}
                variant="pophausOutline"
              >
                Reagendar
              </Button>
            </DashedContainer>

            <div>
              <PaginatedTable
                pageSize={10}
                columns={columns}
                data={resultCalendar}
              />
            </div>
          </Box>
        )}

        <RescheduleModal
          icon={BsAlarm}
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
          content="Deseja reagendar?"
          dateReschedule={dateReschedule.reschedule}
          dateTicke={dateReschedule.ticket}
          onChangeReschudule={(e: any) =>
            setDateReschedule({ ...dateReschedule, reschedule: e.target.value })
          }
          onChangeTicket={(e: any) =>
            setDateReschedule({ ...dateReschedule, ticket: e.target.value })
          }
          handleMainClick={handleReschuleDate}
          secondaryButtonText="voltar"
        />

        <SuccessModal
          isOpen={openSuccess}
          onClose={() => setOpenSuccess(false)}
          handleMainClick={() => setOpenSuccess(false)}
          content={messageSuccess}
        />

        <ErrorModal
          isOpen={openError}
          onClose={() => setOpenError(false)}
          handleMainClick={() => setOpenError(false)}
          content={messageError}
          color="red"
        />
      </VStack>
    </PageContainer>
  );
}
