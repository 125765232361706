import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  VStack,
  Icon,
  Text,
  Box,
  Stack,
  useDisclosure,
  Center,
} from '@chakra-ui/react';
import DashedContainer from 'src/components/Container/DashedContainer';
import { PlayerDataValues } from 'src/modules/Forms/PlayerData';
import { useForm, useFieldArray } from 'react-hook-form';
import PlayerDataFieldArrayForm, {
  PlayerDataFieldArrayValues,
} from 'src/modules/Forms/PlayerData/PlayerDataFieldArray';
import { FaRegWindowClose } from 'react-icons/fa';
import dayjs from 'dayjs';
import { HiOutlinePlusCircle } from 'react-icons/hi2';
import { AddPlayerModal } from '../AddPlayerModal';
import { PlayerRequest, PlayerResult } from 'src/api/interfaces/player';
import {
  addBookingPlayers,
  putIdentifyPlayers,
  useGetBookingByID,
} from 'src/api/services/booking';
import usePHToast from 'src/hooks/useToast';
import { mutate } from 'swr';
import { Loading } from 'src/components/Loading';
import BotsPlaceholder from 'src/components/BotsPlaceholder';

interface PophausModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleMainClick?: () => void;
  bookingID: string;
  view?: boolean;
}

export function EditPlayerModal({
  isOpen,
  onClose,
  handleMainClick,
  bookingID,
  view = false,
}: PophausModalProps) {
  const [loading, setLoading] = useState(false);
  const [bookingPlayers, setBookingPlayers] = useState<PlayerResult[]>([]);
  const {
    data: booking,
    isLoading,
    mutate,
  } = useGetBookingByID(isOpen ? bookingID : undefined);
  const [pendingPlayers, setPendingPlayers] = useState<boolean>(false);
  const toast = usePHToast();
  const {
    isOpen: isAddOpen,
    onOpen: onAddOpen,
    onClose: onAddClose,
  } = useDisclosure();

  const playerForm = useForm<PlayerDataFieldArrayValues>({
    reValidateMode: 'onBlur',
  });

  const addPlayerForm = useForm<PlayerDataValues>({
    reValidateMode: 'onBlur',
    defaultValues: {
      nickName: '',
      name: '',
      lastName: '',
      birthDate: '',
    },
  });

  useEffect(() => {
    if (booking?.bookingPlayers) {
      setPendingPlayers(
        booking.bookingPlayers.length >= booking?.tickets ? false : true
      );
      mutate();
    }
  }, [booking]);

  const { control, reset, handleSubmit } = playerForm;

  const { fields, remove: removePlayer } =
    useFieldArray<PlayerDataFieldArrayValues>({
      control: control,
      name: 'playerFieldArray',
    });

  const setPlayerFields = () => {
    const players = booking?.bookingPlayers?.map((p) => ({
      ...p.player,
      birthDate: dayjs(p.player.birthDate).format('DD/MM/YYYY'),
    }));

    reset({ playerFieldArray: players });
  };

  const addPlayer = async (data: PlayerDataValues) => {
    try {
      setLoading(true);
      const bookedPlayers = bookingPlayers?.flatMap((p) => p) ?? [];
      const players = [...bookedPlayers, data];

      const formattedPlayers: PlayerRequest[] = players.map((p) => {
        const brDate = p.birthDate?.includes('/');
        return {
          ...p,
          birthDate: brDate
            ? dayjs(p.birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
            : p.birthDate,
        };
      });

      const res = await addBookingPlayers(bookingID, formattedPlayers);
      const p = res.bookingPlayers?.flatMap((p) => p.player) ?? bookedPlayers;
      reset({ playerFieldArray: p });
      setBookingPlayers(p);
      mutate();
    } catch (e) {
      toast({ status: 'error', description: String(e) });
    } finally {
      onAddClose();
      addPlayerForm.reset();
      setLoading(false);
    }
  };

  const editPlayers = async (data: PlayerDataFieldArrayValues) => {
    try {
      setLoading(true);
      const formattedPlayers = data.playerFieldArray.map((p) => {
        const brDate = p.birthDate?.includes('/');
        return {
          ...p,
          birthDate: brDate
            ? dayjs(p.birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
            : p.birthDate,
        };
      });
      const res = await addBookingPlayers(bookingID, formattedPlayers);

      toast({
        status: 'success',
        description: 'Jogadores atualizados com sucesso',
      });
      mutate();
    } catch (e) {
      toast({ status: 'error', description: String(e) });
    } finally {
      onClose();
      setLoading(false);
      if (handleMainClick) handleMainClick();
    }
  };

  const setIdentifyPlayersFalse = async () => {
    try {
      setLoading(true);
      const res = await putIdentifyPlayers(bookingID, false);
      mutate();
      toast({
        status: 'success',
        description: 'Jogadores não serão identificados',
      });
    } catch (e) {
      toast({ status: 'error', description: String(e) });
    } finally {
      setLoading(false);
    }
  };

  const setIdentifyPlayersTrue = async () => {
    try {
      setLoading(true);
      const res = await putIdentifyPlayers(bookingID, true);
      mutate();
      toast({
        status: 'success',
        description: 'Por favor, identifique os jogadores',
      });
    } catch (e) {
      toast({ status: 'error', description: String(e) });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const p = booking?.bookingPlayers?.flatMap((p) => p.player);
    setBookingPlayers(p ?? []);
    setPlayerFields();
  }, [booking]);

  return (
    <>
      <Modal onClose={onClose} isOpen={isOpen} isCentered size="6xl">
        <ModalOverlay />
        <ModalContent>
          <DashedContainer borderColor={'green'}>
            <ModalCloseButton
              size="sm"
              position="unset"
              alignSelf={'flex-end'}
              color={'green'}
              border={'2px'}
              borderRadius={0}
              borderColor={'green'}
            />
            <ModalBody w="full">
              <VStack spacing={4} w="full" align={'center'}>
                {booking?.identifyPlayers ? (
                  <>
                    {view ? (
                      <Text variant="title" alignSelf={'flex-start'}>
                        Jogadores
                      </Text>
                    ) : (
                      <Text variant="title" alignSelf={'flex-start'}>
                        Identifique os jogadores
                      </Text>
                    )}
                  </>
                ) : (
                  <>
                    {view ? (
                      <Text variant="title" alignSelf={'flex-start'}>
                        Jogadores não foram identificados
                      </Text>
                    ) : (
                      <Text variant="title" alignSelf={'flex-start'}>
                        Jogadores não serão identificados
                      </Text>
                    )}
                  </>
                )}

                {isLoading ? (
                  <Center w="full" h="full">
                    <Loading />
                  </Center>
                ) : (
                  <Stack w="full" py={8}>
                    {fields.map((f, i) => (
                      <Stack direction={'row'} key={f.id} align={'flex-end'}>
                        {booking?.identifyPlayers && (
                          <PlayerDataFieldArrayForm
                            playerNum={i + 1}
                            index={i}
                            form={playerForm}
                            colorScheme="yellow"
                          />
                        )}

                        {!view && booking?.identifyPlayers && (
                          <Box
                            color="white"
                            as="button"
                            _hover={{ color: 'yellow' }}
                            onClick={() => removePlayer(i)}
                          >
                            <Icon as={FaRegWindowClose} w={5} h={5} />
                            <Text>Excluir</Text>
                          </Box>
                        )}
                      </Stack>
                    ))}
                    <Box>
                      {booking?.identifyPlayers && pendingPlayers ? (
                        <>
                          {!view && (
                            <Button onClick={onAddOpen} gap={3} p={0}>
                              <Icon
                                as={HiOutlinePlusCircle}
                                color="green"
                                w={8}
                                h={8}
                              />
                              <Text
                                variant="highlighted"
                                textTransform={'uppercase'}
                              >
                                Adicionar Jogador
                              </Text>
                            </Button>
                          )}
                        </>
                      ) : (
                        <VStack w="100%">
                          {Array.from(
                            {
                              length: booking
                                ? booking.tickets -
                                  (booking?.bookingPlayers?.length ?? 0)
                                : 0,
                            },
                            (_, index) => (
                              <BotsPlaceholder key={index} number={index} />
                            )
                          )}
                        </VStack>
                      )}
                    </Box>
                  </Stack>
                )}
              </VStack>
            </ModalBody>
            <ModalFooter>
              {!view && (
                <Stack gap={3}>
                  {booking?.identifyPlayers ? (
                    <Button
                      variant="pophausOutline"
                      colorScheme="popYellow"
                      onClick={setIdentifyPlayersFalse}
                      isLoading={loading}
                    >
                      não identificar jogadores
                    </Button>
                  ) : (
                    <Button
                      variant="pophausOutline"
                      colorScheme="popGreen"
                      onClick={setIdentifyPlayersTrue}
                      isLoading={loading}
                    >
                      identificar jogadores
                    </Button>
                  )}
                  {booking?.identifyPlayers && (
                    <Button
                      w="full"
                      variant="pophausOutline"
                      colorScheme={'popGreen'}
                      onClick={handleSubmit(editPlayers)}
                      isLoading={loading}
                    >
                      atualizar
                    </Button>
                  )}
                </Stack>
              )}
            </ModalFooter>
          </DashedContainer>
        </ModalContent>
      </Modal>

      <AddPlayerModal
        key={'addPlayerModal'}
        playerNum={(bookingPlayers?.length ?? 0) + 1}
        form={addPlayerForm}
        isOpen={isAddOpen}
        onClose={() => {
          onAddClose();
          playerForm.reset();
        }}
        handleMainClick={addPlayerForm.handleSubmit(addPlayer)}
        loading={loading}
      />
    </>
  );
}
