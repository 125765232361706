import React, { useEffect, useState } from 'react';
import PageContainer from 'src/components/Container/PageContainer';
import {
  HStack,
  Text,
  VStack,
  Stack,
  Flex,
  Button,
  Center,
} from '@chakra-ui/react';
import DashedContainer from 'src/components/Container/DashedContainer';
import SelectTicketForm, {
  SelectTicketFormValues,
} from 'src/modules/Forms/SelectTicketForm';
import { useForm } from 'react-hook-form';
import { RoomImage } from 'src/components/RoomImage';
import { Summary } from 'src/components/Summary';
import { useNavigate, useParams } from 'react-router-dom';
import {
  BookingStatusEnum,
  cancelBooking,
  updateBookingStatus,
  useGetBookingByID,
} from 'src/api/services/booking';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { BookingActions } from 'src/redux/booking';
import usePHToast from 'src/hooks/useToast';
import { Loading } from 'src/components/Loading';

export interface AdminTicketSummaryProps {
  isRebuy?: boolean
}

export default function AdminTicketSummary({ isRebuy = false }: AdminTicketSummaryProps) {
  const { bookingID } = useParams<{ bookingID: string }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const toast = usePHToast();
  const form = useForm<SelectTicketFormValues>();
  const [loading, setLoading] = useState(false)

  const { data, isLoading, mutate } = useGetBookingByID(bookingID);

  useEffect(() => {
    const buId = data?.bookingRooms?.[0]?.room?.businessUnitID ?? 0;
    if (data)
      form.reset({
        date: dayjs(data.startDate).format('YYYY-MM-DD'),
        businessUnitID: buId.toString(),
        numberOfPlayers: data.tickets.toString(),
      });
  }, [data]);

  const handleContinue = async () => {
    if (bookingID) {
      // Podemos colocar um status admin aqui
      // const result = await updateBookingStatus(
      //   bookingID,
      //   BookingStatusEnum.REALIZANDO_PAGAMENTO
      // );
      navigate(`/admin/agenda/pagamento/${bookingID}`);
      return;
    }
    throw new Error(
      'Não foi possível identificar sua reserva, tente novamente.'
    );
  };

  const handleCancel = async () => {
    try {
      setLoading(true)
      if (bookingID) {
        await cancelBooking(bookingID);
        toast({
          status: 'success',
          description: 'Reserva cancelada com sucesso!',
        });
        navigate(`/admin/agenda`);
        return;
      }
      toast({
        status: 'error',
        description:
          'Não foi possível cancelar a reserva. Tente novamente mais tarde.',
      });
    } catch (e: any) {
      toast({
        status: 'error',
        description:
          'Não foi possível cancelar a reserva. Tente novamente mais tarde.',
      });
    } finally {
      setLoading(false)
    }
  };

  return (
    <PageContainer restricted>
      <VStack w="full" spacing={12}>
        <Text color="green" fontSize="xl">
          RESUMO DA RESERVA
        </Text>
        <DashedContainer justify="center" py={12} px={12}>
          <VStack w="full" spacing={12} align="flex-start">
            <SelectTicketForm form={form} showSubmit={false} readonly />
            <Stack direction={['column', 'row']} w="full" h="full" spacing={2}>
              {isLoading || !data ? (
                <Center w="full">
                  <Loading />
                </Center>
              ) : (
                <>
                  {data?.bookingRooms?.map((br) => (
                    <RoomImage
                      key={br.bookingRoomID}
                      title={br?.room?.description}
                    />
                  ))}
                  <Summary isRebuy={isRebuy} hasCoupon w="full" data={data} mutate={mutate} isAdmin />
                </>
              )}
            </Stack>

            <HStack w="full" h={'full'} spacing={2} justify="center">
              <Button
                variant="pophausOutline"
                colorScheme="popTransparent"
                onClick={handleCancel}
                isLoading={loading}
              >
                CANCELAR RESERVA
              </Button>
              <Button variant="pophausOutline" onClick={handleContinue}>
                CONTINUAR
              </Button>
            </HStack>
          </VStack>
        </DashedContainer>
      </VStack>
    </PageContainer>
  );
}
