import { ApiRepository, ApiResponse, useFetch } from '../..';
import { MatchResult } from 'src/api/interfaces/match';
import { UserAttachmentsResult } from 'src/api/interfaces/match';

const api = new ApiRepository();

export function useFilter(BookingID?: string) {
  const args = BookingID
    ? {
        method: 'get',
        url: `Match/${BookingID}`,
      }
    : null;

  const { data, error, isLoading } = useFetch<ApiResponse<MatchResult[]>>(
    api,
    args,
    { revalidateOnFocus: false }
  );
  return { data: data?.result, error, isLoading };
}

export function useGetUserAttachments(UserID?: string) {
  const args = UserID
    ? {
        method: 'get',
        url: `Match/UserAttachments?UserID=${UserID}`,
      }
    : null;

  const { data, error, isLoading } = useFetch<
    ApiResponse<UserAttachmentsResult[]>
  >(api, args, { revalidateOnFocus: false });
  return { data: data?.result, error, isLoading };
}
