import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga'
import TikTokPixel from 'tiktok-pixel'
import { useForm } from 'react-hook-form';
import { Flex, Text, VStack, Wrap, WrapItem } from '@chakra-ui/react';
import PageContainer from 'src/components/Container/PageContainer';
import DashedContainer from 'src/components/Container/DashedContainer';
import EventForm, { EventFormValues } from 'src/modules/Forms/EventForm';
import { getScheduleForEvents, postCreateEvent } from 'src/api/services/event';
import usePHToast from 'src/hooks/useToast';
import { FormAccordion } from 'src/components/Form/Accordion';
import { FormAccordionContent } from 'src/components/Form/Accordion/AccordionContent';
import { ScheduleButtons } from 'src/components/Form/ScheduleButtons';
import { EventRequest, EventResult } from 'src/api/interfaces/event';
import { ScheduleButton } from 'src/components/Form/ScheduleButton';
import ScheduleBlock from 'src/components/Form/Event/ScheduleBlock';
import { ScheduleBlocksGroup } from 'src/components/Form/Event/ScheduleBlocksGroup';
import { useAppSelector } from 'src/redux/hooks';
import { useNavigate } from 'react-router-dom';

export default function AdminEventsCreate() {
  const form = useForm<EventFormValues>();
  const navigate = useNavigate()
  const toast = usePHToast()
  const [loading, setLoading] = useState<boolean>(false)
  const [eventResult, setEventResult] = useState<EventResult[]>()
  const { user } = useAppSelector((state) => state.user)
  const [tickets, setTickets] = useState<number>()
  const [date, setDate] = useState<string>()
  const [businessUnitID, setBusinessUnitID] = useState<string>()
  const [duration, setDuration] = useState<string>()


  const handleFormSubmit = async (data: EventFormValues) => {
    try {
      setLoading(true)
      const res = await getScheduleForEvents(data.businessUnitID, data.date, data.duration)
      setTickets(+data.numberOfPlayers)
      setBusinessUnitID(data.businessUnitID)
      setDuration(data.duration)
      setEventResult(res)
      setDate(data.date)
    } catch (e: any) {
      toast({ status: 'error', title: e.message })
    } finally {
      setLoading(false)
    }
  }

  const handleContinue = async (startTime: string) => {
    try {
      setLoading(true)
      if (!user) throw new Error('Tente fazer login novamente')
      if (!tickets) throw new Error('Erro ao calcular número de ingressos')
      if (!date) throw new Error('Erro ao calcular data do evento')
      if (!businessUnitID) throw new Error('Erro ao calcular unidade do evento')
      if (!duration) throw new Error('Erro ao calcular duração do evento')

      const eventRequestData: EventRequest = {
        isAdmin: true,
        tickets,
        date,
        businessUnitID: +businessUnitID,
        duration: +duration * 2,
        startTime
      }

      const res = await postCreateEvent(eventRequestData)
      if (res) navigate(`/admin/eventos/selecionar-usuario/${res.bookingID}`)
    } catch (e: any) {
      toast({ status: 'error', title: e.message })
    } finally {
      setLoading(false)
    }
  }

  return (
    <PageContainer>
      <VStack w="full" spacing={2} py={{ base: 8, md: 0 }}>
        <Text variant='title'>Selecione a quantidade de participantes</Text>

        <DashedContainer justify='center'>
          <VStack w="full" spacing={2} align="flex-start">
            <EventForm form={form} onSubmit={handleFormSubmit} submitLoading={loading} />
            {eventResult && <ScheduleBlocksGroup eventResult={eventResult} handleContinue={handleContinue} submitLoading={loading} duration={form.getValues('duration')} />}

          </VStack>
        </DashedContainer>



      </VStack >
    </PageContainer >
  );
}
