import TimeIcon from 'src/assets/events/icon.png';
import CalendarIcon from 'src/assets/events/icon-1.png';
import PeopleIcon from 'src/assets/events/icon-2.png';
import SplashIcon from 'src/assets/events/icon-3.png';
import PizzaIcon from 'src/assets/events/icon-4.png';
import CashIcon from 'src/assets/events/icon-5.png';

import TimeIconBlack from 'src/assets/events/icon-black-2.png';
import CalendarIconBlack from 'src/assets/events/icon-black.png';
import PeopleIconBlack from 'src/assets/events/icon-black-1.png';
import SplashIconBlack from 'src/assets/events/icon-black-3.png';
import PizzaIconBlack from 'src/assets/events/icon-black-5.png';
import CashIconBlack from 'src/assets/events/icon-black-4.png';

export const SecondSectionItems = [
  {
    icon: TimeIcon,
    label: '2h e 30 minutos de evento',
  },
  {
    icon: CalendarIcon,
    label: 'Terça a Domingo',
  },
  {
    icon: PeopleIcon,
    label: 'De 15 a 30 convidados',
  },
  {
    icon: SplashIcon,
    label: 'Decoração temática',
  },
  {
    icon: PizzaIcon,
    label: 'Buffet incluído no pacote',
  },
];

export const ThirdSectionItems = [
  {
    icon: CalendarIconBlack,
    label: 'Escolha a data para seu evento',
    color: 'green',
  },
  {
    icon: PeopleIconBlack,
    label: 'Indique o número de convidados',
    color: 'yellow',
  },
  {
    icon: TimeIconBlack,
    label: 'Defina a duração do evento',
    color: 'blue',
  },
  {
    icon: SplashIconBlack,
    label: 'Confira horários disponíveis e selecione o período desejado',
    color: 'red',
  },
  {
    icon: CashIconBlack,
    label:
      'Confira as informações e faça o pagamento com o método que preferir',
    color: 'green',
  },
  {
    icon: PizzaIconBlack,
    label:
      'Se quiser, incremente com buffet da casa servido à vontade durante todo o seu evento',
    color: 'yellow',
  },
];
