import {
  Button,
  Flex,
  FlexProps,
  Modal,
  ModalBody,
  ModalBodyProps,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import DashedContainer from 'src/components/Container/DashedContainer';

interface EmptyModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  colorScheme?: string;
  rightButtonText?: string;
  leftButtonText?: string;
  rightButtonClick?: () => void;
  leftButtonClick?: () => void;
  size?: "6xl" | "sm" | "md" | "lg" | "xl" | "2xl" | "xs" | "3xl" | "4xl" | "5xl" | "full" | undefined
}

export default function EmptyModal({
  isOpen,
  onClose,
  children,
  colorScheme = 'popGreen',
  leftButtonText = 'atualizar dados',
  rightButtonText = 'check in',
  rightButtonClick,
  leftButtonClick,
  size,
  ...rest
}: EmptyModalProps & FlexProps
) {
  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered size={size ? size : '6xl'}>
      <ModalOverlay />

      <ModalContent >
        <DashedContainer borderColor="yellow" {...rest}>
          <ModalCloseButton
            size="sm"
            position="unset"
            alignSelf={'flex-end'}
            color="yellow"
            border={'2px'}
            borderRadius={0}
            borderColor="yellow"
          />
          <ModalBody w="100%">{children}</ModalBody>
          <ModalFooter gap="20px">
            {leftButtonClick && <Button
              onClick={leftButtonClick}
              variant="pophausOutline"
              colorScheme="popTransparent"
            >
              {leftButtonText}
            </Button>}

            {rightButtonClick && <Button
              onClick={rightButtonClick}
              variant="pophausOutline"
              colorScheme={colorScheme}
            >
              {rightButtonText}
            </Button>}

          </ModalFooter>
        </DashedContainer>
      </ModalContent>
    </Modal>
  );
}
