export const weekDays = [
  {
    value: 0,
    label: 'domingo',
  },
  {
    value: 1,
    label: 'segunda',
  },
  {
    value: 2,
    label: 'terça',
  },
  {
    value: 3,
    label: 'quarta',
  },
  {
    value: 4,
    label: 'quinta',
  },
  {
    value: 5,
    label: 'sexta',
  },
  {
    value: 6,
    label: 'sábado',
  },
];
