import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  BoxProps,
  AspectRatio,
  Image,
  useMediaQuery,
} from '@chakra-ui/react';
import ReactPlayer from 'react-player/youtube';
import { useInView } from 'react-intersection-observer';

interface VideoBoxProps {
  src?: string;
  title?: string;
  vertical?: boolean;
  awaysFullWidth?: boolean;
  playOnFocus?: boolean;
}

export default function VideoBox({
  src,
  title,
  vertical = false,
  awaysFullWidth = false,
  playOnFocus = false,
  ...props
}: VideoBoxProps & BoxProps) {
  const [largerThan500] = useMediaQuery('(min-width: 500px)');
  const [isPlaying, setIsPlaying] = useState(false);
  const { ref, inView, entry } = useInView({
    threshold: 1,
  });

  useEffect(() => {
    if (playOnFocus) setIsPlaying(inView);
  }, [inView]);

  return (
    <Box
      position="relative"
      maxH={vertical ? '600px' : 'none'}
      maxW={vertical ? '337px' : 'none'}
      w={awaysFullWidth ? 'full' : { base: '70%', md: 'full' }}
    >
      <AspectRatio
        ratio={vertical ? 9 / 16 : largerThan500 ? 16 / 9 : 9 / 16}
        maxH={vertical ? '600px' : 'none'}
        maxW={vertical ? '337px' : 'none'}
        w="full"
        zIndex={1}
        ref={ref}
      >
        <ReactPlayer
          loop
          playing={isPlaying}
          muted
          url={src}
          height="inherit"
          width="inherit"
        />
        {/* <iframe
          title={title || 'YouTube video player'}
          src={src || placeholder}
          allowFullScreen
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        /> */}
      </AspectRatio>
      <Box
        bg="transparent"
        w="100%"
        h="100%"
        p={4}
        position="absolute"
        top="-15px"
        left="15px"
        {...props}
      />
    </Box>
  );
}
