import { Box, Flex, Spacer, Text } from '@chakra-ui/react';
import React from 'react';
import DashedContainer from 'src/components/Container/DashedContainer';
import { formatCurrency } from 'src/utils/format';

export interface ReservationsValues {
  totalReservations: number;
  avgReservationsValue: number;
  totalTickets: number;
  avgTicketsValue: number;
  totalInvoice: number
}

export default function Reservations({
  avgReservationsValue,
  avgTicketsValue,
  totalReservations,
  totalTickets,
  totalInvoice
}: ReservationsValues) {
  return (
    <DashedContainer
      flex={1 / 2}
      fontSize="18px"
      fontWeight="bold"
      alignItems="center"
      justifyContent="center"
      borderColor="yellow"
    >
      <Flex w="100%" gap="20px" alignItems="center">
        <Text color="yellow">Total de reservas:</Text>
        <Text color="white">{totalReservations}</Text>
      </Flex>
      <Flex w="100%" gap="20px" alignItems="center">
        <Text color="yellow">Ticket médio das reservas:</Text>
        <Text color="white">{formatCurrency(avgReservationsValue)}</Text>
      </Flex>
      <Flex w="100%" gap="20px" alignItems="center">
        <Text color="yellow">Total de ingressos:</Text>
        <Text color="white">{totalTickets}</Text>
      </Flex>
      <Flex w="100%" gap="20px" alignItems="center">
        <Text color="yellow">Ticket médio do ingressos:</Text>
        <Text color="white">{formatCurrency(avgTicketsValue)}</Text>
      </Flex>
      <Flex w="100%" gap="20px" alignItems="center">
        <Text color="yellow">Total vendidos:</Text>
        <Text color="white">{formatCurrency(totalInvoice)}</Text>
      </Flex>
    </DashedContainer>
  );
}