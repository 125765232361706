import React from 'react';
import { Box, Flex, HStack, Text, VStack } from '@chakra-ui/react';
import dayjs from 'dayjs';
import DashedContainer from 'src/components/Container/DashedContainer';
import { BookingStatusResult } from 'src/api/interfaces/booking';

interface PlayerPurchaseSummaryProps {
  pendingPlayers: boolean;
  date: string;
  status: string | undefined;
  unit: string | undefined;
  tickets: number;
  time: string;
  identifyPlayers: boolean;
}

export const PlayerPurchaseSummary = ({
  pendingPlayers,
  date,
  status = '-',
  unit = '-',
  tickets,
  time,
  identifyPlayers,
  ...props
}: PlayerPurchaseSummaryProps) => {
  return (
    <>
      <DashedContainer
        w="full"
        h="full"
        alignItems={'flex-start'}
        p={0}
        {...props}
      >
        <Box p={8} w="full">
          <Text variant="title" fontSize="sm">
            HISTÓRICO DE COMPRAS
          </Text>
          <VStack w="full" alignItems={'flex-start'} py={5}>
            <SummaryText title={'DATA:'} value={date} />
            <SummaryText title={'STATUS DO PAGAMENTO:'} value={status} />
            <SummaryText title={'UNIDADE:'} value={unit} />
            <SummaryText title={'HORÁRIO:'} value={time} />
            <SummaryText title={'INGRESSOS:'} value={tickets.toString()} />
          </VStack>
        </Box>
        {identifyPlayers ? (
          <>
            {pendingPlayers ? (
              <Flex w="full" bg="gray" p={4} justify="center">
                <Text variant="text" color="black" fontWeight={'semibold'}>
                  Seus ingressos estarão disponíveis quando todos os jogadores
                  forem identificados.
                </Text>
              </Flex>
            ) : (
              <Flex w="full" bg="green" p={4} justify="center">
                <Text variant="text" color="black" fontWeight={'bold'}>
                  TODOS OS JOGADORES FORAM IDENTIFICADOS!
                </Text>
              </Flex>
            )}
          </>
        ) : (
          <>
            <Flex w="full" bg="green" p={4} justify="center">
              <Text variant="text" color="black" fontWeight={'bold'}>
                OS JOGADORES NÃO SERÃO IDENTIFICADOS!
              </Text>
            </Flex>
          </>
        )}
      </DashedContainer>
    </>
  );
};

const SummaryText = ({ title, value }: { title: string; value: string }) => {
  return (
    <HStack w="full" justify="space-between">
      <Text variant="highlighted" fontSize="sm" color="white">
        {title}
      </Text>
      <Text variant="highlighted" fontSize="sm">
        {value}
      </Text>
    </HStack>
  );
};
