import React, { useEffect } from 'react';
import TikTokPixel from 'tiktok-pixel';
import { UseFormReturn } from 'react-hook-form';
import { FormSelect } from 'src/components/Form/Select';
import { Button, Stack, StackDirection } from '@chakra-ui/react';
import Form from 'src/components/Form/Form';
import { useGetBusinessUnits } from 'src/api/services/businessUnit';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { IoLocationOutline } from 'react-icons/io5';
import { FiCalendar, FiUsers } from 'react-icons/fi';
import { FormInput } from 'src/components/Form/Input';
import { BookingActions } from 'src/redux/booking';
import * as fbq from '../../../utils/pixels/fpixel';

export interface SelectTicketFormValues {
  date: string;
  businessUnitID: string;
  numberOfPlayers: string;
}

interface SelectTicketFormProps {
  form: UseFormReturn<SelectTicketFormValues, any>;
  overrideSubmit?: (data: SelectTicketFormValues) => Promise<void>;
  direction?: StackDirection;
  showSubmit?: boolean;
  readonly?: boolean;
  externalLoading?: boolean;
}

export default function SelectTicketForm({
  form,
  overrideSubmit,
  direction = ['column', 'row'],
  showSubmit = true,
  readonly = false,
  externalLoading = false,
}: SelectTicketFormProps) {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = form;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: businessUnits, isLoading } = useGetBusinessUnits();

  const onSubmit = (data: SelectTicketFormValues) => {
    if (overrideSubmit) {
      overrideSubmit(data);
      return;
    }
    dispatch(BookingActions.setSearch(data));
    window.gtag('event', 'view_item');
    navigate('/ingressos');
  };

  const isMonday = (date: Date) => {
    const day = date.getDay();
    return day !== 1;
  };

  const unit = businessUnits?.find((item: any) => {
    return item.companyID === 1;
  });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction={direction} w={'full'} spacing={6}>
        <FormInput
          rhfRegister={{
            ...register('date', {
              required: 'Campo obrigatório',
              disabled: readonly,
            }),
          }}
          errorState={errors.date}
          icon={FiCalendar}
          inputProps={{ placeholder: 'DATA', type: 'date' }}
        />
        <FormSelect
          rhfRegister={{
            ...register('businessUnitID', {
              required: 'Campo obrigatório',
            }),
          }}
          errorState={errors.businessUnitID}
          icon={IoLocationOutline}
          selectUnit
        >
          <option value={1}>Unidade Santo Amaro</option>
        </FormSelect>

        <FormSelect
          rhfRegister={{
            ...register('numberOfPlayers', {
              required: 'Campo obrigatório',
              disabled: readonly,
            }),
          }}
          errorState={errors.numberOfPlayers}
          selectProps={{ placeholder: 'Número de Participantes', w: '100%' }}
          icon={FiUsers}
        >
          {Array.from({ length: 11 }).map((_, i) => (
            <option key={i} value={i + 2}>
              {i + 2}
            </option>
          ))}
        </FormSelect>

        {showSubmit && (
          <Button
            onClick={() => {
              fbq.event('AddToWishlist');
              TikTokPixel.track('AddToWishlist', {});
            }}
            isLoading={isLoading || externalLoading}
            type="submit"
            variant="pophausOutline"
            paddingInline={direction === 'column' ? 0 : 12}
          >
            continuar
          </Button>
        )}
      </Stack>
    </Form>
  );
}
