import React from 'react';
import {
  Control,
  Controller,
  FieldError,
  UseFormRegisterReturn,
} from 'react-hook-form';
import {
  Box,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputProps,
  Stack,
} from '@chakra-ui/react';
import InputMask from 'react-input-mask';

interface FormMaskedInputProps {
  id: string;
  control: Control<any>;
  mask: string;
  rules: any;
  errorState?: FieldError;
  inputProps?: InputProps;
  formControlProps?: FormControlProps;
  icon?: any;
  label?: string;
  readonly?: boolean;
  disabled?: boolean;
  width?: string;
  maskChar?: string | null
}

export const FormMaskedInput = ({
  id,
  control,
  mask,
  rules,
  errorState,
  icon,
  label,
  inputProps,
  formControlProps,
  readonly = false,
  disabled = false,
  width,
  maskChar = null
}: FormMaskedInputProps) => {
  return (
    <FormControl
      isRequired={rules?.required ?? false}
      isInvalid={!!errorState}
      isReadOnly={readonly}
      {...formControlProps}
      isDisabled={disabled}
    >
      {label && (
        <FormLabel color={'white'} htmlFor={id} width={width}>
          {label}
        </FormLabel>
      )}
      <Stack
        direction="row"
        alignItems="center"
        bg={'white'}
        border="1px"
        borderColor={'green'}
        pr={3}
      >
        {icon && (
          <Box pl={2}>
            <Icon as={icon} pointerEvents="none" color={'green'} />
          </Box>
        )}
        <Controller
          control={control}
          name={id}
          rules={rules}
          render={({ field }) => (
            <Input
              as={InputMask}
              mask={mask}
              maskChar={maskChar}
              alwaysShowMask={false}
              id={id}
              px={2}
              boxShadow={0}
              variant="flushed"
              color={'#131313'}
              _placeholder={{ color: 'gray' }}
              {...field}
              {...inputProps}
            />
          )}
        />
      </Stack>
      <FormErrorMessage color="red">
        {errorState && errorState.message}
      </FormErrorMessage>
    </FormControl>
  );
};
