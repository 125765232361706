import React from 'react'
import { Icon, Image } from "@chakra-ui/react"

import diamond from '../../../assets/about/DiamondIcon.png'
import checkCircle from '../../../assets/about/check-circle.png'

export const HandIcon = (props: any) => (
  <Icon viewBox='0 0 120 120' {...props}>
    <path d="M40.8697 103.334V84.4936H48.406V88.2617H44.6378V103.334H40.8697ZM40.8697 84.4936V76.9573H37.1016V84.4936H40.8697ZM48.406 73.1892H52.1741V69.4211H48.406V73.1892ZM52.1741 69.4211H67.2465V65.653H52.1741V69.4211ZM37.1016 65.653H33.3335V76.9573H37.1016V65.653ZM40.8697 61.8849H37.1016V65.653H40.8697V61.8849ZM67.2465 65.653H71.0146V61.8849H74.7828V58.1168H67.2465V65.653ZM44.6378 58.1168H40.8697V61.8849H44.6378V58.1168ZM78.5509 58.1168V43.0443H74.7828V58.1168H78.5509ZM67.2465 43.0443H63.4784V54.3486H44.6378V58.1168H67.2465V43.0443ZM40.8697 46.8124V54.3486H44.6378V46.8124H40.8697ZM78.5509 58.1168V61.8849H86.0871V84.4936H82.319V88.2617H86.0871V103.334H89.8552V43.0443H86.0871V58.1168H78.5509ZM55.9422 46.8124V43.0443H52.1741V46.8124H55.9422ZM86.0871 39.2762H78.5509V43.0443H86.0871V39.2762ZM59.7103 31.7399H55.9422V43.0443H59.7103V31.7399ZM37.1016 35.5081V46.8124H40.8697V35.5081H37.1016ZM52.1741 43.0443V35.5081H48.406V43.0443H52.1741ZM67.2465 43.0443H74.7828V39.2762H71.0146V31.7399H67.2465V43.0443ZM63.4784 20.4356H59.7103V31.7399H63.4784V20.4356ZM74.7828 31.7399V20.4356H71.0146V31.7399H74.7828ZM48.406 35.5081V24.2037H44.6378V35.5081H48.406ZM33.3335 24.2037V35.5081H37.1016V24.2037H33.3335ZM71.0146 16.6675H63.4784V20.4356H71.0146V16.6675ZM44.6378 20.4356H37.1016V24.2037H44.6378V20.4356ZM67.2465 92.0298H82.319V88.2617H67.2465V92.0298ZM55.9422 92.0298V88.2617H48.406V92.0298H55.9422Z" fill="#131313" />
  </Icon>
)

export const TargetIcon = (props: any) => (
  <Icon viewBox='0 0 120 120'  {...props}>
    <path d="M73.4279 31.1123H55.8022V34.2909H73.4279V31.1123Z" fill="#131313" stroke="#131313" />
    <path d="M70.5776 45.4521H58.6494V48.6308H70.5776V45.4521Z" fill="#131313" stroke="#131313" />
    <path d="M70.5776 82.0425H58.6494V85.2211H70.5776V82.0425Z" fill="#131313" stroke="#131313" />
    <path d="M76.2954 48.6682H70.5786V51.8469H76.2954V48.6682Z" fill="#131313" stroke="#131313" />
    <path d="M58.6496 48.6682H53.6958V51.8469H58.6496V48.6682Z" fill="#131313" stroke="#131313" />
    <path d="M58.6496 78.8636H53.6958V82.0423H58.6496V78.8636Z" fill="#131313" stroke="#131313" />
    <path d="M76.2954 78.8636H70.5786V82.0423H76.2954V78.8636Z" fill="#131313" stroke="#131313" />
    <path d="M79.1643 34.2903H73.4277V37.4689H79.1643V34.2903Z" fill="#131313" stroke="#131313" />
    <path d="M55.801 34.2903H50.0645V37.4689H55.801V34.2903Z" fill="#131313" stroke="#131313" />
    <path d="M84.9006 37.4683H79.1641V40.6469H84.9006V37.4683Z" fill="#131313" stroke="#131313" />
    <path d="M87.7687 40.6472H84.9004V43.8259H87.7687V40.6472Z" fill="#131313" stroke="#131313" />
    <path d="M90.6368 43.8252H87.7686V47.0038H90.6368V43.8252Z" fill="#131313" stroke="#131313" />
    <path d="M93.5045 47.0042H90.6362V50.1828H93.5045V47.0042Z" fill="#131313" stroke="#131313" />
    <path d="M96.3727 50.1829H93.5044V56.6896H96.3727V50.1829Z" fill="#131313" stroke="#131313" />
    <path d="M96.3727 56.6892V73.5885H93.5044V80.0953H96.3727V73.7716H99.241V56.6892H96.3727Z" fill="#131313" stroke="#131313" />
    <path d="M50.0647 37.4683H44.3281V40.6469H50.0647V37.4683Z" fill="#131313" stroke="#131313" />
    <path d="M73.4279 95.9878H55.8022V99.1664H73.4279V95.9878Z" fill="#131313" stroke="#131313" />
    <path d="M79.1643 92.8098H73.4277V95.9885H79.1643V92.8098Z" fill="#131313" stroke="#131313" />
    <path d="M55.801 92.8098H50.0645V95.9885H55.801V92.8098Z" fill="#131313" stroke="#131313" />
    <path d="M84.9006 89.6317H79.1641V92.8104H84.9006V89.6317Z" fill="#131313" stroke="#131313" />
    <path d="M87.7687 86.4529H84.9004V89.6315H87.7687V86.4529Z" fill="#131313" stroke="#131313" />
    <path d="M90.6368 83.274H87.7686V86.4527H90.6368V83.274Z" fill="#131313" stroke="#131313" />
    <path d="M93.5045 80.0952H90.6362V83.2739H93.5045V80.0952Z" fill="#131313" stroke="#131313" />
    <path d="M42.2233 45.841H45.0915V42.6624H42.2233V43.8252H39.355V47.0038H42.2233V45.841Z" fill="#131313" stroke="#131313" />
    <path d="M42.2233 39.4844H39.355V42.663H42.2233V39.4844Z" fill="#131313" stroke="#131313" />
    <path d="M30.8411 31.306V34.1647H33.7094V31.2964H30.9318V28.4377H28.0635V31.306H30.8411Z" fill="#131313" stroke="#131313" />
    <path d="M30.8411 25.5698H33.6181V28.4381H36.4864V25.5698H33.7094V22.7015H30.9318V19.8333H28.0635V22.7015H30.8411V25.5698Z" fill="#131313" stroke="#131313" />
    <path d="M47.9596 45.8411H45.0913V49.0197H47.9596V45.8411Z" fill="#131313" stroke="#131313" />
    <path d="M50.8278 52.1987V55.0255H53.696V51.8469H50.8278V49.02H47.9595V52.1987H50.8278Z" fill="#131313" stroke="#131313" />
    <path d="M56.5641 55.0251H53.6958V58.2038H56.5641V55.0251Z" fill="#131313" stroke="#131313" />
    <path d="M59.4322 58.2039H56.564V61.3825H59.4322V58.2039Z" fill="#131313" stroke="#131313" />
    <path d="M79.1642 51.8469H76.2959V55.0256H79.1642V51.8469Z" fill="#131313" stroke="#131313" />
    <path d="M79.1642 75.5105H76.2959V78.6891H79.1642V75.5105Z" fill="#131313" stroke="#131313" />
    <path d="M82.0323 55.0251H79.1641V59.6175H82.0323V55.0251Z" fill="#131313" stroke="#131313" />
    <path d="M82.0323 70.9177H79.1641V75.5101H82.0323V70.9177Z" fill="#131313" stroke="#131313" />
    <path d="M84.9005 59.6179H82.0322V70.9176H84.9005V59.6179Z" fill="#131313" stroke="#131313" />
    <path d="M53.6959 75.5105H50.8276V78.6891H53.6959V75.5105Z" fill="#131313" stroke="#131313" />
    <path d="M50.8278 56.7498H47.9595V59.618H50.8278V56.7498Z" fill="#131313" stroke="#131313" />
    <path d="M50.8278 70.9177H47.9595V75.5101H50.8278V70.9177Z" fill="#131313" stroke="#131313" />
    <path d="M47.9596 59.6179H45.0913V70.9176H47.9596V59.6179Z" fill="#131313" stroke="#131313" />
    <path d="M62.3004 61.3821H59.4321V64.5607H62.3004V61.3821Z" fill="#131313" stroke="#131313" />
    <path d="M65.1686 64.5608H62.3003V67.7394H65.1686V64.5608Z" fill="#131313" stroke="#131313" />
    <path d="M56.3735 64.7554V67.934H59.2418V70.9175H62.1101V67.7389H59.2418V64.7554H56.3735Z" fill="#131313" stroke="#131313" />
    <path d="M70.5787 59.793H67.7104V62.9716H70.5787V59.793Z" fill="#131313" stroke="#131313" />
    <path d="M73.4469 62.9717H70.5786V67.7393H73.4469V62.9717Z" fill="#131313" stroke="#131313" />
    <path d="M70.5787 67.7389H67.7104V70.9175H70.5787V67.7389Z" fill="#131313" stroke="#131313" />
    <path d="M67.6713 56.614H62.1484V59.7927H67.6713V56.614Z" fill="#131313" stroke="#131313" />
    <path d="M67.6713 70.7805H62.1484V73.9592H67.6713V70.7805Z" fill="#131313" stroke="#131313" />
    <path d="M39.3551 47.0042H36.4868V50.1828H39.3551V47.0042Z" fill="#131313" stroke="#131313" />
    <path d="M36.4869 50.1829H33.6187V56.6896H36.4869V50.1829Z" fill="#131313" stroke="#131313" />
    <path d="M36.4867 28.438V36.616H28.2564V33.7999H25.4794V30.9317H22.7018V28.438H25.1955H25.4794H28.0637V22.7014H25.1955V25.5697H22.6111V28.0634H19.8335V30.9317H22.6111V33.7999H25.3882V36.6682H28.0637V39.4843H36.4867H39.355V28.438H36.4867Z" fill="#131313" stroke="#131313" />
    <path d="M42.2233 83.274H39.355V86.4527H42.2233V83.274Z" fill="#131313" stroke="#131313" />
    <path d="M39.3551 80.0952H36.4868V83.2739H39.3551V80.0952Z" fill="#131313" stroke="#131313" />
    <path d="M33.6188 73.5885V56.6892H30.7505V73.7716H33.6188V80.0953H36.487V73.5885H33.6188Z" fill="#131313" stroke="#131313" />
    <path d="M45.0914 89.6315V86.4529H42.2231V89.6315H44.3284V92.8096H50.065V89.6315H45.0914Z" fill="#131313" stroke="#131313" />
  </Icon>
)

export const EyeIcon = (props: any) => (
  <Icon viewBox='0 0 120 120' {...props}>
    <path d="M50.6773 34.7086V31.4028H67.7488V34.7086H50.6773ZM47.7309 38.0142V41.3188H38.5581V38.0142H47.7309ZM70.6951 38.0142H79.868V41.3188H70.6951V38.0142Z" fill="#131313" stroke="#131313" strokeWidth="3.30556" />
    <path d="M31.7641 47.93V44.6242H35.2513V47.93H31.7641Z" fill="#131313" stroke="#131313" strokeWidth="3.30556" />
    <path d="M24.9741 54.5415V51.2357H28.4613V54.5415H24.9741Z" fill="#131313" stroke="#131313" strokeWidth="3.30556" />
    <path d="M18.1806 61.1531V57.8472H21.6678V61.1531H18.1806Z" fill="#131313" stroke="#131313" strokeWidth="3.30556" />
    <path d="M96.7587 61.1531V57.8472H100.246V61.1531H96.7587Z" fill="#131313" stroke="#131313" strokeWidth="3.30556" />
    <path d="M89.9667 54.5415V51.2357H93.454V54.5415H89.9667Z" fill="#131313" stroke="#131313" strokeWidth="3.30556" />
    <path d="M83.1733 47.93V44.6242H86.6605V47.93H83.1733Z" fill="#131313" stroke="#131313" strokeWidth="3.30556" />
    <path d="M57.4697 47.93V44.6242H60.9569V47.93H57.4697Z" fill="#131313" stroke="#131313" strokeWidth="3.30556" />
    <path d="M50.6762 54.5415V51.2357H54.1634V54.5415H50.6762Z" fill="#131313" stroke="#131313" strokeWidth="3.30556" />
    <path d="M43.8842 59.4212V57.8472H47.3714V59.4212H43.8842Z" fill="#131313" stroke="#131313" strokeWidth="3.30556" />
    <path d="M71.0532 59.4212V57.8472H74.5404V59.4212H71.0532Z" fill="#131313" stroke="#131313" strokeWidth="3.30556" />
    <path d="M64.2631 54.5415V51.2357H67.7503V54.5415H64.2631Z" fill="#131313" stroke="#131313" strokeWidth="3.30556" />
    <path d="M50.6762 66.0323V62.7265H54.1634V66.0323H50.6762Z" fill="#131313" stroke="#131313" strokeWidth="3.30556" />
    <path d="M64.2631 66.0323V62.7265H67.7503V66.0323H64.2631Z" fill="#131313" stroke="#131313" strokeWidth="3.30556" />
    <path d="M57.4697 72.6426V69.3367H60.9569V72.6426H57.4697Z" fill="#131313" stroke="#131313" strokeWidth="3.30556" />
    <path d="M67.7488 84.2915V87.5973H50.6773V84.2915H67.7488ZM70.6951 80.986V77.6801H79.868V80.986H70.6951ZM47.7309 80.986H38.5581V77.6801H47.7309V80.986Z" fill="#131313" stroke="#131313" strokeWidth="3.30556" />
    <path d="M83.1733 74.3745V71.0687H86.6605V74.3745H83.1733Z" fill="#131313" stroke="#131313" strokeWidth="3.30556" />
    <path d="M89.9667 67.7642V64.4583H93.454V67.7642H89.9667Z" fill="#131313" stroke="#131313" strokeWidth="3.30556" />
    <path d="M24.9741 67.7642V64.4583H28.4613V67.7642H24.9741Z" fill="#131313" stroke="#131313" strokeWidth="3.30556" />
    <path d="M31.7641 74.3745V71.0687H35.2513V74.3745H31.7641Z" fill="#131313" stroke="#131313" strokeWidth="3.30556" />
  </Icon>
)

export const DiamondIcon = () => (
  <Image src={diamond} w={24} />
)

export const CheckIcon = () => (
  <Image src={checkCircle} w={6} />
)