import React, { useEffect } from 'react';
import ReactGA from 'react-ga'
import TikTokPixel from 'tiktok-pixel'
import { Box, useMediaQuery, Text, Image, Stack, HStack, Icon, WrapItem, Wrap, SimpleGrid } from '@chakra-ui/react';
import PageContainer from 'src/components/Container/PageContainer';
import SectionDivider from 'src/components/Section/SectionDivider';
import Section from 'src/components/Section';
import BgHome from 'src/assets/about/bg_about.webp';
import BgSec3 from 'src/assets/about/bg_about_1.webp'
import PrizeLogo from 'src/assets/about/prizeLogo.png'
import SectionStart from 'src/assets/about/section_start.webp'
import SectionTwo from 'src/assets/about/section_2.webp'
import SectionThree from 'src/assets/about/section_3.webp'
import ImageBox from 'src/components/ImageBox/ImageBox';
import { CheckIcon, DiamondIcon, EyeIcon, HandIcon, TargetIcon } from './icons';

export default function AboutUs() {
  const [smallerThan1390] = useMediaQuery('(max-width: 1390px)')
  const [smallerThan900] = useMediaQuery('(max-width: 900px)')
  const [smallerThan500] = useMediaQuery('(max-width: 500px)')


  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    TikTokPixel.pageView()
  }, [])

  return (
    <PageContainer containerProps={{ py: 0, px: 0, spacing: 0 }}>
      <Box overflow={'hidden'} position="relative" w='99vw' maxW='100vw'>
        <Image

          opacity={0.4}
          position={'absolute'}
          left={0}
          top={0}
          width={'100%'}
          h='100%'
          src={BgHome}
          fit="cover"
          objectPosition={'center center'}
        />
        <Section
          position="relative"
          px={{ base: 0, md: 64 }}
          minH='75vh'
          gap={smallerThan900 ? 0 : 6}
          direction='row'
          textAlign='center'
        >
          <Stack h='100%' align='center' justify='center'>
            <Text
              fontSize={smallerThan500 ? '24px' : smallerThan1390 ? '36px' : '56px'}
              fontWeight={'black'}
              lineHeight={'normal'}
              textTransform={'uppercase'}
              color="white"
              w="full"
            >
              Uma nova experiência em videogames
            </Text>
            <Text variant='text' fontSize={smallerThan1390 ? '14px' : '20px'}>
              O primeiro fliperama totalmente imersivo da América Latina!
            </Text>
          </Stack>
        </Section>
        <SectionDivider squareColor='green' />
      </Box>

      <Section bg='green'>
        <Stack gap={12} w='100%' direction={['column', 'row']}>
          <Stack gap={6} flex={1 / 2} justifyContent='center' alignItems='center' >
            <HStack justify='center'>
              <HandIcon boxSize={24} />
              <Text fontWeight='bold' fontSize={smallerThan900 ? '42px' : '56px'}>START!</Text>
            </HStack>
            <Text maxW='500px' fontSize={smallerThan900 ? '15px' : '18px'}>Inaugurado em 2023, o <b>Arcade Haus</b> traz uma nova experiência em videogames, que te faz sentir dentro do jogo.</Text>
            <Text maxW='500px' fontSize={smallerThan900 ? '15px' : '18px'}>Na sala com botões no piso e nas paredes, você precisa executar com seu próprio corpo o comando do jogo. Totalmente imersivo,
              como você nunca viu!</Text>
          </Stack>

          <Box flex={1 / 2} maxH='600px' w='100%' justifyContent='center' alignItems='center'>
            <ImageBox maxW='500px' imageMaxWidth='500px' aspectRatio={1 / 1} border='2px solid white' src={SectionStart} objectPosition={'center top'} />
          </Box>
        </Stack>
      </Section>


      <Box overflow={'hidden'} position="relative" w='99vw' maxW='100vw'>
        <SectionDivider squareColor='green' isSmallSquare />
        <Image
          opacity={0.4}
          position={'absolute'}
          left={0}
          top={0}
          width={'100%'}
          h='100%'
          src={BgSec3}
          fit="cover"
          objectPosition={'center center'}
        />
        <Section
          position="relative"
          direction='column'
          justifyContent='center'
          alignItems='center'
          textAlign='center'
        >
          <Image borderRadius='100%' w='120px' h='120px' src={PrizeLogo} />
          <Text variant='text' fontSize={'24px'}>
            <Text fontSize='26px' color='#F9E312'>Entretenimento mais inovador de 2023</Text>
            <Text>segundo o prêmio Maurício de Souza, que reconhece empresas</Text>
            <Text>do setor de parques de diversão de todo o Brasil.</Text>
          </Text>
        </Section>
        <SectionDivider squareColor='red' />
      </Box>

      <Section bg='red'>
        <Stack gap={12} w='100%' direction={['column', 'row']}>
          <Stack gap={6} flex={1 / 2} justifyContent='center' alignItems='center' >
            <HStack justify='center'>
              <TargetIcon boxSize={24} />
              <Text fontWeight='bold' fontSize={smallerThan900 ? '42px' : '56px'}>MISSÃO</Text>
            </HStack>
            <Text maxW='500px' fontSize={smallerThan900 ? '15px' : '18px'}>Promover uma <b>experiência de excelência</b>, <b>inédita</b> e <b>imersiva em videogames</b>, conectando diferentes grupos e gerações.</Text>

          </Stack>

          <Box flex={1 / 2} maxH='600px' w='100%' justifyContent='center' alignItems='center'>
            <ImageBox maxW='500px' imageMaxWidth='500px' aspectRatio={1 / 1} border='2px solid white' src={SectionTwo} objectPosition={'center top'} />
          </Box>
        </Stack>
      </Section>

      <Box overflow={'hidden'} position="relative" w='99vw' maxW='100vw'>
        <SectionDivider squareColor='red' position='absolute' />
        <Section bg='blue'>
          <Stack gap={12} w='100%' direction={['column', 'row']}>
            <Box flex={1 / 2} maxH='600px' w='100%' justifyContent='center' alignItems='center'>
              <ImageBox maxW='500px' imageMaxWidth='500px' aspectRatio={1 / 1} border='2px solid white' src={SectionThree} objectPosition={'center top'} />
            </Box>

            <Stack gap={6} flex={1 / 2} justifyContent='center' alignItems='center' >
              <HStack justify='center'>
                <EyeIcon boxSize={24} />
                <Text fontWeight='bold' fontSize={smallerThan900 ? '42px' : '56px'}>VISÃO</Text>
              </HStack>
              <Text maxW='500px' fontSize={smallerThan900 ? '15px' : '18px'}>Ser referência em <b>games imersivos e interativos</b>  na América Latina.</Text>
            </Stack>
          </Stack>
        </Section>
        <SectionDivider isSmallSquare squareColor='yellow' spaceColor='blue' />
      </Box>

      <Section bg='yellow' >
        <Stack my={12} w='full' justify='center' align='center'>
          <HStack justify='center'>
            <DiamondIcon />
            <Text fontWeight='bold' fontSize={smallerThan900 ? '42px' : '56px'}>VALORES</Text>
          </HStack>
          <SimpleGrid maxW='900px' spacingX={12} spacingY={6} columns={[1, 2]}>
            <HStack>
              <CheckIcon />
              <Text><b>Segurança:</b> sua única preocupação é se divertir!</Text>
            </HStack>
            <HStack>
              <CheckIcon />
              <Text><b>Ética:</b> Respeito e integridade em todas as relações interpessoais e comerciais</Text>
            </HStack>
            <HStack>
              <CheckIcon />
              <Text><b>Excelência no atendimento:</b> Entregar satisfação em todo o atendimento, proporcionando ao cliente uma experiência especial e envolvente!</Text>
            </HStack>
            <HStack>
              <CheckIcon />
              <Text><b>Diversão:</b> Se divertir é um direito de todos e promover a diversão é o que nos move</Text>
            </HStack>
            <HStack>
              <CheckIcon />
              <Text><b>Inclusão:</b> Aumentar a diversidade do nosso público e das nossas equipes</Text>
            </HStack>
            <HStack>
              <CheckIcon />
              <Text><b>Colaboração:</b> Com uma missão em comum, agregamos esforços e alcançamos os melhores resultados</Text>
            </HStack>
            <HStack>
              <CheckIcon />
              <Text><b>Inovação:</b> Entregar experiências inéditas e exclusivas</Text>
            </HStack>
          </SimpleGrid >
        </Stack>
      </Section>
    </PageContainer >
  );
}
