import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import PageContainer from 'src/components/Container/PageContainer';
import ProfileInfoForm from 'src/modules/Forms/Backoffice/ProfileInfoForm';
import DashedContainer from 'src/components/Container/DashedContainer';
import ActiveTicketCard from '../../../../components/TicketCard/ActiveTicketCard';
import InactiveTicketCard from '../../../../components/TicketCard/InactiveTicketCard';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetUserActiveBooking,
  useGetUserBookingHistory,
} from 'src/api/services/booking';
import { BsExclamationTriangle } from 'react-icons/bs';
import { useGetUserByID } from 'src/api/services/user';
import { BookingResult } from 'src/api/interfaces/booking';
import { Loading } from 'src/components/Loading';
import usePHToast from 'src/hooks/useToast';
import dayjs from 'dayjs';
import { useGetUserAttachments } from 'src/api/services/match';

export default function RegistryProfile() {
  const { userID } = useParams<{ userID: string }>();
  const navigate = useNavigate();
  const toast = usePHToast();
  const { data: medias } = useGetUserAttachments(userID);

  const [userBirthDate, setUserBirthDate] = useState<string>('');
  const { data: user, isLoading: isGetUserLoading } = useGetUserByID(userID);
  const {
    data: activeBookings,
    isLoading: isBookingsLoading,
    mutate,
  } = useGetUserActiveBooking(userID);
  const { data: bookingHistory, isLoading: isHistoryLoading } =
    useGetUserBookingHistory(userID);

  useEffect(() => {
    setUserBirthDate(dayjs(user?.birthDate).format('DD/MM/YYYY'));
  }, [user]);

  return (
    <PageContainer restricted>
      {isGetUserLoading || isBookingsLoading || isHistoryLoading ? (
        <Loading />
      ) : (
        <Box w="100%">
          <Stack mb={6} justifyContent="space-between" direction="row">
            <Text variant="title">{`${user?.name} ${user?.lastName} `}</Text>
            <Text onClick={() => navigate('/admin/cadastros')} variant="link">
              voltar
            </Text>
          </Stack>
          <DashedContainer mb={12}>
            <ProfileInfoForm
              userId={user?.userID}
              name={user?.name}
              lastName={user?.lastName}
              nickName={user?.nickName}
              document={user?.document}
              birthDate={userBirthDate}
              email={user?.email}
              zipCode={user?.zipCode}
              address={user?.address}
              number={user?.number}
              complement={user?.complement}
              city={user?.city.name}
              state={user?.city.state.abbreviation}
              phone={user?.phone}
            />
          </DashedContainer>
          <Text mb={6} variant="title">
            Ingressos Ativos
          </Text>
          <Box mb={12}>
            {activeBookings && activeBookings.length > 0 ? (
              activeBookings.map((item: BookingResult) => {
                let roomsFormat = '';
                for (let i = 0; i < item.bookingRooms.length; i++) {
                  if (i < item.bookingRooms.length - 1) {
                    roomsFormat += `${item.bookingRooms[i].room?.description} e `;
                  } else {
                    roomsFormat += `${item.bookingRooms[i].room?.description}`;
                  }
                }

                return (
                  <ActiveTicketCard
                    key={item.bookingID}
                    bookingID={item.bookingID}
                    bookingTypeID={item.bookingTypeID}
                    date={dayjs(item.startDate).format('DD/MM/YYYY')}
                    time={
                      item.bookingRooms.length > 0
                        ? `${dayjs(item.bookingRooms[0].startTime).format(
                          'HH:mm'
                        )} ÀS ${dayjs(item.bookingRooms[0].endTime).format(
                          'HH:mm'
                        )}`
                        : '-'
                    }
                    name={item.user?.name}
                    room={roomsFormat}
                    tickets={item.tickets}
                    unit={
                      item.bookingRooms.length > 0
                        ? item.bookingRooms[0].room?.businessUnit.description
                        : '-'
                    }
                    players={item.bookingPlayers}
                    mutate={mutate}
                  />
                );
              })
            ) : (
              <DashedContainer>
                <HStack gap={3}>
                  <Icon
                    w="36px"
                    h="36px"
                    color="yellow"
                    as={BsExclamationTriangle}
                  />
                  <Text color="white" textTransform="uppercase">
                    Não há reservas ativas para esse usuário.
                  </Text>
                </HStack>
              </DashedContainer>
            )}
          </Box>

          <Text variant="title" color="white" mb={6}>
            Histórico de agendamentos
          </Text>
          <Box mb={12}>
            {bookingHistory && bookingHistory.length > 0 ? (
              bookingHistory.map((item: BookingResult) => {
                let roomsFormat = '';
                for (let i = 0; i < item.bookingRooms.length; i++) {
                  if (i < item.bookingRooms.length - 1) {
                    roomsFormat += `${item.bookingRooms[i].room?.description} e `;
                  } else {
                    roomsFormat += `${item.bookingRooms[i].room?.description}`;
                  }
                }
                return (
                  <InactiveTicketCard
                    key={item.bookingID}
                    bookingID={item.bookingID}
                    date={dayjs(item.startDate).format('DD/MM/YYYY')}
                    medias={medias}
                    status={item.bookingStatusID}
                    time={
                      item.bookingRooms.length > 0
                        ? `${dayjs(item.bookingRooms[0].startTime).format(
                          'HH:mm'
                        )} ÀS ${dayjs(item.bookingRooms[0].endTime).format(
                          'HH:mm'
                        )}`
                        : '-'
                    }
                    name={item.user?.name}
                    room={roomsFormat}
                    tickets={item.tickets}
                    unit={
                      item.bookingRooms.length > 0
                        ? item.bookingRooms[0].room?.businessUnit.description
                        : '-'
                    }
                  />
                );
              })
            ) : (
              <DashedContainer>
                <HStack gap={3}>
                  <Icon
                    w="36px"
                    h="36px"
                    color="yellow"
                    as={BsExclamationTriangle}
                  />
                  <Text color="white" textTransform="uppercase">
                    Não há histórico de reservas para esse usuário.
                  </Text>
                </HStack>
              </DashedContainer>
            )}
          </Box>
        </Box>
      )}
    </PageContainer>
  );
}
