import React from 'react';
import { Accordion } from '@chakra-ui/react';

interface FormAccordionProps {
  children: React.ReactNode;
  onChange?: any;
  index?: number;
}

export const FormAccordion = ({
  children,
  onChange,
  index,
  ...props
}: FormAccordionProps) => {
  return (
    <Accordion w="full" allowToggle onChange={onChange} index={index}>
      {children}
    </Accordion>
  );
};
