import { createSlice } from '@reduxjs/toolkit';
import { BusinessUnitResult } from 'src/api/interfaces/businessUnit';
import { AdminAgendaFilterFormValues } from 'src/modules/Forms/Backoffice/AgendaFiltersForm';

interface ResultState {
  result: BusinessUnitResult | null;
  search: AdminAgendaFilterFormValues;
  calendarUnitRooms: any;
}

const initialResultState: ResultState = {
  result: null,
  search: {
    date: '',
    tickets: 0,
    businessUnitID: '',
  },
  calendarUnitRooms: null,
};

const calendarResultSlice = createSlice({
  name: 'calendarResult',
  initialState: initialResultState,
  reducers: {
    setResult: (state, action) => {
      state.result = action.payload;
    },
    resetResult: (state) => {
      state.result = null;
    },
    setSearchParams: (state, action) => {
      state.search = action.payload;
    },
    resetSearchParams: (state) => {
      state.search = initialResultState.search;
    },
    setCalendarResultRooms: (state, action) => {
      state.calendarUnitRooms = action.payload;
    },
    resetCalendarResultRooms: (state) => {
      state.calendarUnitRooms = initialResultState.calendarUnitRooms;
    },
  },
});

export const calendarResultActions = calendarResultSlice.actions;
export const calendarResultReducer = calendarResultSlice.reducer;
