import { ApiRepository, ApiResponse, useFetch } from 'src/api';
import {
  AvailableHoursResult,
  AvailableHoursResultAdmin,
} from 'src/api/interfaces/availableTime';
import { RoomResult } from 'src/api/interfaces/room';

const api = new ApiRepository();

export async function getSchedulesAvailable(
  roomID: number,
  date: string,
  bookingTypeID: number
) {
  const res = await api.apiRequestWrapper<ApiResponse<AvailableHoursResult[]>>({
    method: 'get',
    url: `Room/SchedulesAvailable?RoomID=${roomID}&Date=${date}&BookingTypeID=${bookingTypeID}`,
  });
  return res.result;
}

export async function getSchedulesAvailableAdmin(roomID: number, date: string) {
  const res = await api.apiRequestWrapper<
    ApiResponse<AvailableHoursResultAdmin[]>
  >({
    method: 'get',
    url: `Room/SchedulesAvailableAdmin?RoomID=${roomID}&Date=${date}`,
  });
  return res.result;
}

export async function getSchedulesAvailableForTwoRooms(
  roomIDs: number[],
  date: string
) {
  let queryString = 'Room/SchedulesAvailableForTwoRooms?';

  for (let i = 0; i < roomIDs.length; i++) {
    if (i == 0) {
      queryString += 'RoomIDs=' + roomIDs[i];
    } else {
      queryString += '&RoomIDs=' + roomIDs[i];
    }
  }

  const res = await api.apiRequestWrapper<ApiResponse<AvailableHoursResult[]>>({
    method: 'get',
    url: `${queryString}&Date=${date}`,
  });
  return res.result;
}

// export function useGetSchedulesAvailable(roomID: number, date: string) {
//   const args =
//     roomID && date
//       ? {
//           method: 'get',
//           url: `Room/SchedulesAvailable?RoomID=${roomID}&Date=${date}`,
//         }
//       : null;

//   const { data, error, isLoading } = useFetch<
//     ApiResponse<AvailableHoursResult[]>
//   >(api, args);
//   return { data: data?.result, error, isLoading };
// }

// export function useSchedulesAvailableForTwoRooms(
//   roomIDs: number[],
//   date: string
// ) {
//   let queryString = 'Room/SchedulesAvailableForTwoRooms?';

//   for (let i = 0; i < roomIDs.length; i++) {
//     if (i == 0) {
//       queryString += 'RoomIDs=' + roomIDs[i];
//     } else {
//       queryString += '&RoomIDs=' + roomIDs[i];
//     }
//   }

//   const args =
//     roomIDs && date
//       ? {
//           method: 'get',
//           url: `${queryString}&Date=${date}`,
//         }
//       : null;

//   const { data, error, isLoading } = useFetch<
//     ApiResponse<AvailableHoursResult[]>
//   >(api, args);
//   return { data: data?.result, error, isLoading };
// }

export function useGetRoom(RoomID: number) {
  const args = RoomID
    ? {
        method: 'get',
        url: `Room/${RoomID}`,
      }
    : null;

  const { data, error, isLoading } = useFetch<ApiResponse<RoomResult>>(
    api,
    args,
    { revalidateOnFocus: false }
  );
  return { data: data?.result, error, isLoading };
}

export async function createRoom(RoomRequest: string) {
  const res = await api.apiRequestWrapper<RoomResult>({
    method: 'post',
    url: 'Room',
    data: { RoomRequest },
  });
  return res;
}

export async function updateRoom(RoomRequest: string, RoomID: number) {
  const res = await api.apiRequestWrapper<RoomResult>({
    method: 'put',
    url: `Room?roomID=${RoomID}`,
    data: { RoomRequest },
  });
  return res;
}
