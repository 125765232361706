export const formatCurrency = (value: number) => {
  return Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);
};

export function truncateName(name: string, maxLength: number) {
  if (name.length <= maxLength) {
    return name;
  }

  const truncatedName = name.substring(0, maxLength - 3) + "...";
  return truncatedName;
}