import React, { useEffect, useState } from 'react';
import { CalendarIcon, QuestionIcon, TimeIcon } from '@chakra-ui/icons';
import {
  Box,
  HStack,
  Icon,
  Spacer,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { FaEdit, FaRegWindowClose } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { ErrorModal } from 'src/components/Modal/ModalVariants/ErrorModal';
import usePHToast from 'src/hooks/useToast';
import { BsAlarm } from 'react-icons/bs';
import { PophausModal } from 'src/components/Modal';
import { FaqRequest, FaqResult } from 'src/api/interfaces/faq';
import { MdNumbers, MdOnlinePrediction, MdQuestionAnswer } from 'react-icons/md';
import { deleteFAQ, putOrderFAQ, putToggleFAQ, putUpdateFAQ } from 'src/api/services/faq';
import UpdateFaqModal from 'src/components/Modal/ModalVariants/UpdateFaqModal';

export const useAdminFaqColumns = (reloadGet: () => void) => {
  const columnHelper = createColumnHelper<FaqResult>();

  const [loading, setLoading] = useState(false);

  const columns: any = [
    columnHelper.accessor('order', {
      cell: (info) => (
        <Stack direction="row" alignItems="center">
          <MdNumbers />
          <Text variant="text">
            {info.getValue()}
          </Text>
        </Stack>
      ),
      header: () => <span>Ordem</span>,
    }),
    columnHelper.accessor('active', {
      cell: (info) => (
        <Stack direction="row" alignItems="center">
          <MdOnlinePrediction />

          <Text variant="text">
            {info.getValue() === true ? 'Ativa' : 'Desativada'}
          </Text>
        </Stack>
      ),
      header: () => <span>Pergunta</span>,
    }),
    columnHelper.accessor('question', {
      cell: (info) => (
        <Stack direction="row" alignItems="center">
          <QuestionIcon />

          <Text variant="text">
            {info.getValue()}
          </Text>
        </Stack>
      ),
      header: () => <span>Pergunta</span>,
    }),

    columnHelper.accessor('answer', {
      cell: (info) => (
        <Stack direction="row" alignItems="center">
          <MdQuestionAnswer />

          <Text variant="text">
            {info.getValue()}
          </Text>
        </Stack>
      ),
      header: () => <span>Resposta</span>,
    }),


    columnHelper.display({
      id: 'actions',
      cell: (info) => {
        const toast = usePHToast();
        const faqID = info.row.original.faqID;
        const question = info.row.original.question
        const answer = info.row.original.answer
        const order = info.row.original.order
        const isActive = info.row.original.active

        // Update faq
        const {
          onClose: onEditClose,
          onOpen: onEditOpen,
          isOpen: isEditOpen,
        } = useDisclosure();

        const handleUpdate = async (q: string, a: string, o?: number, isActive?: boolean) => {
          setLoading(true)
          try {
            const data: FaqRequest = {
              question: q,
              answer: a
            }
            const res = await putUpdateFAQ(faqID, data)
            toast({
              status: 'success',
              description: 'Pergunta alterada com sucesso!',
            });
            if (o) {
              const orderRes = await putOrderFAQ(faqID, o)
            }
            if (isActive != undefined) {
              const activeRes = await putToggleFAQ(faqID, isActive)
            }
            reloadGet();
          } catch {
            toast({
              status: 'error',
              description: 'Erro ao alterar a pergunta.',
            });
          } finally {
            setLoading(false);
            onEditClose();
          }
        }

        // Delete faq
        const {
          isOpen: isCancelOpen,
          onOpen: onCancelOpen,
          onClose: onCancelClose,
        } = useDisclosure();

        const handleDelete = async () => {
          setLoading(true);
          try {
            const res = await deleteFAQ(faqID)
            toast({
              status: 'success',
              description: 'Pergunta apagada com sucesso!',
            });
            reloadGet();
          } catch {
            toast({
              status: 'error',
              description: 'Erro ao apagar a pergunta.',
            });
          } finally {
            setLoading(false);
            onCancelClose();
          }
        };

        return (
          <HStack spacing={4} pl={6} >
            <Spacer borderRight="2px dashed #B0CA0A" />
            <Box
              justifyContent="center"
              as="button"
              _hover={{ color: '#B0CA0A' }}
              onClick={onEditOpen}
            >
              <Icon as={FaEdit} w="20px" h="20px" />
              <Text>Editar</Text>
            </Box>

            <Box
              justifyContent="center"
              as="button"
              _hover={{ color: '#B0CA0A' }}
              onClick={onCancelOpen}
            >
              <Icon as={FaRegWindowClose} w="20px" h="20px" />
              <Text>Apagar</Text>
            </Box>

            <UpdateFaqModal handleClick={handleUpdate} question={question} answer={answer} order={order} isActive={isActive} isOpen={isEditOpen} onClose={onEditClose} />
            <ErrorModal
              isOpen={isCancelOpen}
              onClose={onCancelClose}
              loading={loading}
              content={'DESEJA APAGAR A PERGUNTA?'}
              handleMainClick={handleDelete}
            />
          </HStack >
        );
      },
    }),
  ];

  return columns;
};
