import React from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';
import { FormInput } from 'src/components/Form/Input';
import { FormSelect } from 'src/components/Form/Select';
import { FormInputPassword } from 'src/components/Form/Input/PasswordInput';
import { SearchIcon } from '@chakra-ui/icons';
import { Box, Button, Stack } from '@chakra-ui/react';
import Form from 'src/components/Form/Form';
import { useGetBusinessUnits } from 'src/api/services/businessUnit';

export interface AdminFaqFilterFormValues {
  searchTerm: string;
}

interface AdminFaqFilterFormProps {
  form: UseFormReturn<AdminFaqFilterFormValues, any>;
  onSubmit: (data: AdminFaqFilterFormValues) => Promise<void>;
}

export default function AdminFaqFilterForm({
  form,
  onSubmit,

}: AdminFaqFilterFormProps) {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = form;

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction={['column', 'row']} w={'full'} spacing={6}>
        <FormInput
          rhfRegister={{
            ...register('searchTerm'),
          }}
          icon={SearchIcon}
          inputProps={{ placeholder: 'PESQUISAR CUPONS' }}
          formControlProps={{ width: '200%' }}
        />
        {/* <Button type="submit" variant="pophausOutline" p={0} w="full">
                    OK
                </Button> */}
      </Stack>
    </Form>
  );
}