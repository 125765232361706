import React, { useEffect, useState } from 'react'
import { Box, Button, Center, HStack, Stack, Text, VStack, useDisclosure, useMediaQuery } from '@chakra-ui/react'
import PageContainer from 'src/components/Container/PageContainer'
import SearchRegistryForm, { SearchRegistryFormValues } from 'src/modules/Forms/Backoffice/SearchRegistry'
import { useForm } from 'react-hook-form';
import { getFilteredUsers } from 'src/api/services/user';
import usePHToast from 'src/hooks/useToast';
import { getFilteredFAQ, postCreateFAQ, useGetFaq } from 'src/api/services/faq';
import { FaqRequest, FaqResult } from 'src/api/interfaces/faq';
import { Loading } from 'src/components/Loading';
import { PaginatedTable } from 'src/components/Table/PaginatedTable';
import { useAdminFaqColumns } from './TableData';
import UpdateFaqModal from 'src/components/Modal/ModalVariants/UpdateFaqModal';
import AdminFaqFilterForm from 'src/modules/Forms/Backoffice/SearchFaq';

export default function AdminFAQ() {
  const [largerThan768] = useMediaQuery('(min-width: 768px)')
  const [loading, setLoading] = useState(false)
  const toast = usePHToast()
  const form = useForm<SearchRegistryFormValues>();
  const { data } = useGetFaq()
  const { handleSubmit } = form;
  const [faqData, setFaqData] = useState<FaqResult[]>()

  const handleSearch = async (data?: SearchRegistryFormValues) => {
    try {
      setLoading(true);
      if (data) {
        const res = await getFilteredFAQ(data.searchTerm);
        setFaqData(res)
      }
    } catch (e: any) {
      toast({ status: 'error', title: e.message })
    } finally {
      setLoading(false);
    }
  };
  const columns = useAdminFaqColumns(handleSearch)

  // Create faq
  const { isOpen, onClose, onOpen } = useDisclosure()

  const handleCreate = async (q: string, a: string) => {
    setLoading(true)
    try {
      const data: FaqRequest = {
        question: q,
        answer: a
      }
      const res = await postCreateFAQ(data)
      toast({
        status: 'success',
        description: 'Pergunta criada com sucesso!',
      });
      onClose();
      handleSearch()
    } catch {
      toast({
        status: 'error',
        description: 'Erro ao criar a pergunta.',
      });
    } finally {
      setLoading(false);
    }
  }


  useEffect(() => {
    setFaqData(data)
  }, [data])

  return (
    <PageContainer restricted>
      <Stack
        direction="column"
        pt={8}
        spacing={6}
        width='auto'
        margin="0 1rem">
        <HStack>
          <AdminFaqFilterForm form={form} onSubmit={handleSearch} />
          {/* <Button
            type="submit"
            variant="pophausOutline"
            onClick={handleSubmit(handleSearch)}
            isLoading={loading}
          >
            OK
          </Button> */}
          <Button
            variant="pophausOutline"
            onClick={onOpen}
            isLoading={loading}
          >
            Nova Pergunta
          </Button>
        </HStack>
        {loading ? (
          <Center>
            <Loading />
          </Center>
        ) : (
          <PaginatedTable data={faqData} columns={columns} hasSort width={largerThan768 ? 'calc(100vw - 15rem)' : 'calc(100vw - 2rem)'} padding={2} overflowX='scroll' />
        )}
      </Stack>
      <UpdateFaqModal isOpen={isOpen} onClose={onClose} handleClick={handleCreate} />
    </PageContainer>
  )
}