import React from 'react';
import { BsCheckCircle } from 'react-icons/bs';
import { PophausModal } from '../..';

interface SuccessModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleMainClick: () => void;
  content?: string;
}

export function SuccessModal({
  isOpen,
  onClose,
  handleMainClick,
  content = 'PAGAMENTO CONCLUÍDO COM SUCESSO!',
}: SuccessModalProps) {
  return (
    <PophausModal
      isOpen={isOpen}
      onClose={onClose}
      icon={BsCheckCircle}
      content={content}
      handleMainClick={handleMainClick}
    />
  );
}
