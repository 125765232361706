import { alertAnatomy } from '@chakra-ui/anatomy';
import {
  createMultiStyleConfigHelpers,
  UseToastOptions,
} from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(alertAnatomy.keys);

const baseStyle = definePartsStyle((props) => {
  const { status } = props;

  const s: UseToastOptions['status'] = status;

  const colors = {
    error: 'red',
    info: 'blue',
    success: 'green',
    warning: 'orange',
    loading: 'blue',
  };

  const mappedColor = colors[s || 'info'];
  return {
    container: {
      bg: mappedColor,
    },
    title: {
      color: mappedColor === 'green' ? 'black' : 'white',
    },
  };
});

export const alertTheme = defineMultiStyleConfig({ baseStyle });
