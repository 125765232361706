import React from 'react';
import {
  Control,
  Controller,
  FieldError,
  UseFormRegisterReturn,
} from 'react-hook-form';
import {
  Box,
  FormControl,
  FormControlProps,
  FormLabel,
  Switch,
  SwitchProps,
} from '@chakra-ui/react';

interface FormSwitchProps {
  rhfRegister: UseFormRegisterReturn;
  switchProps?: SwitchProps;
  formControlProps?: FormControlProps;
  label?: string;
  readonly?: boolean;
}

export const FormSwitch = ({
  rhfRegister,
  label,
  switchProps,
  formControlProps,
  readonly = false,
}: FormSwitchProps) => {
  const id = rhfRegister.name;
  return (
    <FormControl
      isRequired={rhfRegister.required}
      isReadOnly={readonly}
      isDisabled={readonly}
      {...formControlProps}
    >
      {label && (
        <FormLabel color={'white'} htmlFor={id}>
          {label}
        </FormLabel>
      )}
      <Box bg="white" p={2}>
        <Switch
          sx={{
            'span.chakra-switch__track:not([data-checked])': {
              backgroundColor: 'red',
            },
            'span.chakra-switch__track:is([data-checked])': {
              backgroundColor: 'green',
            },
          }}
          id={id}
          px={2}
          borderBottom={0}
          {...switchProps}
          {...rhfRegister}
        />
      </Box>
    </FormControl>
  );
};
