import React, { useEffect } from 'react';
import ReactGA from 'react-ga'
import TikTokPixel from 'tiktok-pixel'
import { Box, Center, Flex, useMediaQuery } from '@chakra-ui/react';
import PageContainer from 'src/components/Container/PageContainer';
import SectionDivider from 'src/components/Section/SectionDivider';
import GameItem from 'src/modules/Games/GameItem';
import { useGetAllGames } from 'src/api/services/game';
import { Loading } from 'src/components/Loading';
import gamesJson from './games.json';

const gameStyles = [
  {
    gameItem: { textColor: 'green', borderColor: 'green' },
  },
  {
    sectionDivider: { squareColor: 'green' },
    gameItem: {
      reverse: true,
      bg: 'green',
      textColor: 'black',
      borderColor: 'white',
    },
  },
  {
    gameItem: { bg: 'red', textColor: 'white', borderColor: 'white' },
  },
  {
    sectionDivider: { bg: 'red', isSmallSquare: true, squareColor: 'blue' },
    gameItem: {
      reverse: true,
      bg: 'blue',
      textColor: 'black',
      borderColor: 'white',
    },
  },
];

export default function Games() {
  const { data: games, isLoading } = useGetAllGames(true);
  const [largerThan500] = useMediaQuery('(min-width: 500px)')

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    TikTokPixel.pageView()
  }, [])

  return (
    <PageContainer containerProps={{ py: 0, px: 0, spacing: 0 }}>
      {isLoading && (
        <Center w="full" h="full" minH={{ base: '50vh', md: 'none' }}>
          <Loading />
        </Center>
      )}
      {!isLoading &&
        games?.map((g, i) => {
          const idx = i % 4;
          const gameStyle = gameStyles[idx];

          const hVideoSrc = g.gameAttachments.find(
            (ga) => ga.gameAttachmentTypeID === 4
          )?.url;

          const vVideoSrc = g.gameAttachments.find(
            (ga) => ga.gameAttachmentTypeID === 6
          )?.url;

          return (
            <Flex direction={'column'} w="full" key={i}>
              {gameStyle.sectionDivider && (
                <SectionDivider {...gameStyle.sectionDivider} />
              )}
              <GameItem
                title={g.name}
                description={g.description}
                videoSrc={vVideoSrc}
                {...gameStyle.gameItem}
              />
            </Flex>
          );
        })}
    </PageContainer>
  );
}
