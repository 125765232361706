import { Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import PageContainer from 'src/components/Container/PageContainer';

export default function NoMatch() {
  return (
    <PageContainer>
      <Stack direction="column" align="center">
        <Text variant={'title'}>Ooops, parece que você se perdeu!</Text>
        <Text variant="link">
          <Link to="/">Clique aqui para voltar para home</Link>
        </Text>
      </Stack>
    </PageContainer>
  );
}
