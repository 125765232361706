import React, { useEffect, useState } from 'react';
import DashedContainer from 'src/components/Container/DashedContainer';
import { Flex, Spacer, Stack, Text } from '@chakra-ui/react';
import { SalesPerDurationResult } from 'src/api/interfaces/salesPerDurationResult';

export interface TimeCount {
  30: number;
  60: number;
  90: number;
  120: number;
  150: number;
  180: number;
}

export interface SessionsDurationValues {
  timeCount: TimeCount;
  salesPerDuration: SalesPerDurationResult[] | null
}

export default function SessionsDuration({
  timeCount,
  salesPerDuration,
}: SessionsDurationValues) {
  const sumValues = (obj: TimeCount) =>
    Object.values(obj).reduce((a, b) => a + b, 0);

  const [total, setTotal] = useState<number>(0);

  
  useEffect(() => {
    if(salesPerDuration){
      setTotal(salesPerDuration.reduce((partialSum, a) => partialSum + a.total, 0))
    }

  }, [salesPerDuration]);

  return (
    <DashedContainer borderColor="red" w="100%" alignItems="start">
      <Text variant="title" color="red">
        VENDAS POR DURAÇÃO DA RESERVA
      </Text>
      <Stack mt="20px" px="20px" color="white" w="100%" overflowY="scroll">
        {salesPerDuration?.map((spd) => (
                <Flex key={salesPerDuration.indexOf(spd)}>
                  <Text flex={1 / 3}>{spd.duration}</Text>
                  <Text flex={1 / 3} textAlign="center">
                    {spd.total}
                  </Text>
                  <Text flex={1 / 3} textAlign="right">
                    {(spd.percentage * 100).toLocaleString(undefined, { minimumFractionDigits: 2 }) + '%'}
                  </Text>
                </Flex>
        ))}
      </Stack>
      <Flex
        mt="10px"
        pt="10px"
        borderTop="2px dashed red"
        justifyContent="space-between"
        px="20px"
        w="100%"
        color="red"
      >
        <Text>Total</Text>
        <Text>{total}</Text>
        <Text>100%</Text>
      </Flex>
    </DashedContainer>
  );
}
