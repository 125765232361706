import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import TikTokPixel from 'tiktok-pixel';
import PageContainer from 'src/components/Container/PageContainer';
import { Stack, Text, VStack, Image, Heading, Box } from '@chakra-ui/react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import faqJson from './faq';
import { useGetActiveFaq } from 'src/api/services/faq';
import { Loading } from 'src/components/Loading';

export default function FAQ() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    TikTokPixel.pageView();
  }, []);

  const { data, isLoading } = useGetActiveFaq()

  return (
    <PageContainer>
      <Stack width="100%" bg="#131313" padding="3rem" border-radius="10px">
        <Heading as="h2" color="#fff" marginBottom={5}>
          FAQ
        </Heading>
        {isLoading ? <Loading /> : <Accordion allowToggle borderColor="#fff">
          <AccordionItem

            border="1px dashed rgb(6 174 179)"
            marginBottom="1rem"
          >
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left" color="#b0ca0a">
                  Quais são as regras do Arcade?
                </Box>
                <AccordionIcon color="#fff" />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} color="#fff">
              <Text whiteSpace="pre-line">Todas as nossas regras e recomendações são enviadas junto da confirmação da reserva e podem ser acessadas através do link <a href='https://drive.google.com/file/d/1eIH7AFPRA_gUNrLzNJGjK_722KaTiqCi/view' style={{ fontWeight: 'bold', textDecoration: 'underline' }}>“Regras e Recomendações Arcade Haus”.</a></Text>
            </AccordionPanel>
          </AccordionItem>
          {data && data.map((items: any, idx: number) => (
            <AccordionItem
              key={idx}
              border="1px dashed rgb(6 174 179)"
              marginBottom="1rem"
            >
              <h2>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left" color="#b0ca0a">
                    {items.question}
                  </Box>
                  <AccordionIcon color="#fff" />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} color="#fff">
                <Text whiteSpace="pre-line">{items.answer}</Text>
              </AccordionPanel>
            </AccordionItem>
          ))}

        </Accordion>}
      </Stack>
    </PageContainer>
  );
}
