import React, { useEffect } from 'react';
import ReactGA from 'react-ga'
import TikTokPixel from 'tiktok-pixel'
import PageContainer from 'src/components/Container/PageContainer';
import DashedContainer from 'src/components/Container/DashedContainer';
import { PlayerProfile } from 'src/modules/PlayerHome/Profile';
import { RankingButton } from 'src/modules/PlayerHome/Ranking/RankingButton';
import { Stack, useDisclosure } from '@chakra-ui/react';
import { HistoryOverview } from 'src/modules/PlayerHome/HistoryOverview';
import { ActiveTicketsOverview } from 'src/modules/PlayerHome/ActiveTicketsOverview';
import { RankingModal } from 'src/modules/PlayerHome/Ranking/RankingModal';

export default function PlayerHome() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    TikTokPixel.pageView()
  }, [])

  return (
    <PageContainer restricted>
      <Stack direction={['column', 'row']} spacing={6} w="full">
        <Stack direction="column" spacing={6} w="full">
          <PlayerProfile />
          <RankingButton onOpen={onOpen} />
        </Stack>
        <Stack direction="column" w="full">
          <ActiveTicketsOverview />
          <HistoryOverview />
        </Stack>
      </Stack>
      <RankingModal isOpen={isOpen} onClose={onClose} />
    </PageContainer>
  );
}
