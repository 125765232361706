import React from 'react';

import {
  Box,
  HStack,
  Icon,
  Spacer,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { CalendarIcon, TimeIcon } from '@chakra-ui/icons';
import { FiFileText, FiMapPin, FiUser, FiUsers } from 'react-icons/fi';
import { BsBox } from 'react-icons/bs';
import { FaTicketAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import { BookingResult } from 'src/api/interfaces/booking';
import { EditPlayerModal } from 'src/components/Modal/ModalVariants/EditPlayersModal';
import { ReceiptModal } from 'src/components/Modal/ModalVariants/ReceiptModal';
import { BookingStatusEnum } from 'src/api/services/booking';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { IoGameControllerOutline } from 'react-icons/io5';
import { MatchHistoryModal } from 'src/components/Modal/ModalVariants/GamesPlayedHistoryModal';

export const useHistoryColumns = () => {
  const columnHelper = createColumnHelper<BookingResult>();

  const columns = [
    columnHelper.accessor('startDate', {
      cell: (info) => (
        <HStack>
          <CalendarIcon />
          <Text variant="text">
            {dayjs(info.getValue()).format('DD/MM/YYYY')}
          </Text>
        </HStack>
      ),
      header: () => <span style={{ color: 'white' }}>Data</span>,
    }),
    columnHelper.accessor('bookingRooms', {
      cell: (info) => {
        const rooms = info.getValue();
        const room = rooms?.[rooms.length - 1];
        return (
          <HStack>
            <TimeIcon />

            <Text variant="text">{`${dayjs(room?.startTime).format(
              'HH:mm'
            )} às ${dayjs(room?.endTime).format('HH:mm')}`}</Text>
          </HStack>
        );
      },
      header: () => <span>Horário</span>,
    }),
    columnHelper.accessor((r) => r.user?.name, {
      id: 'user.name',
      cell: (info) => (
        <HStack>
          <FiUser />

          <Text variant="text">{info.getValue()}</Text>
        </HStack>
      ),
      header: () => <span>Username</span>,
    }),
    columnHelper.accessor('bookingRooms', {
      cell: (info) => {
        const rooms = info.getValue();
        const room = rooms?.[rooms.length - 1];

        return (
          <HStack>
            <BsBox />
            <Text variant="text">{room?.room?.description}</Text>
          </HStack>
        );
      },
      header: () => <span>Salas</span>,
    }),
    columnHelper.accessor('bookingRooms', {
      cell: (info) => {
        const rooms = info.getValue();
        const room = rooms?.[0];

        return (
          <HStack>
            <FiMapPin />
            <Text variant="text">
              {rooms.length > 0 ? rooms[0].room?.businessUnit.description : '-'}
            </Text>
          </HStack>
        );
      },
      header: () => <span>Unidade</span>,
    }),
    columnHelper.accessor('tickets', {
      cell: (info) => {
        return (
          <HStack>
            <FaTicketAlt />
            <Text variant="text">{info.getValue()}</Text>
          </HStack>
        );
      },
      header: () => <span>Tickets</span>,
    }),
    columnHelper.accessor('bookingStatus', {
      cell: (info) => (
        <Stack direction="row" alignItems="center">
          <Text variant="text">{`${info.getValue()?.description}`}</Text>
        </Stack>
      ),
      header: () => <span>status da reserva</span>,
    }),
    columnHelper.display({
      id: 'actions',
      cell: (info) => {
        const bookingID = info.row.original.bookingID;
        const status = info.row.original.bookingStatusID;

        const {
          isOpen: isPlayersOpen,
          onClose: onPlayersClose,
          onOpen: onPlayersOpen,
        } = useDisclosure();

        const {
          isOpen: isReceiptOpen,
          onClose: onReceiptClose,
          onOpen: onReceiptOpen,
        } = useDisclosure();

        const {
          isOpen: isMatchHistoryOpen,
          onClose: onMatchHistoryClose,
          onOpen: onMatchHistoryOpen
        } = useDisclosure()

        return (
          <HStack spacing={4} pl={6}>
            <Spacer borderRight="2px dashed #B0CA0A" />
            <Box
              justifyContent="center"
              as="button"
              _hover={{ color: '#B0CA0A' }}
              onClick={onPlayersOpen}
            >
              <Icon as={FiUsers} w="20px" h="20px" />
              <Text variant="text">Jogadores</Text>
            </Box>

            {status === BookingStatusEnum.UTILIZADA && (
              <Box
                justifyContent="center"
                as="button"
                _hover={{ color: '#B0CA0A' }}
                onClick={onMatchHistoryOpen}
              >
                <Icon as={IoGameControllerOutline} w="20px" h="20px" />
                <Text variant="text">Jogos</Text>
              </Box>
            )}

            {/* cortar esse depois */}
            <Box
              justifyContent="center"
              as="button"
              _hover={{ color: '#B0CA0A' }}
              onClick={onMatchHistoryOpen}
            >
              <Icon as={IoGameControllerOutline} w="20px" h="20px" />
              <Text variant="text">Jogos</Text>
            </Box>

            {status === BookingStatusEnum.CONFIRMADA && (
              <Box
                justifyContent="center"
                as="button"
                _hover={{ color: '#B0CA0A' }}
                onClick={onReceiptOpen}
              >
                <Icon as={FiFileText} w="20px" h="20px" />
                <Text variant="text">Recibo</Text>
              </Box>
            )}
            <EditPlayerModal
              bookingID={bookingID}
              isOpen={isPlayersOpen}
              onClose={onPlayersClose}
              view
            />
            <ReceiptModal
              bookingID={bookingID}
              isOpen={isReceiptOpen}
              onClose={onReceiptClose}
            />
            <MatchHistoryModal
              bookingID={bookingID}
              isOpen={isMatchHistoryOpen}
              onClose={onMatchHistoryClose}
            />
          </HStack>
        );
      },
    }),
  ];

  return columns;
};
