import {
  FaUserPlus,
  FaTicketAlt,
  FaRegCalendar,
  FaRegClock,
} from 'react-icons/fa';
import { NavItemChildProps } from '../NavItem';
import { BsDoorOpenFill } from 'react-icons/bs';

export interface NavItemProps {
  label: string;
  subLabel?: string;
  children?: Array<NavItemProps>;
  href?: string;
  isExternal?: boolean;
}

export const NAV_ITEMS: Array<NavItemProps> = [
  { label: 'INGRESSOS', href: '/ingressos' },
  { label: 'JOGOS', href: '/jogos' },
  { label: 'FAÇA SEU EVENTO', href: '/eventos' },
  { label: 'CONTATO', href: '/contatos' },
  { label: 'FAQ', href: '/FAQ' },
  { label: 'SOBRE NÓS', href: '/sobre-nos' },
  {
    label: 'EXPANSÃO',
    href: 'https://forms.monday.com/forms/71bcc81a6cc82569891840ff20b82948?r=use1',
    isExternal: true,
  },
];

export const MOBILE_NAV_ITEMS: Array<NavItemProps> = [
  { label: 'LOGIN', href: '/login' },
  ...NAV_ITEMS,
];

export const PLAYER_MENU_ITEMS: Array<NavItemChildProps> = [
  { name: 'Painel do usuário', icon: FaRegCalendar, url: '/jogador/home' },
  { name: 'Meus dados', icon: FaTicketAlt, url: '/jogador/dados' },
  { name: 'Minhas mídias', icon: FaUserPlus, url: '/jogador/midias' },
  { name: 'Histórico', icon: FaRegClock, url: '/jogador/historico' },
  { name: 'Sair da Conta', icon: BsDoorOpenFill, url: '/logout' },
];
