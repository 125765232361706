import React from 'react';
import {
  Control,
  Controller,
  FieldError,
  UseFormRegisterReturn,
} from 'react-hook-form';
import {
  Box,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputProps,
  Stack,
} from '@chakra-ui/react';

const dateInputStyle = {
  '::-webkit-calendar-picker-indicator': {
    background: 'transparent',
    bottom: 0,
    color: 'transparent',
    cursor: 'pointer',
    height: 'auto',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    width: 'auto',
  },
};

interface FormInputProps {
  rhfRegister: UseFormRegisterReturn;
  errorState?: any;
  inputProps?: InputProps;
  formControlProps?: FormControlProps;
  icon?: any;
  label?: string;
  readonly?: boolean;
  rightIcon?: any;
  rightIconColor?: string;
}

export const FormInput = ({
  rhfRegister,
  errorState,
  icon,
  label,
  inputProps,
  formControlProps,
  readonly = false,
  rightIcon,
  rightIconColor,
}: FormInputProps) => {
  const id = rhfRegister.name;
  return (
    <FormControl
      id={id}
      isRequired={rhfRegister.required}
      isInvalid={!!errorState}
      isReadOnly={readonly}
      {...formControlProps}
    >
      {label && (
        <FormLabel color={'white'} htmlFor={id}>
          {label}
        </FormLabel>
      )}
      <Stack
        direction="row"
        alignItems="center"
        bg={'white'}
        border="1px"
        borderColor={'green'}
        pr={3}

      >
        {icon && (
          <Box pl={2}>
            <Icon as={icon} boxSize={5} pointerEvents="none" color={'green'} />
          </Box>
        )}
        <Input
          px={2}
          boxShadow={0}
          variant="flushed"
          color={'#131313'}
          _placeholder={{ color: 'gray' }}
          sx={dateInputStyle}
          {...inputProps}
          {...rhfRegister}
        />
        {rightIcon && (
          <Box pl={2}>
            <Icon as={rightIcon} boxSize={3} pointerEvents="none" color={rightIconColor ?? 'green'} />
          </Box>
        )}
      </Stack>
      <FormErrorMessage color="red">
        {errorState && errorState.message}
      </FormErrorMessage>
    </FormControl>
  );
};
