import React from 'react';
import LogoVertical from 'src/assets/Logo-vertical.png';
import {
  Link,
  Stack,
  Box,
  useColorModeValue,
  Image,
  Flex,
  useMediaQuery,
} from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { NAV_ITEMS } from '../Container/NavBar/routes';
import SocialMedia from 'src/modules/ContactInfo/SocialMedia';
import HoursAddress from 'src/modules/ContactInfo/HoursAddress';
import Contact from 'src/modules/ContactInfo/Contact';
import CustomLink from 'src/components/CustomLink';

export default function Footer() {
  const linkColor = useColorModeValue('gray', 'gray.200');
  const linkHoverColor = useColorModeValue('green', 'white');
  const FOOTER_NAV_ITEMS = NAV_ITEMS.filter((x) => x.label != 'CONTATO');
  const [smallerThan1120] = useMediaQuery('(max-width: 1300px)');
  const [smallerThan780] = useMediaQuery('(max-width: 780px)');
  const [smallerThan610] = useMediaQuery('(max-width: 610px)');

  return (
    <Stack
      bg={useColorModeValue('black', 'gray.900')}
      maxW="100vw"
      px={6}
      py={6}
    >
      <Stack
        w={'full'}
        direction={['column', 'row']}
        align={'center'}
        justify={'space-between'}
        spacing={6}
      >
        <Flex justify="center">
          {!smallerThan610 && <Image minW="115px" src={LogoVertical} />}
        </Flex>
        <Stack
          direction={smallerThan1120 ? 'column' : 'row'}
          spacing={3}
          w="fit-content"
          alignItems={'center'}
        >
          {FOOTER_NAV_ITEMS.map((navItem) => (
            <CustomLink
              key={navItem.label}
              to={!navItem.isExternal ? navItem.href : undefined}
              href={navItem.isExternal ? navItem.href : undefined}
              isExternal={navItem.isExternal}
              label={navItem.label}
            />
          ))}
          {smallerThan1120 && <SocialMedia />}
        </Stack>
        <Stack
          direction={smallerThan780 ? 'column' : 'row'}
          spacing={0.5}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Contact containerProps={{ w: ['full', 'auto'] }} />
          <HoursAddress />
        </Stack>

        {!smallerThan1120 && <SocialMedia />}
      </Stack>
    </Stack>
  );
}
