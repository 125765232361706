import React from 'react';
import { Flex, Icon, Link, FlexProps } from '@chakra-ui/react';
import { IconType } from 'react-icons';
import { Link as ReactRouterLink } from 'react-router-dom';

export interface NavItemChildProps {
  name: string;
  icon: any;
  url: string;
}

export interface NavItemProps extends FlexProps {
  icon: IconType;
  url: string;
  children: any;
}

export const NavItem = ({ icon, url, children, ...rest }: NavItemProps) => {
  return (
    <Link
      as={ReactRouterLink}
      color="white"
      to={url}
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
    >
      <Flex
        align="center"
        p="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: 'green',
          color: 'black',
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};
