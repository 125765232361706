import React, { useState } from 'react';
import {
  Stack,
  Flex,
  Icon,
  Button,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { MdOutlineReceipt } from 'react-icons/md';
import { RoomImage } from 'src/components/RoomImage';
import { PlayerPurchaseSummary } from 'src/components/Summary/PlayerPurchaseSummary';
import DashedContainer from 'src/components/Container/DashedContainer';
import { BookingPlayerResult, BookingResult } from 'src/api/interfaces/booking';
import { EditPlayerModal } from 'src/components/Modal/ModalVariants/EditPlayersModal';
import usePHToast from 'src/hooks/useToast';
import { ReceiptModal } from 'src/components/Modal/ModalVariants/ReceiptModal';
import { ApiResponse } from 'src/api';
import { KeyedMutator } from 'swr';
import RecommendationsModal from 'src/modules/PlayerHome/RecommendationsModal';

export interface ActiveBookingProps {
  multiple: boolean;
  bookingID: string;
  date: string;
  status: string | undefined;
  unit: string | undefined;
  tickets: number;
  players: BookingPlayerResult[] | undefined;
  rooms: string;
  time: string;
  identifyPlayers: boolean;
  mutate?: KeyedMutator<ApiResponse<BookingResult[]>>;
}





export function ActiveBookingCard({
  multiple,
  bookingID,
  date,
  status,
  unit,
  tickets,
  players,
  time,
  rooms,
  identifyPlayers,
  mutate,
}: ActiveBookingProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isReceiptOpen,
    onOpen: onReceiptOpen,
    onClose: onReceiptClose,
  } = useDisclosure();
  const {
    isOpen: isRecommendationsOpen,
    onOpen: onRecommendationsOpen,
    onClose: onRecommendationsClose,
  } = useDisclosure();
  const toast = usePHToast();
  const handleEditPlayers = () => {
    onOpen();
  };
  const handleEditPlayersModalClose = () => {
    if (mutate) mutate();
    onClose;
  };
  const handleDownloadTickets = () => {
    toast({ status: 'info', description: 'download iniciado...' });
  };
  const [pendingPlayers] = useState(players?.length === tickets ? false : true);

  const bookingId = bookingID.split('-')?.[0];
  return (
    <>
      <DashedContainer justify="center" py={12} px={12} mb={12}>
        <Stack w="full" spacing={4} align="flex-start">
          <Text variant="title">INGRESSOS ATIVOS</Text>

          <Stack
            direction="row"
            w="full"
            align="center"
            justify="space-between"
          >
            <Text color="white" fontSize="14px" fontWeight={'bold'}>
              Código da Reserva:{' '}
              <Text as="span" color="green" fontSize="16px" fontWeight={'bold'}>
                {bookingId}
              </Text>
            </Text>
            <Flex direction="row" gap={6}>
              <Text variant="title" fontSize="md">
                {rooms}
              </Text>
              <Flex
                onClick={onReceiptOpen}
                cursor="pointer"
                direction="row"
                gap={2}
                align="center"
              >
                <Text variant="text" textTransform={'uppercase'}>
                  Recibo
                </Text>
                <Icon as={MdOutlineReceipt} color="green" boxSize={6} />
              </Flex>
            </Flex>
          </Stack>
          <Stack direction={['column', 'row']} w="full" h="full" spacing={2}>
            <RoomImage />
            {multiple && <RoomImage />}
            <PlayerPurchaseSummary
              date={date}
              time={time}
              status={status}
              pendingPlayers={pendingPlayers}
              identifyPlayers={identifyPlayers}
              unit={unit}
              tickets={tickets}
            />
          </Stack>

          <Stack direction="row" w="full" h="full" spacing={2} justify="center">
            <Button onClick={onRecommendationsOpen} variant="pophausOutline" colorScheme="popTransparent">
              RECOMENDAÇÕES
            </Button>
            <Button
              variant="pophausOutline"
              onClick={
                identifyPlayers && pendingPlayers
                  ? handleEditPlayers
                  : handleDownloadTickets
              }
            >
              {identifyPlayers && pendingPlayers
                ? 'JOGADORES'
                : 'BAIXAR INGRESSOS'}
            </Button>
            {identifyPlayers && pendingPlayers ? (
              <></>
            ) : (
              <Button
                variant="pophausOutline"
                colorScheme="popYellow"
                onClick={handleEditPlayers}
              >
                Editar jogadores
              </Button>
            )}
          </Stack>
        </Stack>
        <EditPlayerModal
          bookingID={bookingID}
          isOpen={isOpen}
          onClose={handleEditPlayersModalClose}
        />
      </DashedContainer>
      <ReceiptModal
        isOpen={isReceiptOpen}
        onClose={onReceiptClose}
        bookingID={bookingID}
      />
      <RecommendationsModal isOpen={isRecommendationsOpen} onClose={onRecommendationsClose}></RecommendationsModal>
    </>
  );
}
