import axios from 'axios';
import {
  LoginResult,
  PaginatedListOfUsersResult,
  UserRequest,
  UserResult,
} from 'src/api/interfaces/user';
import { CreateAccountFormValues } from 'src/modules/Forms/CreateAccountForm';
import Cookies from 'universal-cookie';
import { ApiRepository, ApiResponse, useFetch } from '../..';
import { ChangePasswordFormValues } from 'src/modules/Forms/ChangePasswordForm';
import { NewPasswordFormValues } from 'src/pages/login';

const api = new ApiRepository();
const auth = new ApiRepository(true);

export async function checkUsername(email: string) {
  const res = await api.apiRequestWrapper<ApiResponse<UserResult>>({
    method: 'get',
    url: `user/verifyemail?email=${email}`,
  });
  return res.result;
}

function setCookieTokens(result: any) {
  const cookies = new Cookies();
  cookies.set('pophaus_token', result.access_token, {
    sameSite: 'strict',
  });
  cookies.set('pophaus_refresh_token', result.refresh_token, {
    sameSite: 'strict',
  });
}

export async function login(username: string, password: string) {
  const data = new URLSearchParams();

  data.append('grant_type', 'password');
  data.append('client_id', 'pophaus-site');
  data.append('client_secret', 'secret');
  data.append('username', username);
  data.append('password', password);
  data.append('scope', 'arcadeHausGateway');

  const res = await auth.apiRequestWrapper<LoginResult>({
    method: 'post',
    url: 'connect/token',
    data: data,
  });

  setCookieTokens(res);
  return res;
}

// WIP
// export async function refreshToken(refreshToken: string) {
//   const data = new URLSearchParams();

//   data.append('grant_type', 'password');
//   data.append('client_id', 'pophaus-site');
//   data.append('client_secret', 'secret');
//   data.append('refresh_token', refreshToken);
//   data.append('scope', 'arcadeHausGateway');

//   const res = await auth.apiRequestWrapper<LoginResult>({
//     method: 'post',
//     url: 'connect/token',
//     data: data,
//   });

//   setCookieTokens(res);
//   return res;
// }

export async function signUp(data: CreateAccountFormValues) {
  const res = await auth.apiRequestWrapper<ApiResponse<UserResult>>({
    method: 'post',
    url: 'api/account/register',
    data,
  });
  return res.result;
}

export async function resetPassword(data: ChangePasswordFormValues) {
  const res = await auth.apiRequestWrapper<ApiResponse<UserResult>>({
    method: 'post',
    url: 'api/Account/ResetPassword',
    data,
  });
  return res.result;
}

export async function getUserById(userId: string, accessToken: string) {
  const res = await api.apiRequestWrapper<ApiResponse<UserResult>>({
    method: 'get',
    url: `user/${userId}`,
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return res.result;
}

export function useGetUserByID(userId?: string) {
  const args = userId
    ? {
        method: 'get',
        url: `user/${userId}`,
      }
    : null;

  const { data, error, isLoading } = useFetch<ApiResponse<UserResult>>(
    api,
    args,
    { revalidateOnFocus: false }
  );
  return { data: data?.result, error, isLoading };
}
export function useGetUsersByPage(pageIndex: number) {
  const args = pageIndex
    ? {
        method: 'get',
        url: `user/getUsersByPage?PageIndex=${pageIndex}`,
      }
    : null;

  const { data, error, isLoading } = useFetch<
    ApiResponse<PaginatedListOfUsersResult>
  >(api, args, { revalidateOnFocus: false });
  return { data: data?.result, error, isLoading };
}

export async function getFilteredUsers(document: string) {
  const res = await api.apiRequestWrapper<
    ApiResponse<PaginatedListOfUsersResult>
  >({
    method: 'get',
    url: `User/Filter?Document=${document}`,
  });
  return res.result;
}

export async function updateUser(userId: string, data: Partial<UserRequest>) {
  const res = await api.apiRequestWrapper<ApiResponse<UserResult>>({
    method: 'put',
    url: `User?UserID=${userId}`,
    data,
  });
  return res.result;
}

export function useGetFilteredUsers(document: string) {
  const args = document
    ? {
        method: 'get',
        url: `User/Filter?Document=${document}`,
      }
    : null;

  const { data, error, isLoading } = useFetch<ApiResponse<UserResult[]>>(
    api,
    args,
    { revalidateOnFocus: false }
  );
  return { data: data?.result, error, isLoading };
}

export async function ForgotPassword(email: string) {
  const res = await api.apiRequestWrapper<ApiResponse<any>>({
    method: 'post',
    url: `/Account/ForgotPassword?Email=${email}`,
  });
  return res.result;
}

export async function ResetPasswordByEmail(data: NewPasswordFormValues) {
  const res = await api.apiRequestWrapper<ApiResponse<UserResult>>({
    method: 'post',
    url: `/Account/ResetPasswordByEmail?Email=${data.email}&Password=${data.password}&ConfirmPassword=${data.confirmPassword}&Hash=${data.hash}`,
  });
  return res.result;
}
