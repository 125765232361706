import React, { useEffect, useState } from 'react';
import DashedContainer from '../Container/DashedContainer';
import { Button, HStack, Stack, Text } from '@chakra-ui/react';
import { updateNotificationStatus } from 'src/api/services/notification/intex';
import { useNavigate } from 'react-router-dom';
import { BookingNotificationResult } from 'src/api/interfaces/notifications';
import { KeyedMutator } from 'swr';
import { ApiResponse } from 'src/api';

export interface BookingNotificationProps {
  message: string;
  notificationID: number;
  bookingID: string;
  mutate: KeyedMutator<ApiResponse<BookingNotificationResult[]>>;
}

export default function BookingNotification({
  message,
  notificationID,
  bookingID,
  mutate,
}: BookingNotificationProps) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const DismissNotification = async () => {
    try {
      setLoading(true);

      const req = await updateNotificationStatus(notificationID, false);
    } catch (err) {
      console.error;
    } finally {
      mutate();
      setLoading(false);
    }
  };

  return (
    <DashedContainer>
      <Stack>
        <Text variant="text">{message}</Text>
        <HStack>
          <Button
            variant="pophausOutline"
            onClick={() => navigate(`l/admin/ingressos/resumo/${bookingID}`)}
            isLoading={loading}
          >
            ver
          </Button>
          <Button
            variant="pophausOutline"
            isLoading={loading}
            onClick={DismissNotification}
          >
            fechar
          </Button>
        </HStack>
      </Stack>
    </DashedContainer>
  );
}
