import React, { useEffect } from 'react';
import { Button, Stack, Text } from '@chakra-ui/react';
import Form from 'src/components/Form/Form';
import { UseFormReturn } from 'react-hook-form';
import { NewPasswordFormValues } from 'src/pages/login';
import { FormInputPassword } from 'src/components/Form/Input/PasswordInput';
import { FormInput } from 'src/components/Form/Input';

interface NewPasswordStepProps {
  handleNewPassSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  rhfForm: UseFormReturn<NewPasswordFormValues, any>;
  loading: boolean;
  stepSetter: React.Dispatch<React.SetStateAction<number>>
}

export default function NewPasswordStep({
  handleNewPassSubmit,
  rhfForm,
  loading,
  stepSetter
}: NewPasswordStepProps) {
  const {
    register,
    getValues,
    setValue,
    formState: { errors },

  } = rhfForm;

  return (
    <>
      <Stack direction="column">
        <Text variant="title" textAlign={'center'}>
          Nova senha
        </Text>
        <Text variant="text" color="white">
          Por favor, digite seu email novamente, assim como a sua nova senha e a confirmação da sua nova senha.
        </Text>
      </Stack>

      <Form onSubmit={handleNewPassSubmit}>
        <FormInput
          rhfRegister={{
            ...register('email', {
              required: 'Campo obrigatório',
            }),
          }}
          errorState={errors.email}
          label={'CONFIRMAÇÃO DE EMAIL'}
          inputProps={{ placeholder: 'DIGITE SEU E-MAIL NOVAMENTE' }}
        />
        <FormInputPassword
          rhfRegister={{
            ...register('password', {
              required: 'Campo obrigatório',
              pattern: {
                value: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/,
                message:
                  'Senha precisa conter ao menos: 1 letra maiúscula, 1 letra minúscula, 1 número e 1 símbolo. ',
              },
            }),
          }}
          errorState={errors.password}
        />
        <FormInputPassword
          label={'CONFIRMAR SENHA'}
          rhfRegister={{
            ...register('confirmPassword', {
              required: 'Campo obrigatório',
              validate: (value) => {
                const { password } = getValues();
                return password === value || 'As senhas devem ser iguais!';
              },
            }),
          }}
          errorState={errors.confirmPassword}
        />

        <Button
          mt={6}
          w="full"
          variant={'pophausOutline'}
          type="submit"
          isLoading={loading}
        >
          continuar
        </Button>
      </Form>
    </>
  );
}
