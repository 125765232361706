import React from 'react';
import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
  Image,
  Avatar,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  useMediaQuery,
} from '@chakra-ui/react';
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import { FaUserAlt } from 'react-icons/fa';
import { HiOutlineSquares2X2 } from 'react-icons/hi2';
import { Link as ReactRouterLink } from 'react-router-dom';
import Logo from 'src/assets/Logo-horizontal.png';
import {
  MOBILE_NAV_ITEMS,
  NAV_ITEMS,
  NavItemProps,
  PLAYER_MENU_ITEMS,
} from './routes';
import { useAppSelector } from 'src/redux/hooks';
import { FiChevronDown } from 'react-icons/fi';
import { NavItem } from '../NavItem';
import { IoHomeOutline } from 'react-icons/io5';
import { ROLES } from 'src/redux/user';
import { MobileNav } from './Mobile';
import { OpenMobileNavButton } from './Mobile/OpenButton';
import { MobileSideItems } from './Mobile/SideItems';
import CustomLink from 'src/components/CustomLink';

export default function NavBar() {
  const { user } = useAppSelector((state) => state.user);
  const { isOpen, onToggle } = useDisclosure();
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const shouldRenderMobile = isLargerThan900 ? false : true;

  const SideItems = () => {
    return shouldRenderMobile ? <MobileSideItems /> : <DesktopSideItems />;
  };

  return (
    <Box>
      <Flex
        bg={useColorModeValue('black', 'gray.800')}
        color={useColorModeValue('gray', 'white')}
        minH={'60px'}
        py={{ base: 4 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}
        justify={'center'}
      >
        <OpenMobileNavButton isOpen={isOpen} onToggle={onToggle} />

        <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
          <Link as={ReactRouterLink} to="/">
            <Image width={200} src={Logo} alt="Logo" />
          </Link>

          <Flex w={'full'} display={{ base: 'none', md: 'flex' }} ml={10}>
            <DesktopNav />
          </Flex>
        </Flex>

        <Stack
          display={'flex'}
          flex={{ base: 1, md: 0 }}
          justify={'flex-end'}
          direction={'row'}
          spacing={6}
          pl={4}
        >
          {user ? <SideItems /> : <LoginButton />}
        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
}

const DesktopSideItems = () => {
  const { user } = useAppSelector((state) => state.user);

  const userIsAdmin = user?.roles?.includes(ROLES.ADMIN);

  if (!user) return <></>;
  return (
    <Stack direction="row" align="center" spacing={6}>
      <Text color="green" fontWeight={'bold'} display={'inline-flex'}>
        {user.name}
      </Text>
      <Avatar name={user.name} border="2px" color="green" src={user.picture} />
      <Flex display={'inline-flex'} alignItems={'center'} zIndex="dropdown">
        <Menu>
          <MenuButton
            py={2}
            px={4}
            transition="all 0.3s"
            display={'inline-flex'}
            bg={'transparent'}
            rounded={'base'}
            _focus={{ boxShadow: 'none' }}
            _hover={{
              bg: 'green',
            }}
          >
            <HiOutlineSquares2X2 color="white" size="24" />
          </MenuButton>
          <MenuList bg="black" borderLeft="1px" borderColor={'white'} py={6}>
            {userIsAdmin && (
              <NavItem
                icon={IoHomeOutline}
                url={'/admin/ingressos'}
                py={2}
                px={4}
                fontSize={'sm'}
              >
                Ir para Admin
              </NavItem>
            )}
            {PLAYER_MENU_ITEMS.map((link) => (
              <NavItem
                key={link.name}
                icon={link.icon}
                url={link.url}
                py={2}
                px={4}
                fontSize={'sm'}
              >
                {link.name}
              </NavItem>
            ))}
          </MenuList>
        </Menu>
      </Flex>
    </Stack>
  );
};

const LoginButton = () => {
  return (
    <Button
      as={ReactRouterLink}
      display={'inline-flex'}
      fontSize={'xl'}
      fontWeight={600}
      color={'green'}
      bg={'transparent'}
      to={'/login'}
      _hover={{
        bg: 'white',
      }}
    >
      <FaUserAlt />
    </Button>
  );
};

const DesktopNav = () => {
  const { user } = useAppSelector((state) => state.user);
  const popoverContentBgColor = useColorModeValue('white', 'gray.800');

  return (
    <Stack
      w={'full'}
      direction={'row'}
      spacing={4}
      align={'center'}
      justify={user ? 'flex-start' : 'flex-end'}
    >
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>
              <CustomLink
                to={!navItem.isExternal ? navItem.href : undefined}
                href={navItem.isExternal ? navItem.href : undefined}
                isExternal={navItem.isExternal}
                label={navItem.label}
              />
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={'xl'}
                bg={popoverContentBgColor}
                p={4}
                rounded={'xl'}
                minW={'sm'}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel }: NavItemProps) => {
  return (
    <Link
      href={href}
      role={'group'}
      display={'block'}
      p={2}
      rounded={'md'}
      _hover={{ bg: useColorModeValue('pink.50', 'gray.900') }}
    >
      <Stack direction={'row'} align={'center'}>
        <Box>
          <Text
            transition={'all .3s ease'}
            _groupHover={{ color: 'pink.400' }}
            fontWeight={500}
          >
            {label}
          </Text>
          <Text fontSize={'sm'}>{subLabel}</Text>
        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}
        >
          <Icon color={'pink.400'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
};
