import React from 'react';
import { Stack, Text, Button, TextProps, Link } from '@chakra-ui/react';
import { ReactComponent as FacebookIcon } from 'src/assets/icons/FacebookIcon.svg';
import { ReactComponent as InstagramIcon } from 'src/assets/icons/InstagramIcon.svg';
import { ReactComponent as TiktokIcon } from 'src/assets/icons/TiktokIcon.svg'

interface SocialMediaProps {
  textProps?: TextProps;
}

export default function SocialMedia({ textProps }: SocialMediaProps) {
  return (
    <Stack direction="column" align="center">
      <Text
        color="green"
        fontWeight="semibold"
        textTransform={'uppercase'}
        fontSize="sm"
        {...textProps}
      >
        REDES SOCIAIS
      </Text>
      <Stack direction="row">
        <Button bg="green" as={Link} isExternal href='https://www.instagram.com/arcade_haus/'>
          <InstagramIcon width={24} />
        </Button>
        <Button bg="green" as={Link} isExternal href='https://www.tiktok.com/@arcade_haus'>
          <TiktokIcon width={24} />
        </Button>
      </Stack>
    </Stack>
  );
}
