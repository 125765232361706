import React, { useEffect } from 'react';
import { Button, Stack, Text } from '@chakra-ui/react';
import Form from 'src/components/Form/Form';
import { UseFormReturn } from 'react-hook-form';
import { NewPasswordFormValues } from 'src/pages/login';
import { FormInputPassword } from 'src/components/Form/Input/PasswordInput';
import { FormInput } from 'src/components/Form/Input';

interface WaitingForUserActionStepProps {
  name?: string
}

export default function WaitingForUserActionStep({
  name
}: WaitingForUserActionStepProps) {

  return (
    <>
      <Stack direction="column">
        <Text variant="title" textAlign={'center'}>
          Aguardando confirmação...
        </Text>
        <Text variant="text" color="white">
          Quase lá, {name}! Você receberá um email de <b>contato@arcadehaus.com.br</b>,
          com um link que te levará para a definição da nova senha
        </Text>
      </Stack>
    </>
  );
}
