import React, { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { FormSelect } from 'src/components/Form/Select';
import { Button, Stack, StackDirection } from '@chakra-ui/react';
import Form from 'src/components/Form/Form';
import { useGetBusinessUnits } from 'src/api/services/businessUnit';
import { IoLocationOutline } from 'react-icons/io5';
import { FiCalendar, FiUsers } from 'react-icons/fi';
import { FormInput } from 'src/components/Form/Input';
import { TimeIcon } from '@chakra-ui/icons';

export interface EventFormValues {
  date: string;
  businessUnitID: string;
  numberOfPlayers: string;
  duration: string;
}

interface EventFormProps {
  form: UseFormReturn<EventFormValues, any>;
  onSubmit: (data: EventFormValues) => Promise<void>;
  direction?: StackDirection;
  readonly?: boolean;
  submitLoading: boolean
}

export default function EventForm({
  form,
  onSubmit,
  direction = ['column', 'row'],
  readonly = false,
  submitLoading
}: EventFormProps) {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = form;

  const { data: businessUnits, isLoading } = useGetBusinessUnits();

  const unit = businessUnits?.find((item: any) => {
    return item.companyID === 1;
  });

  useEffect(() => {
    setValue("businessUnitID", `${unit?.businessUnitID}`)
  }, [])

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction={direction} w={'full'} spacing={6}>
        <FormInput
          rhfRegister={{
            ...register('date', {
              required: 'Campo obrigatório',
              disabled: readonly,
            }),
          }}
          errorState={errors.date}
          icon={FiCalendar}
          inputProps={{ placeholder: 'DATA', type: 'date' }}
        />
        <FormSelect
          rhfRegister={{
            ...register('businessUnitID', {
              required: 'Campo obrigatório',
              disabled: readonly,
            }),
          }}
          selectProps={{ value: unit?.description }}
          errorState={errors.businessUnitID}
          icon={IoLocationOutline}
          selectUnit
        >
          <option value={unit?.businessUnitID}>
            {unit?.description}
          </option>
        </FormSelect>
        <FormSelect
          rhfRegister={{
            ...register('numberOfPlayers', {
              required: 'Campo obrigatório',
              disabled: readonly,
            }),
          }}
          errorState={errors.numberOfPlayers}
          selectProps={{ placeholder: 'Número de Jogadores', w: '100%' }}
          icon={FiUsers}
        >
          {Array.from({ length: 23 }).map((_, i) => (
            <option key={i} value={i + 8}>
              {i + 8}
            </option>
          ))}
        </FormSelect>
        <FormSelect
          rhfRegister={{
            ...register('duration', {
              required: 'Campo obrigatório',
              disabled: readonly,
            })
          }}
          errorState={errors.duration}
          selectProps={{ placeholder: 'Duração do evento' }}
          icon={TimeIcon}
        >
          <option value={1}>1 hora</option>
          <option value={2}>2 horas</option>
          <option value={3}>3 horas</option>
        </FormSelect>

        <Button isLoading={isLoading || submitLoading} type="submit" variant="pophausOutline" paddingInline={direction === 'column' ? 0 : 12}>
          continuar
        </Button>

      </Stack>
    </Form>
  );
}