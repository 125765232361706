import React from 'react';
import { Button, Stack, Text } from '@chakra-ui/react';
import Form from 'src/components/Form/Form';
import { UseFormReturn } from 'react-hook-form';
import { LoginFormValues } from 'src/pages/login';
import { FormInputPassword } from 'src/components/Form/Input/PasswordInput';

interface PasswordStepProps {
  handlePassSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  rhfForm: UseFormReturn<LoginFormValues, any>;
  loading: boolean;
  stepSetter: React.Dispatch<React.SetStateAction<number>>
}

export default function PasswordStep({
  handlePassSubmit,
  rhfForm,
  loading,
  stepSetter
}: PasswordStepProps) {
  const {
    register,
    getValues,
    formState: { errors },
  } = rhfForm;

  const name = getValues('name');

  return (
    <>
      <Stack direction="column">
        <Text variant="title" color="white" textAlign={'center'}>
          olá,
          <Text as="span" variant="title">
            {` ${name}`}
          </Text>
          !
        </Text>
        <Text variant="title" color="white">
          seja bem vindo de volta!
        </Text>
      </Stack>

      <Form onSubmit={handlePassSubmit}>
        <FormInputPassword
          rhfRegister={{
            ...register('password'),
          }}
          errorState={errors.password}
          formControlProps={{ py: 4 }}
        />
        <Text variant="link" onClick={() => stepSetter(4)} pb={4}>
          esqueci minha senha?
        </Text>

        <Button
          w="full"
          variant={'pophausOutline'}
          type="submit"
          isLoading={loading}
        >
          continuar
        </Button>
      </Form>
    </>
  );
}
