import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  ButtonProps,
  Stack,
  Text,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import {
  getSchedulesAvailable,
  getSchedulesAvailableForTwoRooms,
} from 'src/api/services/room';
import { AvailableHoursResult } from 'src/api/interfaces/availableTime';
import { useAppSelector } from 'src/redux/hooks';
import { postReturnPrices, updateBooking } from 'src/api/services/booking';
import usePHToast from 'src/hooks/useToast';
import { ScheduleButton } from '../ScheduleButton';
import { getDurationInFull } from 'src/utils/duration';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';

interface RescheduleButtonsProps {
  roomIDs: number | number[];
  date: string;
  numberOfPlayers: number;
  duration: number;
  bookingID?: string;
  businessUnitID?: number;
}

export const RescheduleButtons = ({
  roomIDs,
  date,
  numberOfPlayers,
  duration,
  bookingID,
  businessUnitID,
  ...props
}: RescheduleButtonsProps & ButtonProps) => {
  const navigate = useNavigate();
  const toast = usePHToast();
  const { user } = useAppSelector((state) => state.user);

  const [loading, setLoading] = useState(false);
  const [schedule, setSchedule] = useState<AvailableHoursResult[]>([]);
  const [filteredSchedule, setFilteredSchedule] = useState<
    AvailableHoursResult[]
  >([]);
  const [startTimeIndex, setStartTimeIndex] = useState<number>(-1);
  const [endTimeIndex, setEndTimeIndex] = useState<number>(-1);
  const [durationView, setDurationView] = useState<string>('0 minutos');

  const handleOneRoom = useCallback(async () => {
    const result = await getSchedulesAvailable(roomIDs as number, date, 1);
    setSchedule(result);
    setFilteredSchedule(result);
  }, []);

  const handleTwoRooms = useCallback(async () => {
    const result = await getSchedulesAvailableForTwoRooms(
      roomIDs as number[],
      date
    );
    setSchedule(result);
    setFilteredSchedule(result);
  }, []);

  useEffect(() => {
    if (Array.isArray(roomIDs)) {
      handleTwoRooms();
      return;
    }
    handleOneRoom();
  }, []);

  useEffect(() => {
    const apiCall = async () => {
      setLoading(true);
      if (businessUnitID && duration > 0) {
        const reqData = {
          roomIDs: typeof roomIDs === 'number' ? [roomIDs] : roomIDs,
          duration,
          tickets: numberOfPlayers,
          businessUnitID,
          date,
        };

        const req = await postReturnPrices(reqData);
        setDurationView(req.durationFormatted);
      }
    };

    if (duration === 0) {
      setDurationView('0 minutos');
    }

    apiCall()
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [startTimeIndex, endTimeIndex]);

  const handleSelect = (index: number) => {
    const fixedEndTime = index + duration / 2 - 1;

    const nextDisabled = schedule.findIndex(
      (s, i) => !s.available && i > index
    );

    const hasDisabled = nextDisabled != -1;
    const disabledInRange = nextDisabled <= fixedEndTime;
    const outOfBounds = fixedEndTime >= schedule.length;

    // Horário reservado dentro do range da duration
    // e
    // horário dentro dos disponíveis
    if ((hasDisabled && disabledInRange) || outOfBounds) {
      toast({
        status: 'error',
        title: 'Selecione um horário válido.',
        description: `Duração do ingresso: ${duration} blocos.`,
      });
      return;
    }

    if (startTimeIndex === -1) {
      // Primeira seleção
      setStartTimeIndex(index);
      setEndTimeIndex(fixedEndTime);
      const previousDisabled = schedule
        .map((s) => s.available)
        .lastIndexOf(false, index);
      if (nextDisabled === -1 || previousDisabled === -1) return;
      const filtered = schedule.map((s, i) => ({
        ...s,
        available:
          i >= nextDisabled || i <= previousDisabled ? false : s.available,
      }));
      setFilteredSchedule(filtered);
    } else if (index === startTimeIndex) {
      // Deselecionar startTime
      setStartTimeIndex(-1);
      setEndTimeIndex(-1);
      setFilteredSchedule(schedule);
    } else {
      setStartTimeIndex(index);
      setEndTimeIndex(fixedEndTime);
    }
  };

  const handleContinue = async () => {
    try {
      if (startTimeIndex === -1)
        throw new Error('Por favor, selecione um horário válido.');
      if (!bookingID) throw new Error('Reserva inválida.');
      setLoading(true);

      const userID = user?.userID ?? null;
      const startTime = schedule[startTimeIndex].time;
      let endTime =
        endTimeIndex === -1 ? startTime : schedule[endTimeIndex].time;
      endTime = dayjs(endTime, 'HH:mm').add(30, 'minutes').format('HH:mm');

      let bookingRooms;
      if (Array.isArray(roomIDs)) {
        bookingRooms = roomIDs.map((id) => ({
          roomID: id,
          startTime,
          endTime,
        }));
      } else {
        bookingRooms = [{ roomID: roomIDs, startTime, endTime }];
      }

      if (!businessUnitID) throw new Error('Unidade indefinida!');

      const bookingRequest = {
        date,
        userID,
        tickets: numberOfPlayers,
        bookingRooms,
        bookingTypeID: 1,
        businessUnitID,
      };

      await updateBooking(bookingID, bookingRequest);

      toast({ status: 'success', title: 'Reserva reagendada com sucesso!' });
      navigate(`/jogador/ingressos-ativos`);
    } catch (e: any) {
      if (e instanceof AxiosError) {
        const errors = e.response?.data.errors;
        let errorMessage = '';

        if (e.response?.data && errors && Array.isArray(errors)) {
          for (const err of errors) errorMessage += `${err}\n`;

          toast({ status: 'error', title: errorMessage });
          return;
        } else {
          toast({ status: 'error', title: `${errors}` });
          return;
        }
      }

      toast({ status: 'error', title: e?.message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <VStack spacing={12} py={4} px={2}>
      <Wrap
        spacingX={6}
        spacingY={4}
        align="center"
        justify={{ base: 'center', md: 'unset' }}
      >
        {filteredSchedule.map((s, i) => {
          const isStartOrEnd = i === startTimeIndex || i === endTimeIndex;
          const betweenStartAndEnd = i > startTimeIndex && i < endTimeIndex;
          const isSelected = isStartOrEnd || betweenStartAndEnd;
          return (
            <WrapItem key={i}>
              <ScheduleButton
                time={s.placeHolder}
                available={s.available}
                index={i}
                isSelected={isSelected}
                bookingID={s.bookingID}
                scheduleRuleID={s.scheduleRuleID}
                handleSelect={() => handleSelect(i)}
              />
            </WrapItem>
          );
        })}
      </Wrap>
      <Stack
        w={'full'}
        direction={['column', 'row']}
        justifyContent={'space-between'}
      >
        <VStack alignItems={'flex-start'}>
          <Text color="black">{`DURAÇÃO: ${durationView}`}</Text>
        </VStack>
        <Button
          variant="pophausOutline"
          colorScheme="popGreenAlt"
          isLoading={loading}
          onClick={handleContinue}
        >
          reagendar
        </Button>
      </Stack>
    </VStack>
  );
};
