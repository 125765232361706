import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  Box,
  Button,
  HStack,
  Image,
  Select,
  Stack,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';

import EmptyModal from 'src/components/Modal/ModalVariants/EmptyModal';
import formIcon from 'src/assets/events/form-icon.webp';
import Form from 'src/components/Form/Form';
import { UseFormReturn } from 'react-hook-form';
import { FormInput } from 'src/components/Form/Input';
import { FormMaskedInput } from 'src/components/Form/Input/MaskedInput';

export interface EventLeadFormValues {
  name: string;
  lastName: string;
  email: string;
  phone: string;
}

export interface EventLeadFormModalProps {
  form: UseFormReturn<EventLeadFormValues, any>;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => Promise<void>;
  isLoading: boolean;
  pdf: boolean;
  setPdf: Dispatch<SetStateAction<boolean>>;
}

export default function EventLeadFormModal({
  form,
  isOpen,
  onClose,
  onConfirm,
  isLoading,
  pdf,
  setPdf,
}: EventLeadFormModalProps) {
  const [smallerThan500] = useMediaQuery('(max-width: 500px)');
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = form;

  const pathPdf =
    'https://arcadehausstorage.blob.core.windows.net/festas/Apresentacao_Eventos_Arcade_2024.pdf';

  function downloadPdf() {
    window.open(pathPdf, '_blank');
  }

  return (
    <EmptyModal isOpen={isOpen} onClose={onClose} size="2xl">
      <Stack style={{ display: pdf ? 'block' : 'none' }}>
        <iframe src={pathPdf} width="100%" height="400"></iframe>
        <Button
          px={5}
          mt={10}
          variant="pophausOutline"
          type="button"
          isLoading={isLoading}
          onClick={downloadPdf}
        >
          Download
        </Button>
      </Stack>

      {!pdf && (
        <Stack>
          <Image alignSelf="center" src={formIcon} w="100px" />
          <Text textAlign="center" variant="subtitle" fontSize="18px">
            Preencha o formulário para conferir os <br /> preços dos eventos no
            Arcade Haus
          </Text>
          <Form onSubmit={handleSubmit(onConfirm)}>
            <Stack padding={smallerThan500 ? 0 : 12}>
              <Stack direction={['column', 'row']} gap={[3, 6]}>
                <FormInput
                  rhfRegister={{
                    ...register('name', {
                      required: 'campo obrigatório',
                    }),
                  }}
                  label="NOME"
                  errorState={errors.name}
                  inputProps={{ placeholder: 'Digite seu nome' }}
                />
                <FormInput
                  rhfRegister={{
                    ...register('lastName', {
                      required: 'campo obrigatório',
                    }),
                  }}
                  label="SOBRENOME"
                  errorState={errors.lastName}
                  inputProps={{ placeholder: 'Digite seu sobrenome' }}
                />
              </Stack>
              <FormInput
                label="E-MAIL"
                rhfRegister={{
                  ...register('email', {
                    required: 'campo obrigatório',
                  }),
                }}
                errorState={errors.email}
                inputProps={{ placeholder: 'Digite seu email' }}
              />
              <FormMaskedInput
                control={control}
                id={'phone'}
                mask={'+99 99 99999-9999'}
                rules={{
                  required: 'Campo obrigatório',
                }}
                errorState={errors.phone}
                label={'CONTATO'}
                inputProps={{ placeholder: '+55 11 2667-9368' }}
              />
              <Button
                px={0}
                mt={6}
                variant="pophausOutline"
                isLoading={isLoading}
                type={'submit'}
              >
                Enviar Formulário
              </Button>
            </Stack>
          </Form>
        </Stack>
      )}
    </EmptyModal>
  );
}
