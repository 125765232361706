import { Button, Stack, Text, Icon } from '@chakra-ui/react';
import React from 'react';
import DashedContainer from 'src/components/Container/DashedContainer';
import { useAppSelector } from 'src/redux/hooks';
import { IoTrophyOutline } from 'react-icons/io5';

interface RankingButtonProps {
  onOpen: () => void;
}

export const RankingButton = ({ onOpen }: RankingButtonProps) => {
  return (
    <DashedContainer>
      <Stack direction="row" align="center" spacing={4}>
        <Icon as={IoTrophyOutline} boxSize={8} color="green" />
        <Text color="white" fontSize="18px" fontWeight={500}>
          Veja o ranking completo
        </Text>
        <Button w="contain" variant="pophausOutline" p={'0 2'} onClick={onOpen}>
          acessar
        </Button>
      </Stack>
    </DashedContainer>
  );
};
