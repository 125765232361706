import { ApiRepository, ApiResponse, useFetch } from 'src/api';
import {
  CouponRequest,
  CouponResult,
  EditCouponRequest,
  ValidadeCouponResult,
} from 'src/api/interfaces/coupon';

const api = new ApiRepository();

export function useGetCoupons() {
  const args = {
    method: 'get',
    url: `Coupon`,
  };

  const { data, error, isLoading, mutate } = useFetch<
    ApiResponse<CouponResult[]>
  >(api, args, { revalidateOnFocus: false });
  return { data: data?.result, error, isLoading, mutate };
}

export function useGetCouponByID(couponID: string | undefined) {
  const args = {
    method: 'get',
    url: `Coupon/ ${couponID}`,
  };

  const { data, error, isLoading, mutate } = useFetch<
    ApiResponse<CouponResult>
  >(api, args, { revalidateOnFocus: false });
  return { data: data?.result, error, isLoading, mutate };
}

/**
 * This function creates a coupon by making a POST request to an API endpoint and returns the result.
 * @param {CouponRequest} data - CouponRequest object that contains the necessary information to create
 * a coupon. This object likely includes properties such as the coupon code, discount amount,
 * expiration date, and any applicable restrictions or limitations.
 * @returns The function `createCoupon` is returning the `result` property of the response object
 * `res`.
 */
export async function createCoupon(data: CouponRequest) {
  const res = await api.apiRequestWrapper<ApiResponse<CouponResult>>({
    method: 'post',
    url: 'Coupon',
    data,
  });
  return res.result;
}

export async function editCouponById(
  couponID: string,
  data: EditCouponRequest
) {
  const res = await api.apiRequestWrapper<ApiResponse<CouponResult>>({
    method: 'put',
    url: `Coupon/${couponID}`,
    data,
  });
  return res.result;
}

/**
 * This function validates a coupon for a specific booking and user.
 * @param {string} coupon - The coupon code that needs to be validated.
 * @param {string} bookingID - The ID of the booking for which the coupon is being validated.
 * @param {string} userID - The userID parameter is a string that represents the unique identifier of
 * the user who is trying to validate the coupon.
 * @returns the result of a API request to validate a coupon, which is of type `ValidadeCouponResult`.
 */
export async function validateCoupon(
  coupon: string,
  bookingID: string,
  userID: string
) {
  const res = await api.apiRequestWrapper<ApiResponse<ValidadeCouponResult>>({
    method: 'get',
    url: `Coupon/Validate?Coupon=${coupon}&BookingID=${bookingID}&UserID=${userID}`,
  });
  return res.result;
}

/**
 * This function removes a coupon associated with a booking ID.
 * @param {string} BookingID - BookingID is a string parameter that represents the unique identifier of
 * a booking. It is used in the URL of the API request to specify which booking's coupon needs to be
 * removed.
 * @returns the result of the API request made to remove a coupon associated with a booking ID. The
 * result is of type `ValidadeCouponResult`.
 */
export async function removeCoupon(BookingID: string) {
  const res = await api.apiRequestWrapper<ApiResponse<ValidadeCouponResult>>({
    method: 'put',
    url: `Coupon/Remove?BookingID=${BookingID}`,
  });

  return res.result;
}

export async function deleteCoupon(CouponID: string) {
  const res = await api.apiRequestWrapper<ApiResponse<ValidadeCouponResult>>({
    method: 'delete',
    url: `Coupon/${CouponID}`,
  });

  return res.result;
}

export async function getFilteredCoupon(searchTerm: string) {
  const res = await api.apiRequestWrapper<ApiResponse<CouponResult[]>>({
    method: 'get',
    url: `Coupon/Filter?Search=${searchTerm}`,
  });

  return res.result;
}
