import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import Form from 'src/components/Form/Form';
import CreateAccountForm, {
  CreateAccountFormValues,
} from 'src/modules/Forms/CreateAccountForm';
import usePHToast from 'src/hooks/useToast';
import { useForm } from 'react-hook-form';
import { clearNonDigits } from 'src/utils/clearNonDigits';
import { getUserById, login, signUp } from 'src/api/services/user';
import { SuccessModal } from 'src/components/Modal/ModalVariants/SuccessModal';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { UserActions } from 'src/redux/user';
import parseJwt from 'src/utils/parseJwt';
import { addUserToBooking } from 'src/api/services/booking';
import { cpf } from 'cpf-cnpj-validator';

interface SignUpStepProps {
  handleGoBack: () => void;
}

export default function SignUpStep({ handleGoBack }: SignUpStepProps) {
  const dispatch = useAppDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = usePHToast();
  const navigate = useNavigate();
  const createAccountForm = useForm<CreateAccountFormValues>();
  const { handleSubmit, formState: { isValid } } = createAccountForm;


  const { user } = useAppSelector((state) => state.user);
  const { booking } = useAppSelector((state) => state.booking);

  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async (data: CreateAccountFormValues) => {
    try {
      setLoading(true);
      if (!cpf.isValid(data.document)) return toast({ status: 'error', title: 'CPF inválido' })

      const cleanFormValues = {
        ...data,
        phone: clearNonDigits(data.phone),
        document: clearNonDigits(data.document),
        zipCode: clearNonDigits(data.zipCode),
      };

      const signupResult = await signUp(cleanFormValues)

      const result = await login(
        cleanFormValues.email,
        cleanFormValues.password
      );
      const accessToken = result.access_token;
      const authData = parseJwt(accessToken);
      const roles = authData.role;
      const user = await getUserById(authData.sub, accessToken);

      dispatch(
        UserActions.setUser({
          ...user,
          roles,
          accessToken,
        })
      );

      toast({ status: 'success', title: 'Conta criada com sucesso' });
      await handleSuccess();
    } catch (e: any) {
      const validationErrors = e?.response?.data?.errors;
      if (validationErrors && validationErrors.length > 0) {
        for (const error of validationErrors) {
          toast({ status: 'error', title: error });
        }
      } else {
        toast({ status: 'error', title: e?.message });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSuccess = async () => {
    // Checar se tem reserva (se fez não-logado)
    if (booking.bookingId && user) {
      const result = await addUserToBooking(booking.bookingId, user.userID);
      navigate(`/ingressos/resumo/${result.bookingID}`);
      return;
    }
    navigate('/ingressos');
  };

  return (
    <>
      <Text variant="title">crie sua conta</Text>
      <CreateAccountForm rhfForm={createAccountForm} />
      <Button
        w="full"
        variant={'pophausOutline'}
        type="submit"
        isLoading={loading}
        onClick={handleSubmit(onSubmit)}
      >
        continuar
      </Button>
      <Text variant="link" onClick={handleGoBack} pb={4}>
        Voltar para home
      </Text>
      <SuccessModal
        isOpen={isOpen}
        onClose={onClose}
        handleMainClick={handleSuccess}
      />
    </>
  );
}
