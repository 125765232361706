import React, { useState } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';
import Form from 'src/components/Form/Form';
import { UseFormRegister, UseFormReturn } from 'react-hook-form';
import { LoginFormValues, NewPasswordFormValues } from 'src/pages/login';
import { FormInput } from 'src/components/Form/Input';

interface ForgotPasswordStepProps {
  formEmail?: string
  handleEmailConfirm: (e: React.FormEvent<HTMLFormElement>) => void;
  handleGoBack: () => void
  loading: boolean;
  rhfForm: UseFormReturn<NewPasswordFormValues, any>;
}

export default function ForgotPasswordStep({
  handleEmailConfirm,
  formEmail,
  loading,
  handleGoBack,
  rhfForm
}: ForgotPasswordStepProps) {
  const [email, setEmail] = useState(formEmail ?? '')

  const {
    register,
    getValues,
    formState: { errors },
  } = rhfForm;

  return (
    <>
      <Stack direction="column" w='full'>
        <Text variant="title" textAlign={'center'}>
          esqueceu sua senha?
        </Text>
        <Text variant="text" color="white" textAlign={'center'}>
          Por favor, confirme seu email
        </Text>
      </Stack>

      <Form onSubmit={handleEmailConfirm}>
        <Stack gap={3}>
          <FormInput
            rhfRegister={{
              ...register('email', {
                required: 'Campo obrigatório',
              }),
            }}
            errorState={errors.email}
            label={'E-MAIL'}
            inputProps={{ placeholder: 'DIGITE SEU E-MAIL' }}

          />

          <Button
            w="full"
            variant={'pophausOutline'}
            type="submit"
            isLoading={loading}
            paddingInline={0}
          >
            continuar
          </Button>
          <Button
            w="full"
            variant={'pophausOutline'}
            colorScheme={'popTransparent'}
            onClick={handleGoBack}
            paddingInline={0}
          >
            voltar
          </Button>

        </Stack>

      </Form>
    </>
  );
}
