import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  Input,
  Select,
  Stack,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';

import { useNavigate } from 'react-router-dom';
import { BookModal } from 'src/components/Modal/ModalVariants/BookModal';
import EmptyModal from 'src/components/Modal/ModalVariants/EmptyModal';
import { FormInput } from 'src/components/Form/Input';
import { BsPencil, BsTrash } from 'react-icons/bs';
import PlayerDataForm from 'src/modules/Forms/PlayerData';
import { useGetScheduleRules } from 'src/api/services/scheduleRule';
import { Loading } from 'src/components/Loading';
import { ScheduleRuleTypesResult } from 'src/api/interfaces/scheduleRule';

export interface Modals {
  isBookAlertOpen: boolean;
  onBookAlertClose: () => void;
  isViewAlertOpen: boolean;
  onViewAlertClose: () => void;
}

export default function CalendarModals({
  isBookAlertOpen,
  onBookAlertClose,
  isViewAlertOpen,
  onViewAlertClose,
}: Modals) {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleEditorOpen = () => {
    onOpen();
  };

  return (
    <>
      <BookModal
        type="book"
        data={{ time: '11:30 às 12:30', date: '06/12/2022', unit: 'Tatuapé' }}
        isOpen={isBookAlertOpen}
        onClose={onBookAlertClose}
        handleMainClick={() => navigate('/admin/agenda/resumo')}
        handleSecondaryClick={() => {
          return;
        }}
      />

      <BookModal
        type="view"
        data={{ time: '11:30 às 12:30', date: '06/12/2022', unit: 'Tatuapé' }}
        isOpen={isViewAlertOpen}
        onClose={onViewAlertClose}
        handleMainClick={handleEditorOpen}
        handleSecondaryClick={() => {
          return;
        }}
      />

      <EmptyModal
        isOpen={isOpen}
        onClose={onClose}
        colorScheme="popYellow"
        leftButtonClick={onClose}
        rightButtonClick={onClose}
      >
        <Text mb="20px" variant="title" color="yellow">
          Reservado!
        </Text>
        <Stack mb="20px" direction="row" gap="5%">
          <Stack direction="row">
            <Text color="white">Código da reserva:</Text>
            <Text color="yellow">RSW115QE</Text>
          </Stack>

          <Stack direction="row">
            <Text color="white">Nome: </Text>
            <Text color="yellow">Lucas</Text>
          </Stack>

          <Stack direction="row">
            <Text color="white">Data e hora:</Text>
            <Text color="yellow">10:00 às 11:00</Text>
          </Stack>
        </Stack>

        <Stack h="400px" overflowY="scroll" p={5}>
          {/* <PlayerDataForm colorScheme="yellow" playerNum={1} />
          <PlayerDataForm colorScheme="yellow" playerNum={2} />
          <PlayerDataForm colorScheme="yellow" playerNum={3} />
          <PlayerDataForm playerNum={4} colorScheme="yellow" />
          <PlayerDataForm playerNum={5} colorScheme="yellow" />
          <PlayerDataForm playerNum={6} colorScheme="yellow" />
          <PlayerDataForm playerNum={7} colorScheme="yellow" /> */}
        </Stack>
      </EmptyModal>
    </>
  );
}

export interface BlockModalInterface {
  isOpen: boolean
  onClose: () => void
  scheduleRules?: ScheduleRuleTypesResult[]
  isLoading: boolean
  handleClick: (ruleId: number) => void
}

export const BlockModal = ({ isOpen, onClose, scheduleRules, isLoading, handleClick }: BlockModalInterface) => {
  const [scheduleRuleId, setScheduleRuleId] = useState<number>(5)

  return (
    <EmptyModal isOpen={isOpen} onClose={onClose} size='xl' >
      <Text mb="20px" variant="title" color="yellow">
        Bloquear Horário
      </Text>

      <VStack gap={6}>
        <FormControl>
          <FormLabel color='white'>MOTIVO DE BLOQUEIO</FormLabel>
          <Select
            borderBottom={0}
            variant="flushed"
            bg='white'
            color={'#131313'}
            placeholder={'Motivo de bloqueio'}
            onChange={(e) => setScheduleRuleId(+e.target.value)}
            disabled={isLoading}
          >
            {scheduleRules?.map((e, idx: number) => (
              <option key={idx} value={e.scheduleRuleStatusID}>{e.description}</option>
            ))}
          </Select>
        </FormControl>

        <Button onClick={() => handleClick(scheduleRuleId)} w='full' variant='pophausOutline'>Bloquear</Button>

      </VStack>


    </EmptyModal>
  )
}
