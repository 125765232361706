import React from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import {
  Button,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react';
import { ViewOffIcon, ViewIcon } from '@chakra-ui/icons';

interface FormInputPasswordProps {
  rhfRegister: UseFormRegisterReturn;
  label?: string;
  errorState?: FieldError;
  inputProps?: InputProps;
  formControlProps?: FormControlProps;
  readonly?: boolean;
}

export const FormInputPassword = ({
  label = 'SENHA',
  rhfRegister,
  errorState,
  inputProps,
  formControlProps,
  readonly = false,
}: FormInputPasswordProps) => {
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const id = rhfRegister.name;

  return (
    <FormControl
      id={id}
      isRequired={rhfRegister.required}
      isInvalid={!!errorState}
      isReadOnly={readonly}
      {...formControlProps}
    >
      <FormLabel color={'white'} htmlFor={id}>
        {label}
      </FormLabel>
      <InputGroup bg={'white'} border="1px" borderColor={'green'} px={3}>
        <Input
          id={id}
          type={show ? 'text' : 'password'}
          placeholder="*****"
          border="0px"
          variant="flushed"
          color={'#131313'}
          _placeholder={{ color: 'gray' }}
          px={2}
          boxShadow={0}
          {...inputProps}
          {...rhfRegister}
        />
        <InputRightElement width="4.5rem">
          <Button variant="ghost" color="green" onClick={handleClick}>
            {show ? (
              <ViewOffIcon boxSize={'1.5em'} />
            ) : (
              <ViewIcon boxSize={'1.5em'} />
            )}
          </Button>
        </InputRightElement>
      </InputGroup>
      <FormErrorMessage color="red">
        {errorState && errorState.message}
      </FormErrorMessage>
    </FormControl>
  );
};
