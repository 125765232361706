import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
  control: {
    padding: 2,
    border: '1px',
    borderColor: 'green',
    _checked: {
      bg: 'transparent',
      borderColor: 'green',
      _hover: {
        bg: 'transparent',
        borderColor: 'green',
      },
    },
    _hover: {
      bg: 'transparent',
    },
  },
});

export const checkboxTheme = defineMultiStyleConfig({ baseStyle });
