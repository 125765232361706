import React from 'react';
import { Box, Button, Flex, Stack, Text } from '@chakra-ui/react';
import Divider from 'src/components/Divider';

interface ScheduleButtonProps {
  time?: string;
  available?: boolean;
  index: number;
  isSelected: boolean;
  bookingID?: string;
  scheduleRuleID?: number;
  handleSelect: (index: number) => void;
  handleDisabledClick?: (bookingID?: string, scheduleRuleID?: number) => void;
}


const BlockedButton = ({ owner, time, onClick }: { owner?: string, time?: string, onClick: () => void }) => {
  return (
    <Flex onClick={onClick} w='300px' h='100px' bg='red' direction='row' textAlign='center' cursor='pointer' >

      <Flex h='100%' w='100%' alignItems='center' flex={1 / 4} borderRight='1px solid black' p={2}>
        {time}
      </Flex>
      <Stack p={2} flex={3 / 4}>
        <Text >{owner}</Text>
      </Stack>
    </Flex>
  )
}

export const ScheduleButton = ({
  time,
  available,
  index,
  isSelected,
  bookingID,
  scheduleRuleID,
  handleSelect,
  handleDisabledClick,
}: ScheduleButtonProps) => {
  const handleOnClick = (index: number) => {
    if (!available) {
      if (handleDisabledClick) handleDisabledClick(bookingID, scheduleRuleID);
      return;
    }
    handleSelect(index);
  };


  return (
    <>
      <Button
        onClick={() => handleOnClick(index)}
        bg={!available ? 'gray' : 'green'}
        colorScheme={'green'}
        borderRadius={0}
        border={'2px'}
        borderColor={isSelected ? 'black' : 'transparent'}
        minWidth={136}
        py={6}
        px={12}
      >
        <Text
          color={!available ? 'white' : 'black'}
          fontWeight={isSelected ? 'bold' : 'normal'}
        >
          {time}
        </Text>
      </Button>
    </>
  );
};
