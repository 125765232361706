import React from 'react';
import { Box, BoxProps, AspectRatio, Image, useMediaQuery } from '@chakra-ui/react';
// import ReactPlayer from 'react-player'

interface ImageBoxProps {
  src?: string;
  title?: string;
  aspectRatio?: number;
  objectPosition?: string
  imageWidth?: string
  imageMaxWidth?: string
}

export default function ImageBox({
  src,
  title,
  aspectRatio,
  objectPosition = 'center center',
  imageWidth = 'full',
  imageMaxWidth = 'full',
  ...props
}: ImageBoxProps & BoxProps) {
  const placeholder = '../../assets/jogos.jpg';
  const [largerThan500] = useMediaQuery('(min-width: 500px)')

  return (
    <Box position="relative" w={{ base: '70%', md: 'full' }}>
      <AspectRatio ratio={aspectRatio ? aspectRatio : largerThan500 ? 16 / 9 : 9 / 16} w={imageWidth} maxW={imageMaxWidth} zIndex={1}>
        <Image src={src || placeholder} objectPosition={objectPosition} />
        {/* <ReactPlayer config={{ file: { forceHLS: true } }} url={`${src}}(format=m3u8-aapl)`} height='inherit' width='inherit' /> */}
        {/* <iframe
          title={title || 'YouTube video player'}
          src={src || placeholder}
          allowFullScreen
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        /> */}
      </AspectRatio>
      <Box
        bg="transparent"
        w="100%"
        h="100%"
        p={4}
        position="absolute"
        top="-15px"
        left="15px"
        {...props}
      />
    </Box>
  );
}
