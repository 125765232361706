import { FaqResult, FaqRequest } from 'src/api/interfaces/faq';
import { ApiRepository, ApiResponse, useFetch } from '../..';

const api = new ApiRepository();

export async function getFilteredFAQ(searchTerm: string) {
  const res = await api.apiRequestWrapper<ApiResponse<FaqResult[]>>({
    method: 'get',
    url: `Faq/Filter?Search=${searchTerm}`,
  });

  return res.result;
}

export function useGetFaq() {
  const args = {
    method: 'get',
    url: `Faq`,
  };

  const { data, error, isLoading, mutate } = useFetch<ApiResponse<FaqResult[]>>(
    api,
    args,
    { revalidateOnFocus: false }
  );
  return { data: data?.result, error, isLoading, mutate };
}

export function useGetActiveFaq() {
  const args = {
    method: 'get',
    url: `Faq/GetActiveFaqs`,
  };

  const { data, error, isLoading, mutate } = useFetch<ApiResponse<FaqResult[]>>(
    api,
    args,
    { revalidateOnFocus: false }
  );
  return { data: data?.result, error, isLoading, mutate };
}

export function useGetFaqById(faqId: number) {
  const args = faqId
    ? {
        method: 'get',
        url: `Faq/${faqId}`,
      }
    : null;

  const { data, error, isLoading, mutate } = useFetch<ApiResponse<FaqResult>>(
    api,
    args,
    { revalidateOnFocus: false }
  );
  return { data: data?.result, error, isLoading, mutate };
}

export async function postCreateFAQ(data: FaqRequest) {
  const res = await api.apiRequestWrapper<ApiResponse<FaqResult[]>>({
    method: 'post',
    url: `Faq`,
    data,
  });
  return res.result;
}

export async function putToggleFAQ(faqId: number, isActive: boolean) {
  const res = await api.apiRequestWrapper<ApiResponse<FaqResult[]>>({
    method: 'put',
    url: `Faq/EnableFaq?FaqID=${faqId}&isActive=${isActive}`,
  });
  return res.result;
}

export async function putOrderFAQ(faqId: number, order: number) {
  const res = await api.apiRequestWrapper<ApiResponse<FaqResult[]>>({
    method: 'put',
    url: `Faq/OrderFaq?FaqID=${faqId}&Order=${order}`,
  });
  return res.result;
}

export async function putUpdateFAQ(faqId: number, data: FaqRequest) {
  const res = await api.apiRequestWrapper<ApiResponse<FaqResult[]>>({
    method: 'put',
    url: `Faq?FaqID=${faqId}`,
    data,
  });
  return res.result;
}

export async function deleteFAQ(faqID: number) {
  const res = await api.apiRequestWrapper<ApiResponse<FaqResult[]>>({
    method: 'delete',
    url: `Faq/${faqID}`,
  });
  return res.result;
}
