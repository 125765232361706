import React from 'react';
import { BsExclamationTriangle } from 'react-icons/bs';
import { PophausModal } from '../..';

interface ErrorModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleMainClick: (e?: string) => void;
  content?: string;
  color?: string;
  loading?: boolean;
}

export function ErrorModal({
  isOpen,
  onClose,
  handleMainClick,
  content = `ALGO DEU ERRADO!\nTENTE NOVAMENTE.`,
  color,
  loading,
}: ErrorModalProps) {
  return (
    <PophausModal
      isOpen={isOpen}
      onClose={onClose}
      icon={BsExclamationTriangle}
      loading={loading}
      content={content}
      handleMainClick={handleMainClick}
      iconColor={color}
    />
  );
}
