export const getDuration = (
  startTimeIndex: number,
  endTimeIndex: number,
  admin?: boolean
) => {
  if (startTimeIndex === -1 && endTimeIndex === -1) return 0;
  const dur =
    startTimeIndex !== -1 && endTimeIndex === -1
      ? 1
      : endTimeIndex - startTimeIndex + 1;
  return Math.max(0, admin ? dur : dur * 2);
};

export const getDurationInFull = (duration: number, step: number) => {
  if (duration > 1) {
    const totalMinutes = duration * (step + 5) - 5;
    const hours = totalMinutes / 60;
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);

    if (rhours > 0) {
      return `${rhours} HORA(S) E ${rminutes} MINUTO(S).`;
    }
    return `${rminutes} MINUTO(S).`;
  }

  const totalMinutes = duration * step;
  const hours = totalMinutes / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);

  if (rhours > 0) {
    return `${rhours} HORA(S) E ${rminutes} MINUTO(S).`;
  }
  return `${rminutes} MINUTO(S).`;
};
