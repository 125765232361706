import React, { useState } from 'react';
import PageContainer from 'src/components/Container/PageContainer';
import { Button, HStack, Stack, Text } from '@chakra-ui/react';
import CreateCouponForm, {
  CreateCouponFormValues,
} from 'src/modules/Forms/Backoffice/CreateCouponForm';
import { useForm } from 'react-hook-form';
import { createCoupon } from 'src/api/services/coupon';
import { CouponRequest } from 'src/api/interfaces/coupon';
import dayjs from 'dayjs';
import usePHToast from 'src/hooks/useToast';
import { useNavigate } from 'react-router-dom';

export default function CreateNewCouponPage() {
  const [loading, setLoading] = useState(false);
  const createCouponForm = useForm<CreateCouponFormValues>();
  const { handleSubmit } = createCouponForm;
  const navigate = useNavigate();
  const toast = usePHToast();

  const onSubmit = async (data: CreateCouponFormValues) => {

    try {
      setLoading(true);
      const reqData: CouponRequest = {
        ...data,
        lifeSpanStartDate: dayjs(data.lifeSpanStartDate).format('DD/MM/YYYY'),
        lifeSpanEndDate: dayjs(data.lifeSpanEndDate).format('DD/MM/YYYY'),
        maximumValue: data.couponTypeID === 1 ? data.maximumValue : undefined,
      };

      const res = await createCoupon(reqData);
      navigate('/admin/cupons');
      toast({ title: 'Cupom criado com sucesso', status: 'success' });
    } catch (err: any) {
      toast({ status: 'error', title: err?.message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageContainer restricted>
      <Text alignSelf="start" variant="title">
        Adicionar cupom
      </Text>
      <CreateCouponForm rhfForm={createCouponForm} />
      <HStack w={'full'}>
        <Button
          w="full"
          variant={'pophausOutline'}
          colorScheme="popTransparent"
          isLoading={loading}
          onClick={() => navigate('/admin/cupons')}
        >
          Cancelar
        </Button>
        <Button
          w="full"
          variant={'pophausOutline'}
          type="submit"
          onClick={handleSubmit(onSubmit)}
          isLoading={loading}
        >
          Salvar
        </Button>
      </HStack>
    </PageContainer>
  );
}
