import Axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  RawAxiosRequestHeaders,
} from 'axios';
import { store } from 'src/redux/store';
import useSWR, { SWRConfiguration } from 'swr';
import usePHToast from 'src/hooks/useToast';
import Cookies from 'universal-cookie';
import { UserActions } from 'src/redux/user';

export interface ApiRequestWrapperResponse<T = any> {
  data?: T;
  error?: AxiosError;
  success: boolean;
}

export interface ApiRequestWrapperArgs {
  method: AxiosRequestConfig['method'];
  url: string;
  data?: object;
  headers?: RawAxiosRequestHeaders;
  timeout?: AxiosRequestConfig['timeout'];
  params?: AxiosRequestConfig['params'];
}

export interface ApiResponse<T> {
  errors: any;
  message: string;
  result: T;
  statusCode: number;
}

const envAuthServerUrl = process.env.react_app_auth_server_url;
const envGatewayUrl = process.env.react_app_gateway_url;

export class ApiRepository {
  apiInstance: AxiosInstance;
  constructor(isAuth = false) {
    let api;
    isAuth
      ? (api = Axios.create({
          baseURL:
            process.env.react_app_env === 'dev'
              ? envAuthServerUrl ||
                `https://new-arcade-auth-server-dev.azurewebsites.net/` // dev
              : `https://new-arcade-auth-server.azurewebsites.net/`, // prod
        }))
      : (api = Axios.create({
          baseURL:
            process.env.react_app_env === 'dev'
              ? envGatewayUrl ||
                `https://new-arcade-gateway-dev.azurewebsites.net/api/` // dev
              : `https://new-arcade-gateway.azurewebsites.net/api/`, // prod
        }));

    this.apiInstance = api;
  }

  public getAccessToken(): string | null {
    const cookies = new Cookies();
    if (store) {
      const user = store.getState().user.user;
      if (user) {
        return user.accessToken || null;
      }
    }
    return cookies.get<string>('pophaus_token') || null;
  }

  // WIP
  // public getRefreshToken(): string | null {
  //   const cookies = new Cookies();
  //   // if (store) {
  //   //   const user = store.getState().user.user;
  //   //   if (user) {
  //   //     return user.refreshToken || null;
  //   //   }
  //   // }
  //   return cookies.get<string>('pophaus_refresh_token') || null;
  // }

  private async handleUnauthorized() {
    // const refresh = this.getRefreshToken();
    // if (refresh) {
    //   await refreshToken(refresh);
    // }
    if (store) {
      const dispatch = store.dispatch;
      dispatch(UserActions.logout());
    }
  }

  public async apiRequestWrapper<T>(args: ApiRequestWrapperArgs): Promise<T> {
    try {
      const accessToken = this.getAccessToken();

      const config = {
        method: args.method,
        url: args.url,
        data: args.data,
        timeout: args?.timeout ?? 30 * 1000, // 30s timeout
        params: args?.params,
        headers: accessToken
          ? {
              Authorization: `Bearer ${accessToken}`,
              ...(args.headers || {}),
            }
          : { ...(args.headers || {}) },
      };

      const res = await this.apiInstance.request<T>(config);
      return res.data;
    } catch (e) {
      if (Axios.isAxiosError(e)) {
        if (e?.response?.status === 401) {
          this.handleUnauthorized();
          throw new Error('Faça login novamente');
        }

        const errorMessage = e?.response?.data?.error?.message as string;
        const error = e?.response?.data?.message as string;
        const errors = e?.response?.data?.errors;

        if (errors) throw e;

        throw new Error(errorMessage || error || 'Ocorreu um erro.');
      }
      throw e;
    }
  }
}

export function useFetch<Data = any, Error = any>(
  api: ApiRepository,
  args: ApiRequestWrapperArgs | null,
  options?: SWRConfiguration
) {
  const fetcher = (args: ApiRequestWrapperArgs) =>
    api.apiRequestWrapper<Data>(args);

  const { data, error, isLoading, isValidating, mutate } = useSWR<Data, Error>(
    args ? { ...args, url: args.url } : null,
    fetcher,
    options
  );

  return { data, error, isLoading, isValidating, mutate };
}
