import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  VStack,
  Text,
  ModalHeader,
  Stack,
  Center,
} from '@chakra-ui/react';
import { GameSelect } from './GameSelect';
import { RankingView } from './RankingView';
import { GetPlayerRank, useGetAllGames } from 'src/api/services/game';
import { useAppSelector } from 'src/redux/hooks';
import { UserResult } from 'src/api/interfaces/user';
import { Loading } from 'src/components/Loading';
import usePHToast from 'src/hooks/useToast';

interface PophausModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleMainClick?: () => void;
}

const scrollBarStyle = {
  '&::-webkit-scrollbar': {
    width: '24px',
  },
  '&::-webkit-scrollbar-track': {
    width: '24px',
    border: '1px solid #B0CA0A',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#B0CA0A',
  },
};

export function RankingModal({
  isOpen,
  onClose,
  handleMainClick,
}: PophausModalProps) {
  const toast = usePHToast();
  const { user } = useAppSelector((state) => state.user);

  const [loading, setLoading] = useState<boolean>(false);
  const [selectedGameId, setSelectedGameId] = useState<number | null>(null);
  const [totalScore, setTotalScore] = useState<number | undefined>(undefined);
  const [position, setPosition] = useState<number | undefined>(undefined);
  const [nickName, setNickName] = useState<string | undefined>(undefined);

  const { data: games } = useGetAllGames(true);

  const handleSelectGame = async (id: number) => {
    setSelectedGameId(id);
    try {
      setLoading(true);
      const playerRank = await GetPlayerRank(id, user?.userID);
      setPosition(playerRank.position);
      setTotalScore(playerRank.totalScore);
      setNickName(playerRank.player?.nickName);
    } catch (e: any) {
      toast({ status: 'error', description: e?.message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      isCentered
      size="xl"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent
        w="full"
        borderRadius={0}
        border={`2px dashed`}
        borderColor="green"
        backgroundColor="black"
        padding="20px"
      >
        <ModalCloseButton
          size="sm"
          position="unset"
          alignSelf={'flex-end'}
          color={'green'}
          border={'2px'}
          borderRadius={0}
          borderColor={'green'}
        />
        <ModalHeader>
          {!selectedGameId ? (
            <Text variant="title" alignSelf={'flex-start'}>
              RANKING
            </Text>
          ) : (
            <Text
              variant="title"
              alignSelf={'flex-start'}
              textDecoration="underline"
              cursor="pointer"
              onClick={() => setSelectedGameId(null)}
            >
              {games?.find((g) => g.gameID === selectedGameId)?.name ?? ''}
            </Text>
          )}
        </ModalHeader>
        <ModalBody w="full" sx={scrollBarStyle}>
          <VStack px={4} spacing={4} w="full" align={'flex-start'}>
            {!selectedGameId ? (
              <GameSelect gamesList={games} selectGame={handleSelectGame} />
            ) : (
              <RankingView gameId={selectedGameId} />
            )}
          </VStack>
        </ModalBody>
        <ModalFooter mt={4} borderTop={'2px'} borderColor="green">
          {loading && (
            <Center w="full">
              <Loading />
            </Center>
          )}
          {!loading && selectedGameId && totalScore !== 0 && (
            <Stack direction="row" w="full" justify="space-between">
              <Text variant="title">{`${position}. ${nickName}`}</Text>
              <Text variant="title">{`${totalScore} PTS`}</Text>
            </Stack>
          )}
          {!loading && selectedGameId && totalScore === 0 && (
            <Stack w="full" justify="space-between" textAlign="center">
              <Text variant="title">Você ainda não jogou esse jogo</Text>
            </Stack>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
