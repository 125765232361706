import { GameResult, RankingResult } from 'src/api/interfaces/game';
import { ApiRepository, ApiResponse, useFetch } from '../..';

const api = new ApiRepository();

export function useGetGameByID(GameID: number) {
  const args = GameID
    ? {
        method: 'get',
        url: `Game/${GameID}`,
      }
    : null;

  const { data, error, isLoading } = useFetch<ApiResponse<GameResult>>(
    api,
    args,
    { revalidateOnFocus: false }
  );
  return { data: data?.result, error, isLoading };
}

export function useGetAllGames(Active: boolean) {
  const args = Active
    ? {
        method: 'get',
        url: `Game?Active=${Active}`,
      }
    : null;

  const { data, error, isLoading } = useFetch<ApiResponse<GameResult[]>>(
    api,
    args,
    { revalidateOnFocus: false }
  );
  return { data: data?.result, error, isLoading };
}

export async function GetPlayerRank(GameID?: number, UserID?: string) {
  const res = await api.apiRequestWrapper<ApiResponse<RankingResult>>({
    method: 'get',
    url: `Game/Ranking?GameID=${GameID}&UserID=${UserID}`,
  });
  return res.result;
}
