import React, { useEffect } from 'react';
import PageContainer from 'src/components/Container/PageContainer';
import { Stack, Text, Wrap, WrapItem, Image, Box, useMediaQuery, HStack } from '@chakra-ui/react';
import { Route, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useGetUserAttachments } from 'src/api/services/match';
import { useAppSelector } from 'src/redux/hooks';
import { AttachmentIcon } from '@chakra-ui/icons';
import path from 'path';
import { useGetMediasByBookingID } from 'src/api/services/medias';
import { Loading } from 'src/components/Loading';
import ReactPlayer from 'react-player';
import { FacebookIcon, FacebookShareButton, InstapaperIcon, InstapaperShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import { BsInstagram } from 'react-icons/bs';
import { IoLogoInstagram } from 'react-icons/io5';
import { FiInstagram } from 'react-icons/fi';

export default function PlayerMediaDetails() {
  const { bookingId } = useParams<{ bookingId: string }>();
  const { user } = useAppSelector((state) => state.user);
  const { data: medias, isLoading } = useGetMediasByBookingID(bookingId);
  const [smallerThan768] = useMediaQuery('(max-width: 768px)')


  return (
    <PageContainer restricted presentGoBack>
      <Stack px={3} w='full' h='full' direction="column" spacing={6}>
        <Text variant="title" alignSelf={'flex-start'}>
          MINHAS MÍDIAS
        </Text>

        {isLoading && <Loading />}
        {!isLoading && medias && <><Stack direction="row" spacing={6}>
          <Text
            color="white"
            fontSize="xs"
            fontWeight={'bold'}
          >{`Dia ${dayjs(medias[0].created).format('DD/MM/YYYY')}`}</Text>
          <Text
            color="white"
            fontSize="xs"
            fontWeight={'medium'}
          >{`${medias.length} itens`}</Text>
        </Stack>

          <Wrap w='100%' spacingX={8} spacingY={4} align="center" justify={smallerThan768 ? 'center' : 'flex-start'}>
            {medias.map((m, i) => (
              <WrapItem key={i}>
                <Stack bg='black' p={3} borderRadius={10} border='2px dashed #B0CA0A'>
                  <Box border='2px solid white' bg='black'>
                    <ReactPlayer
                      url={m.url}
                      controls
                      width='100%'
                      height='100%'
                    />
                  </Box>
                  <HStack>
                    {/* <WhatsappShareButton url={`https://www.arcadehaus.com.br/midia/${m.midiaID}`}><WhatsappIcon borderRadius={10} size={25} /></WhatsappShareButton>
                    <TwitterShareButton url={`https://www.arcadehaus.com.br/midia/${m.midiaID}`}><TwitterIcon borderRadius={10} size={25} /></TwitterShareButton>
                    <FacebookShareButton url={`https://www.arcadehaus.com.br/midia/${m.midiaID}`}><FacebookIcon borderRadius={10} size={25} /></FacebookShareButton> */}
                    <WhatsappShareButton url={m.url}><WhatsappIcon borderRadius={10} size={25} /></WhatsappShareButton>
                    <TwitterShareButton url={m.url}><TwitterIcon borderRadius={10} size={25} /></TwitterShareButton>
                    <FacebookShareButton url={m.url}><FacebookIcon borderRadius={10} size={25} /></FacebookShareButton>
                  </HStack>
                </Stack>
              </WrapItem>
            ))}
          </Wrap></>}
        {!isLoading && !medias && <Text variant='text' color='red'>Nenhuma midia encontrada</Text>}
      </Stack>
    </PageContainer>
  );
}
