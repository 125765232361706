import React from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';

export default function DashedContainer({ children, ...props }: FlexProps) {
  return (
    <Flex
      flexDir="column"
      alignItems="center"
      w="full"
      border={`2px dashed`}
      borderColor="green"
      backgroundColor="black"
      padding={5}
      {...props}
    >
      {children}
    </Flex>
  );
}
