import React, { useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga';
import TikTokPixel from 'tiktok-pixel';
import { Center, Text, VStack } from '@chakra-ui/react';
import { FormAccordion } from 'src/components/Form/Accordion';
import { FormAccordionContent } from 'src/components/Form/Accordion/AccordionContent';
import PageContainer from 'src/components/Container/PageContainer';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Loading } from 'src/components/Loading';
import { getBusinessUnit } from 'src/api/services/businessUnit';
import { BusinessUnitResult } from 'src/api/interfaces/businessUnit';
import usePHToast from 'src/hooks/useToast';
import dayjs from 'dayjs';
import {
  getBookingTypesByConfig,
  useGetBookingByID,
} from 'src/api/services/booking';
import { RescheduleButtons } from 'src/components/Form/RescheduleButtons';
import RescheduleTicketForm, {
  RescheduleTicketFormValues,
} from 'src/modules/Forms/RescheduleForm';
import { GetBookingTypesByConfigResult } from 'src/api/interfaces/booking';
import SingleScheduleButtons from 'src/components/SingleScheduleButtons';

export default function PlayerRescheduleTickets() {
  const { id } = useParams<{ id: string }>();
  const toast = usePHToast();

  const {
    data: booking,
    isLoading: isBookingLoading,
    mutate,
  } = useGetBookingByID(id);

  useEffect(() => {
    if (id) mutate();
  }, [id]);

  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [businessUnit, setBusinessUnit] = useState<BusinessUnitResult | null>(
    null
  );

  const [loadingBookingTypes, setLoadingBookingTypes] =
    useState<boolean>(false);
  const [bookingTypes, setBookingTypes] = useState<
    GetBookingTypesByConfigResult[]
  >([]);
  const [selectedBookingType, setSelectedBookingType] =
    useState<GetBookingTypesByConfigResult>();

  const form = useForm<RescheduleTicketFormValues>({
    values: {
      date: booking?.startDate ?? dayjs().startOf('day').format('YYYY-MM-DD'),
      businessUnitID: booking?.bookingRooms[0]?.room?.businessUnitID ?? 1,
      numberOfPlayers: booking?.tickets ?? 0,
    },
  });

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    TikTokPixel.pageView();
  }, []);

  const handleMultipleRooms = (
    businessUnit: BusinessUnitResult,
    numberOfPlayers: number
  ) => {
    if (numberOfPlayers < 8 || businessUnit.rooms.length < 2)
      return businessUnit;
    const roomIDs = businessUnit.rooms.flatMap((r) => r.roomID);
    const description = `${businessUnit.rooms
      .map((r) => r.description)
      .join(' e ')} (horários combinados)`;
    const combinedRoom = {
      ...businessUnit.rooms[0],
      roomID: roomIDs,
      description,
    };

    const newBusinessUnit = {
      ...businessUnit,
      rooms: [combinedRoom, ...businessUnit.rooms],
    };
    return newBusinessUnit;
  };

  const handleSearch = async (data: RescheduleTicketFormValues) => {
    if (booking?.bookingTypeID === 5) return handleBookingTypesSearch(data);
    try {
      setLoading(true);
      const result = await getBusinessUnit(+data.businessUnitID);
      const mappedUnit = handleMultipleRooms(result, +data.numberOfPlayers);
      // setBusinessUnit(mappedUnit); VOLTAR QUANDO HORARIOS COMBINADOS VOLTAREM
      setBusinessUnit(result);
      setSelectedDate(data.date);
    } catch (e: any) {
      toast({ status: 'error', title: e?.message });
    } finally {
      setLoading(false);
    }
  };

  const BusinessUnitId = useMemo(() => {
    if (!booking) return;

    const bookingRoomLength = booking?.bookingRooms?.length;
    if (!bookingRoomLength) return 1;

    return (
      booking?.bookingRooms[bookingRoomLength - 1].room?.businessUnitID ?? 1
    );
  }, [booking]);

  const handleBookingTypesSearch = async (data: RescheduleTicketFormValues) => {
    try {
      setLoadingBookingTypes(true);
      const result = await getBookingTypesByConfig({
        Tickets: +data.numberOfPlayers,
        BusinessUnitID: +data.businessUnitID,
        Date: data.date,
      });
      setBookingTypes(result);
      setSelectedDate(data.date);
      setSelectedBookingType(
        result.find((r) => r.id === booking?.bookingTypeID)
      );
    } catch (e: any) {
      toast({ status: 'error', title: e?.message });
    } finally {
      setLoadingBookingTypes(false);
    }
  };

  const showRoomTypeButtons =
    !loading &&
    !loadingBookingTypes &&
    businessUnit?.rooms &&
    booking?.bookingTypeID === 1;

  const showSingleTypeButtons =
    !loading &&
    !loadingBookingTypes &&
    booking?.bookingTypeID === 5 &&
    selectedBookingType?.id;

  return (
    <PageContainer restricted>
      <VStack w="full" spacing={2} align="flex-start" pt={12}>
        <RescheduleTicketForm form={form} onSubmit={handleSearch} />
        {(loadingBookingTypes || loading || isBookingLoading) && (
          <Center w="full">
            <Loading />
          </Center>
        )}
        {showRoomTypeButtons && !loading && booking && businessUnit?.rooms && (
          <>
            <Text variant="title">SELECIONE O NOVO HORÁRIO</Text>
            <FormAccordion>
              {businessUnit.rooms.map((r) => (
                <FormAccordionContent
                  key={
                    Array.isArray(r.roomID)
                      ? `combined-${r.roomID[0]}`
                      : r.roomID
                  }
                  title={r.description}
                >
                  <RescheduleButtons
                    roomIDs={r.roomID}
                    date={selectedDate}
                    numberOfPlayers={booking?.tickets}
                    duration={booking?.duration}
                    bookingID={booking?.bookingID}
                    businessUnitID={BusinessUnitId}
                  />
                </FormAccordionContent>
              ))}
            </FormAccordion>
          </>
        )}
        {showSingleTypeButtons && (
          <SingleScheduleButtons
            businessUnitID={1}
            tickets={booking?.tickets}
            date={selectedDate}
            price={selectedBookingType?.price || 0}
            reschedulingBookingID={id}
          />
        )}
      </VStack>
    </PageContainer>
  );
}
