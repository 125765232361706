import React from 'react';
import { Button, Text } from '@chakra-ui/react';

interface UserNotFoundStepProps {
  handleGoToCreateAccount: () => void;
  handleGoBack: () => void;
}

export default function UserNotFoundStep({
  handleGoToCreateAccount,
  handleGoBack,
}: UserNotFoundStepProps) {
  return (
    <>
      <Text
        variant="text"
        fontSize="12px"
        fontWeight="bold"
        textTransform="uppercase"
        color="red"
        alignSelf={'flex-start'}
      >
        usuário não encontrado
      </Text>

      <Button
        w="full"
        variant={'pophausOutline'}
        type="submit"
        onClick={handleGoToCreateAccount}
      >
        crie sua conta
      </Button>

      <Button
        w="full"
        variant={'pophausOutline'}
        colorScheme={'popTransparent'}
        onClick={handleGoBack}
      >
        voltar
      </Button>
    </>
  );
}
