import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { addUserToBooking } from 'src/api/services/booking';
import { signUp, getUserById } from 'src/api/services/user';
import PageContainer from 'src/components/Container/PageContainer';
import CreateAccountForm, {
  CreateAccountFormValues,
} from 'src/modules/Forms/CreateAccountForm';
import { clearNonDigits } from 'src/utils/clearNonDigits';

export default function RegisterUser() {
  const navigate = useNavigate();
  const createAccountForm = useForm<CreateAccountFormValues>();
  const { handleSubmit, register } = createAccountForm;
  const [loading, setLoading] = useState<boolean>(false);
  const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
  const [acceptPromo, setAcceptPromo] = useState<boolean>(false);
  const toast = useToast();

  const onSubmit = async (data: CreateAccountFormValues) => {
    try {
      setLoading(true);

      const cleanFormValues = {
        ...data,
        phone: clearNonDigits(data.phone),
        document: clearNonDigits(data.document),
        zipCode: clearNonDigits(data.zipCode),
      };

      const signupResult = await signUp(cleanFormValues);

      toast({ status: 'success', title: 'Conta criada com sucesso' });
      navigate(`/admin/cadastros/${signupResult.userID}`);
    } catch (e: any) {
      toast({ status: 'error', title: e?.message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageContainer restricted>
      <Box w="100%">
        <Text mb="20px" variant="title">
          cadastrar
        </Text>
        <CreateAccountForm rhfForm={createAccountForm} />

        <Stack mt="20px">
          <Checkbox
            isChecked={acceptTerms}
            onChange={() => setAcceptTerms(!acceptTerms)}
          >
            <Text color="white">
              Aceito os Termos e Condições e autorizo o uso de meus dados de
              acordo com a Política de privacidade.
            </Text>
          </Checkbox>

          <Checkbox
            isChecked={acceptPromo}
            onChange={() => setAcceptPromo(!acceptPromo)}
          >
            <Text color="white">
              Desejo receber promoções, novidades e notificações do Arcadehaus.
            </Text>
          </Checkbox>
        </Stack>

        <Button
          mt={12}
          w="full"
          variant={'pophausOutline'}
          type="submit"
          isLoading={loading}
          onClick={handleSubmit(onSubmit)}
        >
          continuar
        </Button>
      </Box>
    </PageContainer>
  );
}
