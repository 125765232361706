import {
  FinancialReportListResult,
  FinancialReportResult,
} from 'src/api/interfaces/financialReport';
import { ApiRepository, ApiResponse, useFetch } from '../..';

const api = new ApiRepository();

export async function useGetFinancialReport(
  businessUnitID: number,
  startDate: string,
  endDate: string
) {
  const res = await api.apiRequestWrapper<ApiResponse<FinancialReportResult>>({
    method: 'get',
    url: `/FinancialReport?BusinessUnitID=${businessUnitID}&StartDate=${startDate}&EndDate=${endDate}`,
  });
  return res.result;
}

export async function getFinancialReportList(
  businessUnitID: number,
  startDate: string,
  endDate: string
) {
  const res = await api.apiRequestWrapper<
    ApiResponse<FinancialReportListResult[]>
  >({
    method: 'get',
    url: `/FinancialReport/List?BusinessUnitID=${businessUnitID}&StartDate=${startDate}&EndDate=${endDate}`,
  });
  return res.result;
}
