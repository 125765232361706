import React, { useEffect, useState } from 'react';
import { Box, Center, Stack, useMediaQuery } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import PageContainer from 'src/components/Container/PageContainer';
import AdminTicketsFilterForm, {
  AdminTicketsFilterFormValues,
} from 'src/modules/Forms/Backoffice/TicketsFilterForm';
import { useAdminTicketsColumns } from 'src/pages/admin/tickets/TableData';
import { PaginatedTable } from 'src/components/Table/PaginatedTable';
import {
  getBookingFilter,
  getBookingTicket,
  useBookingFilter,
} from 'src/api/services/booking';
import usePHToast from 'src/hooks/useToast';
import { Loading } from 'src/components/Loading';
import dayjs from 'dayjs';

export default function AdminTickets() {
  const toast = usePHToast();
  const [largerThan768] = useMediaQuery('(min-width: 768px)');
  const [loading, setLoading] = useState(false);
  const [resultBooking, setResultBooking] = useState<any>();

  const form = useForm<AdminTicketsFilterFormValues>();

  const getBooking = async (
    name?: string,
    date?: string,
    businessUnitID?: string
  ) => {
    setLoading(true);
    try {
      const res = await getBookingFilter(
        date ? date : '',
        businessUnitID ? businessUnitID : '',
        name ? name : ''
      );
      setResultBooking(res);
      setLoading(false);
    } catch {
      toast({ status: 'error', title: 'Não foi possível carregar os dados!' });
      setLoading(false);
    }
  };

  useEffect(() => {
    getBooking();
  }, []);

  const columns = useAdminTicketsColumns(getBooking);

  const handleSearch = async (data: AdminTicketsFilterFormValues) => {
    if (data.date) {
      try {
        setLoading(true);
        const formatDate = dayjs(data.date).format('DD/MM/YYYY');
        const res = await getBookingFilter(
          formatDate,
          data.businessUnitID,
          data.searchTerm
        );
        setResultBooking(res);
      } catch {
        toast({ status: 'error', title: 'Erro ao aplicar filtro' });
      } finally {
        setLoading(false);
      }
    } else {
      getBooking(data.searchTerm, data.date, data.businessUnitID);
    }
  };

  return (
    <PageContainer restricted>
      <Stack direction="column" pt={8} spacing={6} width="auto" margin="0 1rem">
        <AdminTicketsFilterForm
          form={form}
          onSubmit={handleSearch}
          loading={loading}
        />
        {loading ? (
          <Center>
            <Loading />
          </Center>
        ) : (
          <PaginatedTable
            data={resultBooking}
            columns={columns}
            hasSort
            width={largerThan768 ? 'calc(100vw - 15rem)' : 'calc(100vw - 2rem)'}
            padding={2}
            overflowX="scroll"
          />
        )}
      </Stack>
    </PageContainer>
  );
}
