import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import TikTokPixel from 'tiktok-pixel';
import PageContainer from '../../components/Container/PageContainer';
import PophausLogo from 'src/assets/Logo-horizontal.png';
import { ROLES, UserActions } from 'src/redux/user';
import DashedContainer from '../../components/Container/DashedContainer';
import usePHToast from 'src/hooks/useToast';
import { Stack, Text, VStack, Image } from '@chakra-ui/react';
import EmailStep from 'src/modules/LoginSteps/EmailStep';
import PasswordStep from 'src/modules/LoginSteps/PasswordStep';
import UserNotFoundStep from 'src/modules/LoginSteps/UserNotFoundStep';
import SignUpStep from 'src/modules/LoginSteps/SignUpStep';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  getUserById,
  login,
  checkUsername,
  ForgotPassword,
  ResetPasswordByEmail,
} from 'src/api/services/user';
import parseJwt from 'src/utils/parseJwt';
import { addUserToBooking } from 'src/api/services/booking';
import ForgotPasswordStep from 'src/modules/LoginSteps/ForgotPasswordStep';
import NewPasswordStep from 'src/modules/LoginSteps/NewPasswordStep';
import WaitingForUserActionStep from 'src/modules/LoginSteps/WaitingForUserActionStep';

export const formSteps = {
  email: 0,
  password: 1,
  userNotFound: 2,
  signUp: 3,
  forgotPassword: 4,
  waitingForUserAction: 5,
  newPassword: 6,
};

export interface LoginFormValues {
  username: string;
  password: string;
  name?: string;
}

export interface NewPasswordFormValues {
  email: string;
  password: string;
  confirmPassword: string;
  hash: string;
}

export default function Login() {
  const dispatch = useAppDispatch();
  const toast = usePHToast();
  const navigate = useNavigate();
  const loginForm = useForm<LoginFormValues>();
  const newPasswordForm = useForm<NewPasswordFormValues>();
  const { reset, handleSubmit, setValue } = loginForm;
  const { reset: resetNP, handleSubmit: handleSubmitNP } = newPasswordForm;

  const { booking } = useAppSelector((state) => state.booking);
  const [hash] = useSearchParams();

  const [formStep, setFormStep] = useState(formSteps.email);
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState('ele');

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    TikTokPixel.pageView();
    if (Array.from(hash).length > 0) {
      return setFormStep(formSteps.newPassword);
    }
    setFormStep(formSteps.email);
  }, []);

  const handleUserSubmit = async (data: LoginFormValues) => {
    try {
      setLoading(true);
      const { username } = data;
      const result = await checkUsername(username);
      if (result) {
        setValue('name', result.name);
        setEmail(result.email);
        setFormStep(formSteps.password);
        return;
      }
      throw new Error('Usuário não encontrado.');
    } catch (e: any) {
      setFormStep(formSteps.userNotFound);
    } finally {
      setLoading(false);
    }
  };

  const handlePassSubmit = async (data: LoginFormValues) => {
    try {
      setLoading(true);
      const result = await login(email, data.password);
      if (result) {
        const accessToken = result.access_token;
        const authData = parseJwt(accessToken);
        const roles: string[] = Array.isArray(authData.role)
          ? authData.role
          : [authData.role];

        const user = await getUserById(authData.sub, accessToken);

        dispatch(
          UserActions.setUser({
            ...user,
            roles,
            accessToken,
          })
        );

        // Checar se tem reserva (se fez não-logado)
        if (booking.bookingId) {
          const result = await addUserToBooking(booking.bookingId, user.userID);
          if (booking.bookingTypeID === 5)
            return navigate(`/ingressos/jogadores/${result.bookingID}`);

          return navigate(`/ingressos/resumo/${result.bookingID}`);
        }

        if (roles?.includes(ROLES.ADMIN)) {
          navigate('/admin/ingressos');
          return;
        }

        navigate('/ingressos');
      }
    } catch (e: any) {
      toast({ status: 'error', title: 'Senha incorreta' });
    } finally {
      setLoading(false);
    }
  };

  const handleEmailSubmit = async (data: NewPasswordFormValues) => {
    try {
      setLoading(true);
      const { email } = data;
      const res = await ForgotPassword(email);
      if (res) {
        setFormStep(formSteps.waitingForUserAction);
      }
    } catch (e: any) {
      toast({ status: 'error', title: 'Email não encontrado' });
      setFormStep(formSteps.forgotPassword);
    } finally {
      setLoading(false);
    }
  };

  const handleNewPassSubmit = async (data: NewPasswordFormValues) => {
    try {
      setLoading(true);

      const hashCode = hash.get('hash') ?? '';
      const dataWithHash = {
        ...data,
        hash: hashCode,
      };
      const res = await ResetPasswordByEmail(dataWithHash);
      if (res) {
        toast({ status: 'success', title: 'Senha alterada com sucesso' });
        setFormStep(formSteps.email);
        navigate('/login');
      }
    } catch (e: any) {
      toast({
        status: 'error',
        title: 'Ops...',
        description: 'O email digitado não bate com o email de confirmação',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSignUp = () => {
    setFormStep(formSteps.signUp);
  };

  const handleGoBack = () => {
    onFormReset();
  };

  const onFormReset = () => {
    setFormStep(formSteps.email);
    reset();
    resetNP();
  };

  return (
    <PageContainer containerProps={{ justify: 'center', py: 12 }}>
      <Stack
        direction={['column', 'row']}
        justify={{ base: 'center', md: 'space-evenly' }}
        align="center"
        w="full"
        spacing={12}
        px={4}
      >
        <VStack align="flex-start">
          <Image maxW="100%" h="auto" width="120px" src={PophausLogo} />
          <Text
            variant="text"
            fontSize={'56px'}
            fontWeight={'black'}
            textTransform={'uppercase'}
            lineHeight={'normal'}
          >
            Entre ou cadastre-se
          </Text>
          <Text
            variant="text"
            fontSize={'18px'}
            fontWeight={'medium'}
            lineHeight={'22px'}
          >
            Se você já for cadastrado, entre com seu e-mail e senha. Se for sua
            primeira vez aqui, cadastre-se agora. É rápido!
          </Text>
        </VStack>
        <DashedContainer
          flexDirection="column"
          alignItems="center"
          px={{ base: 4, md: 16 }}
          py={8}
          w={{ base: 'full', md: '50%' }}
        >
          <Stack direction="column" w="full" align="center" spacing={6}>
            {formStep === formSteps.email && (
              <EmailStep
                handleUserSubmit={handleSubmit(handleUserSubmit)}
                handleSignUp={handleSignUp}
                rhfForm={loginForm}
                loading={loading}
              />
            )}
            {formStep === formSteps.password && (
              <PasswordStep
                handlePassSubmit={handleSubmit(handlePassSubmit)}
                rhfForm={loginForm}
                loading={loading}
                stepSetter={setFormStep}
              />
            )}
            {formStep === formSteps.signUp && (
              <SignUpStep handleGoBack={handleGoBack} />
            )}
            {formStep === formSteps.userNotFound && (
              <UserNotFoundStep
                handleGoBack={handleGoBack}
                handleGoToCreateAccount={() => setFormStep(formSteps.signUp)}
              />
            )}
            {formStep === formSteps.forgotPassword && (
              <ForgotPasswordStep
                rhfForm={newPasswordForm}
                handleGoBack={handleGoBack}
                handleEmailConfirm={handleSubmitNP(handleEmailSubmit)}
                loading={loading}
              />
            )}
            {formStep === formSteps.waitingForUserAction && (
              <WaitingForUserActionStep name={loginForm.getValues().name} />
            )}
            {formStep === formSteps.newPassword && (
              <NewPasswordStep
                handleNewPassSubmit={handleSubmitNP(handleNewPassSubmit)}
                rhfForm={newPasswordForm}
                loading={loading}
                stepSetter={setFormStep}
              />
            )}
          </Stack>
        </DashedContainer>
      </Stack>
    </PageContainer>
  );
}
