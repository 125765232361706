import React, { useState } from 'react';
import {
  Box,
  Button,
  ButtonProps,
  Spacer,
  Stack,
  Text,
  VStack,
  Wrap,
  WrapItem,
  useMediaQuery,
} from '@chakra-ui/react';
import { EventRequest, EventResult } from 'src/api/interfaces/event';
import { FormAccordion } from '../../Accordion';
import { FormAccordionContent } from '../../Accordion/AccordionContent';
import ScheduleBlock from '../ScheduleBlock';
import { formatCurrency } from 'src/utils/format';

interface ScheduleBlocksGroupProps {
  eventResult: EventResult[]
  handleContinue: (startTime: string) => void
  submitLoading: boolean
  duration: string
  reschedule?: boolean
}

export const ScheduleBlocksGroup = ({
  eventResult,
  submitLoading,
  handleContinue,
  duration,
  reschedule = false
}: ScheduleBlocksGroupProps & ButtonProps) => {
  const [selectedBlockIndex, setSelectedBlockIndex] = useState<number>(-1)
  const [largetThan768] = useMediaQuery('(min-width: 768px)')

  const handleSelect = async (index: number) => {
    if (index === selectedBlockIndex) {
      setSelectedBlockIndex(-1);
    } else {
      setSelectedBlockIndex(index);
    }
  };

  return (
    <>
      {eventResult && eventResult.length > 0 ? <>
        <Text variant="title">SELECIONE O HORÁRIO</Text>
        <Text variant='text'>Os valores são fixos, independente da quantidade de participantes</Text>

        <FormAccordion>
          <FormAccordionContent title='Horários disponíveis'>

            <Wrap spacingX={6}
              spacingY={4}
              align="center"
              justify={{ base: 'center', md: 'unset' }}
              mb={12}
            >
              {eventResult.map((e, i) => {
                const isSelected = i === selectedBlockIndex
                return (
                  <WrapItem key={i}>
                    <ScheduleBlock
                      handleSelect={() => handleSelect(i)}
                      index={i}
                      isSelected={isSelected}
                      time={e.placeHolder}
                    />
                  </WrapItem>
                )
              })}
            </Wrap>


            <Stack
              w={'full'}
              direction={{ base: 'column', md: 'row' }}
              justifyContent={'space-between'}
            >
              {!reschedule && <VStack alignItems={'flex-start'}>
                <Text color="black">{`DURAÇÃO: ${selectedBlockIndex != -1 ? `${duration} Horas` : ''}`}</Text>
                <Text color="black">{`SUBTOTAL: ${selectedBlockIndex != -1 ? formatCurrency(eventResult[selectedBlockIndex].total) : ''}`}</Text>
              </VStack>}
              {reschedule && <Spacer />}
              <Button
                paddingInline={largetThan768 ? '120px' : 0}
                variant="pophausOutline"
                colorScheme="popGreenAlt"
                isLoading={submitLoading}
                onClick={() => handleContinue(eventResult[selectedBlockIndex].time)}
                isDisabled={selectedBlockIndex === -1}
              >
                continuar
              </Button>
            </Stack>

          </FormAccordionContent>
        </FormAccordion>



      </> : <Text variant='text' color='red'>Nenhum horário disponível para esses parâmetros</Text>}
    </>
  );
};
