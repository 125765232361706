import React, { useState } from "react"
import { Box, Button, HStack, Select, Stack, Text } from "@chakra-ui/react"

import EmptyModal from "src/components/Modal/ModalVariants/EmptyModal"
import ticketsOptions from './ticketsOptions.json'
import ticketsOptionsHalf from './ticketsOptionsHalf.json'

export interface TicketsModalProps {
  isOpen: boolean
  onClose: () => void
  onConfirm: (tickets: number) => Promise<void>
  fullDuration: boolean
  defaultTickets: number
}

export default function TicketsModal({ isOpen, onClose, onConfirm, fullDuration, defaultTickets }: TicketsModalProps) {
  const [tickets, setTickets] = useState<number>(1)

  return (
    <EmptyModal isOpen={isOpen} onClose={onClose}>
      <Stack w='fit-content' gap={6}>
        <Stack>
          <Text variant='title'>Comprar mais ingressos</Text>
          <Text variant='subtitle'>Para sua duração de reserva, o máximo de ingressos é {fullDuration ? '12' : '8'}.</Text>
          <Text variant='text'>Você já comprou {defaultTickets} ingressos.</Text>
        </Stack>
        <Stack justifyContent='space-between' direction='row'>
          <Text variant='subtitle'>Quantos ingressos?</Text>
          <Select maxW={20} variant='filled' onChange={(e: any) => setTickets(Number(e.target.value))}>
            {fullDuration && ticketsOptions.map((o: any) => { return (<option disabled={o.value > 12 - defaultTickets} key={o.value} value={o.value}>{o.label}</option>) })}
            {!fullDuration && ticketsOptionsHalf.map((o: any) => { return (<option disabled={o.value > 8 - defaultTickets} key={o.value} value={o.value}>{o.label}</option>) })}
          </Select>
        </Stack>

        <Stack gap={3}>

          <Button
            w='full'
            variant='pophausOutline'
            onClick={() => onConfirm(tickets)}>
            Comprar
          </Button>

          <Button
            w='full'
            variant="pophausOutline"
            colorScheme={'popTransparent'}
            onClick={onClose}>

            Cancelar
          </Button>


        </Stack>


      </Stack>
    </EmptyModal>
  )
}