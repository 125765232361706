import React, { useState } from 'react';

import {
  Box,
  Button,
  HStack,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spacer,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { CalendarIcon, TimeIcon } from '@chakra-ui/icons';
import { FiMapPin, FiUser, FiUsers, FiXCircle, FiInfo } from 'react-icons/fi';
import { BsAlarm, BsBox, BsXSquare } from 'react-icons/bs';
import { FaTicketAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import { BookingResult } from 'src/api/interfaces/booking';
import { EditPlayerModal } from 'src/components/Modal/ModalVariants/EditPlayersModal';
import { BookingStatusEnum, cancelBooking } from 'src/api/services/booking';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { PophausModal } from 'src/components/Modal';

export const useActiveBookingsColumns = (mutate?: any) => {
  const columnHelper = createColumnHelper<BookingResult>();

  const columns = [
    columnHelper.accessor('startDate', {
      cell: (info) => (
        <HStack>
          <CalendarIcon />
          <Text variant="text">
            {dayjs(info.getValue()).format('DD/MM/YYYY')}
          </Text>
        </HStack>
      ),
      header: () => <span style={{ color: 'white' }}>Data</span>,
    }),
    columnHelper.accessor('bookingRooms', {
      cell: (info) => {
        const rooms = info.getValue();
        const room = rooms?.[rooms.length - 1];
        return (
          <HStack>
            <TimeIcon />

            <Text variant="text">{`${dayjs(room?.startTime).format(
              'HH:mm'
            )} às ${dayjs(room?.endTime).format('HH:mm')}`}</Text>
          </HStack>
        );
      },
      header: () => <span>Horário</span>,
    }),
    columnHelper.accessor((r) => r.user?.name, {
      id: 'user.name',
      cell: (info) => (
        <HStack>
          <FiUser />

          <Text variant="text">{info.getValue()}</Text>
        </HStack>
      ),
      header: () => <span>Username</span>,
    }),
    columnHelper.accessor('bookingRooms', {
      cell: (info) => {
        const rooms = info.getValue();
        const room = rooms?.[rooms.length - 1];

        return (
          <HStack>
            <BsBox />
            <Text variant="text">{room?.room?.description}</Text>
          </HStack>
        );
      },
      header: () => <span>Salas</span>,
    }),
    columnHelper.accessor('bookingRooms', {
      cell: (info) => {
        const rooms = info.getValue();
        const room = rooms?.[0];

        return (
          <HStack>
            <FiMapPin />
            <Text variant="text">
              {rooms.length > 0 ? rooms[0].room?.businessUnit.description : '-'}
            </Text>
          </HStack>
        );
      },
      header: () => <span>Unidade</span>,
    }),
    columnHelper.accessor('tickets', {
      cell: (info) => {
        return (
          <HStack>
            <FaTicketAlt />
            <Text variant="text">{info.getValue()}</Text>
          </HStack>
        );
      },
      header: () => <span>Tickets</span>,
    }),
    columnHelper.accessor('bookingStatus', {
      cell: (info) => (
        <Stack direction="row" alignItems="center">
          <Text variant="text">{`${info.getValue()?.description}`}</Text>
        </Stack>
      ),
      header: () => <span>status da reserva</span>,
    }),
    columnHelper.display({
      id: 'actions',
      cell: (info) => {
        const navigate = useNavigate();
        const [loading, setLoading] = useState(false);
        const booking = info.row.original;

        const bookingID = booking.bookingID;
        const status = booking.bookingStatusID;
        const bookingUserID = booking.userID;
        const loggedUserID = useSelector(
          (state: RootState) => state.user.user?.userID
        );

        const {
          isOpen: isPlayersOpen,
          onClose: onPlayersClose,
          onOpen: onPlayersOpen,
        } = useDisclosure();

        const {
          isOpen: isReceiptOpen,
          onClose: onReceiptClose,
          onOpen: onReceiptOpen,
        } = useDisclosure();

        const {
          onClose: onRescheduleClose,
          onOpen: onRescheduleOpen,
          isOpen: isRescheduleOpen,
        } = useDisclosure();

        const {
          onClose: onCancelClose,
          onOpen: onCancelOpen,
          isOpen: isCancelOpen,
        } = useDisclosure();

        const handleCancelBooking = async () => {
          try {
            setLoading(true);
            const req = await cancelBooking(bookingID);
            mutate();
          } catch (e) {
            console.error(e);
          } finally {
            onCancelClose();
            setLoading(false);
          }
        };

        const handleRescheduleBooking = () => {
          if (![1, 5].includes(booking.bookingTypeID)) {
            return navigate(`/eventos/reagendar/${bookingID}`);
          }
          return navigate(`/jogador/reagendar/${bookingID}`);
        };

        if (bookingUserID === loggedUserID) {
          return (
            <HStack spacing={4} pl={6}>
              <Spacer borderRight="2px dashed #B0CA0A" />
              <Box
                justifyContent="center"
                as="button"
                _hover={{ color: '#B0CA0A' }}
                onClick={onPlayersOpen}
              >
                <Icon as={FiUsers} w="20px" h="20px" />
                <Text variant="text">Jogadores</Text>
              </Box>

              {/* {status === BookingStatusEnum.CONFIRMADA && (
                <Box
                  justifyContent="center"
                  as="button"
                  _hover={{ color: '#B0CA0A' }}
                  onClick={onReceiptOpen}
                >
                  <Icon as={FiFileText} w="20px" h="20px" />
                  <Text variant="text">Recibo</Text>
                </Box>
              )} */}

              <Box
                justifyContent="center"
                as="button"
                _hover={{ color: '#B0CA0A' }}
                onClick={onRescheduleOpen}
              >
                <Icon as={TimeIcon} w="20px" h="20px" />
                <Text variant="text">Reagendar</Text>
              </Box>

              <Box
                justifyContent="center"
                as="button"
                _hover={{ color: '#B0CA0A' }}
                onClick={onCancelOpen}
              >
                <Icon as={FiXCircle} w="20px" h="20px" />
                <Text variant="text">Cancelar</Text>
              </Box>

              <EditPlayerModal
                bookingID={bookingID}
                isOpen={isPlayersOpen}
                onClose={onPlayersClose}
              />
              {/* <ReceiptModal
                bookingID={bookingID}
                isOpen={isReceiptOpen}
                onClose={onReceiptClose}
              /> */}
              <PophausModal
                icon={BsAlarm}
                isOpen={isRescheduleOpen}
                onClose={onRescheduleClose}
                content="Deseja reagendar?"
                handleMainClick={handleRescheduleBooking}
                secondaryButtonText="voltar"
                handleSecondaryClick={onRescheduleClose}
              />
              <PophausModal
                icon={BsXSquare}
                isOpen={isCancelOpen}
                onClose={onCancelClose}
                content="Deseja cancelar?"
                handleMainClick={handleCancelBooking}
                secondaryButtonText="voltar"
                handleSecondaryClick={onCancelClose}
                loading={loading}
              />
            </HStack>
          );
        } else {
          return (
            <HStack spacing={4} pl={6}>
              <Spacer borderRight="2px dashed #B0CA0A" />
              <Popover placement="top-start">
                <PopoverTrigger>
                  <Box
                    justifyContent="center"
                    as="button"
                    _hover={{ color: '#B0CA0A' }}
                  >
                    <Icon as={FiInfo} w="20px" h="20px" />
                    <Text variant="text">Info</Text>
                  </Box>
                </PopoverTrigger>
                <PopoverContent bg="black" color="#B0CA0A">
                  <PopoverArrow bg="#B0CA0A" />
                  <PopoverCloseButton />
                  <PopoverHeader fontWeight={'500'}>
                    Reserva Adicionada!
                  </PopoverHeader>
                  <PopoverBody>
                    Você foi adicionado como jogador dessa reserva
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </HStack>
          );
        }
      },
    }),
  ];

  return columns;
};
