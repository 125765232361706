import React, { useEffect, useState, useRef } from 'react';
import ReactGA from 'react-ga';
import TikTokPixel from 'tiktok-pixel';
import PageContainer from 'src/components/Container/PageContainer';
import {
  HStack,
  Text,
  VStack,
  Stack,
  Flex,
  Button,
  Center,
  Box,
} from '@chakra-ui/react';
import ReCAPTCHA from 'react-google-recaptcha';
import DashedContainer from 'src/components/Container/DashedContainer';
import SelectTicketForm, {
  SelectTicketFormValues,
} from 'src/modules/Forms/SelectTicketForm';
import { useForm } from 'react-hook-form';
import { RoomImage } from 'src/components/RoomImage';
import { Summary } from 'src/components/Summary';
import { useNavigate, useParams } from 'react-router-dom';
import {
  BookingStatusEnum,
  cancelBooking,
  updateBookingStatus,
  useGetBookingByID,
  validateRecaptcha,
} from 'src/api/services/booking';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { BookingActions } from 'src/redux/booking';
import usePHToast from 'src/hooks/useToast';
import { Loading } from 'src/components/Loading';
import * as fbq from '../../../utils/pixels/fpixel';

interface ReCaptchaProps {
  onChange: (value: string | null) => void;
}

// const ReCaptchaComponent: React.FC<ReCaptchaProps> = ({ onChange }) => {
//   const captchaRef = useRef(null);

//   const handleChange = (value: string | null) => {
//     onChange(value);
//   };

//   return (
//     <ReCAPTCHA
//       ref={captchaRef}
//       sitekey="6Lf57-wmAAAAAPjoK2U3-aXsftWMqKQlQH4oCxpi"
//       onChange={handleChange}
//     />
//   );
// };

export interface TicketSummaryProps {
  isRebuy?: boolean;
}

export default function TicketSummary({ isRebuy = false }: TicketSummaryProps) {
  const { bookingID } = useParams<{ bookingID: string }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const toast = usePHToast();
  const form = useForm<SelectTicketFormValues>();
  const [loading, setLoading] = useState(false);

  const captchaRef = useRef<any>(null);

  const { data, isLoading, mutate } = useGetBookingByID(bookingID);

  const [loadingCancelBooking, setLoadingCancelBooking] =
    useState<boolean>(false);

  useEffect(() => {
    if (data?.bookingStatusID === 3)
      navigate(`/ingressos/pagamento/${bookingID}`);
    const buId = data?.bookingRooms?.[0]?.room?.businessUnitID ?? 0;
    if (data)
      form.reset({
        date: dayjs(data.startDate).format('YYYY-MM-DD'),
        businessUnitID: buId.toString(),
        numberOfPlayers: data.tickets.toString(),
      });
  }, [data]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    TikTokPixel.pageView();
    // dispatch(BookingActions.resetBooking());
  }, []);

  const handleContinue = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    if (bookingID) {
      try {
        const result = await updateBookingStatus(
          bookingID,
          BookingStatusEnum.REALIZANDO_PAGAMENTO
        );
        if (result) {
          fbq.event('initiateCheckout');
          window.gtag('event', 'begin_checkout');
          TikTokPixel.track('InitiateCheckout', {});
          navigate(`/ingressos/pagamento/${result.bookingID}`);
        } else {
          throw new Error('Problema ao atualizar o status da reserva');
        }
      } catch (e: any) {
        toast({ status: 'error', title: e?.message });
      }

      // const reCaptchaValue = captchaRef?.current?.getValue();
      // if (reCaptchaValue) {
      //   const resultValidate = await validateRecaptcha(
      //     { captcha: reCaptchaValue }
      //   );

      //   if (resultValidate) {
      //     const result = await updateBookingStatus(
      //       bookingID,
      //       BookingStatusEnum.REALIZANDO_PAGAMENTO
      //     );
      //     captchaRef.current.reset();
      //     fbq.event('initiateCheckout')
      //     window.gtag('event', 'begin_checkout')
      //     TikTokPixel.track('InitiateCheckout', {})
      //     navigate(`/ingressos/pagamento/${result.bookingID}`);
      //   } else {
      //     alert('Por favor, verifique o reCAPTCHA.');
      //   }

      // } else {
      //   alert('Por favor, verifique o reCAPTCHA.');
      // }
      setLoading(false);
      return;
    }
    setLoading(false);
    throw new Error(
      'Não foi possível identificar sua reserva, tente novamente.'
    );
  };

  const handleCancel = async () => {
    setLoadingCancelBooking(true);
    try {
      if (bookingID) {
        await cancelBooking(bookingID);
        toast({
          status: 'success',
          description: 'Reserva cancelada com sucesso!',
        });
        navigate(`/`);
        return;
      }
      toast({
        status: 'error',
        description:
          'Não foi possível cancelar a reserva. Tente novamente mais tarde.',
      });
    } catch (e: any) {
      toast({
        status: 'error',
        description:
          'Não foi possível cancelar a reserva. Tente novamente mais tarde.',
      });
    } finally {
      setLoadingCancelBooking(false);
    }
  };

  return (
    <PageContainer restricted>
      <VStack w="full" spacing={12} px={4}>
        <Text color="green" fontSize="xl">
          RESUMO DA RESERVA
        </Text>
        <DashedContainer justify="center" py={12} px={12}>
          <VStack w="full" spacing={12} align="flex-start">
            <SelectTicketForm form={form} showSubmit={false} readonly />

            <Stack direction={['column', 'row']} w="full" h="full" spacing={2}>
              {isLoading || !data ? (
                <Center w="full">
                  <Loading />
                </Center>
              ) : (
                <>
                  {data?.bookingRooms?.map((br) => (
                    <RoomImage
                      key={br.bookingRoomID}
                      title={br?.room?.description}
                    />
                  ))}
                  <Summary
                    hasCoupon
                    w="full"
                    data={data}
                    mutate={mutate}
                    isRebuy={isRebuy}
                  />
                </>
              )}
            </Stack>

            <Stack
              w="full"
              h={'full'}
              direction={['column', 'row']}
              spacing={2}
              justify="center"
            >
              <Button
                variant="pophausOutline"
                colorScheme="popTransparent"
                onClick={handleCancel}
                isLoading={loadingCancelBooking}
                isDisabled={isLoading || loading || loadingCancelBooking}
              >
                CANCELAR RESERVA
              </Button>
              <Button
                variant="pophausOutline"
                onClick={handleContinue}
                isLoading={loading}
                isDisabled={isLoading || loading || loadingCancelBooking}
              >
                CONTINUAR
              </Button>
            </Stack>
            <Box display="flex" justifyContent="center" width="100%">
              {/* <ReCAPTCHA
                ref={captchaRef}
                sitekey="6Lf57-wmAAAAAPjoK2U3-aXsftWMqKQlQH4oCxpi"
              // onChange={setReCaptchaValue}
              /> */}
            </Box>
          </VStack>
        </DashedContainer>
      </VStack>
    </PageContainer>
  );
}
