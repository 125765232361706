import React from 'react';
import { Box, Button, Text } from '@chakra-ui/react';
import { GetBookingTypesByConfigResult } from 'src/api/interfaces/booking';

interface BookingTypeItemProps {
  bookingType: GetBookingTypesByConfigResult;
  onClick?: (bookingType: GetBookingTypesByConfigResult) => void;
  key: React.Key;
  selectedBookingTypeID?: number;
}

const BookingTypeItem = ({
  bookingType,
  onClick,
  key,
}: BookingTypeItemProps) => {
  const handleClick = () => {
    if (onClick) return onClick(bookingType);
  };

  return (
    <Box
      key={key}
      py={'2.5rem'}
      px={[4, '3.5rem']}
      display={'flex'}
      flexDirection={'column'}
      gap={'0.6rem'}
      bg={'white'}
      mt={'1rem'}
    >
      <Text
        textTransform={'uppercase'}
        fontWeight={'bold'}
        fontSize={'20px'}
        variant={bookingType.id === 5 ? 'secTitle' : 'title'}
      >
        {bookingType.name}
      </Text>
      <Text fontSize={'13px'} mb={'auto'}>
        {bookingType.description}
      </Text>
      <Box my={'0.9rem'}>
        <Text fontSize={'13px'}>A partir de</Text>
        <Text fontSize={'36px'} fontWeight={'bold'}>
          {bookingType.price.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })}
        </Text>
        <Text fontSize={'13px'}>por pessoa/hora</Text>
      </Box>
      <Button
        variant={'pophausOutline'}
        w={'full'}
        colorScheme={bookingType.id === 5 ? 'popPetGreen' : undefined}
        onClick={handleClick}
      >{`Escolher ${bookingType.name}`}</Button>
    </Box>
  );
};

export default BookingTypeItem;
