import React, { useEffect, useState } from 'react';
import { CalendarIcon, TimeIcon } from '@chakra-ui/icons';
import { Checkbox, Stack, Text } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { FaTicketAlt } from 'react-icons/fa';
import { FiUser } from 'react-icons/fi';
import { BookingResult } from 'src/api/interfaces/booking';
import dayjs from 'dayjs';

interface CalendarData {
  selectedRows: string[];
  setSelectedRows: React.Dispatch<React.SetStateAction<string[]>>;
  selectAll: boolean;
}

export const useAdminCalendarColumns = ({
  selectedRows,
  setSelectedRows,
  selectAll,
}: CalendarData) => {
  const columnHelper = createColumnHelper<BookingResult>();

  const columns: any = [
    columnHelper.accessor('bookingID', {
      id: 'bookingID',
      cell: (info) => {
        const isSelected = selectedRows.includes(info.row.original.bookingID);

        const handleCheckboxChange = () => {
          setSelectedRows((prevSelectedRows) => {
            const isSelected = prevSelectedRows.includes(
              info.row.original.bookingID
            );

            if (selectAll) {
              // Se a checkbox "Selecionar todas" está marcada,
              // remove o bookingID se já estiver selecionado ou o adiciona caso contrário
              if (isSelected) {
                return prevSelectedRows.filter(
                  (id) => id !== info.row.original.bookingID
                );
              } else {
                return [...prevSelectedRows, info.row.original.bookingID];
              }
            } else {
              // Caso contrário, atualiza o estado normalmente
              return isSelected
                ? prevSelectedRows.filter(
                    (id) => id !== info.row.original.bookingID
                  )
                : [...prevSelectedRows, info.row.original.bookingID];
            }
          });
        };

        return (
          <Checkbox onChange={handleCheckboxChange} isChecked={isSelected} />
        );
      },
    }),
    columnHelper.accessor('startDate', {
      cell: (info) => (
        <Stack direction="row" alignItems="center">
          <CalendarIcon />

          <Text variant="text">
            {dayjs(info.getValue()).format('DD/MM/YYYY')}
          </Text>
        </Stack>
      ),
      header: () => <span>Data inicial</span>,
    }),

    columnHelper.accessor('bookingRooms', {
      cell: (info) => {
        const rooms = info.getValue();
        const room = rooms?.[0];
        return (
          <Stack direction="row" alignItems="center">
            <TimeIcon />

            <Text variant="text">{`${dayjs(room?.startTime).format(
              'HH:mm'
            )} às ${dayjs(room?.endTime).format('HH:mm')}`}</Text>
          </Stack>
        );
      },
      header: () => <span>Horário</span>,
    }),
    columnHelper.accessor((r) => r.user?.name, {
      id: 'user.name',
      cell: (info) => (
        <Stack direction="row" alignItems="center">
          <FiUser />

          <Text variant="text">{info.getValue()}</Text>
        </Stack>
      ),
      header: () => <span>Username</span>,
    }),

    columnHelper.accessor('tickets', {
      cell: (info) => (
        <Stack direction="row" alignItems="center">
          <FaTicketAlt />
          <Text variant="text">{`${info.getValue()}`}</Text>
        </Stack>
      ),
      header: () => <span>Ingressos</span>,
    }),

    columnHelper.accessor('bookingRooms', {
      cell: (info) => {
        const rooms = info.getValue();
        const room = rooms?.[0];

        return (
          <Stack direction="row" alignItems="center">
            <Text variant="text"></Text>
          </Stack>
        );
      },
      header: () => <span>Horário</span>,
    }),
  ];

  return columns;
};
