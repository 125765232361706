import React, { useEffect, useState, useRef } from 'react';
import TikTokPixel from 'tiktok-pixel';
import PageContainer from 'src/components/Container/PageContainer';
import {
  HStack,
  Text,
  VStack,
  Stack,
  Button,
  Center,
  Box,
  Link,
} from '@chakra-ui/react';

import DashedContainer from 'src/components/Container/DashedContainer';
import { RoomImage } from 'src/components/RoomImage';
import { useNavigate, useParams } from 'react-router-dom';
import {
  BookingStatusEnum,
  updateBookingStatus,
  useGetBookingByID,
} from 'src/api/services/booking';
import dayjs from 'dayjs';
import usePHToast from 'src/hooks/useToast';
import { Loading } from 'src/components/Loading';
import { removeCoupon, validateCoupon } from 'src/api/services/coupon';
import EventTypeInput from './EventTypeInput';
import EventCouponHandler from './EventCouponHandler';
import { formatCurrency } from 'src/utils/format';
import * as fbq from '../../../../utils/pixels/fpixel';
import { putUpdateEventType } from 'src/api/services/event';
import { useForm } from 'react-hook-form';

export interface CouponFormValues {
  coupon: string;
}

export default function EventsSummary() {
  const navigate = useNavigate();
  const couponForm = useForm<CouponFormValues>();
  const { handleSubmit } = couponForm;
  const [eventType, setEventType] = useState<string>('');
  const [eventTypesLoading, setEventTypesLoading] = useState<boolean>(true);
  const toast = usePHToast();
  const [loading, setLoading] = useState(false);
  const { bookingID } = useParams();
  // get
  const { data, isLoading, mutate } = useGetBookingByID(bookingID);

  const recommendationsLink =
    'https://arcadehausstorage.blob.core.windows.net/documentos-site/Regras_e_Recomendacoes_-_Geral_-_Arcade_Haus.pdf';
  const refoundLink =
    'https://drive.google.com/file/d/1ugyaPogRkYSVrybz-CcDklyZfmndynI2/view';

  const handleCouponApply = async (coupon: CouponFormValues) => {
    try {
      setLoading(true);
      if (!data) throw new Error('Erro ao identificar reserva');

      if (data.coupon != null) await removeCoupon(data.bookingID);
      if (!coupon) throw new Error('Favor digitar cupom');
      const res = await validateCoupon(
        coupon.coupon,
        data.bookingID,
        data.userID
      );
      await mutate();

      if (!res) throw new Error('Erro ao aplicar cumpom');
      if (!res.available) throw new Error('Cupom indisponível');

      toast({ status: 'success', title: 'Cupom aplicado' });
    } catch (err: any) {
      toast({ status: 'error', title: err.message });
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveCoupon = async () => {
    try {
      setLoading(true);
      if (!data)
        throw new Error(
          'Não foi possível identificar sua reserva, tente novamente.'
        );

      const res = await removeCoupon(data.bookingID);
      await mutate();
      if (!res) throw new Error('Erro ao remover cupom');
      toast({ status: 'success', title: 'Cupom removido' });
    } catch (err: any) {
      toast({ status: 'error', title: err.message });
    } finally {
      setLoading(false);
    }
  };

  const handleContinue = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      setLoading(true);
      if (!bookingID)
        throw new Error(
          'Não foi possível identificar sua reserva, tente novamente.'
        );

      // Definir tipo de evento
      const eventSetTypeResult = await putUpdateEventType(bookingID, eventType);
      // erro =>
      if (!eventSetTypeResult)
        throw new Error('Problema ao definir tipo de evento');
      // sucesso => Alterar status para REALIZANDO_PAGAMENTO
      const result = await updateBookingStatus(
        bookingID,
        BookingStatusEnum.REALIZANDO_PAGAMENTO
      );
      // erro =>
      if (!result) throw new Error('Problema ao atualizar o status da reserva');
      // sucesso => Continuar para pagamento
      fbq.event('initiateCheckout');
      window.gtag('event', 'begin_checkout');
      TikTokPixel.track('InitiateCheckout', {});
      navigate(`/ingressos/pagamento/${result.bookingID}`);
    } catch (e: any) {
      toast({ status: 'error', title: e?.message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageContainer restricted>
      <VStack w="full" spacing={12}>
        <Text color="green" fontSize="xl">
          RESUMO DA RESERVA
        </Text>
        <DashedContainer justify="center" py={12} px={12}>
          <VStack w="full" spacing={12} align="flex-start">
            <Stack direction={['column', 'row']} w="full" h="full" spacing={2}>
              {loading ? (
                <Center w="full">
                  <Loading />
                </Center>
              ) : (
                <>
                  <RoomImage
                    flex={1 / 2}
                    title="O arcade inteiro pra você"
                    multipleRooms
                  />

                  <DashedContainer flex={1 / 2} alignItems="normal">
                    {data ? (
                      <>
                        <SummaryText
                          title="horário"
                          value={`${dayjs(
                            data.bookingRooms[0].startTime
                          ).format('HH:mm')} ÀS ${dayjs(
                            data.bookingRooms[0].endTime
                          ).format('HH:mm')}`}
                        />
                        <SummaryText
                          title="data"
                          value={`${dayjs(data.startDate).format(
                            'DD/MM/YYYY'
                          )}`}
                        />
                        <SummaryText
                          title="duração"
                          value={`${data.durationFormatted.slice(1, 2)} Horas`}
                        />
                        <SummaryText
                          title="participantes"
                          value={`${data.tickets}`}
                        />
                        <SummaryText
                          title="subtotal"
                          value={formatCurrency(data.total)}
                        />

                        <Box my={3} border="1px solid green" />

                        <Stack direction="column">
                          <EventTypeInput
                            value={eventType}
                            setter={setEventType}
                            setLoading={setEventTypesLoading}
                          />
                          <EventCouponHandler
                            data={data}
                            handleCouponSubmit={handleSubmit(handleCouponApply)}
                            rhfForm={couponForm}
                            loading={loading}
                            handleRemoveCoupon={handleRemoveCoupon}
                          />
                        </Stack>
                      </>
                    ) : (
                      <Loading />
                    )}
                    <Text mt={6} color="grey" fontSize={14} fontStyle="italic">
                      <Text textDecoration="underline" display="inline">
                        Atenção:
                      </Text>
                      {` `}Por ora, todos nossos jogos são colaborativos e jogam
                      {` `}
                      <Text color="green" display="inline">
                        4 jogadores por partida!
                      </Text>
                    </Text>
                    <Text color="grey" fontSize={14} fontStyle="italic">
                      Clique aqui para consultar:{` `}
                      <Link
                        target="_blank"
                        href={recommendationsLink}
                        color="green"
                      >
                        Regras e Recomendações
                      </Link>
                      {` e `}
                      <Link target="_blank" href={refoundLink} color="green">
                        Políticas de Reagendamento e Reembolso
                      </Link>
                    </Text>
                  </DashedContainer>
                </>
              )}
            </Stack>
            <Stack
              w="full"
              h={'full'}
              direction={['column', 'row']}
              spacing={2}
              justify="center"
            >
              <Button
                variant="pophausOutline"
                onClick={handleContinue}
                isLoading={loading || eventTypesLoading}
              >
                CONTINUAR
              </Button>
            </Stack>
          </VStack>
        </DashedContainer>
      </VStack>
    </PageContainer>
  );
}

const SummaryText = ({ title, value }: { title: string; value: string }) => {
  return (
    <HStack>
      <Text color="green" textTransform="uppercase">
        {title}:
      </Text>
      <Text color="white">{value}</Text>
    </HStack>
  );
};
