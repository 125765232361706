import React, { useEffect } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';
import { FormInput } from 'src/components/Form/Input';
import { FormSelect } from 'src/components/Form/Select';
import { FormInputPassword } from 'src/components/Form/Input/PasswordInput';
import { IoLocationOutline } from 'react-icons/io5';
import { Box, Button, Stack, useMediaQuery } from '@chakra-ui/react';
import Form from 'src/components/Form/Form';
import { useGetBusinessUnits } from 'src/api/services/businessUnit';
import { CopyIcon } from '@chakra-ui/icons';
import { useDispatch } from 'react-redux';
import { calendarResultActions } from 'src/redux/calendarData';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';

export interface AdminAgendaFilterFormValues {
  date: string;
  businessUnitID: string;
  // numberOfRooms: number;
  tickets: number;
}

interface AdminAgendaFilterFormProps {
  form: UseFormReturn<AdminAgendaFilterFormValues, any>;
  overrideSubmit?: (data: AdminAgendaFilterFormValues) => Promise<void>;
}

export default function AdminAgendaFilterForm({
  form,
  overrideSubmit,
}: AdminAgendaFilterFormProps) {
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors },
  } = form;

  const dispatch = useDispatch()
  const { search } = useSelector((state: RootState) => state.calendarResult)
  const [largerThan1000] = useMediaQuery('(max-width: 1000px)')

  const { data: businessUnits } = useGetBusinessUnits();

  useEffect(() => {
    if (search) {
      setValue('date', search.date)
      setValue('businessUnitID', search.businessUnitID)
      setValue('tickets', search.tickets)
    }
  }, [])

  const resetForm = () => {
    dispatch(calendarResultActions.resetResult())
    dispatch(calendarResultActions.resetSearchParams())
    reset()
  }

  const onSubmit = (data: AdminAgendaFilterFormValues) => {
    if (overrideSubmit) {
      overrideSubmit(data);
      return;
    }
  };


  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction={largerThan1000 ? 'column' : 'row'} w={'full'} spacing={6}>
        <Box>
          <FormInput
            rhfRegister={{
              ...register('date'),
            }}
            errorState={errors.date}
            inputProps={{ placeholder: 'DATA', type: 'date' }}
          />
        </Box>
        <FormSelect
          rhfRegister={{
            ...register('businessUnitID', {
              required: 'Campo obrigatório',
            }),
          }}
          errorState={errors.businessUnitID}
          selectProps={{ placeholder: 'SELECIONE A UNIDADE' }}
          icon={CopyIcon}
        >
          {businessUnits?.map((b) => (
            <option key={b.businessUnitID} value={b.businessUnitID}>
              {b.description}
            </option>
          ))}
        </FormSelect>
        {/* <FormSelect
          rhfRegister={{
            ...register('numberOfRooms'),
          }}
          errorState={errors.numberOfRooms}
          selectProps={{ placeholder: 'QTS. DE SALAS', w: '100%' }}
        >
          <option value={4}>4</option>
          <option value={5}>5</option>
          <option value={6}>6</option>
        </FormSelect> */}
        <FormSelect
          rhfRegister={{
            ...register('tickets', {
              required: 'Campo obrigatório',
            }),
          }}
          errorState={errors.tickets}
          selectProps={{ placeholder: 'INGRESSOS', w: '100%' }}
          icon={CopyIcon}
        >
          {Array.from({ length: 9 }).map((_, i) => (
            <option key={i} value={i + 4}>
              {i + 4}
            </option>
          ))}
        </FormSelect>
        <Button w='full' paddingInline={0} type="submit" variant="pophausOutline">
          OK
        </Button>
        <Button
          w='full' paddingInline={0}
          variant="pophausOutline"
          colorScheme="popTransparent"
          onClick={resetForm}
        >
          limpar
        </Button>
      </Stack>
    </Form>
  );
}
