import React from 'react';
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from '@chakra-ui/react';

interface FormAccordionProps {
  children: React.ReactNode;
  bg?: string;
  title: string;
}

export const FormAccordionContent = ({
  children,
  title,
  bg,
  ...props
}: FormAccordionProps) => {
  return (
    <AccordionItem bg={'white'} mb={4}>
      <AccordionButton border="1px" borderColor={'green'}>
        <Box
          as="span"
          flex="1"
          textAlign="left"
          color={'black'}
          textTransform={'uppercase'}
        >
          {title}
        </Box>
        <AccordionIcon color={'lightGreen'} />
      </AccordionButton>
      <AccordionPanel p={0} bg={bg || 'white'} {...props}>
        {children}
      </AccordionPanel>
    </AccordionItem>
  );
};
