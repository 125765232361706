import React from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  VStack,
  Icon,
  Text,
  Container,
} from '@chakra-ui/react';
import DashedContainer from '../Container/DashedContainer';
import { IconType } from 'react-icons/lib';

interface PophausModalProps {
  isOpen: boolean;
  onClose: () => void;
  icon: IconType;
  iconColor?: string;
  colorScheme?: string;
  content?: string;
  overrideContent?: React.ReactNode;
  handleMainClick: () => void;
  mainButtonText?: string;
  handleSecondaryClick?: () => void;
  secondaryButtonText?: string;
  loading?: boolean;
}

export function PophausModal({
  isOpen,
  onClose,
  icon,
  iconColor = 'green',
  colorScheme = 'popGreen',
  content,
  overrideContent,
  handleMainClick,
  mainButtonText = 'CONTINUAR',
  handleSecondaryClick,
  secondaryButtonText,
  loading = false,
}: PophausModalProps) {
  return (
    <>
      <Modal onClose={onClose} isOpen={isOpen} isCentered size="xl">
        <ModalOverlay />
        <ModalContent>
          <DashedContainer borderColor={iconColor}>
            <ModalCloseButton
              size="sm"
              position="unset"
              alignSelf={'flex-end'}
              color={iconColor}
              border={'2px'}
              borderRadius={0}
              borderColor={iconColor}
            />
            <ModalBody>
              <VStack spacing={4} w="full">
                <Icon as={icon} w={24} h={24} color={iconColor} />
                {overrideContent ? (
                  overrideContent
                ) : (
                  <Container maxW="30ch">
                    <Text
                      variant="title"
                      color="white"
                      textAlign={'center'}
                      whiteSpace={'pre-line'}
                    >
                      {content}
                    </Text>
                  </Container>
                )}
              </VStack>
            </ModalBody>
            <ModalFooter>
              <VStack spacing={4} w="full">
                <Button
                  w="full"
                  variant="pophausOutline"
                  colorScheme={colorScheme}
                  onClick={handleMainClick}
                  isLoading={loading}
                >
                  {mainButtonText}
                </Button>
                {handleSecondaryClick && (
                  <Button
                    w="full"
                    variant="pophausOutline"
                    colorScheme={'popTransparent'}
                    onClick={handleSecondaryClick}
                    isLoading={loading}
                  >
                    {secondaryButtonText}
                  </Button>
                )}
              </VStack>
            </ModalFooter>
          </DashedContainer>
        </ModalContent>
      </Modal>
    </>
  );
}
