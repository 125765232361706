import React, { useEffect, useState, useTransition } from 'react';
import {
  Box,
  Text,
  Stack,
  HStack,
  Spacer,
  StackDivider,
} from '@chakra-ui/react';
import { NotificationResult } from 'src/api/interfaces/notifications';
import { formatCurrency } from 'src/utils/format';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { getNotifications } from 'src/api/services/notification/intex';
import relativeTime from 'dayjs/plugin/relativeTime';

export default function AdminNotifications({ isOpen }: { isOpen: boolean }) {
  const [notificationList, setNotificationList] = useState<
    NotificationResult[]
  >([]);

  const getData = async () => {
    try {
      const res = await getNotifications();
      setNotificationList(res);
    } catch (error) {
      console.error('Erro ao buscar notificações:', error);
    }
  };

  const isLocal = window.location.host.startsWith('localhost');

  useEffect(() => {
    getData();
    if (isLocal) return;
    const intervalId = setInterval(getData, 10000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Box
      display={isOpen ? 'block' : 'none'}
      transition="ease-in-out 1s"
      position="absolute"
      zIndex={1}
      right={'300px'}
      bg="black"
      maxH="480px"
      overflowY="scroll"
    >
      <Stack
        gap={0}
        divider={<StackDivider borderColor="grey" borderStyle="dashed" />}
      >
        {notificationList.map((o, i) => {
          return (
            <NotificationBox
              key={i}
              bookingID={o.bookingID}
              total={o.total}
              title={o.title}
              name={o.name}
              tickets={o.tickets}
              created={o.created}
              userID={o.userID}
              bookingStatus={o.bookingStatus}
            />
          );
        })}
      </Stack>
    </Box>
  );
}

const NotificationBox = ({
  name,
  tickets,
  total,
  title,
  bookingID,
  created,
  userID,
  bookingStatus,
}: Partial<NotificationResult>) => {
  const navigate = useNavigate();
  dayjs.extend(relativeTime);

  return (
    <HStack bg="black" w="400px" padding={3} alignItems="start" h="120px">
      <Stack h="100%">
        <HStack>
          <Text variant="text" color={'green'} fontWeight="bold">
            {title}
          </Text>
          <Text variant="text" textTransform="lowercase" fontStyle="italic">
            {bookingStatus}
          </Text>
        </HStack>
        <Stack gap={0}>
          <Text variant="text">
            {name} - {tickets} ingressos
          </Text>
          <Text variant="text">{formatCurrency(total ? +total : 0)}</Text>
          <Text variant="text">Reserva: {bookingID?.split('-')[0]}</Text>
        </Stack>
      </Stack>

      <Spacer />

      <Stack height="100%">
        <Text variant="text" fontStyle="italic" fontWeight="light">
          {' '}
          {dayjs(created).subtract(6, 'hour').fromNow()}
        </Text>
        <Spacer />
        <HStack>
          <Spacer />
          <Text
            onClick={() => navigate(`/admin/cadastros/${userID}`)}
            variant="link"
          >
            ver mais
          </Text>
        </HStack>
      </Stack>
    </HStack>
  );
};
