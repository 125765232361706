import React, { useState } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Text,
} from '@chakra-ui/react';
import Form from 'src/components/Form/Form';
import { UseFormRegister, UseFormReturn } from 'react-hook-form';
import { LoginFormValues } from 'src/pages/login';
import { FormInput } from 'src/components/Form/Input';

interface EmailStepProps {
  handleUserSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  handleSignUp: () => void;
  rhfForm: UseFormReturn<LoginFormValues, any>;
  loading: boolean;
}

export default function EmailStep({
  handleUserSubmit,
  handleSignUp,
  rhfForm,
  loading,
}: EmailStepProps) {
  const {
    register,
    formState: { errors },
  } = rhfForm;

  return (
    <>
      <Text variant="title">faça seu login</Text>
      <Form onSubmit={handleUserSubmit}>
        <FormInput
          rhfRegister={{
            ...register('username', {
              required: 'Campo obrigatório',
            }),
          }}
          errorState={errors.username}
          label={'E-MAIL OU NICKNAME'}
          inputProps={{ placeholder: 'DIGITE SEU E-MAIL OU NICKNAME' }}
          formControlProps={{ py: 4 }}
        />
        <Button
          w="full"
          variant={'pophausOutline'}
          type="submit"
          isLoading={loading}
        >
          continuar
        </Button>
      </Form>
      <HStack w="full" justify="center" py={4}>
        <Text variant="text" fontSize="10px">
          não possui uma conta?
        </Text>
        <Text variant="link" onClick={handleSignUp}>
          cadastre-se
        </Text>
      </HStack>
    </>
  );
}
