import React, { useEffect } from 'react';
import ReactGA from 'react-ga'
import TikTokPixel from 'tiktok-pixel'
import PageContainer from 'src/components/Container/PageContainer';
import { Stack, Text, Wrap, WrapItem } from '@chakra-ui/react';
import { MediaItem } from 'src/components/MediaItem';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'src/redux/hooks';
import { useGetUserAttachments } from 'src/api/services/match';
import { useGetUserActiveBooking } from 'src/api/services/booking';
import { useGetUserBookingWithMedias } from 'src/api/services/medias';
import { Loading } from 'src/components/Loading';

export default function PlayerMedia() {
  const { user } = useAppSelector((state) => state.user);
  const { data: medias, isLoading } = useGetUserBookingWithMedias(user?.userID);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    TikTokPixel.pageView()
  }, [])



  return (
    <PageContainer restricted>
      <Stack px={3} w='full' direction="column" spacing={6}>
        <Text variant="title" alignSelf={'flex-start'}>
          MINHAS MÍDIAS
        </Text>

        {!isLoading && <Wrap spacingX={8} spacingY={4} align="center">
          {medias?.map((m, i) => {
            const url = m.midias && m.midias[0].url
            return <WrapItem key={i} as={Link} to={`/jogador/midias/${m.bookingID}`}>
              <MediaItem
                date={dayjs(m.startDate).format('DD/MM/YYYY')}
                bookingId={m.bookingID}
                numberOfItems={m.midias?.length}
                previewUrl={url}
              />
            </WrapItem>
          })}
        </Wrap>}
        {!isLoading && medias && medias.length < 1 && <Text variant='text' color='red'>Nenhuma midia encontrada</Text>}
        {isLoading && <Loading />}
      </Stack>
    </PageContainer>
  );
}
