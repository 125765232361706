import React from 'react';
import { BsExclamationTriangle } from 'react-icons/bs';
import { PophausModal } from '../..';
import { Stack, HStack, Text } from '@chakra-ui/react';

interface BookModalData {
  time: string;
  date: string;
  unit: string;
}

interface BookModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleMainClick: () => void;
  handleSecondaryClick: () => void;
  type: 'book' | 'view';
  data: BookModalData;
}

const BookText = ({ title, value }: { title: string; value: string }) => {
  return (
    <HStack>
      <Text color="white" fontWeight="bold">
        {title}
      </Text>
      <Text color="white">{value}</Text>
    </HStack>
  );
};

const bookProps = {
  mainButtonText: 'fazer reserva',
  secondaryButtonText: 'bloquear horário',
  iconColor: 'green',
  colorScheme: 'popGreen',
};

const viewProps = {
  mainButtonText: 'ver reserva',
  secondaryButtonText: 'desbloquear horário',
  iconColor: 'yellow',
  colorScheme: 'popYellow',
};

export function BookModal({
  isOpen,
  onClose,
  handleMainClick,
  handleSecondaryClick,
  type,
  data,
}: BookModalProps) {
  const typeProps = type === 'book' ? bookProps : viewProps;
  return (
    <PophausModal
      isOpen={isOpen}
      onClose={onClose}
      icon={BsExclamationTriangle}
      overrideContent={
        <Stack direction="column" align="center">
          <BookText title={'HORÁRIO:'} value={data.time} />
          <BookText title={'DIA:'} value={data.date} />
          <BookText title={'UNIDADE:'} value={data.unit} />
        </Stack>
      }
      handleMainClick={handleMainClick}
      handleSecondaryClick={handleSecondaryClick}
      {...typeProps}
    />
  );
}
