import React from 'react'
import { HStack, Icon, Input, Button, Text } from "@chakra-ui/react";
import { BsXCircle } from "react-icons/bs";
import { BookingResult } from "src/api/interfaces/booking";

export interface CouponHandlerProps {
  data: BookingResult;
  handleRemoveCoupon: () => Promise<void>;
  coupon: string;
  setCoupon: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
  handleCouponApply: () => Promise<void>;
}

const CouponHandler = ({
  data,
  handleRemoveCoupon,
  coupon,
  setCoupon,
  loading,
  handleCouponApply,
}: CouponHandlerProps) => {
  return (
    <HStack>
      <Text color="green">CUPOM: </Text>
      {data.coupon ? (
        <HStack>
          <Text color="red">Cupom {data.coupon.description} aplicado</Text>
          <Icon
            as={BsXCircle}
            color="red"
            cursor="pointer"
            onClick={handleRemoveCoupon}
          />
        </HStack>
      ) : (
        <HStack>
          {' '}
          <Input
            px={2}
            boxShadow={0}
            variant="flushed"
            color={'#131313'}
            bg="white"
            _placeholder={{ color: 'gray' }}
            value={coupon}
            onChange={(e) => setCoupon(e.target.value)}
          />
          <Button
            isLoading={loading}
            onClick={handleCouponApply}
            paddingInline={5}
            variant="pophausOutline"
          >
            OK
          </Button>{' '}
        </HStack>
      )}
    </HStack>
  );
};

export default CouponHandler