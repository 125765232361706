import React from 'react';
import {
  Text,
  VStack,
  ColorProps,
  BorderProps,
  BackgroundProps,
} from '@chakra-ui/react';
import ImageBox from 'src/components/ImageBox/ImageBox';
import Section from 'src/components/Section';
import VideoBox from 'src/components/VideoBox';

interface GameItemProps {
  title: string;
  description: string;
  videoSrc?: string;
  reverse?: boolean;
  bg?: BackgroundProps['bg'];
  textColor: ColorProps['color'];
  borderColor: BorderProps['borderColor'];
}

export default function GameItem({
  title,
  description,
  videoSrc,
  reverse = false,
  bg,
  textColor,
  borderColor,
}: GameItemProps) {
  return (
    <Section pt={12} bg={bg} reverse={reverse}>
      <VStack align="flex-start" px={16} w="full">
        <Text
          fontSize={'6vw'}
          fontWeight={'black'}
          textTransform={'uppercase'}
          lineHeight={'normal'}
          color={textColor}
        >
          {title}
        </Text>
        <Text
          fontSize={'lg'}
          fontWeight={'medium'}
          color={textColor}
          pb={{ base: 8, md: 0 }}
        >
          {description}
        </Text>
      </VStack>
      <VideoBox vertical border="2px" borderColor={borderColor} src={videoSrc} />
    </Section>
  );
}
