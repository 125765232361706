import React from 'react';
import {
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  Spacer,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import Divider from 'src/components/Divider';
import { formatCurrency, truncateName } from 'src/utils/format';
import { getUserById, useGetUserByID } from 'src/api/services/user';

interface ScheduleButtonAdminProps {
  time?: string;
  available?: boolean;
  index: number;
  isSelected: boolean;
  bookingID?: string;
  scheduleRuleID?: number;
  handleSelect: (index: number) => void;
  handleDisabledClick?: (
    bookingID?: string,
    scheduleRuleID?: number,
    placeInRoomGroup?: boolean
  ) => void;
  owner?: string;
  label?: string;
  subLabel?: string;
  total?: number;
  allPlayersIdentified?: boolean;
  numberOfPlayers?: number;
  color?: string;
  hasPassed?: boolean;
  adminID?: string;
  responsible?: string;
  placeInRoomGroup?: boolean;
}

export const ScheduleButtonAdmin = ({
  time,
  available,
  index,
  isSelected,
  bookingID,
  scheduleRuleID,
  handleSelect,
  handleDisabledClick,
  owner,
  allPlayersIdentified,
  label,
  numberOfPlayers,
  subLabel,
  total,
  color,
  hasPassed,
  responsible,
  placeInRoomGroup,
}: ScheduleButtonAdminProps) => {
  const bookingId = bookingID?.split('-')?.[0];

  const handleOnClick = (index: number) => {
    if (hasPassed && available) return;
    if (!available) {
      if (handleDisabledClick)
        handleDisabledClick(bookingID, scheduleRuleID, placeInRoomGroup);
      return;
    }
    handleSelect(index);
  };

  return (
    <>
      {available ? (
        // BOTÃO DISPONIVEL
        <Flex
          cursor={hasPassed ? 'default' : 'pointer'}
          w="300px"
          h="100px"
          onClick={() => handleOnClick(index)}
          bg={hasPassed ? 'grey' : 'green'}
          borderRadius={0}
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          border={'2px'}
          borderColor={isSelected ? 'black' : 'transparent'}
          minWidth={136}
          py={6}
          px={12}
        >
          <Text color={'black'} fontWeight={isSelected ? 'bold' : 'normal'}>
            {time}
          </Text>
        </Flex>
      ) : scheduleRuleID ? (
        // BOTAO BLOQUEIO
        <Flex
          position="relative"
          onClick={() => handleOnClick(index)}
          w="300px"
          h="100px"
          bg={color}
          direction="row"
          cursor={'pointer'}
        >
          <Flex
            fontWeight="bold"
            textAlign="center"
            h="100%"
            w="100%"
            alignItems="center"
            flex={1 / 4}
            borderRight="1px solid black"
            p={2}
          >
            {time}
          </Flex>
          <Flex
            fontWeight="bold"
            textAlign="center"
            justifyContent="center"
            h="100%"
            direction="column"
            p={2}
            flex={3 / 4}
          >
            <Text fontSize="sm" color="white">
              {subLabel}
            </Text>
            {responsible && (
              <Text fontSize="xs">
                por: <span style={{ fontWeight: 'normal' }}>{responsible}</span>
              </Text>
            )}
          </Flex>
          <Badge
            position="absolute"
            right={0}
            fontSize={8}
            color="white"
            bg="black"
          >
            {label}
          </Badge>
        </Flex>
      ) : label == 'Disponível para eventos' ? (
        <Flex
          position="relative"
          onClick={() => handleOnClick(index)}
          w="300px"
          h="100px"
          bg={color}
          direction="row"
          cursor={'pointer'}
        >
          <Flex
            fontWeight="bold"
            textAlign="center"
            h="100%"
            w="100%"
            alignItems="center"
            flex={1 / 4}
            borderRight="1px solid black"
            p={2}
          >
            {time}
          </Flex>
          <Flex
            fontWeight="bold"
            textAlign="center"
            justifyContent="center"
            h="100%"
            direction="column"
            p={2}
            flex={3 / 4}
          >
            <Text fontSize="sm" color="white">
              {subLabel}
            </Text>
            {responsible && (
              <Text fontSize="xs">
                por: <span style={{ fontWeight: 'normal' }}>{responsible}</span>
              </Text>
            )}
          </Flex>
          <Badge
            position="absolute"
            right={0}
            fontSize={8}
            color="white"
            bg="black"
          >
            {label}
          </Badge>
        </Flex>
      ) : (
        // BOTAO RESERVA
        <Flex
          position="relative"
          onClick={() => handleOnClick(index)}
          w="300px"
          h="100px"
          bg={owner ? color : 'grey'}
          direction="row"
          cursor={'pointer'}
        >
          {owner && (
            <>
              <Flex
                fontWeight="bold"
                textAlign="center"
                h="100%"
                w="100%"
                alignItems="center"
                flex={1 / 4}
                borderRight="1px solid black"
                p={2}
              >
                {time}
              </Flex>
              <Flex h="100%" direction="column" p={2} flex={3 / 4}>
                {owner.length > 17 ? (
                  <Tooltip label={owner} color="white" bg="blackAlpha.900">
                    <Text fontWeight="bold" overflow="hidden">
                      {owner && truncateName(owner, 17)}
                    </Text>
                  </Tooltip>
                ) : (
                  <Text fontWeight="bold">{owner}</Text>
                )}

                <Text fontSize={12} margin={0}>
                  {bookingId}
                </Text>

                <HStack fontSize={12}>
                  <Text>{numberOfPlayers} participantes</Text>
                  <Spacer />
                  <Text>{total && formatCurrency(total)}</Text>
                </HStack>
                <Spacer />
                <Box fontSize={12} borderTop="1px solid black" w="100%">
                  {allPlayersIdentified ? (
                    <Text>Jogadores identificados</Text>
                  ) : (
                    <Text>
                      Jogadores <b>NÃO</b> identificados
                    </Text>
                  )}
                </Box>
              </Flex>
              <Badge
                position="absolute"
                right={0}
                fontSize={8}
                color="white"
                bg="black"
              >
                {label}
              </Badge>
            </>
          )}
        </Flex>
      )}
    </>
  );
};
