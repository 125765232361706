import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  VStack,
  Text,
  HStack,
  Stack,
  AccordionButton,
  Accordion,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  useToast,
  Box
} from '@chakra-ui/react';
import DashedContainer from 'src/components/Container/DashedContainer';
import getGameNameByGameID from 'src/utils/getNameNameByGameID';
import { Loading } from 'src/components/Loading';
import { useGetMatchHistoryByBookingID } from 'src/api/services/booking';
// import data from './dummyData.json'

interface PophausModalProps {
  bookingID: string
  isOpen: boolean;
  onClose: () => void;
}

interface MatchBlockProps {
  index: number
  gameId: number
  matchId: number
}

interface PlayerBlockProps {
  index: number
  nickName: string
  score: number
}

function MatchBlock({ index, gameId, matchId }: MatchBlockProps) {
  const gameIndex = index + 1
  const gameName = getGameNameByGameID(gameId)

  return (
    <HStack justifyContent='space-between' w='full'>
      <Text variant='text' fontSize='xl'>{gameIndex}.</Text>
      <Text variant='text' fontSize='xl' fontWeight='bold'>{gameName}</Text>
      <HStack>
        <Text variant='text' fontSize='xl' >MatchID:</Text>
        <Text variant='text' fontSize='xl' fontWeight='bold'>{matchId}</Text>
      </HStack>
    </HStack>

  )
}

function PlayerBlock({ index, nickName, score }: PlayerBlockProps) {
  const playerIndex = index + 1

  return (
    <HStack justifyContent='space-between' w='full'>
      <HStack>
        <Text variant='text' fontSize='xl' >{playerIndex}.</Text>
        <Text variant='text' fontSize='xl' fontWeight='bold'>{nickName}</Text>
      </HStack>
      <HStack>
        <Text variant='text' fontSize='xl' fontWeight='bold' color='green'>{score}</Text>
        <Text variant='text' fontSize='xl'>pts</Text>
      </HStack>
    </HStack>
  )
}

export function MatchHistoryModal({
  isOpen,
  onClose,
  bookingID
}: PophausModalProps) {
  const {
    data,
    isLoading,
  } = useGetMatchHistoryByBookingID(isOpen ? bookingID : undefined);

  return (

    <Modal onClose={onClose} isOpen={isOpen} isCentered size="6xl">
      <ModalOverlay />
      <ModalContent>
        <DashedContainer borderColor={'green'}>
          <ModalCloseButton
            size="sm"
            position="unset"
            alignSelf={'flex-end'}
            color={'green'}
            border={'2px'}
            borderRadius={0}
            borderColor={'green'}
          />
          <ModalBody w="full">

            <Text mb={6} variant='title'>Histórico de Partidas</Text>

            <Box maxH='50vh' overflowY='scroll' p={6}>
              {isLoading && <Loading />}
              {!isLoading && <Accordion allowToggle borderColor="#fff">
                {data?.map((g: any, i: number) => (
                  <AccordionItem key={i} border='1px dashed rgb(6 174 179)' marginBottom='1rem'>
                    <h2>
                      <AccordionButton>
                        <AccordionIcon color="#fff" />
                        <MatchBlock index={i} key={g.matchId} gameId={g.gameID} matchId={g.matchId} />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} color="#fff">

                      <Stack mt={3} gap={3}>
                        <HStack justifyContent='space-between' w='full'>
                          <Text variant='text'>Jogador</Text>
                          <Text variant='text'>Pontuação</Text>
                        </HStack>
                        {g.scores.map((s: any, i: number) => <PlayerBlock key={i} index={i} nickName={s.player.nickName} score={s.points} />)}

                      </Stack>
                    </AccordionPanel>
                  </AccordionItem>
                ))}
              </Accordion>}


            </Box>


          </ModalBody>
          <ModalFooter>
            <VStack spacing={4} w="full">

            </VStack>
          </ModalFooter>
        </DashedContainer>

      </ModalContent>
    </Modal>
  );
}

