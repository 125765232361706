import React, { DetailedHTMLProps, FormHTMLAttributes, ReactNode } from 'react';

interface FormProps {
  children: ReactNode | ReactNode[];
}

const Form = ({
  children,
  style,
  ...props
}: FormProps &
  DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>) => {
  return (
    <form style={{ ...style, width: '100%' }} {...props}>
      {children}
    </form>
  );
};

export default Form;
