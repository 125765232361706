import React, { useEffect } from 'react';
import { useLocation, BrowserRouter, Route, Routes } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import theme from '../theme';
import NoMatch from './NoMatch';
// google analytics / google tags
import ReactGA from 'react-ga';
ReactGA.initialize('G-7ZK87KVY8Z'); // trocar chaves para variáveis de sistema

import AdminHome from './admin/home';
import AdminTicketSummary from './admin/home/summary';
import AdminTickets from './admin/tickets';
import AdminEditTicket from './admin/tickets/edit';
import AdminViewTicket from './admin/tickets/view';
import AdminRegisters from './admin/registers';
import RegisterUser from './admin/registers/new';
import RegistryProfile from './admin/registers/profile';
import AdminFinancial from './admin/financial';
import AdminCoupon from './admin/coupon';

import Home from './client/home';
import Games from './client/games';
import Events from './client/events';
import Contacts from './client/contact';
import Login from './login';
import Logout from './logout';
import Ticket from './tickets';
import TicketSummary from './tickets/summary';
import Payment from './tickets/payment';

import PlayerHome from './player';
import PlayerData from './player/info';
import PlayerActiveTickets from './player/active-tickets';
import PlayerMedia from './player/media';
import PlayerMediaDetails from './player/media/details';
import PlayerHistory from './player/history';
import PlayerRescheduleTickets from './player/reschedule';

// import ComingSoon from './comingSoon';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/pt-br';
import timezone from 'dayjs/plugin/timezone';
import utcPlugin from 'dayjs/plugin/utc';
import CreateNewCouponPage from './admin/coupon/new';
import EditCouponPage from './admin/coupon/edit';
import FAQ from './faq';
import AdminCalendar from './admin/calendar';
import AdminPayment from './admin/home/adminPayment';
import PlayerDataPassword from './player/info/password';
import EventsSummary from './client/events/events-summary';
import AdminFAQ from './admin/faq';
import AdminEvents from './admin/events';
import RescheduleEvent from './client/events/reschedule';
import AdminEventsCreate from './admin/events/new';
import AdminEventsSummary from './admin/events/summary';
import AboutUs from './client/about';
import MediaShareView from './player/media/share-view';
import EventPreSchedule from './client/events/event-pre-schedule';
import TicketPlayers from './tickets/players';
import AdminGroupViewTicket from './admin/tickets/groupView';
import EventsForms from './admin/eventsForms';

dayjs.extend(customParseFormat);
dayjs.extend(utcPlugin);
dayjs.extend(timezone);
dayjs.locale('pt-br');

export const routes = [
  { path: '/', element: <Home /> },
  { path: '/jogos', element: <Games /> },
  { path: '/eventos', element: <EventPreSchedule /> },
  { path: '/eventos/novo', element: <Events /> },
  { path: '/eventos/resumo/:bookingID', element: <EventsSummary /> },
  { path: '/eventos/reagendar/:id', element: <RescheduleEvent /> },
  { path: '/contatos', element: <Contacts /> },
  { path: '/login', element: <Login /> },
  { path: '/logout', element: <Logout /> },
  { path: '/ingressos', element: <Ticket /> },
  { path: '/ingressos/resumo/:bookingID', element: <TicketSummary /> },
  { path: '/ingressos/jogadores/:bookingID', element: <TicketPlayers /> },
  { path: '/ingressos/pagamento/:bookingID', element: <Payment /> },
  {
    path: '/adicionar-ingressos/resumo/:bookingID',
    element: <TicketSummary isRebuy />,
  },
  // { path: '/adicionar-ingressos/pagamento/:bookingID', element: <Payment isRebuy /> },
  { path: '/FAQ', element: <FAQ /> },
  { path: '/sobre-nos', element: <AboutUs /> },
  { path: '/ForgotPassword', element: <Login /> },
  { path: '/ForgotPassword', element: <Login /> },
  { path: '/midia/:mediaID', element: <MediaShareView /> },
];

export const adminRoutes = [
  { path: '/admin/agenda', element: <AdminHome /> },
  { path: '/admin/agenda/pagamento/:bookingID', element: <AdminPayment /> },
  { path: '/admin/ingressos', element: <AdminTickets /> },
  { path: '/admin/ingressos/editar/:bookingID', element: <AdminEditTicket /> },
  {
    path: '/admin/ingressos/visualizar/:bookingID',
    element: <AdminViewTicket />,
  },
  {
    path: '/admin/ingressos/visualizar/grupo/:placeInRoomGroupID',
    element: <AdminGroupViewTicket />,
  },
  { path: '/admin/cadastros', element: <AdminRegisters /> },
  { path: '/admin/cadastros/:userID', element: <RegistryProfile /> },
  { path: '/admin/cadastros/novo', element: <RegisterUser /> },
  { path: '/admin/financeiro', element: <AdminFinancial /> },

  { path: '/admin/cupons', element: <AdminCoupon /> },
  { path: '/admin/cupons/novo', element: <CreateNewCouponPage /> },
  { path: '/admin/cupons/editar/:couponID', element: <EditCouponPage /> },

  { path: '/admin/calendario', element: <AdminCalendar /> },

  {
    path: '/admin/ingressos/resumo/:bookingID',
    element: <AdminTicketSummary />,
  },
  // {
  //   path: '/admin/adicionar-ingressos/resumo/:bookingID',
  //   element: <AdminTicketSummary isRebuy />,
  // },
  {
    path: '/admin/ingressos/selecionar-usuario/:bookingID',
    element: <AdminRegisters isTicketFlow />,
  },
  { path: '/admin/ingressos/pagamento/:bookingID', element: <AdminPayment /> },
  // { path: '/admin/adicionar-ingressos/pagamento/:bookingID', element: <AdminPayment isRebuy /> },
  { path: '/admin/eventos', element: <AdminEvents /> },
  { path: '/admin/eventos/novo', element: <AdminEventsCreate /> },
  {
    path: '/admin/eventos/selecionar-usuario/:bookingID',
    element: <AdminRegisters isEventsFlow />,
  },
  { path: '/admin/eventos/resumo/:bookingID', element: <AdminEventsSummary /> },
  { path: '/admin/faq', element: <AdminFAQ /> },
  { path: '/admin/eventos/leads', element: <EventsForms /> },
];

export const playerRoutes = [
  { path: '/jogador/home', element: <PlayerHome /> },
  { path: '/jogador/dados', element: <PlayerData /> },
  { path: '/jogador/dados/senha', element: <PlayerDataPassword /> },
  { path: '/jogador/midias', element: <PlayerMedia /> },
  { path: '/jogador/midias/:bookingId', element: <PlayerMediaDetails /> },
  { path: '/jogador/ingressos-ativos', element: <PlayerActiveTickets /> },
  { path: '/jogador/historico', element: <PlayerHistory /> },
  { path: '/jogador/reagendar/:id', element: <PlayerRescheduleTickets /> },
];

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto', // Optional if you want to skip the scrolling animation
    });
  }, [pathname]);

  return null;
}

function App() {
  // const user = useSelector((state: RootState) => state.user);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          {routes.map((r) => (
            <Route key={r.path} path={r.path} element={r.element} />
          ))}

          {adminRoutes.map((r) => (
            <Route key={r.path} path={r.path} element={r.element} />
          ))}

          {playerRoutes.map((r) => (
            <Route key={r.path} path={r.path} element={r.element} />
          ))}
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
