import { Box, Button, Flex, VStack } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import FinancialFiltersForm, {
  FinancialFiltersFormValues,
} from 'src/modules/Forms/Backoffice/FinancialFiltersForm';
import PageContainer from '../../../components/Container/PageContainer';
import InfoDashboard from './InfoDashboard';
import {
  getFinancialReportList,
  useGetFinancialReport,
} from 'src/api/services/financialReport';
import usePHToast from 'src/hooks/useToast';
import { FinancialReportResult } from 'src/api/interfaces/financialReport';
import { PaymentGroupResult } from 'src/api/interfaces/paymentGroupResult';
import { SalesPerDurationResult } from 'src/api/interfaces/salesPerDurationResult';

export default function AdminFinancial() {
  const form = useForm<FinancialFiltersFormValues>();
  const toast = usePHToast();

  const [financialReport, setFinancialReport] =
    useState<FinancialReportResult | null>(null);
  const [paymentGroups, setPaymentGroups] = useState<
    PaymentGroupResult[] | null
  >(null);
  const [salesPerDuration, setSalesPerDuration] = useState<
    SalesPerDurationResult[] | null
  >(null);
  const [reportInterval, setReportInterval] = useState<string[]>([]);
  const [loadingDownloadUsageReportCSV, setLoadingDownloadUsageReportCSV] =
    useState<boolean>(false);

  const handleFinancialForm = async (
    businessUnitID: number,
    startDate: string,
    endDate: string
  ) => {
    try {
      const report = await useGetFinancialReport(
        businessUnitID,
        startDate,
        endDate
      );
      setFinancialReport(report);
      setPaymentGroups(report?.paymentGroup);
      setSalesPerDuration(report?.salesPerDuration);
      setReportInterval([startDate, endDate]);

      if (report === null) {
        toast({ status: 'error', description: 'Não há Registro!' });
      }
    } catch (e: any) {
      toast({ status: 'error', description: e?.message });
    }
  };

  const handleDownloadUsageReportCSV = async () => {
    setLoadingDownloadUsageReportCSV(true);

    const reqData = {
      businessUnit: form.getValues('businessUnit'),
      startDate: form.getValues('startDate'),
      endDate: form.getValues('endDate'),
    };

    const result = await getFinancialReportList(
      reqData.businessUnit,
      reqData.startDate,
      reqData.endDate
    );

    if (result && result.length > 0) {
      const headers = Object.keys(result[0]).join(',');
      const rows = result
        .map((item) => Object.values(item).join(','))
        .join('\n');
      const csv = `${headers}\n${rows}`;
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'ArcadeHaus - Consolidado de uso.csv';
      a.click();
      URL.revokeObjectURL(url);
    }

    setLoadingDownloadUsageReportCSV(false);
  };

  return (
    <>
      <PageContainer restricted>
        <VStack w="full" spacing={2} align="center" pt={12}>
          <FinancialFiltersForm
            form={form}
            handleFinancialForm={handleFinancialForm}
          />
          {financialReport != null ? (
            <Flex flexDirection={'column'}>
              <InfoDashboard
                report={financialReport}
                paymentGroups={paymentGroups}
                salesPerDuration={salesPerDuration}
                reportInterval={reportInterval}
              />
              <Button
                isLoading={loadingDownloadUsageReportCSV}
                isDisabled={loadingDownloadUsageReportCSV}
                onClick={handleDownloadUsageReportCSV}
                variant="pophausOutline"
                mt={2}
                alignSelf={['stretch', 'flex-end']}
              >
                Baixar CSV do consolidado de uso
              </Button>
            </Flex>
          ) : null}
        </VStack>
      </PageContainer>
    </>
  );
}
