import React, { useState } from 'react';
import {
  Button,
  ButtonProps,
  HStack,
  Text,
  VStack,
  Wrap,
  WrapItem,
  Image,
  FlexProps,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import DashedContainer from '../Container/DashedContainer';
import GameRoom from 'src/assets/blueRoom.png';
import MultipleGameRooms from 'src/assets/allRoom.png'

interface RoomImageProps {
  title?: string;
  multipleRooms?: boolean
}

export const RoomImage = ({ title, multipleRooms = false, ...props }: RoomImageProps & FlexProps) => {
  return (
    <DashedContainer
      padding={4}
      alignItems={'flex-start'}
      w={{ base: '100%', md: '50%' }} // todo: multiple images logic
      backgroundImage={`url(${multipleRooms ? MultipleGameRooms : GameRoom})`}
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      // backgroundSize="cover"
      backgroundClip="content-box"
      {...props}
    >
      <VStack px={4} py={4}>
        {title && (
          <Text px={4} py={1} bg="green">
            {title}
          </Text>
        )}
      </VStack>
    </DashedContainer>
  );
};
