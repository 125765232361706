import React from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  VStack,
  Icon,
  Text,
  Container,
  HStack,
} from '@chakra-ui/react';
import DashedContainer from 'src/components/Container/DashedContainer';
import { IconType } from 'react-icons/lib';
import PlayerDataForm, { PlayerDataValues } from 'src/modules/Forms/PlayerData';
import { UseFormReturn } from 'react-hook-form';
import { Loading } from 'src/components/Loading';

interface PophausModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleMainClick?: () => void;
  playerNum: number;
  form: UseFormReturn<PlayerDataValues, any>;
  isEdit?: boolean;
  loading?: boolean;
}

export function AddPlayerModal({
  isOpen,
  onClose,
  handleMainClick,
  playerNum,
  form,
  loading = false,
  isEdit = false,
}: PophausModalProps) {
  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        size="6xl"
      >
        <ModalOverlay />
        <ModalContent containerProps={{ px: 4 }}>
          <DashedContainer borderColor={'green'}>
            {loading ? (
              <Loading />
            ) : (
              <>
                <ModalCloseButton
                  size="sm"
                  position="unset"
                  alignSelf={'flex-end'}
                  color={'green'}
                  border={'2px'}
                  borderRadius={0}
                  borderColor={'green'}
                />
                <ModalBody w="full">
                  <VStack spacing={4} w="full" align={'center'}>
                    <Text variant="title" alignSelf={'flex-start'}>
                      {isEdit ? 'Edite o jogador' : 'Adicione um jogador'}
                    </Text>
                    <PlayerDataForm playerNum={playerNum} form={form} />
                  </VStack>
                </ModalBody>
                <ModalFooter w="full">
                  <VStack spacing={4} w={['full', 'auto']}>
                    <Button
                      w="full"
                      variant="pophausOutline"
                      colorScheme={'popGreen'}
                      onClick={handleMainClick}
                    >
                      {isEdit ? 'Editar jogador' : 'Adicionar jogador'}
                    </Button>
                  </VStack>
                </ModalFooter>
              </>
            )}
          </DashedContainer>
        </ModalContent>
      </Modal>
    </>
  );
}
