import { Stack, Progress, Text, Flex, Spacer, Grid } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { PaymentGroupResult } from 'src/api/interfaces/paymentGroupResult';
import DashedContainer from 'src/components/Container/DashedContainer';
import { formatCurrency } from 'src/utils/format';

export interface PaymentTypeValues {
  paymentGroups: PaymentGroupResult[] | null;
}

export default function PaymentType({ paymentGroups }: PaymentTypeValues) {
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    if (paymentGroups) {
      setTotal(
        paymentGroups.reduce((partialSum, a) => partialSum + a.total, 0)
      );
    }
  }, [paymentGroups]);

  return (
    <DashedContainer alignItems="start" flex={1 / 2}>
      <Text mb={10} variant="title">
        tipo de pagamento
      </Text>

      <Stack
        gap={2}
        mt="20px"
        px="20px"
        color="white"
        w="100%"
        overflowY="scroll"
      >
        {paymentGroups?.map((pg) => (
          <Flex
            key={paymentGroups.indexOf(pg)}
            justifyContent="space-between"
            alignItems="center"
            w="100%"
            direction="row"
            color="white"
          >
            <Flex flex={1 / 4}>
              <Text>
                {pg.paymentMethod + (pg.paymentMethodID === 10 ? '*' : '')}
              </Text>
            </Flex>
            <Flex flex={1 / 4}>
              <Text>
                R${' '}
                {pg.paymentMethodID === 10
                  ? (pg.total * 0.9).toFixed(2)
                  : pg.total}
              </Text>
            </Flex>
            <Flex flex={1 / 4}>
              <Progress
                borderRadius={50}
                width="100%"
                value={(pg.total / total) * 100}
                bg="#fff"
                size="md"
                colorScheme="whatsapp"
              />
            </Flex>
            <Flex flex={1 / 4}>
              <Spacer />
              <Text>
                {((pg.total / total) * 100).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) + '%'}
              </Text>
            </Flex>
          </Flex>
        ))}
      </Stack>

      <Grid
        borderTop="2px dashed"
        borderColor="green"
        color="green"
        w="full"
        templateColumns="repeat(4, 1fr)"
        gap={2}
        justifyItems={'center'}
        alignItems={'center'}
        pt="10px"
        mt="10px"
      >
        <Text>Total</Text>
        <Text>{formatCurrency(total)}</Text>
        <>-</>
        <Text>100%</Text>
      </Grid>

      <Text color={'white'} fontSize={12}>
        * Valor a ser recebido, 90% do valor total vendido neste método.
      </Text>
    </DashedContainer>
  );
}
