import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useForm } from 'react-hook-form';
import {
  Text,
  VStack,
  Square,
  Button,
  Stack,
  useMediaQuery,
  Flex,
} from '@chakra-ui/react';
import PageContainer from 'src/components/Container/PageContainer';
import DashedContainer from 'src/components/Container/DashedContainer';
import SelectTicketForm, {
  SelectTicketFormValues,
} from 'src/modules/Forms/SelectTicketForm';
import Section from 'src/components/Section';
import SectionDivider from 'src/components/Section/SectionDivider';
import HoursAddress from 'src/modules/ContactInfo/HoursAddress';
import Contact from 'src/modules/ContactInfo/Contact';
import SocialMedia from 'src/modules/ContactInfo/SocialMedia';
import TikTokPixel from 'tiktok-pixel';

export default function Contacts() {
  const [largerThan350] = useMediaQuery('(min-width: 350px)');
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    TikTokPixel.pageView();
  }, []);

  return (
    <PageContainer containerProps={{ justify: 'center' }}>
      <Text
        fontSize={largerThan350 ? '56px' : '36px'}
        fontWeight={'black'}
        textTransform={'uppercase'}
        color="green"
        textAlign={{ base: 'center', md: 'unset' }}
      >
        CONTATOS
      </Text>

      <Stack
        direction={['column', 'row']}
        spacing={{ base: 6, md: 60 }}
        py={12}
        px={4}
      >
        <HoursAddress containerProps={{ px: 0, w: 'full' }} />
        <Contact containerProps={{ px: 0, w: ['full', 'auto'] }} />
      </Stack>

      <SocialMedia />
    </PageContainer>
  );
}
