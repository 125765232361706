import React, { useCallback, useEffect, useState } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';
import { FormInput } from 'src/components/Form/Input';
import { FormSelect } from 'src/components/Form/Select';
import { FormInputPassword } from 'src/components/Form/Input/PasswordInput';
import { Box, Button, Stack } from '@chakra-ui/react';
import Form from 'src/components/Form/Form';
import { useGetBusinessUnits } from 'src/api/services/businessUnit';
import dayjs from 'dayjs';

export interface FinancialFiltersFormValues {
  startDate: string;
  endDate: string;
  businessUnit: number;
  origin: string;
}

interface FinancialFiltersFormProps {
  form: UseFormReturn<FinancialFiltersFormValues, any>;
  handleFinancialForm: (
    BusinessUnitID: number,
    startDate: string,
    endDate: string
  ) => void;
}

const maxDate = dayjs().format('YYYY-MM-DD');

export default function FinancialFiltersForm({
  form,
  handleFinancialForm,
}: FinancialFiltersFormProps) {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = form;

  const today = new Date().toLocaleDateString('fr-ca');

  const { data: businessUnits } = useGetBusinessUnits();

  const onSubmit = (data: any) => {
    handleFinancialForm(data.businessUnit, data.startDate, data.endDate);
    return;
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction={['column', 'row']} w={'full'} spacing={6}>
        <FormSelect
          label="Unidade"
          rhfRegister={{
            ...register('businessUnit'),
          }}
          errorState={errors.businessUnit}
          selectProps={{ placeholder: 'UNIDADE', w: '100%' }}
        >
          {businessUnits?.map((b) => (
            <option key={b.businessUnitID} value={b.businessUnitID}>
              {b.description}
            </option>
          ))}
        </FormSelect>
        <FormInput
          label="Data de início"
          rhfRegister={{
            ...register('startDate'),
          }}
          errorState={errors.startDate}
          inputProps={{ placeholder: 'DATA INICIO', type: 'date' }}
        />
        <FormInput
          label="Data de fim"
          rhfRegister={{
            ...register('endDate'),
          }}
          errorState={errors.endDate}
          inputProps={{ placeholder: 'DATA', type: 'date' }}
        />

        {/* <FormSelect
          rhfRegister={{
            ...register('origin'),
          }}
          errorState={errors.origin}
          selectProps={{ placeholder: 'ORIGEM', w: '100%'}}
        >
          <option value="presential">PRESENCIAL</option>
          <option value="online">ONLINE</option>
          <option value="all">TODAS</option>
        </FormSelect> */}
        <Button
          alignSelf="end"
          paddingInline={50}
          type="submit"
          variant="pophausOutline"
        >
          OK
        </Button>
      </Stack>
    </Form>
  );
}
