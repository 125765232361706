import React from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';
import { FormInput } from 'src/components/Form/Input';
import { FormSelect } from 'src/components/Form/Select';
import { FormInputPassword } from 'src/components/Form/Input/PasswordInput';
import { SearchIcon } from '@chakra-ui/icons';
import { Box, Button, Stack } from '@chakra-ui/react';
import Form from 'src/components/Form/Form';
import { useGetBusinessUnits } from 'src/api/services/businessUnit';

export interface AdminTicketsFilterFormValues {
  searchTerm: string;
  date: string;
  businessUnitID: string;
  numberOfPlayers: string;

}

interface AdminTicketsFilterFormProps {
  form: UseFormReturn<AdminTicketsFilterFormValues, any>;
  onSubmit: (data: AdminTicketsFilterFormValues) => Promise<void>;
  loading?: boolean
}

export default function AdminTicketsFilterForm({
  form,
  onSubmit,
  loading
}: AdminTicketsFilterFormProps) {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = form;

  const { data: businessUnits } = useGetBusinessUnits();

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction={['column', 'row']} w={'full'} spacing={6}>
        <FormInput
          rhfRegister={{
            ...register('searchTerm'),
          }}
          icon={SearchIcon}
          inputProps={{ placeholder: 'PESQUISAR USERNAME' }}
        />

        <FormInput
          rhfRegister={{
            ...register('date'),
          }}
          errorState={errors.date}
          inputProps={{ placeholder: 'DATA', type: 'date' }}
        />
        <FormSelect
          rhfRegister={{
            ...register('businessUnitID'),
          }}
          errorState={errors.businessUnitID}
          selectProps={{ placeholder: 'SELECIONE A UNIDADE' }}
        >
          {businessUnits?.map((b) => (
            <option key={b.businessUnitID} value={b.businessUnitID}>
              {b.description}
            </option>
          ))}
        </FormSelect>

        {/* <FormSelect
          rhfRegister={{
            ...register('numberOfPlayers'),
          }}
          errorState={errors.numberOfPlayers}
          selectProps={{ placeholder: 'QTS. DE JOGADORES' }}
        >
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
          <option value="11">11</option>
          <option value="12">12</option>
        </FormSelect> */}

        <Button type="submit" variant="pophausOutline" paddingInline={0} w="full" isLoading={loading}>
          OK
        </Button>
      </Stack>
    </Form>
  );
}
