import React from 'react';
import { Stack, Flex, Text } from '@chakra-ui/react';
import { ReactComponent as VisaIcon } from 'src/assets/icons/Cards/VisaIcon.svg';
import { ReactComponent as EloIcon } from 'src/assets/icons/Cards/EloIcon.svg';
import { ReactComponent as MasterCardIcon } from 'src/assets/icons/Cards/MasterCardIcon.svg';

export const AcceptedCards = () => {
  return (
    <Stack direction="column" alignSelf={'center'}>
      <Text variant="text" fontWeight={'bold'} textTransform="uppercase">
        cartões aceitos
      </Text>
      <Stack direction="row">
        <Flex px={2} bg="white" align="center">
          <VisaIcon />
        </Flex>
        <Flex px={2} bg="white" align="center">
          <EloIcon />
        </Flex>
        <Flex px={2} bg="white" align="center">
          <MasterCardIcon />
        </Flex>
      </Stack>
    </Stack>
  );
};
