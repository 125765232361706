import { ApiRepository, ApiResponse, useFetch } from 'src/api';
import {
  ScheduleRuleRequest,
  ScheduleRuleResult,
  ScheduleRuleTypesResult,
} from 'src/api/interfaces/scheduleRule';

const api = new ApiRepository();

export async function createScheduleRule(data: ScheduleRuleRequest) {
  const res = await api.apiRequestWrapper<ApiResponse<ScheduleRuleResult>>({
    method: 'post',
    url: 'ScheduleRule',
    data,
  });
  return res.result;
}

export async function deleteScheduleRule(scheduleRuleId: number) {
  const res = await api.apiRequestWrapper<ApiResponse<ScheduleRuleResult>>({
    method: 'delete',
    url: 'ScheduleRule/UnlockSchedule',
    params: { scheduleRuleId },
  });
  return res.result;
}

export function useGetScheduleRules() {
  const args = {
    method: 'get',
    url: `ScheduleRule/GetRules`,
  };

  const { data, error, isLoading, mutate } = useFetch<
    ApiResponse<ScheduleRuleTypesResult[]>
  >(api, args, { revalidateOnFocus: false });
  return { data: data?.result, error, isLoading, mutate };
}

// export function useGetScheduleRuleByID(ScheduleRuleID: number) {
//   const args = ScheduleRuleID
//     ? {
//         method: 'get',
//         url: `ScheduleRule/${ScheduleRuleID}`,
//       }
//     : null;

//   const { data, error, isLoading } = useFetch<ApiResponse<ScheduleRuleResult>>(
//     api,
//     args
//   );
//   return { data: data?.result, error, isLoading };
// }

// export function useUnlockSchedule(ScheduleRuleID: number) {
//   const args = ScheduleRuleID
//     ? {
//         method: 'get',
//         url: `ScheduleRule//UnlockSchedule?ScheduleRuleID=${ScheduleRuleID}`,
//       }
//     : null;

//   const { data, error, isLoading } = useFetch<ApiResponse<ScheduleRuleResult>>(
//     api,
//     args
//   );
//   return { data: data?.result, error, isLoading };
// }
