import { Avatar, Button, Input, Stack, Text } from '@chakra-ui/react';
import React, { useCallback, useRef } from 'react';
import { getUserById, updateUser } from 'src/api/services/user';
import DashedContainer from 'src/components/Container/DashedContainer';
import usePHToast from 'src/hooks/useToast';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { UserActions } from 'src/redux/user';
import { convertBase64 } from 'src/utils/file';

const maxSizeInBytes = 5e6;
const maxSizeInMb = Math.round(maxSizeInBytes / 1e3);

export const PlayerProfile = () => {
  const toast = usePHToast();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);

  const inputRef = useRef<HTMLInputElement>(null);
  const handleButtonClick = (e: any) => {
    inputRef.current?.click();
  };

  const onSelectFile = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      try {
        if (!user)
          throw new Error('Usuário inválido. Tente fazer login novamente.');

        const fileList = e.target.files;
        if (!fileList?.length || fileList.length > 1)
          throw new Error('Arquivo inválido.');

        const files = Array.from(fileList);
        const filesSize = files
          .map((f) => f.size)
          .reduce((acc, cur) => acc + cur);
        if (filesSize > maxSizeInBytes) {
          throw new Error(
            `Os arquivos têm ${Math.round(filesSize / maxSizeInMb)}mb!`
          );
        }

        const promisesBase64 = files.map(
          async (file) => await convertBase64(file)
        );
        const previewBase64 = await Promise.all(promisesBase64);
        const fileW = previewBase64?.[0];
        const file = fileW.replace('data:image/jpeg;base64,', '');
        const result = await updateUser(user?.userID, {
          address: '',
          birthDate: '',
          cityID: 0,
          complement: '',
          document: '',
          email: '',
          file: file,
          lastName: '',
          name: '',
          nickName: '',
          number: '',
          phone: '',
          stateID: 0,
          zipCode: '',
        });
        if (result) {
          const { picture } = await getUserById(user.userID, user.accessToken);
          dispatch(
            UserActions.setUser({
              ...user,
              picture,
            })
          );
        }
        toast({ status: 'success', title: 'Foto trocada com sucesso!' });
      } catch (e: any) {
        toast({ status: 'error', title: e?.message });
      }
    },
    []
  );

  return (
    <DashedContainer>
      <Stack direction="column" align="center" spacing={4}>
        <Text variant="title" color="white" textAlign={'center'}>
          olá,
          <Text as="span" variant="title">
            {` ${user?.name}!`}
          </Text>
        </Text>
        {/* <Text variant="text" fontWeight={500} textAlign={'center'}>
          Você está em
          <Text as="span" variant="text" color="green">
            {` 323 `}
          </Text>
          do ranking!
        </Text> */}
        <Avatar
          showBorder
          borderColor="green"
          name={user?.name}
          src={user?.picture}
          size="2xl"
        />
        <Button
          w="contain"
          variant="pophausOutline"
          p={'0 2'}
          onClick={handleButtonClick}
        >
          trocar foto
        </Button>
        <Input
          display="none"
          type={'file'}
          ref={inputRef}
          onChange={onSelectFile}
        />
      </Stack>
    </DashedContainer>
  );
};
