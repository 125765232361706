import { ButtonProps, extendTheme } from '@chakra-ui/react';
import Button from './components/button';
import { checkboxTheme } from './components/checkbox';
import { alertTheme } from './components/alert';

const theme = extendTheme({
  colors: {
    green: '#B0CA0A',
    black: '#1E1D20',
    gray: '#B8B8B8',
    yellow: '#F9E312',
    red: '#DE1A19',
    blue: '#00AEB3',
    orange: '#FF841A',
    popGreen: {
      bg: '#B0CA0A',
      text: '#131313',
      border: '#131313',
      outline: 'white',
      bgHover: '#CFED0C',
    },
    popPetGreen: {
      bg: '#00AEB3',
      text: '#131313',
      border: '#131313',
      outline: 'white',
      bgHover: '#00CED3',
    },
    popGreenAlt: {
      bg: '#B0CA0A',
      text: '#131313',
      border: 'white',
      outline: '#131313',
      bgHover: '#CFED0C',
    },
    popTransparent: {
      bg: 'transparent',
      text: 'white',
      border: 'transparent',
      outline: 'white',
      bgHover: '#B0CA0A',
    },
    popWhite: {
      bg: 'white',
      text: '#131313',
      border: '#DE1A19',
      outline: 'white',
      bgHover: '#E0E0E0',
    },
    popYellow: {
      bg: '#F9E312',
      text: '#131313',
      border: '#131313',
      outline: '#F9E312',
      bgHover: '#CFED0C',
    },
  },
  fonts: {
    heading: `Montserrat, sans-serif`,
    body: `Montserrat, sans-serif`,
  },
  // breakpoints: {
  //   sm: '400px',
  //   md: '600px',
  //   lg: '800px',
  //   xl: '1000px',
  //   '2xl': '1200px',
  // },
  components: {
    Button: {
      variants: {
        pophausOutline: (props: ButtonProps) => ({
          bg: `${props.colorScheme}.bg`,
          borderRadius: 0,
          padding: '20px 120px',
          color: `${props.colorScheme}.text`,
          fontSize: '12px',
          fontWeight: 'bold',
          border: '4px solid',
          borderColor: `${props.colorScheme}.border`,
          outline: `1px solid`,
          outlineColor: `${props.colorScheme}.outline`,
          textTransform: 'uppercase',
          _hover: {
            bg: `${props.colorScheme}.bgHover`,
          },
        }),
      },
      defaultProps: {
        colorScheme: 'popGreen',
      },
    },
    Checkbox: checkboxTheme,
    Alert: alertTheme,
    Text: {
      variants: {
        title: {
          color: 'green',
          fontSize: '18px',
          fontWeight: 'bold',
          textTransform: 'uppercase',
        },
        secTitle: {
          color: '#00AEB3',
          fontSize: '18px',
          fontWeight: 'bold',
          textTransform: 'uppercase',
        },
        subtitle: {
          color: 'white',
          fontWeight: 'bold',
          fontSize: 'sm',
        },
        text: {
          color: 'white',
          fontSize: 'sm',
        },
        link: {
          color: 'green',
          fontSize: '12px',
          fontWeight: 'bold',
          textDecoration: 'underline',
          cursor: 'pointer',
          textTransform: 'uppercase',
        },
        highlighted: {
          color: 'green',
          fontSize: '12px',
          fontWeight: 'bold',
        },
      },
    },
  },
});

export default theme;
