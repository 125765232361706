import React, { useEffect, useState } from 'react';
import PageContainer from '../../../components/Container/PageContainer';
import AdminCouponsFilterForm, {
  AdminCouponsFilterFormValues,
} from 'src/modules/Forms/Backoffice/CouponsFIlterForm';
import { useForm } from 'react-hook-form';
import { Button, HStack, Spacer, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { Loading } from 'src/components/Loading';
import LabeledTable from 'src/components/Table/LabeledTable';
import { useAdminCouponsColumns } from './TableData';
import { getFilteredCoupon, useGetCoupons } from 'src/api/services/coupon';
import { CouponResult } from 'src/api/interfaces/coupon';
import usePHToast from 'src/hooks/useToast';

export default function AdminCoupon() {
  const navigate = useNavigate();
  const form = useForm<AdminCouponsFilterFormValues>();
  const { data, isLoading, mutate } = useGetCoupons();
  const [result, setResult] = useState<CouponResult[]>();
  const columns = useAdminCouponsColumns();
  const toast = usePHToast()

  const handleSearch = async (data: AdminCouponsFilterFormValues) => {
    try {
      const res = await getFilteredCoupon(data.searchTerm)
      setResult(res)
    } catch (err: any) {
      toast({ status: 'error', title: err.message })
    }
  };

  useEffect(() => {
    setResult(data)
  }, [data])

  return (
    <PageContainer restricted>
      <VStack w="full">
        <HStack w="full">
          <AdminCouponsFilterForm form={form} onSubmit={handleSearch} />
          <Spacer />
          <Button
            variant="pophausOutline"
            onClick={() => navigate('/admin/cupons/novo')}
          >
            novo cupom
          </Button>
        </HStack>
        {isLoading && <Loading />}
        {result && <LabeledTable columns={columns} data={result} />}
      </VStack>
    </PageContainer>
  );
}
