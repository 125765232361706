import React, { useMemo } from 'react';
import DashedContainer from 'src/components/Container/DashedContainer';
import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Text,
} from '@chakra-ui/react';
import { SalesPerDurationResult } from 'src/api/interfaces/salesPerDurationResult';
import dayjs from 'dayjs';

export interface CapacityRateValues {
  avgTickets: number;
  reportInterval: string[];
  salesPerDuration: SalesPerDurationResult[] | null;
  capacityRate: number;
}

export default function CapacityRate({
  avgTickets,
  reportInterval,
  salesPerDuration,
  capacityRate,
}: CapacityRateValues) {

  // function daysBetweenDates(startDate: Date, endDate: Date): number {
  //   const oneDayInMillis = 24 * 60 * 60 * 1000;
  //   return Math.ceil(
  //     (endDate.getTime() - startDate.getTime()) / oneDayInMillis
  //   );
  // }

  // function calculateAvailableBlocks(startDate: Date, endDate: Date): number {
  //   const startDateFormat = !isNaN(startDate.getTime()) ? startDate : new Date("2023-07-03");
  //   const endDateFormat = !isNaN(endDate.getTime()) ? endDate : new Date();

  //   const totalDays = daysBetweenDates(startDateFormat, endDateFormat);
  //   const averageBlocksPerDay = 20;
  //   return Math.round(totalDays * averageBlocksPerDay);
  // }

  // function calculateReservedBlocks(sales: SalesPerDurationResult[]): number {
  //   let totalBlocks = 0;

  //   for (const sale of sales) {
  //     const [hours, minutes] = sale.duration.split(':').map(Number);
  //     const totalMinutes = hours * 60 + minutes;

  //     const blocksForCurrentDuration = Math.round(totalMinutes / 25);

  //     totalBlocks += blocksForCurrentDuration;
  //   }

  //   return Math.round(totalBlocks);
  // }

  // function calculateCapacityPercentage(): number {
  //   if (!salesPerDuration) return 0;

  //   const availableBlocks = calculateAvailableBlocks(
  //     new Date(reportInterval[0]), 
  //     new Date(reportInterval[1])
  //   );

  //   const reservedBlocks = calculateReservedBlocks(salesPerDuration);
  //   return Math.round((reservedBlocks / availableBlocks) * 100);
  // }

  //const capacityPercentage = useMemo(() => calculateCapacityPercentage(), [reportInterval, salesPerDuration]);

  return (
    <DashedContainer borderColor="#00AEB3" w="100%" alignItems="start">
      <Text variant="title" color="#00AEB3">
        Taxa de lotação
      </Text>
      <Flex p="40px" h="100%" direction="row">
        <CircularProgress
          flex={1 / 2}
          color="#00AEB3"
          size="100%"
          value={capacityRate}
        >
          <CircularProgressLabel color="#00AEB3" fontSize="2em">
            {capacityRate}%
          </CircularProgressLabel>
        </CircularProgress>

        <Box fontWeight="bold" flex={1 / 2} fontSize="1.5em">
          <Text color="#00AEB3">Média de ingressos por reserva</Text>
          <Text color="white">{avgTickets}</Text>
        </Box>
      </Flex>
    </DashedContainer>
  );
}
