export default function getGameNameByGameID(gameId: number) {
  switch (gameId) {
    case 12:
      return 'Genius';
    case 13:
      return 'Jogo da Memória';
    case 14:
      return 'Caça às Cores';
    case 18:
      return 'Corrida do Ouro';
    case 19:
      return 'Missão Verde';
    case 20:
      return 'Não Deixe Acabar';
    case 21:
      return 'Travessia Extrema';
    case 22:
      return 'Mestre dos Mosaicos';
    default:
      return 'Jogo não identificado';
  }
}
