import { ApiRepository, ApiResponse, useFetch } from 'src/api';
import { PlayerResult } from 'src/api/interfaces/player';

const api = new ApiRepository();

export async function createPlayer(PlayerRequest: string) {
  const res = await api.apiRequestWrapper<PlayerResult>({
    method: 'post',
    url: 'Player',
    data: { PlayerRequest },
  });
  return res;
}

export function useGetPlayerByID(PlayerID: number) {
  const args = PlayerID
    ? {
        method: 'get',
        url: `Player/${PlayerID}`,
      }
    : null;

  const { data, error, isLoading } = useFetch<ApiResponse<PlayerResult>>(
    api,
    args,
    { revalidateOnFocus: false }
  );
  return { data: data?.result, error, isLoading };
}

export function useGetPlayerByNickName(NickName: string) {
  const args = NickName
    ? {
        method: 'get',
        url: `Player/GetByNickName?NickName=${NickName}`,
      }
    : null;

  const { data, error, isLoading } = useFetch<ApiResponse<PlayerResult>>(
    api,
    args,
    { revalidateOnFocus: false }
  );
  return { data: data?.result, error, isLoading };
}

export async function getPlayerByNickName(nickName: string) {
  const res = await api.apiRequestWrapper<ApiResponse<PlayerResult>>({
    method: 'get',
    url: `Player/GetByNickName`,
    params: { nickName },
  });
  return res.result;
}

export async function CheckIfNicknameIsAvailableInUpdateUser(
  NickName: string,
  UserId?: string
) {
  const res = await api.apiRequestWrapper<ApiResponse<any>>({
    method: 'get',
    url: `Player/CheckIfNicknameIsAvailableInUpdateUser?Nickname=${NickName}&UserID=${UserId}`,
  });
  return res.result;
}

export async function checkIfNicknameIsAvailable(NickName: string) {
  const res = await api.apiRequestWrapper<ApiResponse<any>>({
    method: 'get',
    url: `Player/CheckIfNicknameIsAvailable?Nickname=${NickName}`,
  });
  return res.result;
}

export async function checkIfNicknameIsAvailableForNewUser(NickName: string) {
  const res = await api.apiRequestWrapper<ApiResponse<any>>({
    method: 'get',
    url: `Player/CheckIfNicknameIsAvailableForNewUser?Nickname=${NickName}`,
  });
  return res.result;
}

export async function updatePlayer(PlayerRequest: string, PlayerID: number) {
  const res = await api.apiRequestWrapper<PlayerResult>({
    method: 'put',
    url: `Player/UpdatePlayer?PlayerID=${PlayerID}`,
    data: { PlayerRequest },
  });
  return res;
}
