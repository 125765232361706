import React, { useEffect } from 'react';
import { Box, HStack, Icon, Spacer, Stack, Text } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { FaEdit, FaPlayCircle, FaTicketAlt, FaUser } from 'react-icons/fa';
import { FiUser, FiBox, FiPlay, FiFile, FiCalendar, FiMail, FiUserCheck } from 'react-icons/fi';
import { useNavigate, useParams } from 'react-router-dom';
import { UserResult } from 'src/api/interfaces/user';
import {
  BookingStatusEnum,
  addUserToBooking,
  updateBookingStatus,
} from 'src/api/services/booking';
import { CalendarIcon } from '@chakra-ui/icons';
import dayjs from 'dayjs';

export const useAdminRegisterColumns = (
  isTicketFlow: boolean,
  isEventsFlow: boolean,
  bookingID?: string
) => {
  const columnHelper = createColumnHelper<UserResult>();

  const columns = [
    columnHelper.accessor('name', {
      cell: (info) => (
        <Stack direction="row" alignItems="center">
          <FiUser />

          <Text variant="text">{info.getValue()}</Text>
        </Stack>
      ),
      header: () => <Text>Nome</Text>,
    }),
    columnHelper.accessor('lastName', {
      cell: (info) => (
        <Stack direction="row" alignItems="center">
          <Text variant="text">{info.getValue()}</Text>
        </Stack>
      ),
      header: () => <Text>Sobrenome</Text>,
    }),
    columnHelper.accessor('nickName', {
      cell: (info) => (
        <Stack direction="row" alignItems="center">
          <FaUser />

          <Text variant="text">{info.getValue()}</Text>
        </Stack>
      ),
      header: () => <Text>Nickname</Text>,
    }),
    columnHelper.accessor('birthDate', {
      cell: (info) => (
        <Stack direction="row" alignItems="center">
          <FiCalendar />
          <Text variant="text">{dayjs(info.getValue()).format('DD/MM/YYYY')}</Text>
        </Stack>
      ),
      header: () => <Text>Data de nascimento</Text>,
    }),
    columnHelper.accessor('document', {
      cell: (info) => (
        <Stack direction="row" alignItems="center">
          <FiFile />
          <Text variant="text">{info.getValue()}</Text>
        </Stack>
      ),
      header: () => <Text>CPF</Text>,
    }),
    columnHelper.accessor('email', {
      cell: (info) => (
        <Stack direction="row" alignItems="center">
          <FiMail />

          <Text variant="text">{info.getValue()}</Text>
        </Stack>
      ),
      header: () => <Text>Email</Text>,
    })
  ];

  if (isTicketFlow || isEventsFlow) {
    const actionColumns = [
      columnHelper.display({
        id: 'actions',
        cell: (info) => {
          const navigate = useNavigate();
          const userID = info.row.original.userID;
          const { bookingID } = useParams();

          const handleContinue = async () => {
            if (bookingID) {
              const _booking = await addUserToBooking(bookingID, userID);
              const _status = await updateBookingStatus(
                bookingID,
                BookingStatusEnum.REALIZANDO_PAGAMENTO
              );
              isTicketFlow && navigate(`/admin/ingressos/resumo/${bookingID}`);
              isEventsFlow && navigate(`/admin/eventos/resumo/${bookingID}`)
              return;
            }
            throw new Error(
              'Não foi possível identificar sua reserva, tente novamente.'
            );
          };

          return (
            <HStack spacing={4} pl={6}>
              <Spacer borderRight="2px dashed #B0CA0A" />
              <Box
                justifyContent="center"
                as="button"
                _hover={{ color: 'green' }}
                onClick={handleContinue}
              >
                <Icon as={FaPlayCircle} w="20px" h="20px" />
                <Text>Selecionar</Text>
              </Box>
            </HStack>
          );
        },
      }),
    ];
    return [...columns, ...actionColumns];
  } else {
    const actionColumns = [
      columnHelper.display({
        id: 'actions',
        cell: (info) => {
          const navigate = useNavigate();
          const userID = info.row.original.userID;

          return (
            <HStack spacing={4} pl={6}>
              <Spacer borderRight="2px dashed #B0CA0A" />
              <HStack
                as="button"
                _hover={{ color: 'green' }}
                onClick={() => navigate(`/admin/cadastros/${userID}`)}
              >
                <Icon as={FaEdit} w="20px" h="20px" />
                <Text>Página do usuário</Text>
              </HStack>
            </HStack>
          );
        },
      }),
    ];
    return [...columns, ...actionColumns];
  }
};
