import React from 'react';
import { Text, OrderedList, ListItem, Stack } from '@chakra-ui/react';
import { useGetAllGames, useGetGameByID } from 'src/api/services/game';
import { RankingResult } from 'src/api/interfaces/game';

interface RankingViewProps {
  gameId: number;
}

export const RankingView = ({gameId}: RankingViewProps) => {
  const { data: games } = useGetGameByID(gameId);
  return (
    <OrderedList w="full">
      {games?.ranking.map((r) => (
        <ListItem
          w="full"
          color="white"
          fontWeight={'bold'}
          textTransform="uppercase"
          key={r.rankingID}
        >
          <Stack direction="row" w="full" justify="space-between">
            <Text variant="text" fontWeight={'bold'} textTransform="uppercase">
              {r.player.nickName}
            </Text>
            <Text variant="text" fontWeight={'bold'} textTransform="uppercase">
              {`${r.totalScore} PTS`}
            </Text>
          </Stack>
        </ListItem>
      ))}
    </OrderedList>
  );

};
