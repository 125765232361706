import React from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';
import { FormInput } from 'src/components/Form/Input';
import { FormSelect } from 'src/components/Form/Select';
import { FormInputPassword } from 'src/components/Form/Input/PasswordInput';
import { SearchIcon } from '@chakra-ui/icons';
import { Box, Button, Stack } from '@chakra-ui/react';
import Form from 'src/components/Form/Form';

export interface SearchRegistryFormValues {
  searchTerm: string;
}

interface SearchRegistryFormProps {
  form: UseFormReturn<SearchRegistryFormValues, any>;
}

export default function SearchRegistryForm({ form }: SearchRegistryFormProps) {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = form;

  const onSubmit = (data: any) => {
    return;
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction={['column', 'row']} w={'full'} spacing={6}>
        <FormInput
          rhfRegister={{
            ...register('searchTerm'),
          }}
          icon={SearchIcon}
          inputProps={{ placeholder: 'PESQUISAR' }}
        />
      </Stack>
    </Form>
  );
}
