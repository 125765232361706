import React, { useState } from 'react';
import { Box, Button, HStack, Icon, Stack, Text } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { UserResult } from 'src/api/interfaces/user';
import { getFilteredUsers, useGetFilteredUsers } from 'src/api/services/user';
import PageContainer from 'src/components/Container/PageContainer';
import { PaginatedTable } from 'src/components/Table/PaginatedTable';
import SearchRegistryForm, {
  SearchRegistryFormValues,
} from 'src/modules/Forms/Backoffice/SearchRegistry';
import { useAdminRegisterColumns } from './TableData';
import usePHToast from 'src/hooks/useToast';
import UserResultView from './UserResultView';
import { BsExclamationTriangle } from 'react-icons/bs';
import { addUserToBooking } from 'src/api/services/booking';

interface AdminRegistersProps {
  isTicketFlow?: boolean;
  isEventsFlow?: boolean
}

export default function AdminRegisters({
  isTicketFlow = false,
  isEventsFlow = false
}: AdminRegistersProps) {
  const { bookingID } = useParams();
  const toast = usePHToast();
  const navigate = useNavigate();
  const columns = useAdminRegisterColumns(isTicketFlow, isEventsFlow);

  const [userResult, setUserResult] = useState<UserResult[]>();
  const [userNotFound, setUserNotFound] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const form = useForm<SearchRegistryFormValues>();
  const { handleSubmit } = form;


  const handleSearch = async (data: SearchRegistryFormValues) => {
    try {
      setLoading(true);
      const req = await getFilteredUsers(data.searchTerm);
      setUserResult(req.users);
    } catch (e) {
      setUserResult(undefined);
      setUserNotFound(true);
    } finally {
      setLoading(false);
    }
  };

  // const handleAddUserToBooking = async (userID: string) => {
  //   try {
  //     setLoading(true);
  //     if (bookingID) {
  //       const req = await addUserToBooking(bookingID, userID);
  //       navigate(`/admin/ingressos/resumo/${bookingID}`);
  //     } else {
  //       toast({
  //         status: 'error',
  //         title: 'código da reserva inválido, tente novamente',
  //       });
  //     }
  //   } catch (e) {
  //     toast({ status: 'error', title: `${e}` });
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  return (
    <>
      <PageContainer restricted>
        <Box w="100%">
          <HStack mb={12}>
            <SearchRegistryForm form={form} />
            <Button
              type="submit"
              variant="pophausOutline"
              onClick={handleSubmit(handleSearch)}
              isLoading={loading}
            >
              OK
            </Button>
          </HStack>
          {userResult ? (
            <PaginatedTable data={userResult} columns={columns} hasSort />
          ) : (
            <Stack alignItems="center" w="100%">
              {userNotFound && (
                <>
                  <Icon
                    color="yellow"
                    as={BsExclamationTriangle}
                    w={24}
                    h={24}
                  />
                  <Text variant="text" fontSize={16} fontWeight="bold">
                    USUÁRIO NÃO ENCONTRADO
                  </Text>
                </>
              )}

              <Button
                onClick={() => navigate('/admin/cadastros/novo')}
                w="200px"
                mt="40px"
                variant="pophausOutline"
                isLoading={loading}
              >
                Criar cadastro
              </Button>
            </Stack>
          )}

          {/* <PaginatedTable data={tableData} columns={columns} /> */}
        </Box>
      </PageContainer>
    </>
  );
}
