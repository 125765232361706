import { Box, Grid, GridItem, GridProps, Stack } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

interface SectionDividerProps {
  isSmallSquare?: boolean;
  squareColor?: string;
  children?: ReactNode | ReactNode[];
  spaceColor?: string
}

export default function SectionDivider({
  isSmallSquare,
  squareColor,
  spaceColor,
  ...props
}: SectionDividerProps & GridProps) {
  const big = true;
  return (
    <Grid bg={spaceColor} templateColumns="repeat(5, 1fr)" w="full" {...props} zIndex={1}>
      {!isSmallSquare ? (
        <GridItem zIndex={1} w="110px" h="90" bg={squareColor} />
      ) : (
        <>
          <GridItem zIndex={1} />
          <GridItem zIndex={1} w="70px" h="70px" bg={squareColor} />
        </>
      )}
    </Grid>
  );
}
