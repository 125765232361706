import React, { useState } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Stack,
  StackProps,
  Text,
} from '@chakra-ui/react';
import { FieldArrayWithId, UseFormReturn, useForm } from 'react-hook-form';

import { PlayerDataValues } from '..';

export interface PlayerDataFieldArrayValues {
  playerFieldArray: PlayerDataValues[];
}

export interface PlayerDataProps {
  playerNum: number;
  index: number;
  form: UseFormReturn<PlayerDataFieldArrayValues, any>;
  colorScheme?: 'green' | 'yellow';
}

export default function PlayerDataFieldArrayForm({
  playerNum,
  index,
  form,
  colorScheme = 'green',
  ...props
}: PlayerDataProps & StackProps) {
  const {
    register,
    setValue,
    control,
    formState: { errors },
  } = form;

  return (
    <Stack w="100%" direction={['column', 'row']} alignItems="end" {...props}>
      <Text
        color={colorScheme}
        fontSize={'4xl'}
        fontWeight="bold"
        lineHeight={'initial'}
        alignSelf={['flex-start', 'flex-end']}
      >
        {playerNum}
      </Text>
      <FormControl>
        <FormLabel color="white">USERNAME</FormLabel>
        <Input
          bg="white"
          disabled
          value={form.getValues(`playerFieldArray.${index}.nickName`)}
        />
      </FormControl>
      <FormControl>
        <FormLabel color="white">NOME</FormLabel>
        <Input
          bg="white"
          disabled
          value={form.getValues(`playerFieldArray.${index}.name`)}
        />
      </FormControl>
      <FormControl>
        <FormLabel color="white">SOBRENOME</FormLabel>
        <Input
          bg="white"
          disabled
          value={form.getValues(`playerFieldArray.${index}.lastName`)}
        />
      </FormControl>
      <FormControl>
        <FormLabel color="white">CPF</FormLabel>
        <Input
          bg="white"
          disabled
          value={form.getValues(`playerFieldArray.${index}.document`)}
        />
      </FormControl>
      <FormControl>
        <FormLabel color="white">DATA NASC</FormLabel>
        <Input
          bg="white"
          disabled
          value={form.getValues(`playerFieldArray.${index}.birthDateFormatted`)}
        />
      </FormControl>
    </Stack>
  );
}
