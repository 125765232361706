import { CalendarIcon, TimeIcon } from '@chakra-ui/icons';
import {
  AbsoluteCenter,
  Box,
  Icon,
  Link,
  Text,
  useDisclosure,
  Center,
} from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';
import React from 'react';
import { BsBox } from 'react-icons/bs';
import { FaTicketAlt } from 'react-icons/fa';
import { FiUsers, FiFileText, FiMapPin, FiUser, FiVideo } from 'react-icons/fi';
import { BookingPlayerResult } from 'src/api/interfaces/booking';
import DashedContainer from 'src/components/Container/DashedContainer';
import { EditPlayerModal } from 'src/components/Modal/ModalVariants/EditPlayersModal';
import { ReceiptModal } from 'src/components/Modal/ModalVariants/ReceiptModal';
import { ViewPlayersModal } from 'src/components/Modal/ModalVariants/ViewPlayersModal';
import { UserAttachmentsResult } from '../../../api/interfaces/match/index';
import { equal } from 'assert';
import dayjs from 'dayjs';

export interface InactiveTicketProps {
  bookingID: string;
  date: string;
  time: string;
  name: string | undefined;
  room: string;
  unit: string | undefined;
  tickets: number;
  medias: UserAttachmentsResult[] | undefined;
  status: number;
}

export default function InactiveTicketCard({
  date,
  time,
  name,
  room,
  unit,
  tickets,
  bookingID,
  medias,
  status,
}: InactiveTicketProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isPlayersOpen,
    onClose: onPlayersClose,
    onOpen: onPlayersOpen,
  } = useDisclosure();

  return (
    <>
      <DashedContainer
        mb={2}
        color="white"
        flexDir="row"
        justifyContent="space-around"
        borderColor="white"
      >
        <Box display="flex" gap="5px" alignItems="center">
          <CalendarIcon />
          <Text>{date}</Text>
        </Box>
        <Box display="flex" gap="5px" alignItems="center">
          <TimeIcon />
          <Text>{time}</Text>
        </Box>
        <Box display="flex" gap="5px" alignItems="center">
          <Icon as={FiUser} />
          <Text>{name}</Text>
        </Box>
        <Box display="flex" gap="5px" alignItems="center">
          <Icon as={BsBox} />
          <Text>{room}</Text>
        </Box>
        <Box display="flex" gap="5px" alignItems="center">
          <Icon as={FiMapPin} />
          <Text>{unit}</Text>
        </Box>
        <Box display="flex" gap="5px" alignItems="center">
          <Icon as={FaTicketAlt} />
          <Text>{tickets}</Text>
        </Box>

        <Box h="50px" border="2px dashed white" />

        <Box
          justifyContent="center"
          as="button"
          _hover={{ color: '#B0CA0A' }}
          onClick={onPlayersOpen}
        >
          <Icon as={FiUsers} />
          <Text fontSize="12px">Jogadores</Text>
        </Box>

        {medias?.map((m, i) =>
          dayjs(m.date).format('DD/MM/YYYY') ===
            dayjs(date).format('DD/MM/YYYY') && status == 7 ? (
            <Box
              key={i}
              as={ReactRouterLink}
              to={`/jogador/midias/${i}`}
              _hover={{ color: '#B0CA0A' }}
              textAlign={'center'}
            >
              <Icon as={FiVideo} />
              <Text fontSize="12px">Mídias</Text>
            </Box>
          ) : null
        )}

        {/* <Box
          justifyContent="center"
          as="button"
          _hover={{ color: '#B0CA0A' }}
          onClick={onOpen}
        >
          <Icon as={FiFileText} />
          <Text fontSize="12px">Recibo</Text>
        </Box> */}
      </DashedContainer>
      <EditPlayerModal
        bookingID={bookingID}
        isOpen={isPlayersOpen}
        onClose={onPlayersClose}
        view
      />
      <ReceiptModal isOpen={isOpen} onClose={onClose} bookingID={bookingID} />
    </>
  );
}
