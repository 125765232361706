import { CircularProgress, Image, Stack, Text, Tooltip } from '@chakra-ui/react';
import dayjs from 'dayjs';
import React from 'react';
import ReactPlayer from 'react-player';

interface MediaItemProps {
  previewUrl?: string;
  date: string;
  numberOfItems?: number;
  bookingId: string;
}

export const MediaItem = ({
  previewUrl,
  date,
  bookingId,
  numberOfItems,
}: MediaItemProps) => {
  return (
    <Stack direction="column" gap={0}>
      <ReactPlayer
        fallbackSrc={'https://via.placeholder.com/125x140'}
        url={previewUrl}
        alt="preview"
        width={'125px'}
        height={'140px'}
      />
      <Text color="white" fontSize="xs" fontWeight={'bold'}>{`Dia ${date}`}</Text>
      {<Text
        color="white"
        fontSize="xs"
        fontWeight={'medium'}
      >{`${numberOfItems} itens`}</Text>}

      <Tooltip label={bookingId}>
        <Text
          color="white"
          fontSize="xs"
          fontWeight={'medium'}
        >{`id ${bookingId.split('-')[0]}`}</Text>
      </Tooltip>

    </Stack>
  );
};
