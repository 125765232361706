import React, { useState } from 'react';
import PageContainer from 'src/components/Container/PageContainer';
import { Button, Checkbox, Stack, Text } from '@chakra-ui/react';
import CreateAccountForm, {
  ChangePasswordFormValues,
} from 'src/modules/Forms/ChangePasswordForm';
import { useForm } from 'react-hook-form';
import { clearNonDigits } from 'src/utils/clearNonDigits';
import { resetPassword, updateUser } from 'src/api/services/user';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { UserActions } from 'src/redux/user';
import usePHToast from 'src/hooks/useToast';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import ChangePasswordForm from 'src/modules/Forms/ChangePasswordForm';
import { Loading } from 'src/components/Loading';

export default function PlayerDataPassword() {
  const dispatch = useAppDispatch();
  const toast = usePHToast();
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.user);

  const [loading, setLoading] = useState<boolean>(false);
  const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
  const [acceptPromo, setAcceptPromo] = useState<boolean>(false);

  const updateUserPasswordForm = useForm<ChangePasswordFormValues>();
  const { handleSubmit } = updateUserPasswordForm;

  const onSubmit = async (data: ChangePasswordFormValues) => {
    try {
      setLoading(true);

      if (user) {
        const res = await resetPassword(data);
      }

      toast({ status: 'success', title: 'Senha alterada com sucesso' });
      navigate('/jogador/dados');
    } catch (e: any) {
      toast({ status: 'error', title: e?.message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageContainer restricted>
      <Stack direction={'column'} spacing={6} w="full" pb={4}>
        <Text variant="link" onClick={() => navigate(-1)}>
          voltar
        </Text>
        <Text variant="title">Minha Senha</Text>
        {loading ? (
          <Loading />
        ) : (
          <ChangePasswordForm rhfForm={updateUserPasswordForm} />
        )}
      </Stack>
      {!loading && (
        <Button
          variant="pophausOutline"
          w="50%"
          type="submit"
          isLoading={loading}
          onClick={handleSubmit(onSubmit)}
        >
          Alterar senha
        </Button>
      )}
    </PageContainer>
  );
}
