import React from 'react';
import { Wrap, WrapItem, Image, Tooltip, Text } from '@chakra-ui/react';
import { useGetAllGames } from 'src/api/services/game';
import { GameResult } from 'src/api/interfaces/game';

interface GameSelectProps {
  gamesList: GameResult[] | undefined;
  selectGame: (id: number) => void;
}

export const GameSelect = ({ gamesList, selectGame }: GameSelectProps) => {
  return (
    <Wrap spacingX={6} spacingY={4} align="center">
      {gamesList?.map((g) => (

        <WrapItem
          key={g.gameID}
          onClick={() => selectGame(g.gameID)}
          border={'2px'}
          padding={2}
          cursor='pointer'
          borderColor={'transparent'}
          color='white'

          transition='ease-in-out 0.2s'
          _hover={{ borderColor: 'green', color: 'green' }}
        >
          <Text variant='text' color='inherit' fontWeight='bold'>{g.name}</Text>
        </WrapItem>

      ))}
    </Wrap>
  );
};
