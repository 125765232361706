import React, { useEffect } from 'react';
import { UserActions } from 'src/redux/user';
import { useAppDispatch } from 'src/redux/hooks';
import { useNavigate } from 'react-router-dom';

export default function Logout() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(UserActions.logout());
    navigate('/');
  }, []);

  return <></>;
}
