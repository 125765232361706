import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface TicketFormValues {
  date: string;
  businessUnitID: string;
  numberOfPlayers: string;
}

interface BookingInformation {
  bookingId?: string;
  timer?: number;
  paymentTimer?: number;
  idempotencyKey?: string;
  bookingTypeID?: number | undefined;
  bookingStatusID?: number | undefined;
}

interface BookingState {
  search: TicketFormValues;
  booking: BookingInformation;
  eventFormID: number | undefined;
}

const initialBookingState: BookingState = {
  search: {
    date: '',
    businessUnitID: '',
    numberOfPlayers: '',
  },
  booking: {
    bookingId: undefined,
    paymentTimer: 300,
    timer: 120,
    idempotencyKey: undefined,
    bookingTypeID: undefined,
    bookingStatusID: undefined,
  },
  eventFormID: undefined,
};

const bookingSlice = createSlice({
  name: 'booking',
  initialState: initialBookingState,
  reducers: {
    reset: () => initialBookingState,
    resetSearch: (state) => {
      state.search = initialBookingState.search;
    },
    resetBooking: (state) => {
      state.booking = initialBookingState.booking;
    },
    setSearch: (state, action: PayloadAction<TicketFormValues>) => {
      state.search = { ...state.search, ...action.payload };
    },
    setBooking: (state, action: PayloadAction<BookingInformation>) => {
      state.booking = { ...state.booking, ...action.payload };
    },
    setBookingId: (state, action: PayloadAction<string>) => {
      state.booking.bookingId = action.payload;
    },
    setBookingTypeID: (state, action: PayloadAction<number>) => {
      state.booking.bookingTypeID = action.payload;
    },
    setBookingStatusID: (state, action: PayloadAction<number>) => {
      state.booking.bookingStatusID = action.payload;
    },
    setTimer: (state, action: PayloadAction<number>) => {
      state.booking.timer = action.payload;
    },
    setPaymentTimer: (state, action: PayloadAction<number>) => {
      state.booking.paymentTimer = action.payload;
    },
    setIdempotencyKey: (state, action: PayloadAction<string>) => {
      state.booking.idempotencyKey = action.payload;
    },
    setEventFormID: (state, action: PayloadAction<number>) => {
      state.eventFormID = action.payload;
    },
    resetEventFormID: (state) => {
      state.eventFormID = initialBookingState.eventFormID;
    },
  },
});

export const BookingActions = bookingSlice.actions;
export const bookingReducer = bookingSlice.reducer;
