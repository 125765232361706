import { BookingResult } from 'src/api/interfaces/booking';
import { ApiRepository, ApiResponse, useFetch } from '../..';
import { MediaResult } from 'src/api/interfaces/medias';

const api = new ApiRepository();

export function useGetMediasByBookingID(bookingId?: string) {
  const args = {
    method: 'get',
    url: `Midia/GetMidiasByBookingID?BookingID=${bookingId}`,
  };

  const { data, error, isLoading } = useFetch<ApiResponse<MediaResult[]>>(
    api,
    args,
    { revalidateOnFocus: false }
  );
  return { data: data?.result, error, isLoading };
}

export function useGetUserBookingWithMedias(UserID?: string) {
  const args = {
    method: 'get',
    url: `Booking/GetUserBookingsActiveMidias?UserID=${UserID}`,
  };

  const { data, error, isLoading, mutate } = useFetch<
    ApiResponse<BookingResult[]>
  >(api, args, { revalidateOnFocus: false });
  return { data: data?.result, error, isLoading, mutate };
}

export function useGetMediaById(mediaID?: string) {
  const args = {
    method: 'get',
    url: `Midia/${mediaID}`,
  };

  const { data, error, isLoading, mutate } = useFetch<ApiResponse<MediaResult>>(
    api,
    args,
    { revalidateOnFocus: false }
  );
  return { data: data?.result, error, isLoading, mutate };
}
