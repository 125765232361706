import {
  Box,
  Stack,
  Flex,
  Square,
  Grid,
  GridItem,
  StackProps,
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';

interface SectionProps {
  reverse?: boolean;
  children: ReactNode | ReactNode[];
}

export default function Section({
  reverse,
  children,
  ...props
}: SectionProps & StackProps) {
  return (
    <Stack
      direction={reverse ? ['column', 'row-reverse'] : ['column', 'row']}
      w="full"
      px={{ base: 4, md: 40 }}
      py={8}
      align={'center'}
      justify={'space-between'}
      {...props}
    >
      {children}
    </Stack>
  );
}
