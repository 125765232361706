import React, { useState } from 'react';
import { Button, Stack, useDisclosure } from '@chakra-ui/react';
import { UseFormReturn, useForm } from 'react-hook-form';
import { AcceptedCards } from 'src/components/AcceptedCards';
import Form from 'src/components/Form/Form';
import { FormInput } from 'src/components/Form/Input';
import { FormSelect } from 'src/components/Form/Select';
import { useAppSelector } from 'src/redux/hooks';
import { useGetBookingByID } from 'src/api/services/booking';
import {
  LocalPayment,
  chargeCardOneShoot,
  useGetBookingInstallments,
} from 'src/api/services/charge';
import {
  ChargeCardOneShootRequest,
  LocalPaymentRequest,
} from 'src/api/interfaces/charge';
import usePHToast from 'src/hooks/useToast';

interface CardFormProps {
  //paymentMethod: 'local';
  cardForm: UseFormReturn<PaymentFormValues, any>;
  idempotencyKey?: string;
  bookingID?: string;
  onOpen: () => void;
  onErrorOpen: () => void;
  handleIdempotencyKey: (key?: string) => void;
  triggerPurchaseEvent: () => void;
}

export interface PaymentFormValues {
  paymentMethodID: number;
  installments: number;
}

export default function LocalPaymentForm({
  //paymentMethod,
  idempotencyKey,
  bookingID,
  onOpen,
  onErrorOpen,
  handleIdempotencyKey,
  triggerPurchaseEvent,
}: CardFormProps) {
  const toast = usePHToast();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<PaymentFormValues>();

  const { user } = useAppSelector((state) => state.user);
  const { data: bookingData } = useGetBookingByID(bookingID);

  const [loading, setLoading] = useState(false);

  const { data: installmentsOptions, isLoading } = useGetBookingInstallments(
    bookingID,
    4
  );

  const onSubmit = async (data: PaymentFormValues) => {
    try {
      const { installments, paymentMethodID } = data;
      if (!user) throw new Error('É necessário estar logado para comprar.');
      if (!bookingData)
        throw new Error('É necessário ter uma reserva comprar.');
      setLoading(true);

      const request: LocalPaymentRequest = {
        userId: user.userID,
        bookingId: bookingData.bookingID,
        paymentMethodID: paymentMethodID,
        installments: installments,
      };
      const response = await LocalPayment(request);
      if (response.result.sucess) {
        triggerPurchaseEvent();
        onOpen();
      } else {
        handleIdempotencyKey(response.result.idempotencyKey);
        throw new Error(response.errors);
      }
    } catch (e: any) {
      onErrorOpen();
      toast({ status: 'error', title: e?.message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction={'column'} spacing={4} px={4} py={4}>
        <Stack direction={'row'}>
          <FormSelect
            rhfRegister={{
              ...register('paymentMethodID', {
                required: 'Campo obrigatório',
              }),
            }}
            label={'FORMA DE PAGAMENTO'}
            errorState={errors.installments}
            selectProps={{ placeholder: 'SELECIONE A FORMA DE PAGAMENTO' }}
          >
            <option value="4">Débito</option>
            <option value="5">Crédito</option>
            <option value="6">Pix</option>
            <option value="10">FunPass</option>
            <option value="7">Cortesia</option>
            <option value="8">Através do Atendimento</option>
          </FormSelect>

          <FormSelect
            rhfRegister={{
              ...register('installments', {
                required: 'Campo obrigatório',
              }),
            }}
            label={'PARCELAS'}
            errorState={errors.installments}
            selectProps={{ placeholder: 'SELECIONE AS PARCELAS' }}
          >
            {installmentsOptions?.installments.map((o, i) => (
              <option key={i} value={o.installmentNumber}>
                {o.installmentNumber <= 5
                  ? `${
                      o.installmentNumber
                    }x ${o.installmentTotal.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })} (Sem Juros)`
                  : `${
                      o.installmentNumber
                    }x ${o.installmentTotal.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}`}
              </option>
            ))}
          </FormSelect>
        </Stack>
        <Stack
          w="full"
          direction={['column', 'row']}
          align="center"
          justify="space-between"
          py={4}
          spacing={4}
        >
          <AcceptedCards />
          <Button
            w={{ base: 'full', md: 'unset' }}
            variant="pophausOutline"
            type="submit"
            isLoading={loading}
          >
            FINALIZAR COMPRA
          </Button>
        </Stack>
      </Stack>
    </Form>
  );
}
