import React, { useEffect, useState } from 'react'
import EmptyModal from '../EmptyModal'
import { Box, Checkbox, FormLabel, HStack, Input, Select, Textarea, VStack } from '@chakra-ui/react'
import { useGetFaqById } from 'src/api/services/faq'
import { Loading } from 'src/components/Loading'

export interface UpdateModalProps {
  question?: string
  answer?: string
  order?: number
  isActive?: boolean
  isOpen: boolean
  onClose: () => void
  handleClick: (q: string, a: string, o?: number, isActive?: boolean) => void
}

export default function UpdateFaqModal({ isOpen, onClose, question = '', answer = '', handleClick, order, isActive }: UpdateModalProps) {
  const [q, setQ] = useState(question)
  const [a, setA] = useState(answer)
  const [o, setO] = useState(order)
  const [isFaqActive, setFaqIsActive] = useState(isActive)

  return (
    <EmptyModal rightButtonText='Confirmar' rightButtonClick={() => handleClick(q, a, o, isFaqActive)} isOpen={isOpen} onClose={onClose}>
      <VStack>
        <HStack w='full'>
          <FormLabel color='white'>
            Ordem
          </FormLabel>
          <Box>
            <Input color='white' type='number' value={o} onChange={(e: any) => setO(e.target.value)} />
          </Box>
          <FormLabel color='white'>
            Ativado

          </FormLabel>
          <Checkbox isChecked={isFaqActive} onChange={(e: any) => setFaqIsActive(e.target.checked)} />
        </HStack>
        <FormLabel w='full' color='white'>
          Pergunta
          <Textarea value={q} onChange={(e) => setQ(e.target.value)} _focus={{ borderColor: 'green' }} />
        </FormLabel>

        <FormLabel w='full' color='white'>
          Resposta
          <Textarea value={a} onChange={(e) => setA(e.target.value)} _focus={{ borderColor: 'green' }} />
        </FormLabel>
      </VStack>
    </EmptyModal >
  )
}