import React, { useState } from 'react';
import PageContainer from 'src/components/Container/PageContainer';
import { Button, HStack, Stack, Text } from '@chakra-ui/react';
import CreateCouponForm, {
  CreateCouponFormValues,
} from 'src/modules/Forms/Backoffice/CreateCouponForm';
import { useForm } from 'react-hook-form';
import {
  deleteCoupon,
  editCouponById,
  useGetCouponByID,
} from 'src/api/services/coupon';
import { CouponRequest } from 'src/api/interfaces/coupon';
import dayjs from 'dayjs';
import usePHToast from 'src/hooks/useToast';
import { useNavigate, useParams } from 'react-router-dom';

export default function EditCouponPage() {
  const { couponID } = useParams<{ couponID: string }>();
  const [loading, setLoading] = useState(false);
  const { data, isLoading, mutate } = useGetCouponByID(couponID);

  const editCoupon = useForm<CreateCouponFormValues>({
    values: {
      isActive: data?.isActive ?? false,
      applicableUntil: data?.applicableUntil ?? 0,
      applicationEndDate: data?.applicationEndDate ?? 0,
      applicationEndTime: data?.applicationEndTime ?? '',
      applicationStartDate: data?.applicationStartDate ?? 0,
      applicationStartTime: data?.applicationStartTime ?? '',
      businessUnitID: data?.businessUnitID ?? 0,
      couponTypeID: data?.couponTypeID ?? 0,
      description: data?.description ?? '',
      lifeSpanEndDate:
        dayjs(data?.lifeSpanEndDate, 'DD-MM-YYYY').format('YYYY-MM-DD') ?? '',
      lifeSpanStartDate:
        dayjs(data?.lifeSpanStartDate, 'DD-MM-YYYY').format('YYYY-MM-DD') ?? '',
      maximumValue: data?.maximumValue ?? '',
      singleUse: data?.singleUse ?? false,
      ticketEndDate: data?.ticketEndDate ?? 0,
      ticketEndTime: data?.ticketEndTime ?? '',
      ticketStartDate: data?.ticketStartDate ?? 0,
      ticketStartTime: data?.ticketStartTime ?? '',
      usageLimit: data?.usageLimit ?? 0,
      value: data?.value ?? 0,
      intDurationsOnly: data?.intDurationsOnly ?? false,
      bookingMinimumValue: data?.bookingMinimumValue ?? 0,
      singleBookingType: data?.singleBookingType ?? true,
      roomBookingType: data?.roomBookingType ?? true,
      ticketCounts: data?.ticketCounts ?? '',
    },
  });
  const { handleSubmit } = editCoupon;
  const navigate = useNavigate();
  const toast = usePHToast();
  const [submitControl, setSubmitControl] = useState(false);

  const onSubmit = async (data: CreateCouponFormValues) => {
    try {
      setLoading(true);
      if (couponID) {
        const reqData: CouponRequest = {
          ...data,
          lifeSpanStartDate: dayjs(data.lifeSpanStartDate).format('DD/MM/YYYY'),
          lifeSpanEndDate: dayjs(data.lifeSpanEndDate).format('DD/MM/YYYY'),
        };
        const res = await editCouponById(couponID, reqData);
        navigate('/admin/cupons');
        toast({ title: 'Cupom editado com sucesso', status: 'success' });
      }
    } catch (err: any) {
      toast({ status: 'error', title: err?.message });
    } finally {
      setLoading(false);
      setSubmitControl(!submitControl);
    }
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      if (couponID) {
        const res = await deleteCoupon(couponID);
        navigate('/admin/cupons');
        toast({ title: 'Cupom deletado com sucesso', status: 'success' });
      }
    } catch (err: any) {
      toast({ status: 'error', title: err?.message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageContainer restricted>
      <Text alignSelf="start" variant="title">
        Editar cupom
      </Text>
      <CreateCouponForm
        isEdit
        maxValue={data?.maximumValue}
        couponTypeID={data?.couponTypeID}
        rhfForm={editCoupon}
        submitControl={submitControl}
        loading={isLoading}
      />
      <HStack w={'full'}>
        <Button
          w="full"
          variant={'pophausOutline'}
          colorScheme="popTransparent"
          isLoading={loading}
          onClick={() => navigate('/admin/cupons')}
        >
          Cancelar
        </Button>
        <Button
          w="full"
          variant={'pophausOutline'}
          colorScheme="popTransparent"
          isLoading={loading}
          onClick={handleDelete}
        >
          Deletar
        </Button>
        <Button
          w="full"
          variant={'pophausOutline'}
          type="submit"
          onClick={handleSubmit(onSubmit)}
          isLoading={loading}
        >
          Salvar
        </Button>
      </HStack>
    </PageContainer>
  );
}
