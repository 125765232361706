import React, { useCallback, useEffect, useState } from 'react';
import TikTokPixel from 'tiktok-pixel';
import PageContainer from 'src/components/Container/PageContainer';
import { Button, Stack, Text, VStack, useDisclosure } from '@chakra-ui/react';
import DashedContainer from 'src/components/Container/DashedContainer';
import CardForm, { CardFormValues } from 'src/modules/Forms/CardForm';
import PixForm from 'src/modules/Forms/PixForm';
import { SuccessModal } from 'src/components/Modal/ModalVariants/SuccessModal';
import { ErrorModal } from 'src/components/Modal/ModalVariants/ErrorModal';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from 'src/redux/hooks';
import { BookingActions } from 'src/redux/booking';
import { BookingStatusEnum, useGetBookingByID } from 'src/api/services/booking';
import { useForm } from 'react-hook-form';
import * as fbq from '../../../../utils/pixels/fpixel';
import LocalPaymentForm from 'src/modules/Forms/Backoffice/LocalPaymentForm';
import { PaymentFormValues } from 'src/modules/Forms/Backoffice/LocalPaymentForm';
import { useIsFirstRender } from 'src/hooks/useIsFirstRender';

export interface PixData {
  qrCodeImageUrl: string;
  qrCodeData: string;
}

export interface AdminPaymentsProps {
  isRebuy?: boolean;
}

export default function AdminPayment({ isRebuy = false }: AdminPaymentsProps) {
  const { bookingID } = useParams<{ bookingID: string }>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isFirstRender = useIsFirstRender();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();
  const cardForm = useForm<CardFormValues>();
  const paymentForm = useForm<PaymentFormValues>();

  const [paymentMethod, setPaymentMethod] = useState<
    'credit' | 'debit' | 'pix' | 'local'
  >('credit');
  const [qrCode, setQrCode] = useState<PixData | undefined>(undefined);
  const [idempotencyKey, setIdempotencyKey] = useState<string | undefined>(
    undefined
  );

  const { data: booking } = useGetBookingByID(bookingID);

  const handlePixGenerated = (data?: PixData) => {
    setQrCode(data);
  };

  const handleIdempotencyKey = (key?: string) => {
    setIdempotencyKey(key);
  };

  const handleSuccess = () => {
    dispatch(BookingActions.resetBooking());
    onClose();
    navigate('/');
  };

  const triggerPurchaseEvent = () => {
    if (isRebuy || !booking || process.env.react_app_env === 'dev') return;

    fbq.purchaseEvent(booking.total, 'BRL');
    window.gtag('event', 'purchase', {
      value: booking.total,
      currency: 'BRL',
    });
    TikTokPixel.track('CompletePayment', {
      value: booking.total,
      currency: 'BRL',
    });
  };

  const handleStatusChange = useCallback(() => {
    if (booking?.bookingStatusID === BookingStatusEnum.CONFIRMADA) {
      triggerPurchaseEvent();
      if (!isOpen) onOpen();
      dispatch(BookingActions.resetBooking());
    }
  }, []);

  useEffect(() => {
    handleStatusChange();
  }, [booking?.bookingStatusID]);

  useEffect(() => {
    if (isFirstRender && booking) {
      fbq.event('AddPaymentInfo');
      TikTokPixel.track('AddPaymentInfo', {});
    }
  }, []);

  return (
    <PageContainer restricted>
      <VStack w="full" spacing={2}>
        <Text variant="title">FORMAS DE PAGAMENTO</Text>
        <DashedContainer justify="center">
          {!qrCode && (
            <Stack
              direction={['column', 'row']}
              w="full"
              spacing={2}
              align="flex-start"
            >
              <Button
                variant="pophausOutline"
                bg={paymentMethod === 'credit' ? 'white' : undefined}
                w="full"
                onClick={() => setPaymentMethod('credit')}
              >
                cartão de crédito
              </Button>
              {/* <Button
                variant="pophausOutline"
                bg={paymentMethod === 'debit' ? 'white' : undefined}
                w="full"
                onClick={() => setPaymentMethod('debit')}
              >
                cartão de débito
              </Button> */}
              <Button
                variant="pophausOutline"
                bg={paymentMethod === 'pix' ? 'white' : undefined}
                w="full"
                onClick={() => setPaymentMethod('pix')}
              >
                pix
              </Button>
              <Button
                variant="pophausOutline"
                bg={paymentMethod === 'local' ? 'white' : undefined}
                w="full"
                onClick={() => setPaymentMethod('local')}
              >
                pagamento offline
              </Button>
            </Stack>
          )}
          {['credit', 'debit'].includes(paymentMethod) && (
            <CardForm
              cardForm={cardForm}
              paymentMethod={paymentMethod}
              idempotencyKey={idempotencyKey}
              bookingID={bookingID}
              onOpen={onOpen}
              onErrorOpen={onErrorOpen}
              handleIdempotencyKey={handleIdempotencyKey}
              triggerPurchaseEvent={triggerPurchaseEvent}
            />
          )}
          {['pix'].includes(paymentMethod) && (
            <PixForm
              qrCode={qrCode}
              idempotencyKey={idempotencyKey}
              bookingID={bookingID}
              handleIdempotencyKey={handleIdempotencyKey}
              handlePixGenerated={handlePixGenerated}
              onErrorOpen={onErrorOpen}
            />
          )}
          {['local'].includes(paymentMethod) && (
            <LocalPaymentForm
              cardForm={paymentForm}
              //paymentMethod={paymentMethod}
              idempotencyKey={idempotencyKey}
              bookingID={bookingID}
              onOpen={onOpen}
              onErrorOpen={onErrorOpen}
              handleIdempotencyKey={handleIdempotencyKey}
              triggerPurchaseEvent={triggerPurchaseEvent}
            />
          )}
        </DashedContainer>
      </VStack>

      <SuccessModal
        isOpen={isOpen}
        onClose={onClose}
        handleMainClick={handleSuccess}
      />
      <ErrorModal
        isOpen={isErrorOpen}
        onClose={onErrorClose}
        handleMainClick={onErrorClose}
      />
    </PageContainer>
  );
}
