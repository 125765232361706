import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Img,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import Logo from '../../../../assets/Logo-horizontal-black.png';
import { useGetBookingByID } from 'src/api/services/booking';
import { useGetReceiptByBookingID } from 'src/api/services/receipt';
import { Loading } from 'src/components/Loading';
import dayjs from 'dayjs';

interface ReceiptModalProps {
  bookingID: string;
  isOpen: boolean;
  onClose: () => void;
}

export function ReceiptModal({
  bookingID,
  isOpen,
  onClose,
}: ReceiptModalProps) {
  const { data, isLoading, mutate } = useGetReceiptByBookingID(
    isOpen ? bookingID : undefined
  );
  const [month, setMonth] = useState('');
  const [day, setDay] = useState('');
  const [year, setYear] = useState('');
  const [time, setTime] = useState('');

  useEffect(() => {
    if (data) {
      const fullDate = data.transactionDetails[0].date;
      const monthIndex = Number(fullDate.slice(5, 7));
      const monthDetail = [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ][monthIndex - 1];
      setDay(fullDate.slice(8, 10));
      setMonth(monthDetail);
      setYear(fullDate.slice(0, 4));
      setTime(fullDate.slice(11, 19));
    }
  }, [data]);

  return (
    <Modal isCentered size={'3xl'} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <Flex direction="column" p="20px">
          <ModalCloseButton
            size="sm"
            position="unset"
            alignSelf={'flex-end'}
            color="green"
            border={'2px'}
            borderRadius={0}
            borderColor="green"
          />
          <ModalBody>
            {isLoading ? (
              <Loading />
            ) : (
              <>
                <Flex w="100%" justifyContent="right">
                  <Img src={Logo} />
                </Flex>
                <Text fontWeight="bold">Seu recibo do Arcadehaus</Text>

                <Text mb="20px" fontSize="12px" color="#737373">
                  Identificação do Recibo: {data?.bookingID}
                </Text>

                <Flex
                  mb="20px"
                  color="#737373"
                  fontSize="12px"
                  direction="row"
                  gap="10px"
                >
                  <Flex
                    direction="column"
                    flex={1 / 2}
                    p="20px"
                    gap="10px"
                    border="2px solid #B0CA0A"
                  >
                    <Text fontWeight="bold" mb="10px">
                      {data?.businessUnit}
                    </Text>
                    <Text>{data?.tickets} jogadores</Text>
                    <Stack py="10px" borderY="1px solid #B0CA0A">
                      <Text>{data?.durationFormatted}</Text>
                      <Text>{data?.room}</Text>
                    </Stack>
                    <Link href='https://drive.google.com/file/d/1ugyaPogRkYSVrybz-CcDklyZfmndynI2/view?usp=sharing' target='_blank'>
                      <Text color='#00AEB3' variant='link'>Política de reagendamento e reembolso</Text>
                    </Link>
                    {/* <Text>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Aliquam sit amet ligula nec tellus malesuada luctus eu
                      eget felis.
                    </Text> */}
                  </Flex>
                  <Flex flex={1 / 2} gap="10px" direction="column">
                    <Flex
                      flex={1 / 2}
                      direction="column"
                      p="20px"
                      border="2px solid #DE1A19"
                      fontWeight="bold"
                    >
                      <Text mb="20px">Detalhamento do preço</Text>
                      <Flex>
                        <Text>Valor total</Text>
                        <Spacer />
                        <Text>R$ {data?.total}</Text>
                      </Flex>
                    </Flex>
                    <Flex
                      direction="column"
                      p="20px"
                      border="2px solid #00AEB3"
                      color="#737373"
                      fontSize="12px"
                      fontWeight="bold"
                      flex={1 / 2}
                    >
                      <Text mb="20px">Pagamento</Text>
                      <Flex w="100%">
                        <Stack flex={1 / 3} w="100%">
                          <Text>{data?.transactionDetails[0].description}</Text>
                          <Text fontSize="8px">
                            {day} de {month} de {year} às {dayjs(time).subtract(3, 'hour').toString()} BRT
                          </Text>
                        </Stack>
                        <Spacer flex={1 / 3} />
                        <Text flex={1 / 3}>
                          R$ {data?.transactionDetails[0].value}
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
                <Text fontSize="12px" fontWeight="bold" color="#737373">
                  Dúvidas?
                </Text>
                <Stack color="#737373" fontSize="10px" direction="row">
                  <Text>Visite a</Text>
                  <Link href='https://www.arcadehaus.com.br/FAQ' target='_blank'>
                    <Text color="#00AEB3" fontSize="10px" variant="link">
                      Central de Ajuda
                    </Text>
                  </Link>
                </Stack>

                <Box pt="20px" mt="20px" borderTop="2px solid #B8B8B8">
                  {/* <Text fontSize="10px" color="#737373">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Aliquam sit amet ligula nec tellus malesuada luctus eu eget
                    felis. Suspendisse vitae sem tristique, ultrices est in,
                    condimentum nisl. Quisque quis accumsan nulla. Praesent
                    tincidunt, dui in auctor varius, ante ligula varius nibh,
                    sed maximus nulla nulla at justo. Maecenas scelerisque diam
                    aliquet orci ultrices dapibus. Curabitur sit amet magna
                    purus. Ut finibus gravida est id pellentesque. Curabitur
                    volutpat convallis pharetra. Mauris nunc purus, venenatis eu
                    metus vitae, auctor convallis neque. Morbi vel mollis eros.
                    Vestibulum ante ipsum primis in faucibus orci luctus et
                    ultrices posuere cubilia curae;
                  </Text> */}
                </Box>
              </>
            )}
          </ModalBody>
        </Flex>
      </ModalContent>
    </Modal>
  );
}
