import React from 'react';
import { Avatar, HStack, Input } from '@chakra-ui/react';

export interface BotsPlaceholderProps {
  number: number;
}

export default function BotsPlaceholder({ number }: BotsPlaceholderProps) {
  return (
    <HStack w="100%">
      <Avatar name={`B ${number + 1}`} />
      <Input
        bg="white"
        borderRadius={0}
        value={`Jogador não identificado ${number + 1}`}
        readOnly
      />
    </HStack>
  );
}
