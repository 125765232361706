import React, { useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  VStack,
  Icon,
  Text,
  Container,
  Input,
  Box,
} from '@chakra-ui/react';
import { IconType } from 'react-icons/lib';
import DashedContainer from 'src/components/Container/DashedContainer';

const labelStyle = {
  color: '#fff',
  fontSize: '12px',
};
interface PophausModalProps {
  isOpen: boolean;
  onClose: () => void;
  icon: IconType;
  iconColor?: string;
  colorScheme?: string;
  content?: string;
  overrideContent?: React.ReactNode;
  handleMainClick: () => void;
  mainButtonText?: string;
  secondaryButtonText?: string;
  loading?: boolean;
  dateReschedule: string;
  dateTicke: string;
  onChangeReschudule: any;
  onChangeTicket: any;
}

export function RescheduleModal({
  isOpen,
  onClose,
  icon,
  iconColor = '#F9E312',
  colorScheme = 'popGreen',
  content,
  overrideContent,
  handleMainClick,
  mainButtonText = 'REAGENDAR',
  secondaryButtonText,
  loading = false,
  dateReschedule,
  dateTicke,
  onChangeReschudule,
  onChangeTicket,
}: PophausModalProps) {
  return (
    <>
      <Modal onClose={onClose} isOpen={isOpen} isCentered size="xl">
        <ModalOverlay />
        <ModalContent>
          <DashedContainer borderColor={iconColor}>
            <ModalCloseButton
              size="sm"
              position="unset"
              alignSelf={'flex-end'}
              color={iconColor}
              border={'2px'}
              borderRadius={0}
              borderColor={iconColor}
            />
            <ModalBody>
              <VStack spacing={4} w="full">
                <Icon as={icon} w={24} h={24} color={iconColor} />
                {overrideContent ? (
                  overrideContent
                ) : (
                  <Container maxW="30ch">
                    <Text
                      variant="title"
                      color="white"
                      textAlign={'center'}
                      whiteSpace={'pre-line'}
                    >
                      {content}
                    </Text>
                    <Text
                      color="white"
                      textAlign="center"
                      whiteSpace="pre-line"
                    >
                      Todos os clientes selecionados receberão um e-mail para
                      reagendamento dos ingressos.
                    </Text>
                  </Container>
                )}
              </VStack>
            </ModalBody>
            <ModalFooter>
              <VStack spacing={4} w="full">
                <Box>
                  <label style={labelStyle}>
                    DATA LIMITE PARA REAGENDAMENTO
                  </label>
                  <Input
                    px={2}
                    bg="#fff"
                    color="#000"
                    size="md"
                    type="date"
                    value={dateReschedule}
                    onChange={onChangeReschudule}
                  />
                </Box>

                <Box>
                  <label style={labelStyle}>
                    DATA LIMITE PARA USO DO INGRESSO
                  </label>
                  <Input
                    px={2}
                    bg="#fff"
                    color="#000"
                    size="md"
                    type="date"
                    value={dateTicke}
                    onChange={onChangeTicket}
                  />
                </Box>

                <Button
                  w="full"
                  variant="pophausOutline"
                  bg={iconColor}
                  onClick={handleMainClick}
                  isLoading={loading}
                >
                  {mainButtonText}
                </Button>
                <Button
                  w="full"
                  variant="pophausOutline"
                  colorScheme={'popTransparent'}
                  onClick={onClose}
                  isLoading={loading}
                >
                  {secondaryButtonText}
                </Button>
              </VStack>
            </ModalFooter>
          </DashedContainer>
        </ModalContent>
      </Modal>
    </>
  );
}
