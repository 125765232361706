import { Progress, Stack, StackProps, Text } from '@chakra-ui/react';
import React from 'react';
import { FinancialReportResult } from 'src/api/interfaces/financialReport';
import { PaymentGroupResult } from 'src/api/interfaces/paymentGroupResult';
import { SalesPerDurationResult } from 'src/api/interfaces/salesPerDurationResult';
import DashedContainer from 'src/components/Container/DashedContainer';
import CapacityRate from './CapacityRate';
import PaymentType from './PaymentType';
import Reservations from './Reservations';
import SessionsDuration from './SessionsDuration';

interface InfoDashboardProps {
  report: FinancialReportResult | null;
  paymentGroups: PaymentGroupResult[] | null;
  salesPerDuration: SalesPerDurationResult[] | null;
  reportInterval: string[];
}

export default function InfoDashboard({
  report,
  paymentGroups,
  salesPerDuration,
  reportInterval,
  ...props
}: InfoDashboardProps) {
  const timeCount = {
    30: 32,
    60: 21,
    90: 10,
    120: 9,
    150: 5,
    180: 2,
  };

  return (
    <Stack {...props}>
      <Stack h="300px" direction="row" mt="40px">
        <PaymentType paymentGroups={paymentGroups} />
        <Reservations
          totalReservations={report ? report.bookingTotal : 0}
          avgReservationsValue={report ? report.bookingAverageTicket : 0}
          totalTickets={report ? report.bookingTotalTickets : 0}
          avgTicketsValue={report ? report.averageTicket : 0}
          totalInvoice={report ? report?.totalInvoice : 0}
        />
      </Stack>
      <Stack h="300px" direction="row">
        <CapacityRate
          avgTickets={
            report
              ? Math.round(
                  (report.bookingTotalTickets / report.bookingTotal) * 100
                ) / 100
              : 0
          }
          reportInterval={reportInterval}
          salesPerDuration={salesPerDuration}
          capacityRate={report ? report.capacityRate : 0}
        />
        <SessionsDuration
          timeCount={timeCount}
          salesPerDuration={salesPerDuration}
        />
      </Stack>
    </Stack>
  );
}
