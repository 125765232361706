import {
  Progress,
  Select,
  Stack,
  Text,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import {
  EventFormResult,
  GetFormEventsRequest,
  GetFormEventsResult,
} from 'src/api/interfaces/event';
import { getFormEvents, updateContacted } from 'src/api/services/event';
import PageContainer from 'src/components/Container/PageContainer';
import { Loading } from 'src/components/Loading';
import { PaginatedTable } from 'src/components/Table/PaginatedTable';
import usePHToast from 'src/hooks/useToast';

const EventsForms = () => {
  const [largerThan768] = useMediaQuery('(min-width: 768px)');
  const [loadingEventsForms, setLoadingEventsForms] = useState<boolean>(false);
  const [eventsForms, setEventsForms] = useState<GetFormEventsResult>({
    total: 0,
    list: [],
  });
  const [filter, setFilter] = useState<GetFormEventsRequest>({
    page: 0,
    limit: 10,
  });
  const toast = usePHToast();
  const columnHelper = createColumnHelper<EventFormResult>();
  const [loadingUpdateEventFormContacted, setLoadingUpdateEventFormContacted] =
    useState<number>(0);

  const getEventsForms = () => {
    setLoadingEventsForms(true);
    getFormEvents(filter)
      .then((res) => {
        setEventsForms(res);
      })
      .catch((err) => {
        toast({ status: 'error', title: err.response.data.errors.join('; ') });
      })
      .finally(() => setLoadingEventsForms(false));
  };

  useEffect(() => {
    getEventsForms();
  }, [filter]);

  const handleEventFormContactedChange = (
    formEventID: number,
    contacted: boolean
  ) => {
    setLoadingUpdateEventFormContacted(formEventID);
    updateContacted(formEventID, contacted)
      .then(() => {
        getEventsForms();
      })
      .catch((err) => {
        toast({ status: 'error', title: err.response.data.errors.join('; ') });
      })
      .finally(() => setLoadingUpdateEventFormContacted(0));
  };

  const tableColumns = [
    columnHelper.accessor('formEventID', {
      cell: (info) => (
        <Stack direction="row" alignItems="center">
          <Text variant="text">{info.getValue()}</Text>
        </Stack>
      ),
      header: () => <span>ID</span>,
    }),
    columnHelper.accessor('created', {
      cell: (info) => (
        <Stack direction="row" alignItems="center">
          <Text variant="text">
            {dayjs
              .utc(info.getValue())
              .tz('America/Sao_Paulo')
              .format('DD/MM/YYYY HH:mm')}
          </Text>
        </Stack>
      ),
      header: () => <span>Data criação</span>,
    }),
    columnHelper.accessor('name', {
      cell: (info) => (
        <Stack direction="row" alignItems="center">
          <Text variant="text">{info.getValue()}</Text>
        </Stack>
      ),
      header: () => <span>Nome</span>,
    }),
    columnHelper.accessor('lastName', {
      cell: (info) => (
        <Stack direction="row" alignItems="center">
          <Text variant="text">{info.getValue()}</Text>
        </Stack>
      ),
      header: () => <span>Sobrenome</span>,
    }),
    columnHelper.accessor('phone', {
      cell: (info) => (
        <Stack direction="row" alignItems="center">
          <Text variant="text">{info.getValue()}</Text>
        </Stack>
      ),
      header: () => <span>Telefone</span>,
    }),
    columnHelper.accessor('email', {
      cell: (info) => (
        <Stack direction="row" alignItems="center">
          <Text variant="text">{info.getValue()}</Text>
        </Stack>
      ),
      header: () => <span>E-mail</span>,
    }),
    columnHelper.accessor('contacted', {
      cell: (info) => (
        <Stack direction="row" alignItems="center">
          <Select
            value={info.getValue().toString()}
            variant="outline"
            textColor={info.getValue() ? 'black' : 'green'}
            _active={{ borderColor: 'green' }}
            _focus={{ borderColor: 'green' }}
            onChange={(e) =>
              handleEventFormContactedChange(
                info.row.getValue('formEventID'),
                [true, 'true'].includes(e.target.value)
              )
            }
            bgColor={info.getValue() ? 'green' : 'transparent'}
            disabled={
              loadingEventsForms ||
              loadingUpdateEventFormContacted ===
                info.row.getValue('formEventID')
            }
          >
            <option
              style={{ backgroundColor: '#1E1D20', color: '#B0CA0A' }}
              value={'false'}
            >
              Não
            </option>
            <option
              style={{ backgroundColor: '#1E1D20', color: '#B0CA0A' }}
              value={'true'}
            >
              Sim
            </option>
          </Select>
        </Stack>
      ),
      header: () => <span>Contatado</span>,
    }),
  ];

  const handlePageChange = (page: number) => {
    setEventsForms((prev) => ({ ...prev, list: [] }));
    setFilter((prev) => ({ ...prev, page }));
  };

  return (
    <PageContainer restricted>
      <Stack direction="column" pt={8} spacing={6} width="auto" margin="0 1rem">
        <Text variant={'title'} alignSelf={'flex-start'}>
          Leads de eventos
        </Text>
        <VStack>
          {loadingEventsForms && (
            <Progress w={'full'} isAnimated isIndeterminate bg="green" />
          )}
          <PaginatedTable
            columns={tableColumns}
            data={eventsForms?.list}
            pageSize={filter.limit}
            total={eventsForms?.total}
            page={filter.page}
            hasSort
            width={largerThan768 ? 'calc(100vw - 15rem)' : 'calc(100vw - 2rem)'}
            padding={2}
            overflowX="auto"
            onPageChange={handlePageChange}
          />
        </VStack>
      </Stack>
    </PageContainer>
  );
};

export default EventsForms;
