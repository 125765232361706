import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga'
import TikTokPixel from 'tiktok-pixel'
import PageContainer from 'src/components/Container/PageContainer';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  Icon,
  Stack,
  Text,
} from '@chakra-ui/react';
import CreateAccountForm from 'src/modules/Forms/CreateAccountForm';
import { RoomImage } from 'src/components/RoomImage';
import DashedContainer from 'src/components/Container/DashedContainer';
import { Summary } from 'src/components/Summary';
import { MdOutlineReceipt } from 'react-icons/md';
import { PlayerPurchaseSummary } from 'src/components/Summary/PlayerPurchaseSummary';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import {
  useGetUserActiveBooking,
  useGetUserBookingHistory,
} from 'src/api/services/booking';
import { BookingResult } from 'src/api/interfaces/booking';
import { BsExclamationTriangle } from 'react-icons/bs';
import { ActiveBookingCard } from './ActiveBookingCard';
import dayjs from 'dayjs';
import { Loading } from 'src/components/Loading';

export default function PlayerActiveTickets() {
  const { user } = useSelector((state: RootState) => state.user);
  const {
    data: activeBookings,
    isLoading: isActiveBookingsLoading,
    mutate,
  } = useGetUserActiveBooking(user?.userID);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    TikTokPixel.pageView()
  }, [])

  return (
    <PageContainer restricted presentGoBack>
      {isActiveBookingsLoading ? (
        <Loading />
      ) : (
        <Box w="100%">
          {activeBookings && activeBookings.length > 0 ? (
            activeBookings.map((item) => {
              let roomsFormat = '';
              for (let i = 0; i < item.bookingRooms.length; i++) {
                if (i < item.bookingRooms.length - 1) {
                  roomsFormat += `${item.bookingRooms[i].room?.description} e `;
                } else {
                  roomsFormat += `${item.bookingRooms[i].room?.description}`;
                }
              }
              return (
                <ActiveBookingCard
                  key={item.bookingID}
                  bookingID={item.bookingID}
                  multiple={item.bookingRooms.length > 1}
                  status={item.bookingStatus?.description}
                  time={
                    item.bookingRooms.length > 0
                      ? `${dayjs(item.bookingRooms[0].startTime).format(
                        'HH:mm'
                      )} ÀS ${dayjs(item.bookingRooms[0].endTime).format(
                        'HH:mm'
                      )}`
                      : '-'
                  }
                  date={dayjs(item.startDate).format('DD/MM/YYYY')}
                  unit={
                    item.bookingRooms.length > 0
                      ? item.bookingRooms[0].room?.businessUnit.description
                      : '-'
                  }
                  tickets={item.tickets}
                  players={item.bookingPlayers}
                  rooms={roomsFormat}
                  identifyPlayers={item.identifyPlayers}
                  mutate={mutate}
                />
              );
            })
          ) : (
            <DashedContainer>
              <HStack gap={3}>
                <Icon
                  w="36px"
                  h="36px"
                  color="yellow"
                  as={BsExclamationTriangle}
                />
                <Text color="white" textTransform="uppercase">
                  Não há reservas ativas para esse usuário.
                </Text>
              </HStack>
            </DashedContainer>
          )}
        </Box>
      )}
    </PageContainer>
  );
}
