import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga'
import TikTokPixel from 'tiktok-pixel'
import PageContainer from 'src/components/Container/PageContainer';
import { Box, HStack, Icon, Text } from '@chakra-ui/react';
import {
  BookingStatusEnum,
  useGetUserActiveBooking,
  useGetUserBookingHistory,
} from 'src/api/services/booking';
import { useAppSelector } from 'src/redux/hooks';
import DashedContainer from 'src/components/Container/DashedContainer';
import { BsExclamationTriangle } from 'react-icons/bs';
import { Loading } from 'src/components/Loading';
import { PaginatedTable } from 'src/components/Table/PaginatedTable';
import { useHistoryColumns } from './HistoryTableData';
import { useNavigate } from 'react-router-dom';
import { useActiveBookingsColumns } from './ActiveBookingsTableData';
import { BookingResult } from 'src/api/interfaces/booking';
// import { useGetUserAttachments } from 'src/api/services/match';

export default function PlayerHistory() {
  const { user } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  // const { data: medias } = useGetUserAttachments(user?.userID);
  const {
    data: activeBookings,
    isLoading: isBookingsLoading,
    mutate,
  } = useGetUserActiveBooking(user?.userID);
  const { data: bookingHistory, isLoading: isHistoryLoading } =
    useGetUserBookingHistory(user?.userID);
  const columns = useHistoryColumns();
  const activeBookingColumns = useActiveBookingsColumns(mutate);
  const [filteredBookingHistory, setFilteredBookingHistory] = useState<BookingResult[]>([])

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    TikTokPixel.pageView()
  }, [])

  useEffect(() => {
    if (bookingHistory)
      setFilteredBookingHistory(bookingHistory.filter(i => i.bookingStatusID !== BookingStatusEnum.EXPIRADA))
  }, bookingHistory)

  useEffect(() => {
    mutate()
  }, [activeBookings])

  return (
    <PageContainer restricted>
      {isBookingsLoading || isHistoryLoading ? (
        <Loading />
      ) : (
        <Box w="100%">
          <Text mb={6} variant="link" onClick={() => navigate(-1)}>
            voltar
          </Text>
          <Text mb="20px" variant="title">
            Ingressos Ativos
          </Text>
          <Box mb={12}>
            {activeBookings && activeBookings.length > 0 ? (
              <PaginatedTable
                columns={activeBookingColumns}
                data={activeBookings}
                hasSort
              />
            ) : (
              <DashedContainer>
                <HStack gap={3}>
                  <Icon
                    w="36px"
                    h="36px"
                    color="yellow"
                    as={BsExclamationTriangle}
                  />
                  <Text color="white" textTransform="uppercase">
                    Não há reservas ativas para esse usuário.
                  </Text>
                </HStack>
              </DashedContainer>
            )}
          </Box>
          <Text variant="title" color="white" mb="20px">
            Histórico de agendamentos
          </Text>
          <PaginatedTable columns={columns} data={filteredBookingHistory} hasSort />
        </Box>
      )}
    </PageContainer>
  );
}
