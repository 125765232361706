import React from 'react';
import {
  Flex,
  Stack,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
} from '@chakra-ui/react';
import { useAppSelector } from 'src/redux/hooks';
import { IoHomeOutline } from 'react-icons/io5';
import { ROLES } from 'src/redux/user';
import { NavItem } from 'src/components/Container/NavItem';
import { PLAYER_MENU_ITEMS } from '../../routes';

export const MobileSideItems = () => {
  const { user } = useAppSelector((state) => state.user);

  const userIsAdmin = user?.roles?.includes(ROLES.ADMIN);

  if (!user) return <></>;
  return (
    <Stack direction="row" align="center" spacing={6}>
      <Flex display={'inline-flex'} alignItems={'center'} zIndex="dropdown">
        <Menu>
          <MenuButton
            py={2}
            px={4}
            transition="all 0.3s"
            display={'inline-flex'}
            bg={'transparent'}
            rounded={'base'}
            _focus={{ boxShadow: 'none' }}
            _hover={{
              bg: 'green',
            }}
          >
            <Avatar
              name={user.name}
              border="2px"
              color="green"
              src={user.picture}
            />
          </MenuButton>
          <MenuList bg="black" borderLeft="1px" borderColor={'white'} py={6}>
            {userIsAdmin && (
              <NavItem
                icon={IoHomeOutline}
                url={'/admin/agenda'}
                py={2}
                px={4}
                fontSize={'sm'}
              >
                Ir para Admin
              </NavItem>
            )}
            {PLAYER_MENU_ITEMS.map((link) => (
              <NavItem
                key={link.name}
                icon={link.icon}
                url={link.url}
                py={2}
                px={4}
                fontSize={'sm'}
              >
                {link.name}
              </NavItem>
            ))}
          </MenuList>
        </Menu>
      </Flex>
    </Stack>
  );
};
