import React, { useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga';
import TikTokPixel from 'tiktok-pixel';
import { Center, Text, VStack } from '@chakra-ui/react';
import { FormAccordion } from 'src/components/Form/Accordion';
import { FormAccordionContent } from 'src/components/Form/Accordion/AccordionContent';
import PageContainer from 'src/components/Container/PageContainer';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Loading } from 'src/components/Loading';
import { getBusinessUnit } from 'src/api/services/businessUnit';
import { BusinessUnitResult } from 'src/api/interfaces/businessUnit';
import usePHToast from 'src/hooks/useToast';
import dayjs from 'dayjs';
import { useGetBookingByID } from 'src/api/services/booking';
import { RescheduleButtons } from 'src/components/Form/RescheduleButtons';
import RescheduleTicketForm, {
  RescheduleTicketFormValues,
} from 'src/modules/Forms/RescheduleForm';
import {
  getReschedulesForEvent,
  getScheduleForEvents,
  putUpdateEvent,
} from 'src/api/services/event';
import { EventRequest, EventResult } from 'src/api/interfaces/event';
import { ScheduleBlocksGroup } from 'src/components/Form/Event/ScheduleBlocksGroup';

export default function PlayerRescheduleTickets() {
  const { id } = useParams<{ id: string }>();
  const toast = usePHToast();
  const navigate = useNavigate();

  const {
    data: booking,
    isLoading: isBookingLoading,
    mutate,
  } = useGetBookingByID(id);

  useEffect(() => {
    if (id) mutate();
  }, [id]);

  const [loading, setLoading] = useState(false);
  const [rescheduleDate, setRescheduleDate] = useState<string>();
  const [rescheduleTickets, setRescheduleTickets] = useState<number>();
  const [rescheduleDuration, setRescheduleDuration] = useState<number>();
  const [rescheduleBuId, setRescheduleBuId] = useState<number>();
  const [schedulesResult, setSchedulesResult] = useState<EventResult[]>();

  const form = useForm<RescheduleTicketFormValues>({
    values: {
      date: booking?.startDate ?? dayjs().startOf('day').format('YYYY-MM-DD'),
      businessUnitID: booking?.bookingRooms[0]?.room?.businessUnitID ?? 1,
      numberOfPlayers: booking?.tickets ?? 0,
    },
  });

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    TikTokPixel.pageView();
  }, []);

  const handleSearch = async (data: RescheduleTicketFormValues) => {
    try {
      setLoading(true);
      if (!id) throw new Error('Reserva não identificada');
      if (!booking) throw new Error('Reserva não identificada');

      setRescheduleDate(data.date);
      setRescheduleDuration(booking.duration);
      setRescheduleTickets(data.numberOfPlayers);
      setRescheduleBuId(data.businessUnitID);
      const result = await getReschedulesForEvent(data.date, id);
      setSchedulesResult(result);
    } catch (e: any) {
      toast({ status: 'error', title: e?.message });
    } finally {
      setLoading(false);
    }
  };

  const handleContinue = async (st: string) => {
    try {
      setLoading(true);
      if (!id) throw new Error('Reserva não identificada');
      if (!booking) throw new Error('Reserva não identificada');
      if (!rescheduleDate) throw new Error('Data inválida');
      if (!rescheduleBuId) throw new Error('Unidade inválida');
      if (!rescheduleDuration) throw new Error('Duração inválida');
      if (!rescheduleTickets)
        throw new Error('Quantidade de ingressos inválida');

      const updateData: EventRequest = {
        date: rescheduleDate,
        businessUnitID: rescheduleBuId,
        duration: rescheduleDuration,
        tickets: rescheduleTickets,
        startTime: st,
      };

      const res = await putUpdateEvent(id, updateData);

      if (res) {
        toast({ status: 'success', title: 'Reserva reagenda com sucesso' });
        navigate('/');
      }
    } catch (e: any) {
      toast({ status: 'error', title: e?.message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageContainer restricted>
      <VStack w="full" spacing={2} align="flex-start" pt={12}>
        <RescheduleTicketForm form={form} onSubmit={handleSearch} />
        {(loading || isBookingLoading) && (
          <Center w="full">
            <Loading />
          </Center>
        )}
        {!loading && booking && (
          <>
            {schedulesResult && (
              <ScheduleBlocksGroup
                reschedule
                eventResult={schedulesResult}
                handleContinue={handleContinue}
                submitLoading={loading}
                duration={`${booking.duration / 2}`}
              />
            )}
          </>
        )}
      </VStack>
    </PageContainer>
  );
}
