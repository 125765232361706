import { ApiRepository, ApiResponse, useFetch } from 'src/api';
import {
  BookingNotificationResult,
  NotificationResult,
} from 'src/api/interfaces/notifications';

const api = new ApiRepository();

export function useGetUserBookingNotifications(UserID: string) {
  const args = UserID
    ? {
        method: 'get',
        url: `Alert/?UserID=${UserID}`,
      }
    : null;

  const { data, error, isLoading, mutate } = useFetch<
    ApiResponse<BookingNotificationResult[]>
  >(api, args, { revalidateOnFocus: false });
  return { data: data?.result, error, isLoading, mutate };
}

export async function updateNotificationStatus(
  NotificationID: number,
  Status: boolean
) {
  const res = await api.apiRequestWrapper<
    ApiResponse<BookingNotificationResult>
  >({
    method: 'put',
    url: `Alert/2/ChangeStatus?Active=false`,
  });
  return res.result;
}

export async function getNotifications() {
  const res = await api.apiRequestWrapper<ApiResponse<NotificationResult[]>>({
    method: 'get',
    url: 'Notification',
  });
  return res.result;
}
