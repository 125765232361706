import React, { useEffect, useState } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';
import { FormInput } from 'src/components/Form/Input';
import { FormSelect } from 'src/components/Form/Select';
import { Button, Stack, StackDirection } from '@chakra-ui/react';
import Form from 'src/components/Form/Form';
import { useGetBusinessUnits } from 'src/api/services/businessUnit';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { BookingActions } from 'src/redux/booking';
import { useAppSelector } from 'src/redux/hooks';
import { IoLocationOutline } from 'react-icons/io5';
import { FiCalendar, FiUsers } from 'react-icons/fi';

export interface RescheduleTicketFormValues {
  date: string;
  businessUnitID: number;
  numberOfPlayers: number;
}

interface RescheduleTicketFormProps {
  form: UseFormReturn<RescheduleTicketFormValues, any>;
  onSubmit: (data: RescheduleTicketFormValues) => Promise<void>;
}

export default function RescheduleTicketForm({
  form,
  onSubmit,
}: RescheduleTicketFormProps) {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = form;

  const { data: businessUnits, mutate } = useGetBusinessUnits();

  useEffect(() => {
    mutate();
  }, []);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction={['column', 'row']} w={'full'} spacing={6}>
        <FormInput
          rhfRegister={{
            ...register('date', {
              required: 'Campo obrigatório',
            }),
          }}
          errorState={errors.date}
          icon={FiCalendar}
          inputProps={{ placeholder: 'DATA', type: 'date' }}
        />
        <FormSelect
          rhfRegister={{
            ...register('businessUnitID', {
              required: 'Campo obrigatório',
              valueAsNumber: true,
            }),
          }}
          readonly
          errorState={errors.businessUnitID}
          selectProps={{ placeholder: 'SELECIONE A UNIDADE' }}
          icon={IoLocationOutline}
        >
          {businessUnits?.map((b) => (
            <option key={b.businessUnitID} value={b.businessUnitID}>
              {b.description}
            </option>
          ))}
        </FormSelect>
        <FormSelect
          rhfRegister={{
            ...register('numberOfPlayers', {
              required: 'Campo obrigatório',
              valueAsNumber: true,
            }),
          }}
          readonly
          errorState={errors.numberOfPlayers}
          selectProps={{ placeholder: 'SELECIONE OS PARTICIPANTES', w: '100%' }}
          icon={FiUsers}
        >
          {Array.from({ length: 27 }).map((_, i) => (
            <option key={i} value={i + 2}>
              {i + 2}
            </option>
          ))}
        </FormSelect>

        <Button type="submit" variant="pophausOutline">
          continuar
        </Button>
      </Stack>
    </Form>
  );
}
